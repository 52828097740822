import styled from 'styled-components'
import {primaryColor} from '../common/StyleConstants'

const MerchantCode = styled.div`
  color: ${primaryColor};
  font-size: inherit;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  word-break: break-all;
  ${props => props.enableWhiteSpace && ` 
      white-space: pre-wrap;
  `}
`
export {MerchantCode}
