
const removeState = (TOKEN_NAME) => {
  try {
    localStorage.removeItem(TOKEN_NAME)
    return true
  } catch (e) {
    return false
  }
}

export { removeState }
