// NPM and others
import _ from 'lodash'
import { get, post, put, remove } from '../../helpers'
import { push } from 'react-router-redux'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { encodeFilter } from '../../filter/EncodeFilter'
// Actions types

export const ADD_NEW_LOCATION = 'add_new_location'
export const ADD_NEW_LOCATION_SUCCESS = 'add_new_location_success'
export const ADD_NEW_LOCATION_FAIL = 'add_new_location_fail'

export const EDIT_LOCATION = 'edit_location'
export const EDIT_LOCATION_SUCCESS = 'edit_location_success'
export const EDIT_LOCATION_FAIL = 'edit_location_fail'

export const FETCH_LOCATIONS = 'fetch_locations'
export const FETCH_LOCATIONS_SUCCESS = 'fetch_locations_success'
export const FETCH_LOCATIONS_FAIL = 'fetch_locations_fail'

export const APPEND_LOCATIONS = 'append_locations'
export const APPEND_LOCATIONS_SUCCESS = 'append_locations_success'
export const APPEND_LOCATIONS_FAIL = 'append_locations_fail'

export const FETCH_SINGLE_LOCATION = 'fetch_location_by_id'
export const FETCH_SINGLE_LOCATION_SUCCESS = 'fetch_location_by_id_success'
export const FETCH_SINGLE_LOCATION_FAIL = 'fetch_location_by_id_fail'

export const DELETE_LOCATION = 'delete_location'
export const DELETE_LOCATION_SUCCESS = 'delete_location_success'
export const DELETE_LOCATION_FAIL = 'delete_location_fail'

export const FETCH_COUNTIES = 'fetch_counties'
export const FETCH_COUNTIES_SUCCESS = 'fetch_counties_success'
export const FETCH_COUNTIES_FAIL = 'fetch_counties_fail'

export const FETCH_COUNTIES_BY_COUNTRY = 'fetch_counties_by_country'
export const FETCH_COUNTIES_BY_COUNTRY_SUCCESS = 'fetch_counties_by_country_success'
export const FETCH_COUNTIES_BY_COUNTRY_FAIL = 'fetch_counties_by_country_fail'

export const FETCH_CITIES = 'fetch_cities'
export const FETCH_CITIES_SUCCESS = 'fetch_cities_success'
export const FETCH_CITIES_FAIL = 'fetch_cities_fail'

export const FETCH_COUNTRIES = 'fetch_countries'
export const FETCH_COUNTRIES_SUCCESS = 'fetch_countries_success'
export const FETCH_COUNTRIES_FAIL = 'fetch_countries_fail'

export const GET_ADDRESS_COORDINATES = 'get_address_coordinates'
export const GET_ADDRESS_COORDINATES_SUCCESS = 'get_address_coordinates_success'
export const GET_ADDRESS_COORDINATES_FAIL = 'get_address_coordinates_fail'

export const fetchLocations = companyId => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_LOCATIONS })
    let response
    let filterParams = encodeFilter(getState().filter.locations)
    let url = `/locations${filterParams}`
    if (companyId) {
      // filterParams = encodeFilter({
      //   ...getState().filter.locations,
      //   currentIndex: 1
      // })
      // url = `/locations?filterBy[0][0]=companyId&filterBy[0][1]==&filterBy[0][2]=${companyId}${filterParams}&orderBy[0][0]=cities.name&orderBy[0][1]=ASC&orderBy[1][0]=street&orderBy[1][1]=ASC`
      url = `/locations${filterParams}&orderBy[0][0]=cities.name&orderBy[0][1]=ASC&orderBy[1][0]=street&orderBy[1][1]=ASC&filterBy[2][0]=companyId&filterBy[2][1]==&filterBy[2][2]=${companyId}`
    }
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_LOCATIONS_SUCCESS,
        payload: _.get(response, 'data')
      })
      await dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'locations',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      await dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'locations',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_LOCATIONS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
export const FETCH_COUNTIES_MERCHANT = 'fetchcountiesmerchant'
export const FETCH_COUNTIES_LOCATION = 'fetch counties locations'
export const FETCH_COUNTIES_TERMINAL = 'fetch counties terminals'
export const FETCH_COUNTIES_CASHIER = 'fetch counties cashiers'
export const FETCH_COUNTIES_TRANSACTION = 'fetch counties transactions'
export const EMPTY_CITIES = 'empty_cities_array'

export const fetchCounties = (type, filter = '') => {
  return async dispatch => {
    dispatch({ type: FETCH_COUNTIES })
    let response
    try {
      response = await get({ url: `/locations/counties/all${filter}` })
      switch (type) {
        case 'merchant':
          dispatch({
            type: FETCH_COUNTIES_MERCHANT,
            payload: _.get(response, 'data')
          })
          break
        case 'location':
          dispatch({
            type: FETCH_COUNTIES_LOCATION,
            payload: _.get(response, 'data')
          })
          break
        case 'terminal':
          dispatch({
            type: FETCH_COUNTIES_TERMINAL,
            payload: _.get(response, 'data')
          })
          break
        case 'cashier':
          dispatch({
            type: FETCH_COUNTIES_CASHIER,
            payload: _.get(response, 'data')
          })
          break
        case 'transaction':
          dispatch({
            type: FETCH_COUNTIES_TRANSACTION,
            payload: _.get(response, 'data')
          })
          break
        default:
          dispatch({
            type: FETCH_COUNTIES_SUCCESS,
            payload: _.get(response, 'data')
          })
      }
    } catch (error) {
      dispatch({
        type: FETCH_COUNTIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchLocationById = locationId => {
  return async dispatch => {
    dispatch({ type: FETCH_SINGLE_LOCATION })
    let response
    try {
      response = await get({ url: `/locations/${locationId}` })
      dispatch({
        type: FETCH_SINGLE_LOCATION_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_LOCATION_FAIL,
        payload: _.get(error, 'response.data')
      })
      throw error
    }
  }
}

export const addNewLocation = (location, schedules) => {
  return async dispatch => {
    dispatch({ type: ADD_NEW_LOCATION })
    let response
    try {
      let params = {}
      params.location = location
      params.schedules = schedules
      response = await post({ url: `/locations/`, data: params })
      dispatch({
        type: ADD_NEW_LOCATION_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`/locations/confirmation/${response.data.id}`))
    } catch (error) {
      dispatch({
        type: ADD_NEW_LOCATION_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editLocation = (location, schedules, locationId) => {
  return async dispatch => {
    dispatch({ type: EDIT_LOCATION })
    let response
    try {
      let data = {}
      data.location = location
      data.schedules = schedules
      response = await put({ url: `/locations/${locationId}`, data })
      dispatch({
        type: EDIT_LOCATION_SUCCESS,
        payload: _.get(response, 'data')
      })
      // dispatch(push(`/locations/view/${response.data.id}`))
      await dispatch(fetchLocationById(locationId))
    } catch (error) {
      dispatch({
        type: EDIT_LOCATION_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
export const appendLocations = (companyId, page = 1, pageSize = 10) => {
  return async (dispatch, getState) => {
    dispatch({ type: APPEND_LOCATIONS })
    let response
    let filters = getState().filter.locations
    if (filters.pagination.page) {
      filters.pagination.page.value += 1
    }
    let filterParams = encodeFilter(filters)
    let url = `/locations${filterParams}`
    if (companyId) {
      // filterParams = encodeFilter({
      //   ...getState().filter.locations,
      //   currentIndex: 1
      // })
      url = `/locations?filterBy[0][0]=companyId&filterBy[0][1]==&filterBy[0][2]=${companyId}&${filterParams}&orderBy[0][0]=cities.name&orderBy[0][1]=ASC&orderBy[1][0]=street&orderBy[1][1]=ASC`
    }
    const data = { companyId }
    try {
      response = await get(
        {
          url,
          data
        }
      )
      dispatch({
        type: APPEND_LOCATIONS_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'locations',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'locations',
          name: 'page',
          value: page,
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: APPEND_LOCATIONS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const deleteLocation = locationId => {
  return async dispatch => {
    dispatch({ type: DELETE_LOCATION })
    try {
      await remove({ url: `/locations/${locationId}` })
      dispatch({
        type: DELETE_LOCATION_SUCCESS,
        payload: locationId
      })
    } catch (error) {
      dispatch({
        type: DELETE_LOCATION_FAIL,
        payload: locationId
      })
      throw error
    }
  }
}

export const fetchCitiesByCountyId = countyId => {
  return async dispatch => {
    let response
    try {
      response = await get({ url: `/locations/counties/${countyId}/cities` })
      dispatch({
        type: FETCH_CITIES_SUCCESS,
        payload: _.get(response, 'data.cities')
      })
    } catch (error) {
      dispatch({
        type: FETCH_CITIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const emptyCitiesArray = () => dispatch => {
  dispatch({
    type: EMPTY_CITIES
  })
}

export const fetchCountries = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COUNTRIES })
    let response
    try {
      response = await get({ url: `/locations/countries` })
      dispatch({
        type: FETCH_COUNTRIES_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_COUNTRIES_FAIL,
        payload: _.get(error, 'response.data')
      })
      throw error
    }
  }
}

export const fetchCountiesByCountry = (countryId) => {
  return async (dispatch) => {
    let response
    try {
      response = await get({ url: `/locations/counties/all?countryId=${countryId}` })
      dispatch({
        type: FETCH_COUNTIES_BY_COUNTRY_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_COUNTIES_BY_COUNTRY_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchCountryCities = (id, search = null) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_CITIES })
    let response
    try {
      response = await get({ url: `/locations/cities?countryId=${id}${search ? '&searchBy=' + search : ''}&pageSize=1000` })
      dispatch({
        type: FETCH_CITIES_SUCCESS,
        payload: _.get(response, 'data.cities')
      })
    } catch (error) {
      dispatch({
        type: FETCH_CITIES_FAIL,
        payload: _.get(error, 'response.data')
      })
      throw error
    }
  }
}

export const SET_COORDINATES = 'set_coordinates'

export const setCoordinates = (coordinates) => {
  return async dispatch => {
    dispatch({
      type: SET_COORDINATES,
      payload: coordinates
    })
  }
}
