
import { get, post, put } from '../../helpers'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { encodeFilter } from '../../filter/EncodeFilter'
import { RELEASES_ROUTE } from '../../../router/ClientRoutes'
import { push } from 'react-router-redux'
import _ from 'lodash'

export const FETCH_RELEASES = 'FETCH_RELEASES'
export const FETCH_RELEASES_SUCCESS = 'FETCH_RELEASES_SUCCESS'
export const FETCH_RELEASES_FAIL = 'FETCH_RELEASES_FAIL'

export const ADD_RELEASE = 'ADD_RELEASES'
export const ADD_RELEASE_SUCCESS = 'ADD_RELEASES_SUCCESS'
export const ADD_RELEASE_FAIL = 'ADD_RELEASES_FAIL'

export const APPEND_RELEASES = 'APPEND_RELEASE'
export const APPEND_RELEASES_SUCCESS = 'APPEND_RELEASE_SUCCESS'
export const APPEND_RELEASES_FAIL = 'APPEND_RELEASE_FAIL'

export const EDIT_RELEASE = 'EDIT_RELEASE'
export const EDIT_RELEASE_SUCCESS = 'EDIT_RELEASE_SUCCESS'
export const EDIT_RELEASE_FAIL = 'EDIT_RELEASE_FAIL'

export const FETCH_RELEASE = 'FETCH_RELEASE'
export const FETCH_RELEASE_SUCCESS = 'FETCH_RELEASE_SUCCESS'
export const FETCH_RELEASE_FAIL = 'FETCH_RELEASE_FAIL'

export const ASSIGN_RELEASE = 'ASSIGN_RELEASE'
export const ASSIGN_RELEASE_SUCCESS = 'ASSIGN_RELEASE_SUCCESS'
export const ASSIGN_RELEASE_FAIL = 'ASSIGN_RELEASE_FAIL'

export const fetchReleases = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_RELEASES })
    let response
    let filterParams = encodeFilter(getState().filter.release)
    let url = `/release${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_RELEASES_SUCCESS,
        payload: _.get(response, 'data.apkVersions')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'release',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'release',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_RELEASES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}
export const appendReleases = (page = 1, pageSize = 10) => {
  return async (dispatch, getState) => {
    dispatch({ type: APPEND_RELEASES })
    let response
    let filters = getState().filter.release
    filters.pagination.page.value += 1
    let filterParams = encodeFilter(filters)
    let url = `/release${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: APPEND_RELEASES_SUCCESS,
        payload: _.get(response, 'data.apkVersions')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'release',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'release',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: APPEND_RELEASES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const addRelease = (data) => {
  return async dispatch => {
    let response
    dispatch({
      type: ADD_RELEASE
    })
    try {
      response = await post({ url: '/release', data })
      dispatch({
        type: ADD_RELEASE_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`${RELEASES_ROUTE}/view/${response.data.id}`))
    } catch (error) {
      dispatch({
        type: ADD_RELEASE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const editRelease = (id, data) => {
  return async dispatch => {
    let response
    dispatch({
      type: EDIT_RELEASE
    })
    try {
      response = await put({ url: `/release/${id}`, data })
      dispatch({
        type: EDIT_RELEASE_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`${RELEASES_ROUTE}/view/${response.data.id}`))
    } catch (error) {
      dispatch({
        type: EDIT_RELEASE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const fetchRelease = (id) => {
  return async dispatch => {
    let response
    dispatch({
      type: FETCH_RELEASE
    })
    try {
      response = await get({ url: `/release/${id}` })
      dispatch({
        type: FETCH_RELEASE_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_RELEASE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const assignRelease = ({ rejected, release, ...data }) => async (dispatch) => {
  let response
  dispatch({ type: ASSIGN_RELEASE })
  try {
    response = await post({ url: `/release/${release}/batch-assign`, data })
    dispatch({
      type: ASSIGN_RELEASE_SUCCESS,
      payload: _.get(response, 'data')
    })
    let { rejectedCompanyIds, rejectedTerminalIds } = response.data

    if (rejectedTerminalIds.length === 0 && rejectedCompanyIds.length === 0) {
      dispatch(
        push(
          `/releases/success`
        )
      )
    } else {
      rejected([...rejectedCompanyIds, ...rejectedTerminalIds])
    }
  } catch (error) {
    dispatch({
      type: ASSIGN_RELEASE_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
  }
}
