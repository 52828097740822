// NPM and others

import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

// Common components

import { ErrorMessage } from './ErrorMessage'
import { secondaryColor, textColor, errorColor } from '../common/StyleConstants'

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  @media all and (max-width: 560px) {
    min-width: 100%;
    margin-top: 10px;
    padding-left: 24px;
  }
  ${props =>
    !props.error &&
    props.touched &&
    `
    &:after{
      color:${secondaryColor};
    }
  `} ${props =>
    ((props.error && props.touched) || props.serverError) &&
    `
    &:after{
      color:${errorColor};
    }
  `};
`
const InputFieldWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &:before {
    content: '';
    background-color: inherit;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    transform: translate(-50%);
    border-bottom: 1px solid ${errorColor};
    color: ${errorColor};
    transition: width 0.3s ease;
  }
  ${props =>
    ((props.error && props.touched) || props.serverError) &&
    `
      &:before{
        width: 100%;
      }
`};
`
const InputFieldStyle = styled.input`
  display:block;
  line-height: 34px;
  margin-left: 10px;
  flex: 1;
  padding-left:10px;
  margin:0;
  font-family:"Opensans";
  letter-spacing:0.075em;
  color: ${textColor};
  font-size: 14px;
  outline:none;
  border:none;
  text-aling: right;
  border-bottom:1px solid rgb(204,204,204);
  &[type='password']{
    font-size: 24px;
    letter-spacing: 8px;
    color: ${secondaryColor};
  }
  @media all and (max-width: 1023px){
    font-size: 12p;x
  }
`

const InputFieldWrapDisabled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &:before {
    content: '';
    background-color: inherit;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    transform: translate(-50%);
    color: ${errorColor};
    transition: width 0.3s ease;
  }
`
const InputFieldStyleDisabled = styled.input`
  display:block;
  line-height: 34px;
  margin-left: 10px;
  flex: 1;
  padding-left:10px;
  margin:0;
  font-family:"Opensans";
  letter-spacing:0.075em;
  color: ${textColor};
  font-size: 14px;
  outline:none;
  border:none;
  text-aling: right;
  &[type='password']{
    font-size: 24px;
    letter-spacing: 8px;
    color: ${secondaryColor};
  }
  @media all and (max-width: 1023px){
    font-size: 12p;x
  }
`

class CustomNumberInput extends React.Component {
  render() {
    const {
      required,
      wrapperStyle,
      input,
      meta: { error, touched },
      serverError,
      placeholder,
      currency,
      disabled
    } = this.props

    let actualInput
    if (disabled) {
      actualInput = (
        <InputFieldWrapDisabled>
          <InputFieldStyleDisabled
            {...input}
            style={{ textAlign: 'right' }}
            disabled
          />
          {currency ? <span style={{ width: '35px' }}>{currency}</span> : ''}
        </InputFieldWrapDisabled>
      )
    } else {
      actualInput = (
        <InputFieldWrap
          error={error}
          touched={touched}
          serverError={serverError}
          required={required}
        >
          <InputFieldStyle
            {...input}
            style={{ textAlign: 'right' }}
            placeholder={placeholder}
            disabled={this.props.disabled}
          />
          {currency ? <span style={{ width: '35px' }}>{currency}</span> : ''}
        </InputFieldWrap>
      )
    }

    return (
      <Translate>
        {({ translate }) => (
          <Wrapper
            required={required}
            style={wrapperStyle}
            error={error}
            touched={touched}
            serverError={serverError}
          >
            {actualInput}
            <ErrorMessage
              error={error}
              touched={touched}
              serverError={serverError}
            >
              {(error && translate(error)) || serverError}
            </ErrorMessage>
          </Wrapper>
        )}
      </Translate>
    )
  }
}
CustomNumberInput.propTypes = {
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  error: PropTypes.object
}

export default CustomNumberInput
