import axios from 'axios'

const deleteRequest = ({ url, data }) => {
  return axios({
    method: 'DELETE',
    url,
    data
  })
}
export default deleteRequest
