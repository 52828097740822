import React, {Component} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {LocationIcon} from '../index'

const Wrapper = styled.div`
`

class LocationCircle extends Component {
  onCityClick = (city) => { // eslint-disable-line
    this.props.cityClicked(city)
  }
  render () {
    return (
      <Wrapper className={this.props.className}>
        {this.props.cities.map((city, index) => <LocationIcon active key={index} onClick={() => this.onCityClick(city)}>{city.code}</LocationIcon>)}
      </Wrapper>
    )
  }
}

LocationCircle.propTypes = {
  cities: PropTypes.array,
  cityClicked: PropTypes.func
}
export {LocationCircle}
