import _ from 'lodash'
import moment from 'moment'
import get from '../../helpers/GetRequest'

export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS'
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS'
export const GET_ALL_TRANSACTIONS_FAIL = 'GET_ALL_TRANSACTIONS_FAIL'
export const FETCH_DASHBOARD_COMPANY = 'FETCH_DASHBOARD_COMPANY'
export const FETCH_DASHBOARD_COMPANY_SUCCESS = 'FETCH_DASHBOARD_COMPANY_SUCCESS'
export const FETCH_DASHBOARD_COMPANY_FAIL = 'FETCH_DASHBOARD_COMPANY_FAIL'
export const GET_TRANSACTIONS_FOR_SPECIFIC_WEEK = 'GET_TRANSACTIONS_FOR_SPECIFIC_WEEK'
export const GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_SUCCESS = 'GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_SUCCESS'
export const GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_FAIL = 'GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_FAIL'


export const fetchAllTransactions = () => {
  return async dispatch => {
    dispatch({ type: GET_ALL_TRANSACTIONS })
    let response
    let filterParams = `?fetchAll=true&orderBy[0][0]=created_at&orderBy[0][1]=ASC&filterBy[1][0]=created_at&filterBy[1][1]=>=&filterBy[1][2]=${moment()
      .subtract(30, 'days')
      .format(
        'YYYY-MM-DD'
      )}&filterBy[2][0]=status&filterBy[2][1]==&filterBy[2][2]=completed`
    let url = `/transactions${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: GET_ALL_TRANSACTIONS_SUCCESS,
        payload: _.get(response, 'data.transactions')
      })
    } catch (error) {
      dispatch({
        type: GET_ALL_TRANSACTIONS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const fetchTransactionsForSpecificWeek = (firstDayOfWeek, lastDayOfWeek, currency) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_TRANSACTIONS_FOR_SPECIFIC_WEEK })

    let companyType = '', companyFilter, company = {}, hasChildrenFilter = ''
    let role = getState().login.role
    if (role.code === 'ROLE_MERCHANT' || window.location.pathname.includes('/merchants/')) {
      company = getState().merchants.activeMerchant
    } else if (role.code === 'ROLE_VENDOR' || window.location.pathname.includes('/vendors/')) {
      company = getState().vendors.activeCompany
    }

    if (company && !_.isEmpty(company)) {
      companyType = company.type
      let companyId = company.id
      companyFilter = `&filterBy[3][0]=${companyType === 'merchant' ? 'merchantId' : 'vendorId'}&filterBy[3][1]==&filterBy[3][2]=${companyId}`
      if (company && company.type) {
        if (company.type === 'merchant') {
          hasChildrenFilter = `&filterBy[4][0]=hasChildren&filterBy[4][1]==&filterBy[4][2]=0`
        } else if (company.type === 'vendor') {
          hasChildrenFilter = `&filterBy[4][0]=parentId&filterBy[4][1]=is null`
        }
      }
    } else {
      hasChildrenFilter = `&filterBy[4][0]=hasChildren&filterBy[4][1]==&filterBy[4][2]=0`
    }

    let response, currencyFilter
    if (currency) {
      currencyFilter = `&filterBy[2][0]=currency&filterBy[2][1]==&filterBy[2][2]=${currency}`
    }
    let filterParams = `?filterBy[0][0]=completed_at&filterBy[0][1]=>=&filterBy[0][2]=${firstDayOfWeek}&filterBy[1][0]=completed_at&filterBy[1][1]=<=&filterBy[1][2]=${lastDayOfWeek}${currencyFilter ? currencyFilter : ''}${companyFilter ? companyFilter : ''}${hasChildrenFilter ? hasChildrenFilter : ''}`
    let url = `/transactions/grouped${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const fetchCompanyById = comapnyId => {
  return async dispatch => {
    dispatch({ type: FETCH_DASHBOARD_COMPANY })
    let response
    try {
      response = await get({ url: `/companies/${comapnyId}` })
      dispatch({
        type: FETCH_DASHBOARD_COMPANY_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_DASHBOARD_COMPANY_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}


