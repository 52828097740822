import {
  FETCH_LOGS,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAIL,
  FETCH_LOG_BY_ID,
  FETCH_LOG_BY_ID_SUCCESS,
  FETCH_LOG_BY_ID_FAIL, DELETE_LOG, DELETE_LOG_SUCCESS, DELETE_LOG_FAIL
} from './LogsActions'

const INITIAL_STATE = {
  logs: [],
  activeLog: {},
  error: {},
  loading: false,
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOGS:
      return {
        ...state,
        loading: true
      }
    case FETCH_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: action.payload
      }
    case FETCH_LOGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case FETCH_LOG_BY_ID:
      return {
        ...state,
        loading: true
      }
    case FETCH_LOG_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        activeLog: action.payload
      }
    case FETCH_LOG_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case DELETE_LOG:
      return { ...state, loading: true }
    case DELETE_LOG_SUCCESS:
      const newLogs = state.logs.filter((log) =>
        log.id !== action.payload)
      return { ...state, logs: newLogs, loading: false }
    case DELETE_LOG_FAIL:
      return { ...state, loading: false }
    default:
      return state
  }
}