import {
  ADD_NEW_LOCATION,
  ADD_NEW_LOCATION_SUCCESS,
  ADD_NEW_LOCATION_FAIL,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAIL,
  EDIT_LOCATION,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAIL,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAIL,
  FETCH_SINGLE_LOCATION,
  FETCH_SINGLE_LOCATION_FAIL,
  FETCH_SINGLE_LOCATION_SUCCESS,
  APPEND_LOCATIONS,
  APPEND_LOCATIONS_FAIL,
  APPEND_LOCATIONS_SUCCESS,
  FETCH_COUNTIES,
  FETCH_COUNTIES_SUCCESS,
  FETCH_COUNTIES_FAIL,
  FETCH_COUNTIES_LOCATION,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAIL,
  EMPTY_CITIES,
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAIL,
  FETCH_COUNTIES_BY_COUNTRY_SUCCESS,
  FETCH_COUNTIES_BY_COUNTRY_FAIL,
  SET_COORDINATES
} from './LocationActions';

const INITIAL_STATE = {
  locations: [],
  counties: [],
  activeLocation: null,
  error: {},
  filterCounties: [],
  loading: false,
  activeState: [],
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  Schedules: {},
  cities: [],
  countries: [],
  coordinates: {},
  listLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COUNTIES_LOCATION:
      return { ...state, filterCounties: action.payload };
    // APPEND LOCATIONS TO SELECT
    case APPEND_LOCATIONS:
      return { ...state, loading: true };
    case APPEND_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: [...state.locations, ...action.payload.locations],
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        },
        loading: false
      };
    case APPEND_LOCATIONS_FAIL:
      return { ...state, error: action.payload, loading: false };

    // EDIT LOCATION
    case EDIT_LOCATION:
      return { ...state, loading: true };
    case EDIT_LOCATION_SUCCESS:
      const newEditedLocationss = state.locations.map(location => {
        if (location.id === action.payload.id) {
          return action.payload;
        }
        return location;
      });
      return {
        ...state,
        locations: newEditedLocationss,
        loading: false,
        cities: []
      };
    case EDIT_LOCATION_FAIL:
      return { ...state, loading: false, error: action.payload };

    // DELETE LOCATION
    case DELETE_LOCATION:
      return { ...state, loading: true };
    case DELETE_LOCATION_SUCCESS:
      const newLocations = state.locations.filter(
        location => location.id !== action.payload
      );
      return { ...state, locations: newLocations, loading: false };
    case DELETE_LOCATION_FAIL:
      return { ...state, loading: false };

    case SET_COORDINATES:
      return { ...state, coordinates: action.payload };

    // FETCH ALL LOCATIONS
    case FETCH_LOCATIONS:
      return { ...state, loading: true, listLoading: true };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload.locations,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount
        },
        loading: false,
        listLoading: false
      };
    case FETCH_LOCATIONS_FAIL:
      return { ...state, error: action.payload, loading: false, listLoading: false };

    // ADD NEW LOCATION
    case ADD_NEW_LOCATION:
      return { ...state, loading: true };
    case ADD_NEW_LOCATION_SUCCESS:
      return { ...state, name: '', error: {}, loading: false };
    case ADD_NEW_LOCATION_FAIL:
      return { ...state, error: action.payload, loading: false };

    // FETCH LOCATION BY ID
    case FETCH_SINGLE_LOCATION:
      return { ...state, loading: true };
    case FETCH_SINGLE_LOCATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FETCH_SINGLE_LOCATION_SUCCESS:
      return { ...state, loading: false, activeLocation: action.payload };
    // FETCH LOCATION BY ID
    case FETCH_COUNTIES:
      return { ...state, loading: true };
    case FETCH_COUNTIES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FETCH_COUNTIES_SUCCESS:
      const countiesArray = action.payload.map(county => ({
        label: county.name,
        value: county.id,
        loading: false
      }));
      return { ...state, loading: false, counties: countiesArray };
    case FETCH_CITIES_SUCCESS:
      const citiesArray = action.payload.map(city => ({
        label: city.name,
        value: city.id,
        loading: false
      }));
      return { ...state, cities: citiesArray };
    case FETCH_CITIES_FAIL:
      return { ...state, cities: [], error: action.payload };
    case EMPTY_CITIES:
      return { ...state, cities: [] };
    case FETCH_COUNTRIES:
      return { ...state, countries: [] };
    case FETCH_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload, loading: false };
    case FETCH_COUNTRIES_FAIL:
      return { ...state, countries: [] };
    case FETCH_COUNTIES_BY_COUNTRY_SUCCESS:
      const newCountiesArray = action.payload.map(county => ({
        label: county.name,
        value: county.id
      }));
      return { ...state, counties: newCountiesArray };
    case FETCH_COUNTIES_BY_COUNTRY_FAIL:
      return { ...state, counties: [], error: action.payload };
    default:
      return state;
  }
};
