import { IMPERSONATE_USER, IMPERSONATE_USER_FAIL, IMPERSONATE_USER_SUCCESS } from '../../auth/login/LoginActions'
import {
  FETCH_DASHBOARD_COMPANY,
  FETCH_DASHBOARD_COMPANY_FAIL,
  FETCH_DASHBOARD_COMPANY_SUCCESS,
  GET_ALL_TRANSACTIONS,
  GET_ALL_TRANSACTIONS_FAIL,
  GET_ALL_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FOR_SPECIFIC_WEEK,
  GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_SUCCESS,
  GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_FAIL
} from './DashboardActions'

const INITIAL_STATE = {
  transactions: [],
  loading: false,
  activeState: [],
  myCompany: {},
  transactionsForSpecificWeek: [],
  transactionsForSpecificWeekLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMPERSONATE_USER:
      return {
        ...INITIAL_STATE,
        loading: true,
        error: '',
        transactions: [],
        activeState: [],
        transactionsForSpecificWeek: [],
        transactionsForSpecificWeekLoading: true
      }
    case IMPERSONATE_USER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case IMPERSONATE_USER_SUCCESS:
      return { ...state, loading: false, error: '' }
    case GET_ALL_TRANSACTIONS: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_ALL_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions: action.payload,
        loading: false
      }
    }
    case GET_ALL_TRANSACTIONS_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    }
    case GET_TRANSACTIONS_FOR_SPECIFIC_WEEK: {
      return {
        ...state,
        transactionsForSpecificWeekLoading: true
      }
    }
    case GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_SUCCESS: {
      return {
        ...state,
        transactionsForSpecificWeek: action.payload,
        transactionsForSpecificWeekLoading: false
      }
    }
    case GET_TRANSACTIONS_FOR_SPECIFIC_WEEK_FAIL: {
      return {
        ...state,
        error: action.payload,
        transactionsForSpecificWeekLoading: false
      }
    }
    case FETCH_DASHBOARD_COMPANY: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_DASHBOARD_COMPANY_SUCCESS: {
      return {
        ...state,
        myCompany: action.payload,
        loading: false
      }
    }
    case FETCH_DASHBOARD_COMPANY_FAIL: {
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    }
    default:
      return state
  }
}
