// NPM and others

import React, { Component } from 'react'
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'

// Common components

import LocationFilterSelect from '../LocationFilterSelect'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > .col--full {
    margin-bottom: 30px;
    flex-basis: 100%;
    max-width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media all and (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
    > p {
      margin-bottom: 10px;
    }
  }
`

class TableSort extends Component {
  render () {
    const { options, className, activeValues, onChange } = this.props
    return (
      <Translate>
        {({ translate }) => (
          <Wrapper className={className}>
            <SortWrapper>
              <LocationFilterSelect
                onChange={onChange}
                value={activeValues}
                placeholder={translate('counties')}
                options={options}
              />
            </SortWrapper>
          </Wrapper>
        )}
      </Translate>
    )
  }
}

export default TableSort
