const saveState = (stateName, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(stateName, serializedState)
    return true
  } catch (err) {
    return false
  }
}

export { saveState }
