import * as moment from 'moment';
import 'moment/min/locales';

export function getUserLocale() {
    let locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale)
    return locale
}

export function getLocaleDateFormat(date) {
    let locale = getUserLocale()
    moment.locale(locale)
    let newDate = moment(date).utc().format('L')
    return newDate
}

export function getLocaleDateFormatWithoutUTC(date) {
    let locale = getUserLocale()
    moment.locale(locale)
    let newDate = moment(date).format('L')
    return newDate
}


export function getLocaleDateFormatMinusOneDay(date) {
    let locale = getUserLocale()
    moment.locale(locale)
    let newDate = moment(date).utc().subtract(1, "days").format('L')
    return newDate
}

export function getLocaleDateFormatWithHour(date) {
    let newDate = getLocaleDateFormat(date)
    let time = moment(date).utc().format('LT')
    return `${newDate}, ${time}`
}

export function getLocaleDateFormatWithHourAndSeconds(date) {
    let newDate = getLocaleDateFormat(date)
    let time = moment(date).utc().format('LTS')
    return `${newDate}, ${time}`
}

export function getLocaleDateFormatWithHourWithoutUtc(date) {
    let newDate = getLocaleDateFormat(date)
    let time = moment(date).format('LT')
    return `${newDate}, ${time}`
}