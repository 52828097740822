import _ from 'lodash'
import { push } from 'react-router-redux'

import { API_URL_LIVE } from '../../../config/apiRoutes'
import { SAFE_DEPOSITS_ROUTE } from '../../../router/ClientRoutes'
import { encodeFilter } from '../../filter/EncodeFilter'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { get, post, put } from '../../helpers'

export const GET_SAFE_DEPOSITS_LIST = 'GET_SAFE_DEPOSITS_LIST'
export const GET_SAFE_DEPOSITS_LIST_SUCCESS = 'GET_SAFE_DEPOSITS_LIST_SUCCESS'
export const GET_SAFE_DEPOSITS_LIST_FAIL = 'GET_SAFE_DEPOSITS_LIST_FAIL'
export const GET_SAFE_DEPOSIT = 'GET_SAFE_DEPOSIT'
export const GET_SAFE_DEPOSIT_SUCCESS = 'GET_SAFE_DEPOSIT_SUCCESS'
export const GET_SAFE_DEPOSIT_FAIL = 'GET_SAFE_DEPOSIT_FAIL'
export const UPDATE_SAFE_DEPOSIT = 'UPDATE_SAFE_DEPOSIT'
export const UPDATE_SAFE_DEPOSIT_SUCCESS = 'UPDATE_SAFE_DEPOSIT_SUCCESS'
export const UPDATE_SAFE_DEPOSIT_FAIL = 'UPDATE_SAFE_DEPOSIT_FAIL'
export const ADD_SAFE_DEPOSIT = 'ADD_SAFE_DEPOSIT'
export const ADD_SAFE_DEPOSIT_SUCCESS = 'ADD_SAFE_DEPOSIT_SUCCESS'
export const ADD_SAFE_DEPOSIT_FAIL = 'ADD_SAFE_DEPOSIT_FAIL'
export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS'
export const GET_CURRENCIES_FAIL = 'GET_CURRENCIES_FAIL'

export const GET_STATUS_INTERVALS = 'GET_STATUS_INTERVALS'
export const GET_STATUS_INTERVALS_SUCCESS = 'GET_STATUS_INTERVALS_SUCCESS'
export const GET_STATUS_INTERVALS_FAIL = 'GET_STATUS_INTERVALS_FAIL'

export const CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY'
export const CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS'
export const CHECK_ELIGIBILITY_FAIL = 'CHECK_ELIGIBILITY_FAIL'

export const getSafeDepositsList = () => async (dispatch, getState) => {
  dispatch({ type: GET_SAFE_DEPOSITS_LIST })
  let response
  let filterParams = encodeFilter({
    ...getState().filter.safeDeposits,
    isSafeDeposits: true
  })
  let url = `/safe-deposits${filterParams}`
  try {
    response = await get({ url })
    dispatch({
      type: GET_SAFE_DEPOSITS_LIST_SUCCESS,
      payload: _.get(response, 'data'),
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'safeDeposits',
        name: 'pageCount',
        value: _.get(response, 'data.pagination.pageCount'),
        type: 'pagination',
        noFetch: true
      }
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'safeDeposits',
        name: 'page',
        value: _.get(response, 'data.pagination.page'),
        type: 'pagination',
        noFetch: true
      }
    })
  } catch (error) {
    dispatch({
      type: GET_SAFE_DEPOSITS_LIST_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
    throw error
  }
}

export const getSafeDeposit = id => {
  return async dispatch => {
    dispatch({ type: GET_SAFE_DEPOSIT })
    let response
    try {
      response = await get({ url: `/safe-deposits/${id}` })
      dispatch({
        type: GET_SAFE_DEPOSIT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GET_SAFE_DEPOSIT_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const addSafeDeposit = data => {
  return async dispatch => {
    dispatch({ type: ADD_SAFE_DEPOSIT })
    let response
    try {
      response = await post({ url: '/safe-deposits', data })
      dispatch({
        type: ADD_SAFE_DEPOSIT_SUCCESS,
        payload: _.get(response, 'data'),
      })
      const id = _.get(response, 'data.id')
      dispatch(push(`${SAFE_DEPOSITS_ROUTE}/confirmation/${id}`))
    } catch (error) {
      dispatch({
        type: ADD_SAFE_DEPOSIT_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}


export const updateSafeDeposit = (settlementId, data) => {
  return async dispatch => {
    dispatch({ type: UPDATE_SAFE_DEPOSIT })
    let response
    try {
      response = await put({
        url: `${API_URL_LIVE}/safe-deposits/${settlementId}`,
        data
      })
      dispatch({
        type: UPDATE_SAFE_DEPOSIT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: UPDATE_SAFE_DEPOSIT_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const getCurrencies = id => {
  return async dispatch => {
    let response
    dispatch({
      type: GET_CURRENCIES
    })
    try {
      response = await get({
        url: `${API_URL_LIVE}/companies/${id}`
      })
      dispatch({
        type: GET_CURRENCIES_SUCCESS,
        payload: _.get(response, 'data.currencies')
      })
    } catch (error) {
      dispatch({
        type: GET_CURRENCIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchSafeDepositStatusIntervals = (id) => {
  return async dispatch => {
    dispatch({ type: GET_STATUS_INTERVALS })
    let response
    try {
      response = await get({ url: `/safe-deposits/${id}/status-intervals` })
      dispatch({
        type: GET_STATUS_INTERVALS_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GET_STATUS_INTERVALS_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const checkEligibility = (companyId, currency) => {
  return async dispatch => {
    dispatch({ type: CHECK_ELIGIBILITY })
    let response
    try {
      response = await get({
        url: `/safe-deposits/check-eligibility?companyId=${companyId}&currency=${currency}`
      })
      dispatch({
        type: CHECK_ELIGIBILITY_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: CHECK_ELIGIBILITY_FAIL,
        payload: _.get(error, 'response.data')
      })
      throw error
    }
  }
}