import styled from 'styled-components'
import {primaryColor, secondaryColor} from '../StyleConstants'

const GradientButton = styled.button`
background: linear-gradient(to right, ${primaryColor},${secondaryColor});
cursor:pointer;
max-width:${props => props.width ? props.width : '200'}px;
width: 100%;
color:white;
height:50px;
outline:none;
border:none;
letter-spacing:0.24em;
font-size: 14px;
font-family: Opensans;
color: rgb(255, 255, 255);
font-weight: bold;
text-transform: uppercase;
line-height: 1.857;
transition: box-shadow .2s ease, text-shadow .2s ease;
&:hover{
  box-shadow: 5px 20px 50px 10px rgba(0,0,0,0.1);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
`

export {GradientButton}
