import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import styled from 'styled-components'
import { changeFilter } from './filter/FilterActions'

import asyncComponent from '../router/AsyncComponent'
import {
  CASHIERS_ROUTE,
  DASHBOARD_ROUTE,
  LOCATIONS_ROUTE,
  LOGIN_ROUTE,
  LOGS_ROUTE,
  MERCHANTS_ROUTE,
  PLATFORM_SETTINGS_ROUTE,
  RECEIPTS_ROUTE,
  RELEASES_ROUTE,
  REPORTS_ROUTE,
  ROLES_ROUTE,
  SAFE_DEPOSITS_ROUTE,
  SETTINGS_ROUTE,
  SETTLEMENTS_ROUTE,
  TECHNICIANS_ROUTE,
  TERMINALS_ROUTE,
  TRANSACTION_BUNDLES_ROUTE,
  TRANSACTIONS_ROUTE,
  UNAUTHORIZED_ROUTE,
  USERS_ROUTE,
  VENDORS_ROUTE,
  VIRTUAL_TERMINALS_ROUTE,
  PAYMENTS_ROUTE, IP_ROUTE,
} from '../router/ClientRoutes'
import UnauthorizedPage from '../router/UnauthorizedPage'
import { logOut, stopImpersonatingUser } from './auth/login/LoginActions'
import PrivateRoute from './helpers/PrivateRoute'
import { fetchMerchants } from './pages/merchants/MerchantsActions'
import { fetchVendors } from './pages/vendors/VendorActions'
import { fontNormal, Footer, Header, NavItem, primaryColor, secondaryColor, textColor } from './partials'
import LanguageSelect from './partials/header/LanguageSelect'
import Sidebar from './sidebar/Sidebar'
import { getAllLanguages } from '../modules/language/languageActions'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const UserName = styled.div`
  font-size: ${fontNormal};
  color: ${props => (props.nolink ? textColor : primaryColor)};
  font-weight: ${props => (props.nolink ? '400' : '600')};
`

const DashboardWap = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  height: 100%;
`

const DashboardContent = styled.main`
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 50px;
  > div:first-child {
    margin-bottom: 20px;
  }
  @media all and (max-width: 1200px) {
    padding: 24px 20px;
  }

  @media all and (max-width: 640px) {
    padding: 24px 10px;
  }

  @media all and (max-width: 420px) {
    padding: 16px 10px;
  }
`

const Impersonation = styled.span`
  cursor: pointer;
  color: rgb(159, 163, 167);

  &:hover {
    color: ${secondaryColor};
  }
`

const AsyncApk = asyncComponent(() => import('./pages/apk/ReleasesPage.js'))
const RolesPage = asyncComponent(() => import('./roles/RolePage'))
const UserPage = asyncComponent(() => import('./pages/users/UsersPage')) //eslint-disable-line
const SettlementsPage = asyncComponent(() =>
  import('./pages/settlements/SettlementsPage')
)
const SafeDepositsPage = asyncComponent(() =>
  import('./pages/safeDeposits/SafeDepositsPage')
)
const TechnicianPage = asyncComponent(() => import('./pages/technician/TechnicianPage'))
const SettingsPage = asyncComponent(() => import('./pages/settings/SettingsPage'))
const BundlesPage = asyncComponent(() => import('./pages/transactionBundles/BundlesPage'))
const AsyncCashiers = asyncComponent(() => import('./pages/cashiers/Cashiers')) //eslint-disable-line
const AsyncTerminals = asyncComponent(() =>
  import('./pages/terminals/Terminals')
) //eslint-disable-line
const AsyncVirtualTerminals = asyncComponent(() =>
  import('./pages/virtualTerminals/VirtualTerminals')
) //eslint-disable-line
const AsyncMerchants = asyncComponent(() =>
  import('./pages/merchants/Merchants')
) //eslint-disable-line
const AsyncTransactions = asyncComponent(() =>
  import('./pages/transactions/TransactionsPage')
) //eslint-disable-line
const AsyncClients = asyncComponent(() => import('./pages/clients/ClientPage')) //eslint-disable-line
const AsyncLocations = asyncComponent(() => import('./pages/locations/LocationsPage')) //eslint-disable-line
const AsyncIPs = asyncComponent(() => import('./pages/ip/IPs'))
const AsyncVendors = asyncComponent(() => import('./pages/vendors/VendorsPage')) //eslint-disable-line
const AsyncDashboard = asyncComponent(() => import('./pages/dashboard/DashboardPage'))
const AsyncLogs = asyncComponent(() => import('./pages/logs/LogsPage'))
const PlatformSettingsPage = asyncComponent(() =>
  import('./pages/platformSettings/PlatformSettingsPage')
)

const AsyncReports = asyncComponent(() => import('./pages/reports/Reports'))

const AsyncReceipts = asyncComponent(() => import('./pages/receipts/ReceiptsPage'))

const AsyncPayments = asyncComponent(() => import('./pages/payments/Payments'))

class HomePage extends Component {
  componentDidMount() {
    const { role, changeFilter, getAllLanguages } = this.props
    if (role.code !== 'ROLE_ADMIN') {
      // this.props.fetchVendors()
      changeFilter({
        fetchFunction: fetchVendors,
        type: 'filterBy',
        name: 'vendorType',
        page: 'vendors',
        value: 'vendor',
        operator: '=',
      })
      // this.props.fetchMerchants()
      changeFilter({
        fetchFunction: fetchMerchants,
        type: 'filterBy',
        name: 'merchantType',
        page: 'merchants',
        value: 'merchant',
        operator: '=',
      })
    }
    getAllLanguages()
  }

  componentDidUpdate = prevProps => {
    // const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code

    // const hasLanguageChanged = prevLangCode !== curLangCode

    localStorage.setItem('languageCode', curLangCode)


    // if (hasLanguageChanged) {
    //   localStorage.setItem('languageCode', curLangCode)
    // }
  }

  logout = () => {
    //eslint-disable-line
    this.props.logOut(this.props.header)
  }

  render() {
    const {
      user,
      permissions,
      location: routeLocation,
      companies,
      merchants,
      role,
      stopImpersonatingUser,
      loading
    } = this.props
    if (!permissions || Object.keys(permissions).length === 0) {
      this.props.history.push(LOGIN_ROUTE)
    }

    let companyId

    if (companies.length > 0) {
      for (let i = 0; i < companies.length; i++) {
        if (companies[i].userId === user.id) {
          companyId = companies[0].id
        }
      }
    } else if (merchants) {
      for (let i = 0; i < merchants.length; i++) {
        if (merchants[i] && merchants[i].userId === user.id) {
          companyId = merchants[0].id
        }
      }
    }

    return (
      <Translate>
        {({ translate }) => (
          <PageContent>
            <Header>
              {!loading && (
                <NavItem onlyText>
                  {this.props.user && (
                    <div style={{ display: 'flex' }}>
                      {translate('hello')}
                      {role.code !== 'ROLE_ADMIN' ? (
                        <Link to={`${VENDORS_ROUTE}/view/${companyId}`}>
                          <UserName>
                            {user && user.firstName
                              ? `,  ${user.firstName} ${user.lastName}`
                              : user.email}
                            !
                          </UserName>
                        </Link>
                      ) : (
                        <UserName nolink>
                          {user && user.firstName
                            ? `, ${user.firstName} ${user.lastName}`
                            : user.email}
                          !
                        </UserName>
                      )}
                    </div>
                  )}
                </NavItem>
              )}
              {!loading && user && user.isImpersonating && (
                <NavItem divider>
                  <Impersonation onClick={stopImpersonatingUser}>
                    {translate('stopImpersonation')}
                  </Impersonation>
                </NavItem>
              )}
              <NavItem divider>
                <LanguageSelect />
              </NavItem>
              <NavItem divider icon='alarm' iconSize={24} route='/'>
                {translate('notifications')}
              </NavItem>
              <NavItem
                divider
                onClick={this.logout}
                route={LOGIN_ROUTE}
                icon='power-switch'
                iconSize={24}
                style={{ marginRight: 0 }}
              >
                {translate('disconnect')}
              </NavItem>
              {/* <NavItem icon='magnifier' iconSize={18} /> */}
            </Header>
            <DashboardWap>
              {!loading && (
                <Sidebar location={routeLocation.pathname} permissions={permissions} />
              )}
              <DashboardContent>
                <div>
                  <PrivateRoute authenticated path={ROLES_ROUTE} component={RolesPage} />
                  <PrivateRoute
                    authenticated
                    path={PLATFORM_SETTINGS_ROUTE}
                    component={PlatformSettingsPage}
                  />
                  <PrivateRoute
                    authenticated
                    path={SETTLEMENTS_ROUTE}
                    component={SettlementsPage}
                  />
                  <PrivateRoute
                    authenticated
                    path={SAFE_DEPOSITS_ROUTE}
                    component={SafeDepositsPage}
                  />
                  <PrivateRoute
                    authenticated
                    path={TECHNICIANS_ROUTE}
                    component={TechnicianPage}
                  />
                  <PrivateRoute
                    authenticated
                    path={SETTINGS_ROUTE}
                    component={SettingsPage}
                  />
                  <PrivateRoute authenticated path={LOGS_ROUTE} component={AsyncLogs} />
                  <PrivateRoute
                    authenticated
                    path={TRANSACTION_BUNDLES_ROUTE}
                    component={BundlesPage}
                  />

                  <PrivateRoute authenticated path={USERS_ROUTE} component={UserPage} />
                  <PrivateRoute
                    authenticated
                    path={DASHBOARD_ROUTE}
                    component={AsyncDashboard}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${MERCHANTS_ROUTE}`}
                    component={AsyncMerchants}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${TERMINALS_ROUTE}`}
                    component={AsyncTerminals}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${VIRTUAL_TERMINALS_ROUTE}`}
                    component={AsyncVirtualTerminals}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${PAYMENTS_ROUTE}`}
                    component={AsyncPayments}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${TRANSACTIONS_ROUTE}`}
                    component={AsyncTransactions}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${CASHIERS_ROUTE}`}
                    component={AsyncCashiers}
                  />
                  {/* <Route path={`${CLIENTS_ROUTE}`} component={AsyncClients} /> */}
                  <PrivateRoute
                    authenticated
                    path={`${LOCATIONS_ROUTE}`}
                    component={AsyncLocations}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${IP_ROUTE}`}
                    component={AsyncIPs}
                  />
                  <PrivateRoute
                    authenticated
                    path={`${VENDORS_ROUTE}`}
                    component={AsyncVendors}
                  />
                  <PrivateRoute
                    authenticated
                    path={RELEASES_ROUTE}
                    component={AsyncApk}
                  />
                  <PrivateRoute
                    authenticated
                    path={REPORTS_ROUTE}
                    component={AsyncReports}
                  />
                  <PrivateRoute
                    authenticated
                    path={RECEIPTS_ROUTE}
                    component={AsyncReceipts}
                  />
                  <Route path={UNAUTHORIZED_ROUTE} component={UnauthorizedPage} />
                </div>
                <Footer />
              </DashboardContent>
            </DashboardWap>
          </PageContent>
        )}
      </Translate>
    )
  }
}

HomePage.propTypes = {
  logOut: PropTypes.func,
  header: PropTypes.string
}
const mapStateToProps = state => {
  return {
    header: state.login.header,
    user: state.login.user,
    role: state.login.role,
    permissions: state.login.permissions,
    companies: state.vendors.companies,
    merchants: state.merchants.merchants,
    loading: state.login.loading
  }
}

export default withLocalize(
  connect(
    mapStateToProps,
    { logOut, fetchMerchants, fetchVendors, stopImpersonatingUser, changeFilter, getAllLanguages }
  )(HomePage)
)
