import {
    FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS,
    FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_SUCCESS,
    FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_FAIL,
    ADD_ALLOCATION,
    ADD_ALLOCATION_SUCCESS,
    ADD_ALLOCATION_FAIL,
    SET_ALLOCATION_FIELD,
    FETCH_ALLOCATIONS_FOR_TRANSACTIONS,
    FETCH_ALLOCATIONS_FOR_TRANSACTIONS_SUCCESS,
    FETCH_ALLOCATIONS_FOR_TRANSACTIONS_FAIL,
    RETURN_INITIAL_STATE
} from './AllocationActions'

const INITIAL_STATE = {
    error: {},
    pagination: {},
    loading: false,
    availableTransactions: [],
    availableTransactionsAux: [],
    allocatedTransactionsAux: [],
    allocations: [],
    isCompleted: false,
    doNotAllocateSafeDepositsAndSettlements: false,
    totalAllocated: 0,
    totalUnallocated: 0,
    allocatedTransactions: [],
    // isValid: true
    isValid: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ALLOCATION_FIELD:
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }

        case FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS:
            return { ...state, loading: true }
        case FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                availableTransactions: action.payload,
                availableTransactionsAux: JSON.parse(JSON.stringify(action.payload)),
                // allocatedTransactions: [],
                // isValid: true,
                // totalAllocated: 0
            }
        case FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_FAIL:
            return { ...state, loading: false, error: action.payload }

        case ADD_ALLOCATION:
            return { ...state, loading: true }
        case ADD_ALLOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                allocatedTransactions: [],
                isValid: false,
                totalAllocated: 0,
                // availableTransactions: action.payload
            }
        case ADD_ALLOCATION_FAIL:
            return { ...state, loading: false, error: action.payload }

        case FETCH_ALLOCATIONS_FOR_TRANSACTIONS:
            return { ...state, loading: true }
        case FETCH_ALLOCATIONS_FOR_TRANSACTIONS_SUCCESS:
            let allocatedAmount = 0
            if (action.payload && action.payload.length > 0) {
                action.payload.forEach(item => {
                    // allocatedAmount += Math.abs(item.allocatedAmount)
                    allocatedAmount += item.allocatedAmount
                })
            }
            return {
                ...state,
                loading: false,
                allocatedTransactions: action.payload,
                totalAllocated: allocatedAmount
            }
        case FETCH_ALLOCATIONS_FOR_TRANSACTIONS_FAIL:
            return { ...state, loading: false, error: action.payload }

        case RETURN_INITIAL_STATE:
            return INITIAL_STATE

        default:
            return state
    }
}