// NPM and others

import createHistory from 'history/createBrowserHistory'
import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import ReduxThunk from 'redux-thunk'
import logger from 'redux-logger'
import { persistStore } from 'redux-persist'

// Needed configurations and tools

import rootReducer from './RootReducer'
import DevTools from './DevTools'
import RefreshTokenMiddleware from './RefreshTokenMiddleware'
import { loadToken, loadState } from '../local_storage'
import { setHeader } from '../modules/helpers'
import config from '../config'

export const history = createHistory()
const historyMiddleware = routerMiddleware(history)

export default () => {
  const token = loadToken(config.token_name)
  const permissions = loadState(config.toppay_permissions)
  const initialState = {
    login: {
      ...token.login,
      ...permissions
    }
  }
  if (token) {
    setHeader(token.login.header)
  }

  let store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(
        RefreshTokenMiddleware,
        historyMiddleware,
        ReduxThunk,
        logger
      ),
      DevTools.instrument()
    )
  )

  let persistor = persistStore(store)

  // return createStore(
  //   rootReducer,
  //   initialState,
  //   compose(
  //     applyMiddleware(RefreshTokenMiddleware, historyMiddleware, ReduxThunk, logger),
  //     DevTools.instrument()
  //   )
  // )

  return {
    store,
    persistor
  }
}
