import React, { Component } from 'react'
import asyncComponent from '../../router/AsyncComponent'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  LOGIN_ROUTE,
  REGISTER_ROUTE
} from '../../router/ClientRoutes'
import {
  UnderHeaderTitle,
  Header,
  Footer,
  UnderHeader,
  NavItem
} from '../partials'
import { Translate } from 'react-localize-redux'

const AsyncLogin = asyncComponent(() => import('./login/Login.js'))
const AsyncRegister = asyncComponent(() => import('./register/Register.js'))

class AuthPage extends Component {
  render() {
    const { pathname } = this.props.location
    const { url } = this.props.match
    const activeLogin = pathname === `${url}${LOGIN_ROUTE}`
    const activeRegister = pathname === `${url}${REGISTER_ROUTE}`
    return (
      <Translate>{({ translate }) =>
        <div className='authPage'>
          <Header>
            <NavItem active={activeLogin} route={`${url}${LOGIN_ROUTE}`} >{translate('loginLogo')}</NavItem>
            <NavItem active={activeRegister} route={`${url}${REGISTER_ROUTE}`}>{translate('registerLogo')}</NavItem>
            {/* <NavItem icon='magnifier' /> */}
          </Header>
          <UnderHeader>
            <UnderHeaderTitle>
              {activeLogin && translate('loginLogo')}
              {activeRegister && translate('registerLogo')}
            </UnderHeaderTitle>
          </UnderHeader>
          <Route exact path={`${url}${LOGIN_ROUTE}`} component={AsyncLogin} />
          <Route exact path={`${url}${REGISTER_ROUTE}`} component={AsyncRegister} />
          <Footer auth />
        </div>}
      </Translate>
    )
  }
}

AuthPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default AuthPage
