import styled from 'styled-components'

import { secondaryFont, textColor } from './StyleConstants'

const SectionItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 18px;
  color: ${textColor};
  border-bottom: 1px solid #F0F0F0;

  @media only screen and (max-width: 767px){
    padding: 18px 10px;
  }

  ${({ vendorForm }) => {
    let style = ''
    if (vendorForm === true) style = 'padding: 0;'
    return style
  }}

  ${({ autoWidth }) => autoWidth && `width: auto;`}

  ${({ end }) => end && `
    align-items: flex-end;
      @media only screen and (max-width: 767px){
        align-items: flex-start;
      }
  `}

  ${({ maxWidth }) => maxWidth && `max-width: 50%;`}

  ${({ flexWrap }) => flexWrap && `flex-wrap: wrap;`}

  ${({ overflowHidden }) => overflowHidden && `overflow: hidden;`}

  ${({ invoiceFields }) => invoiceFields && `padding-left: 0px;`}

  ${({ flexStart }) => flexStart && `align-items: flex-start;`}

  ${({ acceptedDate }) => acceptedDate && `width: auto;`}

  ${({ footer }) => footer && `background-color: rgba(0,0,0,.12);`}

  ${({ noBorder }) => noBorder && `border: none;`}

  ${({ noPaddingBottom }) => noPaddingBottom && `padding-bottom: 0;`}

  ${({ deleteIcon }) => deleteIcon && `align-items: center; justify-content: flex-end;`}

  ${props => props.enableWhiteSpace && ` 
    white-space: pre-wrap;
  `}

  ${props =>
    props.inline &&
    `
    flex-direction: row;
  `}

  ${props =>
    props.width30 &&
    `
    width: 30%;
    border-bottom: none;
    padding: 10px 16px;
  `}

  ${props =>
    props.alignCenter &&
    `
    align-items: center;
  `}

  span {
    font-family: "Opensans";
    color: #686F7F;
    margin-bottom: 8px;
    font-size: 12px;
    text-transform: uppercase;

    @media only screen and (max-width: 767px){
      font-size: 11px;
    }
  }
  
  h3, label {
    // font-family: ${secondaryFont};
    // color: rgba(0, 0, 0, 0.6);

    font-family: "Opensans";
    color: #686F7F;
    margin-bottom: 8px;
    font-size: 12px;
    text-transform: uppercase;

    & > i {
      margin-right: 4px;
    }
  }

  & > p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-family: "Opensans Semibold";
    color: #1B2640;
    font-weight: bold;
    word-break: break-word;

    ${({ checkbox }) => checkbox && `justify-content: flex-start; margin-top: 15px;`}

    @media only screen and (max-width: 767px){
      font-size: 12px;
    }

    ${({ total }) => total && `color: #135AFC;  font-family: "Opensans Bold";`}

    ${({ disableWordBreak }) => disableWordBreak && `word-break: keep-all;`}

    ${({ comment }) => comment && `
      color: #4C4C4C;  
      font-family: "Open Sans, Regular";
      font-weight: normal;
      line-height: 20px;
    `}

    ${({ valueStyle }) => {
      let style = ''
      if (valueStyle) style = valueStyle;
      return style
    }}
    
    ${({ lineHeight }) => lineHeight && `
      line-height: 20px;
    `}

    & > div {
      flex: 0;
      margin-left: auto;

      ${({ checkbox }) => checkbox && `margin-left: 0px;`}
    }

    & > span {
      display: contents;
    }

    ${({ txt }) => {
    let style = ''
    if (txt === false) style =
      'color:#1B2640B3; font-size: 16px; font-weight: normal; font-family: Opensans; font-weight: normal';
    return style
  }}

    }
    & > i {
      display: none;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;
      font-family: "Opensans Semibold";
      color: #1B2640;
      font-weight: bold;
      word-break: break-word;
      width: fit-content;
  
      @media only screen and (max-width: 767px){
        font-size: 12px;
      }

      :hover {
        opacity: 0.75;
      }
    }

    ${({ styles }) => {
      let style = ''
      if (styles) style = styles;
      return style
   }}
`

export { SectionItem }
