
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import getTranslationsForSelectedLanguage from './getTranslationsForSwalAlert';
import {
    HOME_ROUTE
} from '../router/ClientRoutes'

export function getMySwalSuccessfullPopup() {
    let currentLanguage = localStorage.getItem('languageCode')
    let translations = getTranslationsForSelectedLanguage(currentLanguage)
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: translations.successfullyChanged,
        confirmButtonText: 'Ok',
        type: 'success'
    });
}

export function getMySwalErrorPopup(err, errorType) {
    if (errorType === 'validation') {
        return
    }

    let currentLanguage = localStorage.getItem('languageCode')
    let translations = getTranslationsForSelectedLanguage(currentLanguage)
    const MySwal = withReactContent(Swal);
    let title = 'Oops...'

    if (err && err === 'fileNotFound') {
        err = translations.fileNotFound
    }

    MySwal.fire({
        title: title,
        text: err ? err : translations.something_went_wrong,
        confirmButtonText: 'Ok',
        type: 'error'
    });
}

export function getMySwalErrorPopupForValidation(err, errorType) {
    // if (errorType === 'validation') {
    //     return
    // }

    let currentLanguage = localStorage.getItem('languageCode')
    let translations = getTranslationsForSelectedLanguage(currentLanguage)
    const MySwal = withReactContent(Swal);
    let title = 'Oops...'

    if (err && err === 'fileNotFound') {
        err = translations.fileNotFound
    }

    MySwal.fire({
        title: title,
        text: err ? err : translations.something_went_wrong,
        confirmButtonText: 'Ok',
        type: 'error'
    });
}

export function getMySwalForbiddenPopup(history) {
    let currentLanguage = localStorage.getItem('languageCode')
    let translations = getTranslationsForSelectedLanguage(currentLanguage)
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        onOpen: () => {
            MySwal.clickConfirm();
        }
    }).then(() => {
        return MySwal.fire({
            title: translations.forbidden,
            text: translations.forbiddenText,
            confirmButtonText: 'Ok',
            type: 'error'
        }).then(() => {
            history && history.push(HOME_ROUTE)
        });
    });
}

export function getMySwalUnexistingElementPopup(msg, history) {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        onOpen: () => {
            MySwal.clickConfirm();
        }
    }).then(() => {
        return MySwal.fire({
            title: 'Oops...',
            text: msg,
            confirmButtonText: 'Ok',
            type: 'error'
        }).then(() => {
            history && history.push(HOME_ROUTE)
        });
    });
}

export function getMySwalWarningPopup(msg) {
    let currentLanguage = localStorage.getItem('languageCode')
    let translations = getTranslationsForSelectedLanguage(currentLanguage)
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        text: translations[msg],
        confirmButtonText: 'Ok',
        type: 'warning'
    });
}