import { push } from 'react-router-redux'
import { post, setHeader, get } from '../../helpers'
import {
  API_LOGIN_ROUTE,
  API_LOGOUT_ROUTE
} from '../../../config/apiRoutes'
import { saveToken, removeToken, saveState, removeState } from '../../../local_storage'
import config from '../../../config'

import {
  HOME_ROUTE
} from '../../../router/ClientRoutes'
import { fetchAllTransactions } from '../../pages/dashboard/DashboardActions'
import _ from 'lodash'
import { getPlatformCurrencies } from '../../partials/balances/BalancesActions'

export const LOGIN_SUCCESS = 'login_success'
export const LOGIN_FAIL = 'login_failed'
export const REFRESH_TOKEN_SUCCESS = 'done_refreshing_token_with_success'
export const REFRESH_TOKEN_FAIL = 'failed_refreshing_token'
export const REFRESHING_TOKEN = 'refreshing_token'
export const LOGOUT_SUCCESS = 'logout_success'
export const LOGOUT_FAIL = 'logout_fail'
export const GET_PERMISSIONS = 'get_permissions'
export const GET_PERMISSIONS_SUCCESS = 'get_permissions_success'
export const GET_PERMISSIONS_FAIL = 'get_permissions_fail'
export const IMPERSONATE_USER_SUCCESS = 'impersonat_user_success'
export const IMPERSONATE_USER = 'impersonat_user'
export const IMPERSONATE_USER_FAIL = 'impersonat_user_fail'

// login actions

export const logIn = (params) => {
  return async (dispatch) => {
    let response
    try {
      response = await post({
        url: API_LOGIN_ROUTE,
        data: {
          ...params,
          client_id: config.client_id,
          client_secret: config.client_secret,
          grant_type: config.grant_type_password
        }
      })
      const expires = ((response.data.expires_in - 10) * 1000) + Date.now()
      const payload = { ...response.data, expires_in: expires }
      saveToken(payload)
      setHeader(`${response.data.token_type} ${response.data.access_token}`)
      await savePermissions(dispatch)
      dispatch({
        type: LOGIN_SUCCESS,
        payload
      })
      dispatch(push(HOME_ROUTE))
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.default.msg) {
        dispatch({
          type: LOGIN_FAIL,
          payload: error.response.data.errors.default.msg
        })
      }
    }
  }
}

export const logOut = () => {
  return async (dispatch) => {
    let response
    try {
      response = await post({
        url: API_LOGOUT_ROUTE,
        data: {
          client_id: config.client_id,
          client_secret: config.client_secret
        }
      })
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: response
      })
      removeToken()
      removeState(config.toppay_permissions)
    } catch (error) {
      // dispatch({
      //   type: LOGOUT_FAIL,
      //   payload: error.response.data
      // })
      throw error
    }
  }
}


export const tokenExpiredlogOut = () => {
  removeToken()
  removeState(config.toppay_permissions)
  return async (dispatch) => {
    let response
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: response
    })
  }
}

export const savePermissions = async (dispatch) => {
  dispatch({ type: GET_PERMISSIONS })
  let response
  try {
    response = await get({
      url: '/users/user'
    })
    saveState(config.toppay_permissions, response.data)
    dispatch({ 
      type: GET_PERMISSIONS_SUCCESS, 
      // payload: response.data 
      payload: _.get(response, 'data')
    })
  } catch (error) {
    dispatch({ 
      type: GET_PERMISSIONS_FAIL, 
      // payload: error.response.data
      payload: _.get(error, 'response.data')
     })
  }
}

export const impersonateUser = (userId) => {
  return async dispatch => {
    let response
    try {
      response = await post({ url: `/impersonate-user`, data: { userId } })
      dispatch({
        type: IMPERSONATE_USER
      })
      dispatch(push(HOME_ROUTE))
      if (response) {
        try {
          let secondResponse = await get({ url: `/users/user` })
          saveState(config.toppay_permissions, secondResponse.data)
          dispatch({
            type: IMPERSONATE_USER_SUCCESS,
            payload: secondResponse.data
          })
        } catch (error) {
          dispatch({
            type: IMPERSONATE_USER_FAIL,
            // payload: error.response.data.errors
            payload: _.get(error, 'response.data.errors')
          })
        }
      }
    } catch (err) {
      dispatch({
        type: IMPERSONATE_USER_FAIL,
        // payload: err.response.data.errors
        payload: _.get(err, 'response.data.errors')
      })
    }
  }
}

export const stopImpersonatingUser = () => {
  return async dispatch => {
    let response
    try {
      response = await post({ url: `/disable-impersonate-user` })
      dispatch({
        type: IMPERSONATE_USER
      })
      dispatch(fetchAllTransactions())
      dispatch(push(HOME_ROUTE))
      dispatch(getPlatformCurrencies())

      if (response) {
        try {
          let secondResponse = await get({ url: `/users/user` })
          saveState(config.toppay_permissions, secondResponse.data)
          dispatch({
            type: IMPERSONATE_USER_SUCCESS,
            payload: secondResponse.data
          })
        } catch (error) {
          dispatch({
            type: IMPERSONATE_USER_FAIL,
            payload: error.response.data.errors
          })
        }
      }
    } catch (err) {
      dispatch({
        type: IMPERSONATE_USER_FAIL,
        payload: err.response.data.errors
      })
    }
  }
}
