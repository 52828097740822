// NPM and others

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

// Common components

import { Select } from '../index'
import { SortIcon } from '../common'

const Wrapper = styled.div`
display:flex;
flex:1;
align-items:center;
justify-content:space-between;
font-size: 14px;
font-family: "Opensans";
max-width:300px;
color:rgb(153, 153, 154);
`
const Option = styled.option`
font-weight:bold;
`
class SortBy extends React.Component {
  render () {
    const { sortBy, onSortClick } = this.props
    return (
      <Translate>{({ translate }) =>
        <Wrapper>
          <p style={{ marginRight: 20 }}>
            {translate('orderBy')}:
        </p>
          <Select valueChanged={(e) => onSortClick(['created_at', e.target.value])} value={sortBy[0] === 'created_at' && sortBy[1]}>
            <Option value='DESC'>
              {translate('newer')}
            </Option>
            <Option value='ASC'>
              {translate('older')}
            </Option>
          </Select>
          <SortIcon active={sortBy[0] === 'created_at'} up={sortBy[1] === 'ASC'} onSortClick={() => onSortClick(['created_at', sortBy[1] === 'ASC' ? 'DESC' : 'ASC'])} />
        </Wrapper>}
      </Translate>
    )
  }
}
SortBy.propTypes = {
  onSortClick: PropTypes.func.isRequired
}

export { SortBy }
