import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { primaryColor } from '../partials/common'

const ListTitleCollapsed = `
  margin-bottom: 15px;
  > a {
    justify-content: center;
  }
  @media all and (max-width: 560px){
    margin-bottom: 25px;
  }
`
const ListTitleWrapper = styled.div`
  a{
    display:flex;
    align-items:center;
    font-size: 18px;
    font-family: Opensans;
    line-height: 1.2;
    cursor:default;
    color:white;
    text-decoration:none;
    div{
      transition: all .2s ease;
    }
    p{
      letter-spacing: .45px;
      font-weight: ${props => props.active ? '600' : '400'};
      @media all and (max-height: 820px){
        font-size: 15px;
      }
      @media all and (max-height: 770px){
        font-size: 12px;
      }
    }
  }
  ${props => props.enabled && `
  a{
    cursor:pointer;
  }
  &:hover{
    div{
      background: white;
      color: ${primaryColor};
      transition: background .2s ease-in, color .2s ease-in;
    }
  }
  `}
  
 
  ${props => props.active &&
  `div{
      background: white;
      color: ${primaryColor};
      font-weight: 700;
      transition: background .2s ease-in, color .2s ease-in;

    }
  `}
  ${props => props.collapsed ? ListTitleCollapsed : ''}
  `
const TitleIconCollapsed = `
    margin-right: 0;
`
const TitleIcon = styled.div`
  font-size:24px;
  border-radius:50px;
  padding:10px 12px;
  margin-right:10px;
  border:1px solid transparent;
  @media all and (max-width: 1023px){
    font-size: 18px;
  }
  ${props => props.collapsed ? TitleIconCollapsed : ''}
  @media all and (max-width: 560px){
    font-size: 16px;
    padding: 6px 8px;
  }
  @media all and (max-height: 820px){
    font-size: 18px;
  }
  @media all and (max-height: 770px){
    font-size: 15px;
  }
`

class ListTitle extends Component {
  render () {
    const { hidden, icon, text, route, active, enabled, iconItem} = this.props
    return (
      <ListTitleWrapper collapsed={hidden} active={active} enabled={enabled}>
        <Link to={enabled ? route : '#'}>
          <TitleIcon collapsed={hidden}>
            {
              iconItem ? iconItem : <span className={`lnr lnr-${icon}`}/>
            }
          </TitleIcon>
          {!hidden &&
          <p>
            {text}
          </p>
          }
        </Link>

      </ListTitleWrapper>
    )
  }
}

ListTitle.propTypes = {
  icon: PropTypes.string,
  route: PropTypes.string,
  text: PropTypes.string,
  hidden: PropTypes.bool
}

export default ListTitle
