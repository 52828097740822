import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { primaryColor, secondaryColor } from './StyleConstants';

const SelectWrapper = styled.div`
display:block;
position:relative;
flex:1;
cursor: pointer;
span{
  position:absolute;
  top:50%;
  right:10px;
  font-size:12px;
  font-weight:bold;
  color:${primaryColor};
  pointer-events:none;
  transform: translateY(-50%);
}
${props =>
  props.noBorder ? 'border: none;' : 'border:1px solid  rgb(221, 221, 221);'}
${props => props.maxWidth && `max-width: ${props.maxWidth}px !important;`}
${props =>
  props.extensionsSelect &&
  `
  flex: none;
  width: 50px;

  span {
    right: 0;
  }
`}
select {
  border:none;
}
`;
const SelectInput = styled.select`
  width: 100%;
  cursor: pointer;
  min-width: 70px;
  padding-left: 10px;
  appearance: none;
  border: 1px solid rgb(221, 221, 221);
  color: ${primaryColor};
  box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  ${props =>
    props.extensionsSelect &&
    `
  box-shadow: none;
  border: none;
  min-width: auto;
  width: 100%;  
  color: ${secondaryColor};
`} ${props =>
    props.autoHeight ? 'height: auto;' : 'height:30px;'}
font-weight:bold;
  outline: none;
  @media all and (max-width: 400px) {
    min-width: 60px;
    font-size: 10px;
  }
`;

class Select extends React.Component {
  handleValueChange = e => {
    //eslint-disable-line
    this.props.valueChanged(e);
  };
  render() {
    const { noBorder, maxWidth, extensionsSelect } = this.props;
    return (
      <SelectWrapper
        noBorder={noBorder}
        maxWidth={maxWidth}
        extensionsSelect={extensionsSelect}
      >
        <SelectInput onChange={this.handleValueChange} {...this.props}>
          {this.props.children}
        </SelectInput>
        <span className="lnr lnr-chevron-down" style={{ fontSize: '9px' }} />
      </SelectWrapper>
    );
  }
}

Select.propTypes = {
  valueChanged: PropTypes.func.isRequired,
  children: PropTypes.node
};

export { Select };
