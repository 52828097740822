export const mainText = '#9fa3a7'
export const headerTexColor = 'rgb(159, 163, 167)'
export const addIconColor = '#00ACDE'

export const primaryColor = '#0151ff'
export const primaryColorDarker = '#0049E8'
export const secondaryColor = '#0086fe'
export const secondaryColorRgba = 'rgba(0, 134, 254, 0)'
export const secondaryColorDarker = '#0070D4'
export const textColor = '#4c4c4c'
export const secondaryTextColor = '#b7babd'

export const primaryFont = 'Opensans'
export const secondaryFont = 'Opensans'
export const inactiveColor = '#a9ccff'
export const inactiveColorBackground = 'rgba(169, 204, 255, .3)'
export const criticalColor = '#fd3535'
export const errorColor = '#fe9250'
export const infoColor = '#c0c0c0'
export const goGreen = '#3dc456'
export const warningColor = '#feee00'
export const errorColorDarker = '#CC5C41'

export const pending = 'orange'

export const colorSecondary = '#00b9a1'
export const colorSecondaryDarker = '#006d5e'

export const colorWhite = '#ffffff'
export const colorBlack = '#000000'

export const bigPadding = '24px'
export const mediumPadding = '16px'
export const smallPadding = '8px'

export const fontSmall = '10px'
export const fontMedium = '12px'
export const fontNormal = '15px'
export const fontLarge = '16px'
export const fontExtraLarge = '18px'

export const completedStatus = '#0086FC'
export const pendingStatus = '#F8AD63'
export const canceledStatus = '#E61515'
export const acceptedStatus = '#FBCC5E'