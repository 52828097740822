import styled from 'styled-components'
import { primaryColor } from '../StyleConstants'
import { errorColor, errorColorDarker } from '../../index'

const SubmitButton = styled.button`
  display: inline-block;
  padding: 15px 15px;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  opacity: 1;
  border: none;
  background-color: ${props => props.color ? props.color : primaryColor};
  ${props => props.border ? `border: 1px solid ${props.color}; background-color: transparent; color: ${props.color};` : ''}
  border-radius: 100em;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    opacity: .8;
    transition: opacity .2 ease-in;
  }
  
  & > .lnr {
    margin-right: 8px;
  }

  ${props => props.settlement && `
    margin-top: 10px;
    padding: 0;
    height: 37px;
    color: #FFFFFF;
    font-family: "Opensans Semibold";
    font-size: 14px;

    & i {
      margin-left: 10px;
    }
  `}

  ${props => props.flex && `
  display: flex;
  justify-content: center;
  align-items: center;
  `}

  ${props => props.commissionSaveButton && `
  width: 90%;
  height: 37px;
  padding: 0;
  text-transform: capitalize;
  font-family: "Opensans Semibold";

  & i {
    margin-left: 10px;
  }
  `}

  ${props => props.commissionAddButton && `
    width: 100%;
    height: 30px;
    padding: 0;
    text-transform: capitalize;
    font-family: "Opensans Semibold";
    font-size: 14px;

    & i {
      margin-left: 10px;
      font-size: 17px;
    }
  `}
  
  ${props => props.disabled && `
  cursor:not-allowed;
  background-color: #D8D8D8;
  `}
  ${props => props.cancel && `
      &:before{
          content: 'e880';
          font-family: 'Linearicons-Free';
      }
      color:${errorColor};
      &:hover{
      color: ${errorColorDarker};
      }
  
  `}
  ${props => props.small && `
  
      font-size:35px;
      width:35px;
     
  `}
  
  @media all and (max-width: 1023px) {
    font-size: 16px;
  }
`

export { SubmitButton }
