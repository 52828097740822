import styled from 'styled-components'

const Section = styled.div`
  font-family: "Opensans";
  font-size:16px;
  background-color: #fafafa;
  border-radius: 8px;
  margin-bottom: 48px;
  
 ${props => props.margin && `
    margin-bottom: ${props.margin}px;
  `}

  &:last-child {
    margin-bottom: 0;
  }

  ${props => props.checkbox && `
    margin-bottom: 20px;
    background-color: #ffffff;
  `}
`
export { Section }
