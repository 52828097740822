// NPM and others
import _ from 'lodash'
import moment from 'moment'

import { isEmpty } from '../helpers/validation/is-empty'


// Common component

export const encodeFilter = ({
  filterBy,
  orderBy,
  searchBy,
  currentIndex,
  pagination,
  isSettlement,
  isSafeDeposit,
  isBundles,
},
) => {
  const {
    parentId,
    amount,
    status,
    clientType,
    startDate,
    endDate,
    settlementId,
    safeDepositId,
    transactionBundleId,
    merchantType,
    vendorType,
    isCustom,
    errorSource,
    isLocationManager,
    isVirtualTerminal,
    companyType,
    lastPinged,
    is_internal,
    hasChildren,
    locationId,
    isVIP,
    installedApkVersionId,
    appendId,
    currency,
    company,
    entryPeriod,
    datePeriod,
    paymentType,
    transactionType,
    bankAccountId,
    minPaymentAmount,
    maxPaymentAmount,
    positive,
    excludeTypes,
    currencyQuery,
    companyQuery,
    idQuery,
    typeQuery
  } = filterBy

  const counties = filterBy['counties.code']
  let index = currentIndex || 0

  let encodedFilter
  encodedFilter = '?pageCount=10'

  if (currentIndex) {
    // encodedFilter = '?pageCount=10'
  }

  if (positive) {
    encodedFilter = '?'
  }

  if (filterBy && filterBy.settlementId && filterBy.parentId) {
    delete filterBy.parentId
  }

  if (pagination && pagination.pageSize) {
    encodedFilter += `&pageSize=${pagination.pageSize.value}`
  }

  if (filterBy && filterBy.role && filterBy.role.value) {
    encodedFilter += `&filterBy[${index}][0]=roles.id&filterBy[${index}][1]=${filterBy.role.operator}&filterBy[${index}][2]=${filterBy.role.value}`
    index++
  }

  if (installedApkVersionId && installedApkVersionId.value && installedApkVersionId.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=installedApkVersionId&filterBy[${index}][1]=${filterBy.installedApkVersionId.operator}&filterBy[${index}][2]=${filterBy.installedApkVersionId.value}`
    index++
  }


  if (companyType) {
    encodedFilter = ''
    if (companyType === 'vendor') {
      encodedFilter = `&filterBy[${index}][0]=vendorSettlementId&filterBy[${index}][1]==&filterBy[${index}][2]=${settlementId}`
    }
    if (companyType === 'merchant') {
      encodedFilter = `&filterBy[${index}][0]=merchantSettlementId&filterBy[${index}][1]==&filterBy[${index}][2]=${settlementId}`
    }
    index++
  }

  if (pagination && pagination.page && pagination.page.value) {
    encodedFilter += `&page=${pagination.page.value}`
  }
  if (searchBy && searchBy.value && searchBy.value !== '') {
    encodedFilter += `&searchBy=${searchBy.value}`
  }
  if (counties && counties.value) {
    counties.value.forEach(item => {
      encodedFilter += `&filterBy[${index}][0]=counties.code&filterBy[${index}][1]==&filterBy[${index}][2]=${item.value
        }`
      index++
    })
  }
  if (orderBy && orderBy.value) {
    encodedFilter += `&orderBy[${index}][0]=${orderBy.name
      }&orderBy[${index}][1]=${orderBy.value}`
    index++
  }
  if (parentId) {
    encodedFilter += `&filterBy[${index}][0]=parentId&filterBy[${index}][1]=${parentId.operator}&filterBy[${index}][2]=${parentId.value}`
    index++
  }
  if (hasChildren) {
    encodedFilter += `&filterBy[${index}][0]=hasChildren&filterBy[${index}][1]=${hasChildren.operator}&filterBy[${index}][2]=${hasChildren.value}`
    index++
  }
  if (clientType && clientType.value && clientType.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=companies.type&filterBy[${index}][1]==&filterBy[${index}][2]=${clientType.value
      }`
    index++
  }

  if (appendId && appendId.value) {
    encodedFilter += `&filterBy[${index}][0]=id&filterBy[${index}][1]=${appendId.operator}&filterBy[${index}][2]=${appendId.value}`
    index++
  }

  if (amount && amount.value) {
    if (isSettlement || isSafeDeposit) {
      encodedFilter += `&filterBy[${index}][0]=finalAmount&filterBy[${index}][1]=>=&filterBy[${index}][2]=${amount.value[0]
        }`
      index++
      encodedFilter += `&filterBy[${index}][0]=finalAmount&filterBy[${index}][1]=<=&filterBy[${index}][2]=${amount.value[1]
        }`
      index++
    } else if (isBundles) {
      encodedFilter += `&filterBy[${index}][0]=totalAmount&filterBy[${index}][1]=>=&filterBy[${index}][2]=${amount.value[0]
        }`
      index++
      encodedFilter += `&filterBy[${index}][0]=totalAmount&filterBy[${index}][1]=<=&filterBy[${index}][2]=${amount.value[1]
        }`
      index++
    } else {
      encodedFilter += `&filterBy[${index}][0]=amount&filterBy[${index}][1]=>=&filterBy[${index}][2]=${amount.value[0]
        }`
      index++
      encodedFilter += `&filterBy[${index}][0]=amount&filterBy[${index}][1]=<=&filterBy[${index}][2]=${amount.value[1]
        }`
      index++
    }
  }
  if (startDate && startDate.value) {
    // encodedFilter += `&filterBy[${index}][0]=created_at&filterBy[${index}][1]=>=&filterBy[${index}][2]=${moment(
    //   startDate.value
    // ).format('YYYY-MM-DD')}`
    // index++
    encodedFilter += `&filterBy[${index}][0]=${orderBy && orderBy.name ? orderBy.name : 'created_at'}&filterBy[${index}][1]=>=&filterBy[${index}][2]=${moment(
      startDate.value
    ).format('YYYY-MM-DD')}`
    index++
  }
  if (endDate && endDate.value) {
    // let date = _.cloneDeep(endDate.value)
    // encodedFilter += `&filterBy[${index}][0]=created_at&filterBy[${index}][1]=<=&filterBy[${index}][2]=${moment(
    //   date
    // )
    //   .add(1, 'days')
    //   .format('YYYY-MM-DD')}`
    // index++
    let date = _.cloneDeep(endDate.value)
    encodedFilter += `&filterBy[${index}][0]=${orderBy && orderBy.name ? orderBy.name : 'created_at'}&filterBy[${index}][1]=<=&filterBy[${index}][2]=${moment(
      date
    )
      .add(1, 'days')
      .format('YYYY-MM-DD')}`
    index++
  }
  if (status && status.value && status.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=status&filterBy[${index}][1]==&filterBy[${index}][2]=${status.value
      }`
    index++
  }

  if (isVIP && isVIP.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=isVIP&filterBy[${index}][1]==&filterBy[${index}][2]=${isVIP.value
      }`
    index++
  }

  if (lastPinged && lastPinged.value && lastPinged.value !== 'all') {
    if (lastPinged.value === 'active') {
      encodedFilter += `&filterBy[${index}][0]=lastPinged&filterBy[${index}][1]=>=&filterBy[${index}][2]=${moment().subtract(1, 'minute').utc().format('YYYY-MM-DD HH:mm:ss')}
        `
    } else if (lastPinged.value === 'pending') {
      encodedFilter += `&filterBy[${index}][0]=lastPinged&filterBy[${index}][1]=%3C&filterBy[${index}][2]=${moment().subtract(1, 'minute').utc().format('YYYY-MM-DD HH:mm:ss')}
      `
      index++
      encodedFilter += `&filterBy[${index}][0]=lastPinged&filterBy[${index}][1]=>=&filterBy[${index}][2]=${moment().subtract(2, 'minute').utc().format('YYYY-MM-DD HH:mm:ss')}`

    } else if (lastPinged.value === 'interrupted') {
      encodedFilter += `&filterBy[${index}][0]=lastPinged&filterBy[${index}][1]=%3C&filterBy[${index}][2]=${moment().subtract(2, 'minute').utc().format('YYYY-MM-DD HH:mm:ss')}
    `
    }
    index++
  }

  if (isCustom && !isEmpty(isCustom.value) && isCustom.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=isCustom&filterBy[${index}][1]==&filterBy[${index}][2]=${isCustom.value
      }`
    index++
  }

  if (errorSource && !isEmpty(errorSource.value) && errorSource.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=errorSource&filterBy[${index}][1]==&filterBy[${index}][2]=${errorSource.value}`
    index++
  }

  if (filterBy && settlementId && settlementId.value) {
    encodedFilter += `&filterBy[${index}][0]=settlementId&filterBy[${index}][1]==&filterBy[${index}][2]=${settlementId.value
      }`
    index++
  }
  if (filterBy && safeDepositId && safeDepositId.value) {
    encodedFilter += `&filterBy[${index}][0]=settlementId&filterBy[${index}][1]==&filterBy[${index}][2]=${safeDepositId.value
      }`
    index++
  }

  if (filterBy && transactionBundleId && transactionBundleId.value) {
    encodedFilter += `&filterBy[${index}][0]=transactionBundleId&filterBy[${index}][1]==&filterBy[${index}][2]=${transactionBundleId.value
      }`
    index++
  }

  if (filterBy && locationId && locationId.value) {
    encodedFilter += `&filterBy[${index}][0]=locationId&filterBy[${index}][1]==&filterBy[${index}][2]=${locationId.value
      }`
    index++
  }

  if (filterBy && merchantType && merchantType.value) {
    encodedFilter += `&filterBy[${index}][0]=type&filterBy[${index}][1]==&filterBy[${index}][2]=${merchantType.value
      }`
    index++
  }
  if (filterBy && is_internal && is_internal.value !== undefined && is_internal.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=is_internal&filterBy[${index}][1]==&filterBy[${index}][2]=${is_internal.value
      }`
    index++
  }

  if (filterBy && vendorType && vendorType.value) {
    encodedFilter += `&filterBy[${index}][0]=type&filterBy[${index}][1]==&filterBy[${index}][2]=${vendorType.value
      }`
    index++
  }

  if (filterBy && isLocationManager) {
    if (isLocationManager && isLocationManager.value !== 'all') {
      encodedFilter += `&filterBy[${index}][0]=isLocationManager&filterBy[${index}][1]==&filterBy[${index}][2]=${isLocationManager.value === 'manager' ? '1' : '0'}`
    }
  }
  if (filterBy && isVirtualTerminal) {
    if (isVirtualTerminal && isVirtualTerminal.value !== 'all') {
      encodedFilter += `&filterBy[${index}][0]=isVirtualTerminal&filterBy[${index}][1]==&filterBy[${index}][2]=${isVirtualTerminal.value === 'virtual' ? '1' : '0'}`
    }
    index++
  }


  if (filterBy && bankAccountId) {
    encodedFilter += `&filterBy[${index}][0]=bankAccountId&filterBy[${index}][1]==&filterBy[${index}][2]=${bankAccountId.value}`
    index++
  }

  if (filterBy && entryPeriod && entryPeriod.value) {
    encodedFilter += `&filterBy[${index}][0]=entryDate&filterBy[${index}][1]=>=&filterBy[${index}][2]=${moment(
      entryPeriod.value.startDate
    ).format('YYYY-MM-DD')}`
    index++
    encodedFilter += `&filterBy[${index}][0]=entryDate&filterBy[${index}][1]=<=&filterBy[${index}][2]=${moment(
      entryPeriod.value.endDate
    ).format('YYYY-MM-DD')}`
    index++
  }

  if (filterBy && datePeriod && datePeriod.value) {
    encodedFilter += `&filterBy[${index}][0]=date&filterBy[${index}][1]=>=&filterBy[${index}][2]=${moment(
      datePeriod.value.startDate
    ).format('YYYY-MM-DD')}`
    index++
    encodedFilter += `&filterBy[${index}][0]=date&filterBy[${index}][1]=<=&filterBy[${index}][2]=${moment(
      datePeriod.value.endDate
    ).format('YYYY-MM-DD')}`
    index++
  }

  if (transactionType && transactionType.value && transactionType.value !== 'all') {
    encodedFilter += `&filterBy[${index}][0]=amount&filterBy[${index}][1]=${transactionType.value === 'payments' ? '<' : '>'}&filterBy[${index}][2]=0`
    index++
  }

  if (paymentType && paymentType.value) {
    encodedFilter += `&filterBy[${index}][0]=paymentTypeId&filterBy[${index}][1]==&filterBy[${index}][2]=${paymentType.value}`
    index++
  }

  if (company && company.value) {
    encodedFilter += `&filterBy[${index}][0]=companyId&filterBy[${index}][1]==&filterBy[${index}][2]=${company.value}`
    index++
  }

  if (currency && currency.value) {
    // encodedFilter += `&filterBy[${index}][0]=currencyId&filterBy[${index}][1]==&filterBy[${index}][2]=${currency.value}`
    encodedFilter += `&filterBy[${index}][0]=${currency.name}&filterBy[${index}][1]==&filterBy[${index}][2]=${currency.value}`
    index++
  }

  if (minPaymentAmount && minPaymentAmount.value) {
    encodedFilter += `&filterBy[${index}][0]=amount&filterBy[${index}][1]=>=&filterBy[${index}][2]=${minPaymentAmount.value}`
    index++
  }

  if (maxPaymentAmount && maxPaymentAmount.value) {
    encodedFilter += `&filterBy[${index}][0]=amount&filterBy[${index}][1]=<=&filterBy[${index}][2]=${maxPaymentAmount.value}`
    index++
  }

  if (filterBy && positive) {
    encodedFilter += `&positive=${positive.value}`
  }

  if (filterBy && excludeTypes) {
    encodedFilter += `&excludeTypes=${JSON.stringify(excludeTypes.value)}`
  }

  if (filterBy && companyQuery) {
    encodedFilter += `&companyId=${companyQuery.value}`
  }

  if (filterBy && currencyQuery) {
    encodedFilter += `&currency=${currencyQuery.value}`
  }

  if (filterBy && idQuery) {
    encodedFilter += `&id=${currencyQuery.value}`
  }

  if (filterBy && typeQuery) {
    encodedFilter += `&type=${currencyQuery.value}`
  }

  return encodedFilter
}

// fetchWithParams = (page = 1) => {
//   const {range, status, code, search, sortBy, startDate, endDate} = this.state
//   let currentIndex = 0
//   let filterToSend = ''

//   if (code.length > 1) {
//     for (let i = 0; i < code.length - 1; i++) {
//       filterToSend += `&filterBy[${currentIndex}][0]=${code[i][0]}&filterBy[${currentIndex}][1]=${code[i][1]}&filterBy[${currentIndex}][2]=${code[i][2]}&filterBy[${currentIndex}][3]=OR`
//       currentIndex++
//     }
//     filterToSend += `&filterBy[${currentIndex}][0]=${code[code.length - 1][0]}&filterBy[${currentIndex}][1]=${code[code.length - 1][1]}&filterBy[${currentIndex}][2]=${code[code.length - 1][2]}`
//     currentIndex++
//   } else {
//     code.forEach((item) => {
//       filterToSend += `&filterBy[${currentIndex}][0]=${item[0]}&filterBy[${currentIndex}][1]=${item[1]}&filterBy[${currentIndex}][2]=${item[2]}`
//       currentIndex++
//     })
//   }

//   if (status.length > 0) {
//     for (let i = 0; i < status.length - 1; i++) {
//       filterToSend += `&filterBy[${currentIndex}][0]=${status[i][0]}&filterBy[${currentIndex}][1]=${status[i][1]}&filterBy[${currentIndex}][2]=${status[i][2]}&filterBy[${currentIndex}][3]=OR`
//       currentIndex++
//     }
//     filterToSend += `&filterBy[${currentIndex}][0]=${status[status.length - 1][0]}&filterBy[${currentIndex}][1]=${status[status.length - 1][1]}&filterBy[${currentIndex}][2]=${status[status.length - 1][2]}`
//     currentIndex++
//   } else {
//     status.forEach((item) => {
//       filterToSend += `&filterBy[${currentIndex}][0]=${item[0]}&filterBy[${currentIndex}][1]=${item[1]}&filterBy[${currentIndex}][2]=${item[2]}`
//       currentIndex++
//     })
//   }
//   if (range.length > 0) {
//     range.forEach((item, index) => {
//       if (item[0] !== '') {
//         filterToSend += `&filterBy[${currentIndex}][0]=${item[0]}&filterBy[${currentIndex}][1]=${item[1]}&filterBy[${currentIndex}][2]=${item[2]}`
//         currentIndex++
//       }
//     })
//   }
//   filterToSend += `&orderBy[${currentIndex}][0]=${sortBy[0]}&orderBy[${currentIndex}][1]=${sortBy[1]}`
//   if (startDate) {
//     filterToSend += `&filterBy[${++currentIndex}][0]=created_at&filterBy[${currentIndex}][1]=>=&filterBy[${currentIndex}][2]=${startDate.format('YYYY-MM-DD')}`
//   }
//   if (endDate) {
//     filterToSend += `&filterBy[${++currentIndex}][0]=created_at&filterBy[${currentIndex}][1]=<=&filterBy[${currentIndex}][2]=${endDate.format('YYYY-MM-DD')}`
//   }
//   if (this.props.match.params.settlementId) {
//     filterToSend += `&filterBy[${++currentIndex}][0]=settlementId&filterBy[${currentIndex}][1]==&filterBy[${currentIndex}][2]=${this.props.match.params.settlementId}`
//   }

//   this.props.fetchTransactions(page, undefined, search, filterToSend)
// }
