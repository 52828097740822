import { get, put } from '../../helpers'
import _ from 'lodash'

export const FETCH_IPS = 'fetch_ips'
export const FETCH_IPS_SUCCESS = 'fetch_ips_success'
export const FETCH_IPS_FAIL = 'fetch_ips_success'

export const DELETE_IP = 'delete_ip'
export const DELETE_IP_SUCCESS = 'delete_ips_success'
export const DELETE_IP_FAIL = 'delete_ips_fail'

export const ADD_IP = 'add_ip'
export const ADD_IP_SUCCESS = 'add_ip_success'
export const ADD_IP_FAIL = 'add_ip_fail'



export const sortIps = (ips, sortBy, asc = false) => {
  return async (dispatch) => {
    let ipsLocal = [...ips]
    console.log(sortBy)
    console.log(sortBy.includes('_at'))
    ipsLocal = ipsLocal.sort((a, b) => {
      const firstEl = sortBy.includes('_at') ? new Date(a) : (sortBy !== 'status' ? a[sortBy] : (a.deleted_at === null ? 1 : 0))
      const secondEl = sortBy.includes('_at') ? new Date(b) : (sortBy !== 'status' ? b[sortBy] : (b.deleted_at === null ? 1 : 0))
      return (firstEl === secondEl) ? 0 : ((firstEl < secondEl) ^ asc ? -1 : 1)
    })
    dispatch({
      type: FETCH_IPS_SUCCESS,
      payload: { filteredIPs: ipsLocal }
    })
  }
}

export const fetchIps = (locationId, fetchDeleted = false) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_IPS })
    let response
    let url = `/locations/${locationId}/filtered-ips${fetchDeleted ? '?withDeleted=true' : ''}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_IPS_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_IPS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const addIps = (locationId, data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_IP })
    let response
    try {
      response = await put({ url: `/locations/${locationId}/filtered-ips`, data })
      dispatch({
        type: ADD_IP_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: ADD_IP_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const deleteIp = (locationId, data, simulateAdd = false) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_IP })
    let response
    try {
      response = await put({ url: `/locations/${locationId}/filtered-ips`, data })
      !simulateAdd && dispatch({
        type: DELETE_IP_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: DELETE_IP_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
