import styled from 'styled-components'

const FormGroup = styled.div`
display:flex;
margin-top:20px;
@media all and (max-width: 420px){
  margin: 0;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
`

export {FormGroup}
