export function sortCurrencies(currencies) {
    if (currencies) {
        let mainCurrency = ''
        let mainCurrencyIndex = ''
        currencies.forEach((item, index) => {
            if (item.isMainCurrency === 1) {
                mainCurrency = item
                mainCurrencyIndex = index
            }
        })
        currencies.splice(mainCurrencyIndex, 1)
        currencies.sort(function (a, b) {
            if (a.code < b.code) { return -1; }
            if (a.code > b.code) { return 1; }
            return 0;
        })
        currencies.unshift(mainCurrency)
        return currencies
    } else return []
}