export default function getTranslationsForSelectedLanguage(language) {
    var arrLang = {
        "US": {
            "confirm": "Confirm",
            "cancel": "Cancel",
            "titleCancelSettlement": "Are you sure you want to cancel this settlement?",
            "titleCompletedSettlement": "Are you sure you want to close this settlement?",
            "footer": "PLEASE PAY ATTENTION!",
            "successfullyChanged": "Operation successfully completed!",
            "settlementTitleErrorForMerchant": "Commission invoice must be loaded before approving the settlement!",
            "settlementTextErrorForMerchant": "In case you don't want to get the commission payment at this moment then you can cancel the commission payment for next settlement",
            "titleAcceptedSettlement": "Are you sure you want to approve this settlement?",
            "settlementInvoiceAmountWarning": "Invoiced amount is different from settled commission. Are you sure you want to continue?",
            "settlementSettledCommissionValue": "The value of settled commission is: ",
            "yes": "YES",
            "no": "NO",
            "safeDepositStatusTitle": "This action is permanent",
            "safeDepositCanceledStatusText": "If you cancel this safe deposit, you won't be able to change it's status anymore.",
            "safeDepositCompletedStatusText": "You will only be able to change this safe deposit's status if it's the last one with this partner.",
            "are_you_sure": "Are you sure?",
            "cashierDeleteText": "Do you really want to delete this cashier?",
            "ipDeleteText": "Do you really want to delete this IP - ",
            "ipAddText": "Are you sure you want to add these IPs?",
            "locationDeleteText": "Do you really want to delete this location?",
            "merchantDeleteText": "Do you really want to delete this merchant?",
            "terminalDeleteText": "Do you really want to delete this terminal?",
            "vendorDeleteText": "Do you really want to delete this vendor?",
            "virtualTerminalDeleteText": "Do you really want to delete this virtual terminal?",
            "commissionDeleteText": "Do you really want to delete this commission?",
            "created_commissions": "created commissions",
            "updated_commissions": "Commissions updated",
            "you_just_created": "You just created commissions for",
            "commissions_for_your_vendors": "commissions for your vendors.",
            "wrongSelection": "Wrong selection",
            "commissionsErrorMessage": "Some companies have commissions which can no longer be modified. Verify your input data in order to be able to add the modifications.",
            "logoDeleteText": "Do you really want to delete this logo?",
            "roleDeleteText": "Do you really want to delete this role?",
            "deletionSuccessful": "Deletion successful!",
            "something_went_wrong": "Something went wrong!",
            "old_password_does_not_match": "Old password does not match!",
            "user_updated": "User successfully updated!",
            "new_password_does_not_match": "New password does not match!",
            "no_transactions_found": "No transactions found!",
            "token_expired": "Your session has expired. Please re-login to renew your session.",
            "disconnect": "Disconnect",
            "titleAcceptedSafeDeposit": "Are you sure you want to approve this safe deposit?",
            "no_transactions_for_selected_period": "Are you sure you want to generate the documents without transactions in the selected period?",
            "forbiddenText": "You are not allowed to access this page!",
            "forbidden": "Not Allowed",
            "fileNotFound": "The file does not exist!",
            "created_commissions_successfully": "Commissions created successfully",
            "updated_commissions_successfully": "Commissions updated successfully",
            "merchants": "merchants",
            "will_be_used": "which will be used from",
            "commissions_add_successfully": (vendorsNr, date) => `You just created commissions for ${vendorsNr} vendors, which will be used from <b>${date}</b>`,
            "vendor_commissions_add_successfully": (date) => `You just created a commission, which will be used from <b>${date}</b>`,
            "vendorCommissionsErrorMessage": "This company has commissions which can no longer be modified. Verify your input data in order to be able to add the modifications.",
            "bankDeleteText": "Do you really want to delete this bank and accounts?",
            "lastPingedTooltip": (lastPinged) => `(${lastPinged} ago)`,
            "unlimited": "Unlimited",
            "tobal": "Tobal",
            "creditLimit": "Credit limit",
            "debitLimit": "Debit limit",
            "permanentAction": "This action is permanent",
            "yourPassword": "Your password",
            "cancelTransactionWarning": "Once the transaction is canceled, you can no longer change its status. Enter your account password to confirm the change.",
            "locationManager": "Location manager",
            "cashier": "Cashier"
        },
        "RO": {
            "confirm": "Confirma",
            "cancel": "Anuleaza",
            "titleCancelSettlement": "Esti sigur ca doresti sa anulezi acest decont?",
            "titleCompletedSettlement": "Esti sigur ca doresti sa inchizi acest decont?",
            "footer": "ATENTIE!",
            "successfullyChanged": "Actiunea s-a realizat cu succes!",
            "settlementTitleErrorForMerchant": "Trebuie incarcata factura de comision pentru a aproba decontul!",
            "settlementTextErrorForMerchant": "Daca nu doresti plata comisionului in acest moment atunci poti amana plata acestuia pentru urmatorul decont.",
            "titleAcceptedSettlement": "Esti sigur ca doresti sa aprobi acest decont?",
            "settlementInvoiceAmountWarning": "Suma facturata este diferita de valoarea comisionului de pe decont. Esti sigur ca vrei sa continui?",
            "settlementSettledCommissionValue": "Valoarea comisionului de pe decont este:",
            "yes": "DA",
            "no": "NU",
            "safeDepositStatusTitle": "Aceasta actiune este permanenta",
            "safeDepositCanceledStatusText": "Daca inchizi aceasta garantie, nu ii vei mai putea schimba statusul!",
            "safeDepositCompletedStatusText": "Vei putea schimba statusul garantiei doar daca este ultima cu acest partener.",
            "are_you_sure": "Sunteti sigur?",
            "cashierDeleteText": "Sunteti sigur ca doriti sa stergeti acest casier?",
            "ipDeleteText": "Sunteti sigur ca doriti sa stergeti acest IP - ",
            "ipAddText": "Sunteti sigur ca doriti sa adaugati aceste IP-uri?",
            "locationDeleteText": "Sunteti sigur ca doriti sa stergeti aceasta locatie?",
            "merchantDeleteText": "Sunteti sigur ca doriti sa stergeti acest comerciant?",
            "terminalDeleteText": "Sunteti sigur ca doriti sa stergeti acest terminal?",
            "vendorDeleteText": "Sunteti sigur ca doriti sa stergeti acest furnizor?",
            "virtualTerminalDeleteText": "Sunteti sigur ca doriti sa stergeti acest terminal virtual?",
            "commissionDeleteText": "Sunteti sigur ca doriti sa stergeti acest comision?",
            "created_commissions": "comisioane create",
            "updated_commissions": "comisioane actualizare",
            "you_just_created": "Ai adaugat comisioane noi pentru",
            "commissions_for_your_vendors": "comisioane pentru furnizor.",
            "wrongSelection": "Selectie gresita",
            "commissionsErrorMessage": "Unele companii au comisioane care nu mai pot fi modificate. Verificati datele cu scopul de a putea adauga modificarile.",
            "logoDeleteText": "Sunteti sigur ca doriti sa stergeti acest logo?",
            "roleDeleteText": "Sunteti sigur ca doriti sa stergeti acest rol?",
            "deletionSuccessful": "Stergere realizata cu succes!",
            "something_went_wrong": "Ceva nu a functionat corect!",
            "old_password_does_not_match": "Parola veche nu corespunde!",
            "user_updated": "Modificarile au fost realizate cu succes!",
            "new_password_does_not_match": "Parola noua nu corespunde!",
            "no_transactions_found": "Nu au fost gasite tranzactii!",
            "token_expired": "Sesiunea dumneavoastra a expirat. Va rugam sa va reconectati.",
            "disconnect": "Deconectare",
            "titleAcceptedSafeDeposit": "Esti sigur ca doresti sa aprobi aceasta garantie?",
            "no_transactions_for_selected_period": "Esti sigur ca doresti sa generezi documentele fara tranzactii in perioada selectata?",
            "forbiddenText": "Nu este permis accesul pe aceasta pagina!",
            "forbidden": "Acces nepermis",
            "fileNotFound": "Fisierul nu exista!",
            "created_commissions_successfully": "Comisioane adaugate cu succes",
            "updated_commissions_successfully": "Comisioane actualizate cu succes",
            "merchants": "comercianti",
            "commissions_add_successfully": (vendorsNr, date) => `Ai adaugat comisioane noi pentru ${vendorsNr} furnizori, care se vor activa de la <b>${date}</b>`,
            "vendor_commissions_add_successfully": (date) => `Ai adaugat un comision nou, care va fi activ de la <b>${date}</b>`,
            "vendorCommissionsErrorMessage": "Aceasta companie are comisioane care nu mai pot fi modificate. Verificati datele cu scopul de a putea adauga modificarile.",
            "bankDeleteText": "Sunteti sigur ca doriti sa stergeti aceasta banca?",
            "lastPingedTooltip": (lastPinged) => `(acum ${lastPinged})`,
            "unlimited": "Nelimitat",
            "tobal": "Tobal",
            "creditLimit": "Limita credit",
            "debitLimit": "Limita debit",
            "permanentAction": "Actiunea este permanenta",
            "yourPassword": "Parola ta",
            "cancelTransactionWarning": "Odata anulata tranzactia, nu mai poti modifica statusul acesteia. Introdu parola contului tau pentru a confirma modificarea.",
            "locationManager": "Manager de locatie",
            "cashier": "Casier"
        },
        "PT": {
            "confirm": "Confirm",
            "cancel": "Cancel",
            "titleCancelSettlement": "Are you sure you want to cancel this settlement?",
            "titleCompletedSettlement": "Are you sure you want to close this settlement?",
            "footer": "PLEASE PAY ATTENTION!",
            "successfullyChanged": "Operation successfully completed!",
            "settlementTitleErrorForMerchant": "Commission invoice must be loaded before approving the settlement!",
            "settlementTextErrorForMerchant": "In case you don't want to get the commission payment at this moment then you can cancel the commission payment for next settlement",
            "titleAcceptedSettlement": "Are you sure you want to approve this settlement?",
            "settlementInvoiceAmountWarning": "Invoiced amount is different from settled commission. Are you sure you want to continue?",
            "settlementSettledCommissionValue": "The value of settled commission is: ",
            "yes": "YES",
            "no": "NO",
            "safeDepositStatusTitle": "This action is permanent",
            "safeDepositCanceledStatusText": "If you cancel this safe deposit, you won't be able to change it's status anymore.",
            "safeDepositCompletedStatusText": "You will only be able to change this safe deposit's status if it's the last one with this partner.",
            "are_you_sure": "Are you sure?",
            "cashierDeleteText": "Do you really want to delete this cashier?",
            "locationDeleteText": "Do you really want to delete this location?",
            "merchantDeleteText": "Do you really want to delete this merchant?",
            "terminalDeleteText": "Do you really want to delete this terminal?",
            "vendorDeleteText": "Do you really want to delete this vendor?",
            "virtualTerminalDeleteText": "Do you really want to delete this virtual terminal?",
            "commissionDeleteText": "Do you really want to delete this commission?",
            "created_commissions": "created commissions",
            "updated_commissions": "Commissions updated",
            "you_just_created": "You just created commissions for",
            "commissions_for_your_vendors": "commissions for your vendors.",
            "wrongSelection": "Wrong selection",
            "commissionsErrorMessage": "Some companies have commissions which can no longer be modified. Verify your input data in order to be able to add the modifications.",
            "logoDeleteText": "Do you really want to delete this logo?",
            "roleDeleteText": "Do you really want to delete this role?",
            "deletionSuccessful": "Deletion successful!",
            "something_went_wrong": "Something went wrong!",
            "old_password_does_not_match": "Old password does not match!",
            "user_updated": "User successfully updated!",
            "new_password_does_not_match": "New password does not match!",
            "no_transactions_found": "No transactions found!",
            "token_expired": "Your session has expired. Please re-login to renew your session.",
            "disconnect": "Disconnect",
            "titleAcceptedSafeDeposit": "Are you sure you want to approve this safe deposit?",
            "no_transactions_for_selected_period": "Are you sure you want to generate the documents without transactions in the selected period?",
            "forbiddenText": "You are not allowed to access this page!",
            "forbidden": "Not Allowed",
            "fileNotFound": "The file does not exist!",
            "created_commissions_successfully": "Commissions created successfully",
            "updated_commissions_successfully": "Commissions updated successfully",
            "merchants": "merchants",
            "commissions_add_successfully": (vendorsNr, date) => `You just created commissions for ${vendorsNr} vendors, which will be used from <b>${date}</b>`,
            "vendor_commissions_add_successfully": (date) => `You just created a commission, which will be used from <b>${date}</b>`,
            "vendorCommissionsErrorMessage": "This company has commissions which can no longer be modified. Verify your input data in order to be able to add the modifications.",
            "bankDeleteText": "Do you really want to delete this bank and accounts?",
            "lastPingedTooltip": (lastPinged) => `(${lastPinged} ago)`,
            "unlimited": "Unlimited",
            "tobal": "Tobal",
            "creditLimit": "Credit limit",
            "debitLimit": "Debit limit",
            "permanentAction": "This action is permanent",
            "yourPassword": "Your password",
            "cancelTransactionWarning": "Once the transaction is canceled, you can no longer change its status. Enter your account password to confirm the change.",
            "locationManager": "Location manager",
            "cashier": "Cashier"
        }
    }
    return arrLang[language]
}