import styled from 'styled-components'
import { primaryColor, textColor } from './StyleConstants'

const ConfirmationBody = styled.div`
  max-width: 480px;
  margin:0 auto;
  text-align: center;
  > svg {
    width: 65px;
    margin-bottom 20px;
    .confirmation-icon{
      stroke: ${primaryColor};
    }
  }
  h2{
    margin-bottom: 40px;
    color: ${primaryColor};
    font-size: 18px;
  }
  h3{
    font-size: 18px;
    color: ${textColor};
    margin-bottom: 15px;
  }
  >div{
    opacity: 0;
    visibility: hidden;
    transform: translateY(50px);
    transition: all .5s ease;
    &.visible{
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    .btn-wrap{
      > a{
        margin: 15px 0;
      }
    }
    a{
      color: ${primaryColor};
    }
    ul{
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #efefef;
      li{
        margin-bottom: 10px;
      }
    }
  }
`
export {ConfirmationBody}
