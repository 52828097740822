// NPM and others
import React, { Component } from 'react'
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Loader } from '../common'
import {
  bigPadding,
  colorWhite,
  errorColor,
  secondaryColor,
  smallPadding,
  textColor,
} from './StyleConstants'


const LocationMarker = styled.i`
  font-size: 35px;
  color: ${errorColor};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`

const Wrapper = styled.div`
  flex: 1;
  > p {
    font-weight: bold;
    margin-bottom: 15px;
  }
  > div {
    position: relative;
  }
`

const ConfirmButton = styled.span`
  color: ${textColor};
  border: 1px solid ${textColor};
  border-radius: 8px;
  padding: ${smallPadding} ${bigPadding};
  cursor: pointer;
  margin: 8px 0;

  :hover {
    color: ${colorWhite};
    border-color: ${secondaryColor};
    background-color: ${secondaryColor};
  }
`

class MapComponent extends Component {
  confirmLocation = () => {
    const { saveCoordinates } = this.props
    const center = this._map.getCenter()

    saveCoordinates({ lat: center.lat(), lng: center.lng() })
  }

  render() {
    const { coordinates } = this.props
    const MapGoogle = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultCenter={coordinates}
          defaultZoom={19}
          ref={map => (this._map = map)}
        >
          <LocationMarker className='lnr lnr-map-marker' />
          {/* <Marker position={coordinates} /> */}
        </GoogleMap>
      ))
    )
    return (
      <Translate>
        {({ translate }) => (
          <Wrapper>
            <p style={{ textDecoration: 'bold' }}>{translate('setExactLocation')}</p>
            <MapGoogle
              googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCFdZ3KmIVhhtxC5O0fsIdOuVF3EV8qD24&libraries=geometry,drawing,places'
              loadingElement={<Loader />}
              containerElement={<div style={{ height: `400px`, marginBottom: 20 }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
            <ConfirmButton onClick={this.confirmLocation}>
              {translate('confirmLocation')}
            </ConfirmButton>
          </Wrapper>
        )}
      </Translate>
    )
  }
}

const mapStateToProps = state => ({
  coordinates: state.locations.coordinates
})

export default connect(mapStateToProps)(MapComponent)
