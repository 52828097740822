import { loadState } from './loadState'

const loadToken = (TOKEN_NAME) => {
  const token = loadState(TOKEN_NAME)

  if (!token) {
    return {login: {}}
  }
  const header = `${token.token_type} ${token.access_token}`
  return {
    login: {
      isAuthenticated: true,
      token,
      header,
      error: ''
    }
  }
}

export { loadToken }
