import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  display: flex;
  padding: 15px 15px 5px 15px;
  // background-color: rgba(0,0,0,0.04);
  align-items: center;
  justify-content: space-around;
  color: #686F7F;
  font-size: 12px;
  flex-wrap: wrap;
  font-family: "Opensans";
  
  & > * {
    display: flex;
  }
`

const TableHeader = props => (
  <Wrapper style={props.style}>{props.children}</Wrapper>
)
TableHeader.propTypes = {
  children: PropTypes.node
}

export { TableHeader }
