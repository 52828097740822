// NPM and others

import styled from 'styled-components'
import React from 'react'
import { Translate } from 'react-localize-redux'

// Common components

import { secondaryColor, errorColor } from '../common/StyleConstants'
import { ErrorMessage } from './ErrorMessage'

const Wrapper = styled.div`
  position: relative;
  flex:1;
  ${props => props.auth && 'flex:4'}
  ${props => props.required &&
    `
    &:after{
      background-color:inherit;
      content:'*';
      position:absolute;
      top: 50%;
      right: 5px;
      margin-top: -8px;
      color:${secondaryColor}
    }
    `
  }

  @media all and (max-width: 560px){
    min-width: 100%;
    margin-top: 10px;
    padding-left: 24px;
      ${({ noPadding }) => {
        let style = ''
        if (noPadding === true) style = 'padding: 0;';
        return style
      }}
  }
`

const InputField = styled.input`
  display:block;
  height:35px;
  margin-left: 10px;
  width:${props => props.width ? `${props.width}px` : '100%'};
  padding-bottom:5px;
  padding-left:10px;
  margin:0;
  font-family:Opensans;
  letter-spacing:0.075em;
  color: rgb(44, 44, 44);
  font-size: 13.56px;
  line-height: 1.917;
  outline:none;
  border:none;
  border-bottom:1px solid rgb(204,204,204);
  ${props => props.error && onError}
  &[type='password']{
    font-size: 24px;
    letter-spacing: 8px;
    color: ${secondaryColor};
  }
`
const onError = `border-bottom:1px solid ${errorColor};`

const Input = ({ input, auth, type, placeholder, meta: { error, touched }, required }) =>
  (
    <Translate>{({ translate }) =>
      <Wrapper auth={auth} required={required}>
        <InputField
          error={touched && error}
          {...input}
          placeholder={placeholder}
          type={type}
        />
        <ErrorMessage error={error} touched={touched}>{error && translate(error)}</ErrorMessage>
      </Wrapper>}
    </Translate>
  )

export { Input }
