import {
    FETCH_RELEASES,
    FETCH_RELEASES_FAIL,
    FETCH_RELEASES_SUCCESS,
    ADD_RELEASE,
    ADD_RELEASE_FAIL,
    ADD_RELEASE_SUCCESS,
    FETCH_RELEASE,
    FETCH_RELEASE_FAIL,
    FETCH_RELEASE_SUCCESS,
    EDIT_RELEASE,
    EDIT_RELEASE_FAIL,
    EDIT_RELEASE_SUCCESS,
    APPEND_RELEASES,
    APPEND_RELEASES_FAIL,
    APPEND_RELEASES_SUCCESS
} from './ReleasesActions'

const INITIAL_STATE = {
  releasesList: [],
  loadingList: false,
  loadingForm: false,
  loadingDetails: false,
  error: {},
  activeRelease: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RELEASES:
      return {...state, loadingList: true, error: {}, releasesList: []}
    case FETCH_RELEASES_FAIL:
      return {...state, loadingList: false, error: action.payload, releasesList: []}
    case FETCH_RELEASES_SUCCESS:
      return {...state, loadingList: false, error: {}, releasesList: action.payload}
    case APPEND_RELEASES:
      return {...state, loadingList: true, error: {}, releasesList: []}
    case APPEND_RELEASES_FAIL:
      return {...state, loadingList: false, error: action.payload, releasesList: []}
    case APPEND_RELEASES_SUCCESS:
      return {...state, loadingList: false, error: {}, releasesList: [...state.releasesList, ...action.payload]}
    case ADD_RELEASE:
      return {...state, loadingForm: true, error: {}}
    case ADD_RELEASE_SUCCESS:
      return {...state, loadingForm: false, error: {}}
    case ADD_RELEASE_FAIL:
      return {...state, loadingForm: false, error: action.payload}
    case EDIT_RELEASE:
      return {...state, loadingForm: true, error: {}}
    case EDIT_RELEASE_SUCCESS:
      return {...state, loadingForm: false, error: {}}
    case EDIT_RELEASE_FAIL:
      return {...state, loadingForm: false, error: action.payload}
    case FETCH_RELEASE:
      return {...state, loadingDetails: true, loadingForm: true, error: {}, activeRelease: {}}
    case FETCH_RELEASE_FAIL:
      return {...state, loadingDetails: false, loadingForm: false, error: action.payload, activeRelease: {}}
    case FETCH_RELEASE_SUCCESS:
      return {...state, loadingDetails: false, loadingForm: false, error: {}, activeRelease: action.payload}
    default:
      return state
  }
}
