import styled from 'styled-components'

const ControlsBar = styled.div`
  display: flex;
  justify-content: flex-end;
  
  & > *   {
    margin-right: 15px;
  
    &:last-child {
      margin-right: 0;
    }
  }
`

export { ControlsBar }
