import styled from 'styled-components';
import { secondaryColor } from '../StyleConstants';

const ButtonUpload = styled.label`
  font-size:12px;
  font-family:'Opensans';
  background-color: transparent;
  color: ${secondaryColor};
  width: 210px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content: center;
  letter-spacing: 1.2px;
  border-color: white;
  border-radius: 20px;
  border: 1px;
  border-style: solid;
  text-transform:uppercase;
  cursor: pointer;  
  ${props =>
    props.disabled &&
    `
    cursor: pointer;
  `}
  transition:background-color .2s ease, width .2s ease, margin-left .2s ease;
  @media all and (max-width: 1200px){
    font-size: 10px;
`;
export { ButtonUpload };
