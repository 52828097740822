import styled from 'styled-components'
import { primaryColor, secondaryColor } from '../common/StyleConstants'

const FieldLabel = styled.label`
${'' /* padding-right: 10px; */}
${props => props.bold && 'font-weight: 700;'}
${props => props.primary && `color: ${primaryColor};`}
${props => props.secondary && `color: ${secondaryColor};`}
`

const FieldLabelCurrency = styled.label`
${props => props.bold && 'font-weight: 700;'}
${props => props.primary && `color: ${primaryColor};`}
${props => props.secondary && `color: ${secondaryColor};`}
`

export { FieldLabel, FieldLabelCurrency }
