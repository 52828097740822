import {
    SET_BANK,
    SET_ACCOUNT,
    FETCH_PAYMENT_ACCOUNTS,
    FETCH_PAYMENT_ACCOUNTS_SUCCESS,
    FETCH_PAYMENT_ACCOUNTS_FAIL,
    FETCH_PAYMENT_ACCOUNT,
    FETCH_PAYMENT_ACCOUNT_SUCCESS,
    FETCH_PAYMENT_ACCOUNT_FAIL,
    FETCH_COMPANIES,
    FETCH_COMPANIES_SUCCESS,
    FETCH_COMPANIES_FAIL,
    SET_PAYMENT_FIELD,
    FETCH_PAYMENT_TYPES,
    FETCH_PAYMENT_TYPES_SUCCESS,
    FETCH_PAYMENT_TYPES_FAIL,
    FETCH_PAYMENT_SUBTYPES,
    FETCH_PAYMENT_SUBTYPES_SUCCESS,
    FETCH_PAYMENT_SUBTYPES_FAIL,
    FETCH_PAYMENTS,
    FETCH_PAYMENTS_SUCCESS,
    FETCH_PAYMENTS_FAIL,
    ADD_PAYMENT_TYPE,
    ADD_PAYMENT_TYPE_SUCCESS,
    ADD_PAYMENT_TYPE_FAIL,
    FETCH_PAYMENT_CURRENCIES,
    FETCH_PAYMENT_CURRENCIES_SUCCESS,
    FETCH_PAYMENT_CURRENCIES_FAIL,
    FETCH_PAYMENT_BY_ID,
    FETCH_PAYMENT_BY_ID_SUCCESS,
    FETCH_PAYMENT_BY_ID_FAIL,
    ADD_PAYMENT,
    ADD_PAYMENT_SUCCESS,
    ADD_PAYMENT_FAIL,
    DELETE_PAYMENT,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAIL,
    UPDATE_PAYMENT,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_FAIL
} from './PaymentsActions'

const INITIAL_STATE = {
    bank: {},
    account: {},
    accounts: [],
    error: {},
    companies: {},
    paymentTypes: [],
    paymentSubTypes: [],
    payments: [],
    pagination: {},
    loading: false,
    loadingList: false,
    currencies: [],
    payment: {},
    loadingPayment: false,
    showLessFilters: true,
    loadingAccount: false,
    paymentType: {},
    paymentSubType: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BANK:
            return { ...state, bank: action.payload }

        case SET_ACCOUNT:
            return { ...state, account: action.payload }

        case FETCH_PAYMENT_ACCOUNTS:
            return { ...state, loading: true }
        case FETCH_PAYMENT_ACCOUNTS_SUCCESS:
            return { ...state, loading: false, accounts: action.payload }
        case FETCH_PAYMENT_ACCOUNTS_FAIL:
            return { ...state, loading: false, error: action.payload }

        case FETCH_PAYMENT_ACCOUNT:
            return { ...state, loadingAccount: true }
        case FETCH_PAYMENT_ACCOUNT_SUCCESS:
            return { ...state, loadingAccount: false, account: action.payload }
        case FETCH_PAYMENT_ACCOUNT_FAIL:
            return { ...state, loadingAccount: false, error: action.payload }

        case FETCH_COMPANIES:
            return { ...state, loading: true }
        case FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                loading: false,
                companies: action.payload
            }
        case FETCH_COMPANIES_FAIL:
            return { ...state, loading: false, error: action.payload }

        case SET_PAYMENT_FIELD:
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }

        case FETCH_PAYMENT_TYPES:
            return { ...state, loadingList: true }
        case FETCH_PAYMENT_TYPES_SUCCESS:
            return { ...state, loadingList: false, paymentTypes: action.payload }
        case FETCH_PAYMENT_TYPES_FAIL:
            return { ...state, loadingList: false, error: action.payload }

        case FETCH_PAYMENT_SUBTYPES:
            return { ...state, loadingList: true }
        // return { ...state, loadingList: true, paymentSubTypes: [] }
        case FETCH_PAYMENT_SUBTYPES_SUCCESS:
            return { ...state, loadingList: false, paymentSubTypes: action.payload }
        case FETCH_PAYMENT_SUBTYPES_FAIL:
            return { ...state, loadingList: false, error: action.payload }


        case FETCH_PAYMENTS:
            return { ...state, loading: true, loadingList: true }
        case FETCH_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: action.payload.payments,
                pagination: {
                    ...state.pagination,
                    pageCount: action.payload.pagination.pageCount,
                    currentPage: action.payload.pagination.page
                },
                loading: false,
                loadingList: false
            }
        case FETCH_PAYMENTS_FAIL:
            return { ...state, error: action.payload, loading: false, loadingList: false }

        case ADD_PAYMENT_TYPE:
            return { ...state, loading: true }
        case ADD_PAYMENT_TYPE_SUCCESS:
            let p = [...state.paymentTypes, ...state.paymentSubTypes]
            let paymentTypesAux = p.filter(item => item && item.parentId === null)
            let paymentSubTypesAux = p.filter(item => item && item.parentId && item.parentId !== null)
            return {
                ...state,
                error: {},
                loading: false,
                paymentTypes: paymentTypesAux,
                paymentSubTypes: paymentSubTypesAux,
                ...(action.payload && action.payload.parentId === null ? { paymentType: action.payload } : { paymentSubTypes: action.payload }),
            }
        case ADD_PAYMENT_TYPE_FAIL:
            return { ...state, loading: false, error: action.payload }

        case FETCH_PAYMENT_CURRENCIES:
            return { ...state, loading: true }
        case FETCH_PAYMENT_CURRENCIES_SUCCESS:
            return { ...state, loading: false, currencies: action.payload }
        case FETCH_PAYMENT_CURRENCIES_FAIL:
            return { ...state, loading: false, error: action.payload }

        case FETCH_PAYMENT_BY_ID:
            return { ...state, loadingPayment: true }
        case FETCH_PAYMENT_BY_ID_SUCCESS:
            return { ...state, loadingPayment: false, payment: action.payload }
        case FETCH_PAYMENT_BY_ID_FAIL:
            return { ...state, loadingPayment: false, error: action.payload }

        case ADD_PAYMENT:
            return { ...state, loadingPayment: true }
        case ADD_PAYMENT_SUCCESS:
            return { ...state, loadingPayment: false, payment: action.payload }
        case ADD_PAYMENT_FAIL:
            return { ...state, loadingPayment: false, error: action.payload }

        case DELETE_PAYMENT:
            return { ...state, loading: true }
        case DELETE_PAYMENT_SUCCESS:
            return { ...state, loading: false, payment: {} }
        case DELETE_PAYMENT_FAIL:
            return { ...state, loading: false, error: action.payload }

        case UPDATE_PAYMENT:
            return { ...state, loading: true }
        case UPDATE_PAYMENT_SUCCESS:
            return { ...state, loading: false, payment: action.payload }
        case UPDATE_PAYMENT_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}