import PropTypes from 'prop-types'
import React from 'react'
import { Translate } from 'react-localize-redux'
import Select from 'react-select'
import styled from 'styled-components'

import { errorColor, primaryColor, secondaryColor, secondaryFont } from '../common/StyleConstants'
import { ErrorMessage } from './ErrorMessage'

// NPM and others

// Common components

const SelectWrap = styled.div`
  flex: 1;
  outline: none;
  box-shadow: none;
  position: relative;
  width:100%;
  ${props => ((props.error && props.touched) || props.serverError) && `
    margin-bottom:20px;
  `}
  
  @media all and (max-width: 560px) {
    min-width: 100%;
    margin-top: 10px;
    padding-left: 24px;
    ${({ noPadding }) => {
    let style = ''
    if (noPadding === true) style = 'padding: 0;';
    return style
  }}
  }

  .Select-clear-zone {
    display: none;
  }

  .Select-arrow-zone {
    position: absolute;
    top: 10px;
    right: 8px;
    color: #B7B7B7;
    font-size: 9px;
    text-align: center;
  }

  .Select-arrow {
    color: #B7B7B7;
    font-size: 8px;
    border: none;
    font-weight: bold;
    ${props => props.smallArrow && `font-size: 12px;`}
    &:before {
      font-family: 'Linearicons-Free';
      content: '\\E874';
    }
  }

  .Select-control {
    min-height: 44px;
    position: relative;
    padding-right: 50px;
    border: none;
    border: 1px solid #E6E7EA;
    border-radius: 4px;

    ${props => ((props.error && props.touched) || props.serverError) && `
      border-bottom:1px solid ${errorColor};
    `}

    box-shadow:none !important;
    box-sizing: border-box;

    ${props => props.required && `
      // &:after{
      //   background-color:transparent;
      //   content:'*';
      //   position:absolute;
      //   top: 0;
      //   right: 5px;
      //   color:${secondaryColor}
      // }
    `}

    ${props => props.square && `
      padding-right: 15px;
      border: 1px solid #ddd;
      box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
      .Select-value-label{
        color: ${primaryColor} !important;
        font-family: ${secondaryFont};
        font-size: 14px;
        font-weight: 700;
      }
      .Select-arrow-zone{
        right: 5px;
      }
      .Select-arrow{
        color: ${primaryColor};
      }
    `}
  }

  .Select-menu-list {
    ${({ listLoading }) => {
    let style = ''
    if (listLoading === false || listLoading === undefined) style = 'cursor: wait;';
    return style
  }}
  }

  @media all and (max-width: 1023px) {
    font-size: 14px;
    .Select-arrow-zone {
      top: 12px;
      width: 15px;
      font-size: 10px;
    }
    ${props => props.square && `
      .Select-control{
        .Select-value-label{
          font-size: 12px;
        }
      }
    `}
  }

  .Select-control {
    .Select-value {
      top: 2px;
    }
    .Select-value-label {
      font-size: 14px;
      font-family: "Open Sans, Semibold";
      color: #1B2640;
    }
    .Select-multi-value-wrapper {
      max-width: calc(100% - 50px);
      min-width: 100%;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;

      .Select-input > input {
        max-width: fit-content;
      }
    }
    .Select-placeholder {
      top: 2px;
    }
  }
`

class CustomSelect extends React.Component {
  render() {
    const {
      padding,
      defaultValue,
      required = false,
      wrapperStyle,
      meta: { error, touched },
      square,
      onChange,
      input: { value, onBlur, onFocus },
      smallArrow,
      inputChange,
      noValue,
      ifFromAddSettlementClient,
      ifFromAddSettlementCurrency,
      listLoading,
      noPadding,
      translateCurrentValue,
      serverError
    } = this.props

    const handler = onChange || this.props.input.onChange

    return (
      <Translate>
        {({ translate }) => (
          <SelectWrap
            padding={padding}
            required={required}
            style={wrapperStyle}
            error={error}
            touched={touched}
            square={square}
            smallArrow={smallArrow}
            inputChange={() => inputChange()}
            listLoading={listLoading}
            noPadding={noPadding}
            serverError={serverError}
          >
            <Select
              {...this.props}
              onMenuScrollToBottom={this.props.onMenuScrollToBottom}
              onFocus={() => onFocus(value)}
              onBlur={() => onBlur(value)}
              required={false}
              onBlurResetsInput={false}
              value={noValue ? null :
                translateCurrentValue && value ?
                  { label: translate(value.key), value: value.value } :
                  value || defaultValue}
              onChange={selected => {
                if (ifFromAddSettlementClient === true || ifFromAddSettlementCurrency === true) {
                  this.props.input.onChange(selected)
                }
                else if (this.props.allOptions && this.props.allOptions.onToggleAllOptions && this.props.allOptions.option
                  && selected.length > 0
                  && selected[selected.length - 1].value === this.props.allOptions.option.value) {
                  if (selected.length === this.props.options.length) {
                    this.props.allOptions.onToggleAllOptions(0)
                    return handler([]);
                  } else {
                    this.props.allOptions.onToggleAllOptions(1)
                    return handler(this.props.options.slice(1));
                  }
                }
                return handler(selected);
              }}
            />
            <ErrorMessage error={error} touched={touched} serverError={serverError}>
              {error ? translate(error) : serverError}
            </ErrorMessage>
          </SelectWrap>
        )}
      </Translate>
    )
  }
}
CustomSelect.propTypes = {
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object
}

export { CustomSelect }
