// NPM and others

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Range } from 'rc-slider'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

// Common components

import { secondaryColor } from '../common/StyleConstants'
import { isEmpty } from '../../helpers/validation/is-empty'

const RangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  // margin-left: 10px;
  // margin-right: 10px;
  width: 100%;
  // padding-left: 15px;

  @media only screen and (min-width: 1400px) {
    &,
    & * {
      justify-content: center;
      align-items: center;
    }
  }
`
const Wrapper = styled.div`
  // max-width: 200px;
  //   transform: translateY(10px);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0;

  .rc-slider {
    // max-width: 200px;
    width: 100%;
    margin-bottom: -5px;
    position: absolute;
    // bottom: -12px
    top: 100%;
    left: 3%;
  }

  .rc-slider-track {
    background-color: ${secondaryColor};
  }
  .rc-slider-handle {
    border: 1px solid rgba(0,0,0,.2);
    // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.21);
  }

  @media all and (max-width: 1024px) {
    max-width: 350px;
  }

  @media all and (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media all and (max-width: 420px) {
    // width: 90%;
  }
`
// const InputWrapper = styled.div`
//   position:relative;
//   &:after {
//     position:absolute;
//     padding:7px 5px 8px 5px;
//     margin-top:1px;
//     box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
//     content:'Lei';
//     right:1px;
//     font-size:13px;
//     font-family:'Opensans';
//     color:rgb(153,153,153);
//     background-color: rgb(241, 241, 241);
//   }
//   @media all and (max-width: 560px){
//     width: 100%;
//     display: flex;
//     margin-top: 10px;
//   }
// `
const Input = styled.input`
  min-width: 50px;
  padding-left: 10px;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  appearance: none;
  color: rgb(153, 153, 153);
  font-family: "Opensans";
  background-color: white;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 100em;
  height: 33px;
  font-weight: bold;
  outline: none;

  max-width: 90px;

  @media all and (max-width: 560px) {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
  
`
// const LabelStyle = styled.label`
//   margin-bottom: 10px;
//   font-size: 13px;
//   font-family: "Opensans";
//   color: rgb(153, 153, 153);
// `

class RangeSlider extends Component {
  constructor (props) {
    super(props)
    this.onChange = debounce(this.onChange, 100)
    this.state = {
      from: props.value[0],
      to: props.value[1]
    }
  }
  onChange (value) {
    if (isEmpty(value[0])) {
      value[0] = 0
    }
    if (isEmpty(value[1])) {
      value[1] = 0
    }
    let newValue = [value[0], value[1]]
    if (value[0] > value[1]) {
      newValue[0] = value[1]
      newValue[1] = value[0]
    }
    this.setState({
      from: newValue[0],
      to: newValue[1]
    })
    this.props.onChange({
      fetchFunction: this.props.fetchFunction,
      page: this.props.name,
      name: 'amount',
      type: 'filterBy',
      value: newValue
    })
  }
  componentDidMount () {
    const { name, getInterval, value } = this.props
    getInterval({
      // fetchFunction,
      page: name,
      type: 'filterBy',
      newValues: value[0] === 0 && value[1] === 0
    })
  };

  shouldComponentUpdate (nextProps, nextState) {
    const { value } = this.props
    if (value[0] === 0 && value[1] === 0 && !(nextProps.value[0] === 0 && nextProps.value[1] === 0)) {
      this.onChange(nextProps.value)
    }
    if (value[0] !== nextProps.value[0]) {
      this.setState({
        from: nextProps.value[0]
      })
    }
    if (value[1] !== nextProps.value[1]) {
      this.setState({
        to: nextProps.value[1]
      })
    }

    return true
  }
  onTypeChange = (type, newValue) => {
    const { maxValue } = this.props
    const regexp = /^-?\d+(\.\d{1,2})?$/
    const valueString = newValue.toString()
    if (
      type === 'min'
    ) {
      if (isEmpty(newValue)) {
        this.setState({ from: '' })
      } else if (!isNaN(newValue) && regexp.test(valueString) && newValue >= maxValue[0] && newValue <= maxValue[1]) {
        this.setState({ from: Number(newValue) })
        // this.onChange([Number(newValue), value[1]])
      }
    }
    if (type === 'max') {
      if (isEmpty(newValue)) {
        this.setState({ to: '' })
      } else if (!isNaN(newValue) && regexp.test(valueString) && newValue <= maxValue[1] && newValue >= maxValue[0]) {
        this.setState({ to: Number(newValue) })
        // this.onChange([value[0], newValue])
      }
    }
  };
  onKeyDown = e => {
    if (e.key === 'Enter') {
      const { from, to } = this.state
      this.onChange([from, to])
    }
  }

  render () {
    const { value, maxValue } = this.props
    const { from, to } = this.state
    return (
      <RangeWrapper>
        <Wrapper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // maxWidth: '150px',
              marginBottom: 0,
              position: 'relative',
            }}
          >
            <Input
              type='number'
              min={maxValue[0]}
              value={from}
              onKeyPress={this.onKeyDown}
              onChange={e => {
                this.onTypeChange('min', e.target.value)
              }}
            />
            -
            <Input
              type='number'
              max={maxValue[1]}
              onKeyPress={this.onKeyDown}
              value={to}
              onChange={e => {
                this.onTypeChange('max', e.target.value)
              }}
            />
          </div>
          {((value[0] !== 0 || value[1] !== 0) && !isEmpty(maxValue)) && (
            <Range
              step={
                (!isEmpty(maxValue) && maxValue[0] !== maxValue[1])
                  ? Math.round(Math.abs(
                    ((maxValue[1] - maxValue[0]) / 10) -
                    ((maxValue[1] - maxValue[0]) % 10)))
                  : 10
              }
              min={maxValue[0]}
              max={maxValue[1]}
              onChange={values => {
                this.onChange(values)
              }}
              value={value}
              defaultValue={value}
            />
          )}
        </Wrapper>
      </RangeWrapper>
    )
  }
}

RangeSlider.propTypes = {
  label: PropTypes.string
}

export { RangeSlider }
