import styled from 'styled-components'

const CardItem = styled.div`
  display: inline-block;
  width: 60px;
  img{
      width: 100%;
  }
`
export {CardItem}
