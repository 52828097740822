import remove from './DeleteRequest'
import get from './GetRequest'
import post from './PostRequest'
import PrivateRoute from './PrivateRoute'
import put from './PutRequest'
import setHeader from './setHeader'

export * from './HashLinkScroll'
export * from './downloadFiles'

export { get, post, setHeader, remove, put, PrivateRoute }
