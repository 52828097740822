import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LOGIN_ROUTE } from '../../router/ClientRoutes'
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => authenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: LOGIN_ROUTE, state: { from: props.location } }} />}
    />
  )
}
export default PrivateRoute
