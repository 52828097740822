import { push } from 'react-router-redux';
import { encodeFilter } from '../../filter/EncodeFilter';
import { get, post, put } from '../../helpers';
import { CHANGE_FILTER } from '../../filter/FilterActions';
import _ from 'lodash'

export const ADD_USER = 'add_user';
export const ADD_USER_SUCCESS = 'add_user_success';
export const ADD_USER_FAIL = 'add_user_fail';
export const GET_EMPLOYEES = 'get_employees';
export const GET_EMPLOYEES_SUCCESS = 'get_employees_success';
export const GET_EMPLOYEES_FAIL = 'get_employees_fail';
export const GET_EMPLOYEE_BY_ID = 'get_employee_by_id';
export const GET_EMPLOYEE_BY_ID_SUCCESS = 'get_employee_by_id_success';
export const GET_EMPLOYEE_BY_ID_FAIL = 'get_employee_by_id_fail';
export const EDIT_EMPLOYEE = 'edit_employee';
export const EDIT_EMPLOYEE_SUCCESS = 'edit_employee_success';
export const EDIT_EMPLOYEE_FAIL = 'edit_employee_fail';
export const GET_ALL_USERS_SUCCESS = 'get_all_users_success';
export const GET_ALL_USERS_FAIL = 'get_all_users_fail';
export const GET_ALL_USERS = 'get_all_users';
export const SET_ACTIVE_USER = 'set_active_user';

export const FETCH_COMPANIES = 'fetch_companies'
export const FETCH_COMPANIES_SUCCESS = 'fetch_companies_success'
export const FETCH_COMPANIES_FAIL = 'fetch_companies_fail'

export const APPEND_COMPANIES = 'append_companies'
export const APPEND_COMPANIES_SUCCESS = 'append_companies_success'
export const APPEND_COMPANIES_FAIL = 'append_companies_fail'

export const FETCH_SINGLE_COMPANY = 'fetch_single_company'
export const FETCH_SINGLE_COMPANY_SUCCESS = 'fetch_single_company_success'
export const FETCH_SINGLE_COMPANY_FAIL = 'fetch_single_company_fail'

export const RESET_SERVER_ERROR = 'RESET_SERVER_ERROR'


export const addNewUser = data => {
  return async dispatch => {
    dispatch({ type: ADD_USER });
    let response;
    try {
      response = await post({ url: '/employees', data });
      dispatch({
        type: ADD_USER_SUCCESS,
        payload: _.get(response, 'data')
      });
      if (response.data && response.data.success) {
        dispatch(push(`/users/confirmation/${response.data.success}`));
      }
    } catch (error) {
      dispatch({
        type: ADD_USER_FAIL,
        payload: _.get(error, 'response.data.errors')
      });
      throw error
    }
  };
};

export const getAllEmployees = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_EMPLOYEES });
    let response;
    let filterParams = encodeFilter(getState().filter.users);
    let url = `/employees${filterParams}`;
    try {
      response = await get({ url });
      dispatch({
        type: GET_EMPLOYEES_SUCCESS,
        payload: response.data.employees
      });
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'users',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      });
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'users',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEES_FAIL,
        payload: _.get(error, 'response.data.errors')
      });
      throw error
    }
  };
};

export const getEmployeeById = (employeeId, companyId) => {
  return async dispatch => {
    dispatch({ type: GET_EMPLOYEE_BY_ID });
    let response, url;
    if (!companyId) {
      url = `/employees/${employeeId}`
    } else {
      url = `/employees/${employeeId}?companyId=${companyId}`
    }

    try {
      response = await get({ url });
      dispatch({
        type: GET_EMPLOYEE_BY_ID_SUCCESS,
        payload: _.get(response, 'data')
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEE_BY_ID_FAIL,
        payload: _.get(error, 'response.data.errors')
      });
      throw error
    }
  };
};

export const editEmployee = (employeeId, data) => {
  return async dispatch => {
    dispatch({ type: EDIT_EMPLOYEE });
    let response;

    try {
      response = await put({ url: `/employees/${employeeId}`, data });
      dispatch({
        type: EDIT_EMPLOYEE_SUCCESS,
        payload: _.get(response, 'data')
      });
      if (response.data) {
        dispatch(push(`/users/confirmation`));
      }
    } catch (error) {
      dispatch({
        type: EDIT_EMPLOYEE_FAIL,
        payload: _.get(error, 'response.data.errors')
      });
      throw error
    }
  };
};

export const getAllUsers = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_ALL_USERS });
    let response;
    let filterParams = encodeFilter(getState().filter.users);
    let url = `/users${filterParams}&enabled=1`;
    try {
      response = await get({ url });
      dispatch({
        type: GET_ALL_USERS_SUCCESS,
        payload: _.get(response, 'data.users'),
      });
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'users',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      });
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'users',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_USERS_FAIL,
        payload: _.get(error, 'response.data.errors')
      });
      throw error
    }
  };
};

export const setActiveUser = (user) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_USER, payload: user })
}

export const fetchCompanies = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_COMPANIES })
    let response
    let filterParams = encodeFilter(getState().filter.companies);
    let url = `/companies${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_COMPANIES_SUCCESS,
        payload: _.get(response, 'data'),
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'companies',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'companies',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_COMPANIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const appendCompanies = () => {
  return async (dispatch, getState) => {
    dispatch({ type: APPEND_COMPANIES })
    let response
    let filters = getState().filter.companies
    if (filters.pagination.page) {
      filters.pagination.page.value += 1
    }
    let filterParams = encodeFilter(filters)
    let url = `/companies${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: APPEND_COMPANIES_SUCCESS,
        payload: _.get(response, 'data'),
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'companies',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'companies',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: APPEND_COMPANIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchCompanyById = companyId => {
  return async dispatch => {
    dispatch({ type: FETCH_SINGLE_COMPANY })
    let response
    try {
      response = await get({ url: `/companies/${companyId}` })
      dispatch({
        type: FETCH_SINGLE_COMPANY_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_COMPANY_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const userResetServerError = () => (dispatch) => {
  dispatch({ type: RESET_SERVER_ERROR, payload: {} })
}
