import {
  ADD_NEW_CASHIER,
  ADD_NEW_CASHIER_SUCCESS,
  ADD_NEW_CASHIER_FAIL,
  DELETE_CASHIER,
  DELETE_CASHIER_SUCCESS,
  DELETE_CASHIER_FAIL,
  EDIT_CASHIER,
  EDIT_CASHIER_SUCCESS,
  EDIT_CASHIER_FAIL,
  FETCH_CASHIERS,
  FETCH_CASHIERS_SUCCESS,
  FETCH_CASHIERS_FAIL,
  FETCH_SINGLE_CASHIER,
  FETCH_SINGLE_CASHIER_FAIL,
  FETCH_SINGLE_CASHIER_SUCCESS,
  RESET_SERVER_ERROR
} from './CashierActions'
import { FETCH_COUNTIES_CASHIER } from '../locations/LocationActions'

const INITIAL_STATE = {
  cashiers: [],
  activeCashier: null,
  error: {},
  loading: false,
  activeState: [],
  editCashier: {},
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  serverError: {},
  filterCounties: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COUNTIES_CASHIER:
      return { ...state, filterCounties: action.payload }
    // EDIT CASHIER
    case EDIT_CASHIER:
      return { ...state, loading: true }
    case EDIT_CASHIER_SUCCESS:
      const newEditedCashiers = state.cashiers.map((cashier) => {
        if (cashier.id === action.payload.id) {
          return action.payload
        }
        return cashier
      })
      return { ...state, cashiers: newEditedCashiers, loading: false }
    case EDIT_CASHIER_FAIL:
      return { ...state, loading: false, serverError: action.payload }

    // DELETE CASHIER
    case DELETE_CASHIER:
      return { ...state, loading: true }
    case DELETE_CASHIER_SUCCESS:
      const newCashiers = state.cashiers.filter((cashier) =>
        cashier.id !== action.payload)
      return { ...state, cashiers: newCashiers, loading: false }
    case DELETE_CASHIER_FAIL:
      return { ...state, loading: false }

    // FETCH ALL CASHIERS
    case FETCH_CASHIERS:
      return { ...state, loading: true }
    case FETCH_CASHIERS_SUCCESS:
      return { ...state, cashiers: action.payload.cashiers, pagination: { ...state.pagination, pageCount: action.payload.pagination.pageCount }, loading: false }
    case FETCH_CASHIERS_FAIL:
      return { ...state, error: action.payload, loading: false }

    // ADD NEW CASHIER
    case ADD_NEW_CASHIER:
      return { ...state, loading: true }
    case ADD_NEW_CASHIER_SUCCESS:
      return { ...state, name: '', error: {}, loading: false }
    case ADD_NEW_CASHIER_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    // FETCH CASHIER BY ID
    case FETCH_SINGLE_CASHIER:
      return { ...state, loading: true }
    case FETCH_SINGLE_CASHIER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case FETCH_SINGLE_CASHIER_SUCCESS:
      return { ...state, loading: false, activeCashier: action.payload }

    case RESET_SERVER_ERROR:
      return { ...state, serverError: {} }

    default:
      return state
  }
}
