import saveAs from 'file-saver'
import { get } from '../helpers'

import { encodeFilter } from './EncodeFilter'
import { API_URL_LIVE } from '../../config/apiRoutes'

export const CHANGE_FILTER = '[filter] change specific filter'
export const CLEAR_ALL_FILTERS_ON_A_PAGE =
  '[filter] clear all filter on a specific page'
export const CLEAR_SPECIFIC_FILTER_ON_PAGE =
  '[filter] clear specific filter on page'
export const CLEAR_ALL_FILTERS = '[filter] clear all filters'
export const CLEAR_ALL_FILTERS_BUT_ONE = '[filter] clear all filters but one'
export const RESET_VENDOR_SEARCH_BY = '[filter] reset vendor search by'
export const RESET_MERCHANT_SEARCH_BY = '[filter] reset merchant search by'

export const DOWNLOAD_TRANSACTIONS = 'DOWNLOAD_TRANSACTIONS'
export const DOWNLOAD_TRANSACTIONS_SUCCESS = 'DOWNLOAD_TRANSACTIONS_SUCCESS'
export const DOWNLOAD_TRANSACTIONS_FAIL = 'DOWNLOAD_TRANSACTIONS_FAIL'

export const downloadTransactions = ({ success, filters, extension }) => (dispatch, getState) => {
  dispatch({ type: DOWNLOAD_TRANSACTIONS })
  let token = getState().login.token
  let params = encodeFilter(filters)
  const newParams = params.replace('?', '&')
  const tokenComplete = `${token.token_type} ${token.access_token}`
  let downloadLink = `${API_URL_LIVE}/transactions/export?extension=${extension
    }${newParams}`

  let headers = {
    'Content-Type': 'csv/xlsx',
    Authorization: tokenComplete
  }

  let anchor = document.createElement('a')

  try {
    fetch(downloadLink, { headers })
      .then(res => res.blob())
      .then(blobby => {
        let responseExtension = blobby && blobby.type && blobby.type.split('/')
        let objectUrl = window.URL.createObjectURL(blobby)
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          saveAs(blobby, `transactions.${extension}`)
        } else {
          anchor.href = objectUrl
          anchor.download = responseExtension && responseExtension[1] ?
            `transactions.${responseExtension[1]}` :
            `transactions.${extension}`
          anchor.click()
        }
        window.URL.revokeObjectURL(objectUrl)
        if (success) {
          dispatch({ type: DOWNLOAD_TRANSACTIONS_SUCCESS })
          success()
        }
      })
  }
  catch (error) {
    dispatch({ type: DOWNLOAD_TRANSACTIONS_FAIL })
    throw error
  }
}
export const changeFilter = payload => async dispatch => {
  dispatch({ type: CHANGE_FILTER, payload }).then(async () => {
    if (payload.type !== 'pagination') {
      await dispatch({
        type: CHANGE_FILTER,
        payload: { name: 'page', type: 'pagination', page: payload.page }
      })
    }
  }).then(async () => {
    if (payload.fetchFunction && !payload.noFetch) {
      if (payload.companyId) {
        await payload.fetchFunction(payload.companyId)
      } else {
        await payload.fetchFunction()
      }
    }
  })
}

export const clearAllFilters = payload => dispatch => {
  dispatch({ type: CLEAR_ALL_FILTERS })
  if (payload.fetchFunction && !payload.noFetch) {
    payload.fetchFunction()
  }
}
export const clearAllFiltersOnPage = payload => dispatch => {
  dispatch({ type: CLEAR_ALL_FILTERS_ON_A_PAGE, payload })
  if (payload.fetchFunction) {
    payload.fetchFunction()
  }
}

export const clearAllFiltersButOne = payload => dispatch => {
  dispatch({ type: CLEAR_ALL_FILTERS_BUT_ONE, payload })
  if (payload.fetchFunction && !payload.noFetch) {
    payload.fetchFunction()
  }
}

export const clearSpecificFilter = payload => dispatch => {
  dispatch({ type: CLEAR_SPECIFIC_FILTER_ON_PAGE, payload })
  if (payload.fetchFunction) {
    payload.fetchFunction()
  }
}

export const getRangeSliderValues = payload => async dispatch => {
  try {
    await get({ url: `/transactions/intervals?intervalFor=${payload.page}` }).then((response) => {
      let valueInterval = [
        Math.floor(response.data.min / 10) * 10,
        Math.ceil(response.data.max / 10) * 10
      ]
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          name: 'maxAmount',
          // fetchFunction: payload.fetchFunction,
          value: valueInterval,
          page: payload.page,
          type: 'filterBy'
        }
      })
      if (payload.newValues) {
        dispatch({
          type: CHANGE_FILTER,
          payload: {
            name: 'amount',
            // fetchFunction: payload.fetchFunction,
            value: valueInterval,
            page: payload.page,
            type: 'filterBy'
          }
        })
      }
      if (payload.fetchFunction && !payload.noFetch) {
        payload.fetchFunction()
      }
    })
  } catch (err) { }
}

export const resetVendorSearchBy = () => (dispatch) => {
  dispatch({ type: RESET_VENDOR_SEARCH_BY, payload: {} })
}

export const resetMerchantSearchBy = () => (dispatch) => {
  dispatch({ type: RESET_MERCHANT_SEARCH_BY, payload: {} })
}
