import styled from 'styled-components'
import { criticalColor, infoColor, warningColor, completedStatus, acceptedStatus, pendingStatus, canceledStatus } from './StyleConstants'

const StatusTop = styled.div`
  display: flex;
  justify-content: flex-end;
  
  & > *   {
    // margin-right: 15px;
  
    &:last-child {
      margin-right: 0;
    }
  }
  ${props => props.marginRight && `
    margin-right: 45px;
  `} 
`

const StatusItem = styled.div`
      width: 110px;
      text-align: center;
      padding-bottom: 2px;
      & span {
        font-size: 12px; 
        color: white;
        font-family: 'Opensans Semibold';
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
`

const StatusBorder = styled.div`
    width: 100%;
    align-items: center;
    height: 25px;

    // &:before {
    //   content:"";
    //   width: 8px;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   margin: auto;
      ${props => (props.type && props.type === 'Error') && `
        background-color: ${criticalColor};
  `}
      ${props => (props.type && props.type === 'Info') && `
        background-color: ${infoColor};
  `}
      ${props => (props.type && props.type === 'ExternalError') && `
      background-color: ${warningColor};
  `}
      ${props => (props.type && props.type === 'completed') && `
         background-color: ${completedStatus};  
         border-radius: 20px 0px 0px 20px;
   `}
      ${props => (props.type && props.type === 'accepted') && `
          background-color: ${acceptedStatus};  
   `}
       ${props => (props.type && props.type === 'pending') && `
          background-color: ${pendingStatus};  
   `}
       ${props => (props.type && props.type === 'canceled') && `
          background-color: ${canceledStatus};  
          border-radius: 0px 20px 20px 0px;
    `}
       ${props => (props.type && props.type === 'inactive') && `
          background-color: #989898;
          border-radius: 20px 0px 0px 20px;
    `}  
       ${props => (props.type && props.type === 'active') && `
          background-color: #20C436;
          border-radius: 0px 20px 20px 0px;
    `}  
`
export { StatusTop, StatusItem, StatusBorder }
