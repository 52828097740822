import styled from 'styled-components'

const ContentHeaderStyle = styled.div`
position: relative;
display: flex;
align-items: center;
border-bottom: 1px solid rgba(0,0,0,.1);

padding: 0 50px 24px 50px;
margin: 0 -50px;
margin-bottom: 24px;
@media all and (max-width: 1200px) {
padding: 0 20px 24px 20px;
margin: 0 -20px;
margin-bottom: 24px;
}

@media all and (max-width: 640px) {
  padding: 0 10px 24px 10px;
  margin: 0 -10px;
  margin-bottom: 24px;
}

@media all and (max-width: 420px) {
  padding: 0 10px 16px 10px;
  margin: 0 -10px;
  margin-bottom: 16px;
}

> div{
  &:first-child{
    flex-grow: 1;
    margin-right: 40px;
  }
  &:nth-child(2n+2){
    text-align: right
  }
}

@media only screen and (max-width: 420px){
    flex-direction: column-reverse;
    align-items: stretch;
    
    > div:first-child{
       margin-top: 10px;
    }
}
`;

export {ContentHeaderStyle}
