import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import React from 'react'
import axios from 'axios'
import configureStore from './store/configureStore'
import MainRouter from './router/router'
import { API_URL_LIVE } from './config/apiRoutes'
import { PersistGate } from 'redux-persist/integration/react'

const config = configureStore()
const { store, persistor } = config

axios.defaults.baseURL = API_URL_LIVE
export default () => {
  return (
    <LocalizeProvider>
      <Provider store={store} >
        <PersistGate loading={null} persistor={persistor}>
          <div className='under-root'>
            <MainRouter />
            {/* <DevTools /> */}
          </div>
        </PersistGate>
      </Provider>
    </LocalizeProvider>
  )
}
