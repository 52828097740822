import _ from 'lodash'
import { API_URL_LIVE } from '../../../config/apiRoutes'
import { encodeFilter } from '../../filter/EncodeFilter'
import { get, put, remove } from '../../helpers'

export const GET_COMMISSIONS = 'GET_COMMISSIONS'
export const GET_COMMISSIONS_SUCCESS = 'GET_COMMISSIONS_SUCCESS'
export const GET_COMMISSIONS_FAIL = 'GET_COMMISSIONS_FAIL'
export const ADD_COMMISSION = 'ADD_COMMISSION'
export const ADD_COMMISSION_SUCCESS = 'ADD_COMMISSION_SUCCESS'
export const ADD_COMMISSION_FAIL = 'ADD_COMMISSION_FAIL'
export const REMOVE_COMMISSION = 'REMOVE_COMMISSION'
export const REMOVE_COMMISSION_SUCCESS = 'REMOVE_COMMISSION_SUCCESS'
export const REMOVE_COMMISSION_FAIL = 'REMOVE_COMMISSION_FAIL'
export const SET_PREVIOUS_FORM_DATA = 'SET_PREVIOUS_FORM_DATA'

export const SEARCH_VENDORS = 'SEARCH_VENDORS'
export const GET_VENDORS = 'GET_VENDORS'
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS'
export const CLEAR_FAILED_VENDORS = 'CLEAR_FAILED_VENDORS'
export const SET_SELECTED_VENDORS = 'SET_SELECTED_VENDORS'

export const ADD_MULTIPLE_COMMISSIONS = 'ADD_MULTIPLE_COMMISSIONS'
export const ADD_MULTIPLE_COMMISSIONS_FINISHED = 'ADD_MULTIPLE_COMMISSIONS_FINISHED'

export const getCommission = ({ merchantId, vendorId, references }) => {
  return async dispatch => {
    dispatch({ type: GET_COMMISSIONS })
    let query
    if (merchantId) {
      query =
        `merchantId=${merchantId}&references=${references}` +
        (vendorId ? `&vendorId=${vendorId}` : ``)
    } else {
      query =
        `references=${references}` +
        (vendorId ? `&vendorId=${vendorId}` : ``)
    }

    let response
    try {
      response = await get({
        url: `${API_URL_LIVE}/companies/commissions?${query}`
      })
      // const commissions = response.data.sort(
      //   (a, b) => new Date(a.fromDate) - new Date(b.fromDate)
      // )
      dispatch({
        type: GET_COMMISSIONS_SUCCESS,
        payload: response.data
      })
    } catch (error) {
      dispatch({
        type: GET_COMMISSIONS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const addMultipleCommisions = ({ data }) => {
  let commission = data.commission
  return async dispatch => {
    dispatch({ type: ADD_COMMISSION })
    dispatch({ type: ADD_MULTIPLE_COMMISSIONS })
    for (let item = 0; item < commission.vendors.length; item++) {
      const vendorLabel = commission.vendors[item].name
      let object = {
        ...commission,
        vendorId: commission.vendors[item].id
      }
      delete object.vendors
      let data = { commission: object }

      // let response
      try {
        await put({
          url: `${API_URL_LIVE}/companies/commissions`,
          data
        })
        dispatch({
          type: ADD_COMMISSION_SUCCESS,
          // payload: response.data
          successfullVendor: { vendorLabel }
        })
      } catch (error) {
        dispatch({
          type: ADD_COMMISSION_FAIL,
          payload: _.get(error, 'response.data.errors'),
          failedVendor: { vendorLabel }
        })
      }
    }
    dispatch({ type: ADD_MULTIPLE_COMMISSIONS_FINISHED })
    await dispatch(getCommission({ merchantId: data.commission.merchantId, vendorId: undefined, references: data.commission.references }))
  }
}

export const addCommission = ({ data }) => {
  let vendorLabel
  let isFromVendor = data.isFromVendor
  delete data.isFromVendor

  if (data.commission.vendors) {
    vendorLabel = data.commission.vendors[0].name
    delete data.commission.vendors
  }

  return async dispatch => {
    dispatch({ type: ADD_COMMISSION })
    let response
    try {
      response = await put({
        url: `${API_URL_LIVE}/companies/commissions`,
        data
      })
      dispatch({
        type: ADD_COMMISSION_SUCCESS,
        payload: _.get(response, 'data')

      })
      if (isFromVendor === true) {
        await dispatch(getCommission({ merchantId: undefined, vendorId: data.commission.vendorId, references: data.commission.references }))
      } else {
        await dispatch(getCommission({ merchantId: data.commission.merchantId, vendorId: undefined, references: data.commission.references }))
      }
    } catch (error) {
      dispatch({
        type: ADD_COMMISSION_FAIL,
        payload: _.get(error, 'response.data.errors'),
        failedVendor: { vendorLabel }
      })
      throw error
    }
  }
}

export const deleteCommission = ({ id, data }) => {
  return async (dispatch, getState) => {
    dispatch({ type: REMOVE_COMMISSION })
    let response
    try {
      response = await remove({
        url: `${API_URL_LIVE}/commissions/${id}`
      })
      if (!response.data.success) {
        throw new Error('Cant delete commission')
      } else {
        if (data) {
          dispatch(getCommission({ merchantId: data.merchantId, vendorId: data.vendorId, references: data.references }))
        }
      }
      const commissions = _.filter(
        getState().commissions.commissions,
        item => item.id !== id
      )
      dispatch({
        type: REMOVE_COMMISSION_SUCCESS,
        payload: commissions
      })
    } catch (error) {
      dispatch({
        type: REMOVE_COMMISSION_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const setPreviousFormData = ({ data }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PREVIOUS_FORM_DATA,
      payload: data
    })
  }
}

export const searchVendors = ({ key }) => {
  return async (dispatch) => {
    dispatch({
      type: SEARCH_VENDORS,
      payload: key
    })
  }
}

export const getVendors = () => {
  return async (dispatch, getState) => {
    let response
    let filterParams = encodeFilter(getState().filter.vendors)
    let url = `/companies${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: GET_VENDORS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      throw error
    }
  }
}

export const getAllVendors = () => {
  return async (dispatch, getState) => {
    let response
    let filterParams = encodeFilter(getState().filter.vendors)
    let url = `/companies${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: GET_ALL_VENDORS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      throw error
    }
  }
}

export const clearFailedVendors = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_FAILED_VENDORS
    })
  }
}

export const setSelectedVendors = (vendors) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SELECTED_VENDORS,
      payload: vendors
    })
  }
}
