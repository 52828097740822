import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { secondaryColor, textColor } from '../common/StyleConstants'

const InputStyle = styled.input`
  width: 100%;
  padding: 8px 48px 8px 10px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 100em;
  color: ${textColor};
  font-family: "Open Sans, Regular";
  font-size: 14px;

  &:focus
  {
    // border-color: ${secondaryColor};
    outline: none;
  }

  ${props =>
    (props.vendorsSerach) &&
    `
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    width: 300px;
    color: #B7B7B7 !important;
    max-width: 100%;
    `}

    ::placeholder {
      color: #B7B7B7;
      opacity: 1; /* Firefox */

      ${props => (props.newSortFilter) && `
        color: #686F7F !important;
      `}
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: #B7B7B7;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
     color: #B7B7B7;
    }
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-family: "Opensans";
  // color: rgb(153, 153, 153);
  color: #B7B7B7;
  position: relative;

  // min-width: 9em;
`

const SearchIcon = styled.i`
  position: absolute;
  right: 26px;
  margin-left: auto;
  color: #B7B7B7;
  cursor: pointer;
  
  &:hover {
    color: ${secondaryColor}
  }
`

const DeleteIcon = styled.i`
  position: absolute;
  right: 40px;
  margin-left: auto;
  color: #B7B7B7;
  cursor: pointer;
  
  &:hover {
    color: ${secondaryColor}
  }

  ${props =>
    (props.vendorsSerach) && `display: none;`}

  ${props => (props.newSortFilter) && `
    right: 5px;
  `} 
`

class TableSearch extends Component {
  onClick = () => {
    this.props.onDeleteClick()
  }

  isSearchEmpty = () => {
    let searchInput = document.getElementById('filterSearch')
    if (searchInput) {
      return searchInput.value === null || searchInput.value.trim() === ''
    } else return false
  }

  render() {
    const { style, showMagnifierIcon, type } = this.props
    return (
      <Wrapper style={style}>
        <InputStyle {...this.props} id="filterSearch" type={type} />
        {this.props && this.props.value &&
          <DeleteIcon
            {...this.props}
            className="lnr lnr-cross"
            onClick={this.onClick}>
            &nbsp;
          </DeleteIcon>
        }
        {/* {!this.isSearchEmpty() && (
          <DeleteIcon
            {...this.props}
            className="lnr lnr-cross"
            onClick={this.onClick}>
            &nbsp;
          </DeleteIcon>
        )} */}
        {
          showMagnifierIcon !== false &&
          <SearchIcon
            className="lnr lnr-magnifier"
            onClick={this.props.makeSearch}
          />
        }
      </Wrapper>
    )
  }
}

TableSearch.propTypes = {
  width: PropTypes.number,
  style: PropTypes.object,
  label: PropTypes.string
}

export { TableSearch }
