import styled from 'styled-components'

const CustomForm = styled.form`
display:flex;
flex-direction:column;
overflow: auto;
justify-content:space-around;
max-width: 500px;
width: 100%;

`

export { CustomForm }
