// NPM and others

import React, { Component } from "react";
import styled from "styled-components";

// Common components

import { secondaryColor, secondaryColorRgba } from "./StyleConstants";

const Wrapper = styled.div`
  .loader {
    font-size: 5px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: ${secondaryColor};
    background: -moz-linear-gradient(
      left,
      ${secondaryColor} 10%,
      ${secondaryColorRgba} 42%
    );
    background: -webkit-linear-gradient(
      left,
      ${secondaryColor} 10%,
      ${secondaryColorRgba} 42%
    );
    background: -o-linear-gradient(
      left,
      ${secondaryColor} 10%,
      ${secondaryColorRgba} 42%
    );
    background: -ms-linear-gradient(
      left,
      ${secondaryColor} 10%,
      ${secondaryColorRgba} 42%
    );
    background: linear-gradient(
      to right,
      ${secondaryColor} 10%,
      ${secondaryColorRgba} 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: ${secondaryColor};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .loader:after {
    background: white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

class Loader extends Component {
  render() {
    return (
      <Wrapper
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...this.props.wrapperStyle
        }}
      >
        <div className="loader" style={this.props.style} />
      </Wrapper>
    );
  }
}

export { Loader };
