import axios from 'axios'
import _ from 'lodash'
import { push } from 'react-router-redux'
import { get, remove } from '../helpers'
import { encodeFilter } from '../filter/EncodeFilter'
import { CHANGE_FILTER } from '../filter/FilterActions'

export const EDIT_ROLE = '[roles] edit role start'
export const EDIT_ROLE_SUCCESS = '[roles] edit role success'
export const EDIT_ROLE_FAIL = '[roles] edit role fail'

export const ADD_NEW_ROLE = '[roles] add new role start'
export const ADD_NEW_ROLE_SUCCESS = '[roles] add new role success'
export const ADD_NEW_ROLE_FAIL = '[roles] add new role fail'

export const FETCH_ROLE_BY_ID = '[roles] fetch by id start'
export const FETCH_ROLE_BY_ID_SUCCESS = '[roles] fetch by id success'
export const FETCH_ROLE_BY_ID_FAIL = '[roles] fetch by id fail'

export const FETCH_ROLES = '[roles] fetch start'
export const FETCH_ROLES_SUCCESS = '[roles] fetch success'
export const FETCH_ROLES_FAIL = '[roles] fetch fail'

export const APPEND_ROLES = 'append_roles'
export const APPEND_ROLES_SUCCESS = 'append_roles_success'
export const APPEND_ROLES_FAIL = 'append_roles_fail'

export const GET_PERMISSIONS = 'get_permissions_for_role'
export const GET_PERMISSIONS_SUCCESS = 'get_permissions_success'
export const GET_PERMISSIONS_FAIL = 'get_permissions_fail'

export const GET_ROLE_BY_ID = 'get_role_by_id'
export const GET_ROLE_BY_ID_SUCCESS = 'get_role_by_id_success'
export const GET_ROLE_BY_ID_FAIL = 'get_role_by_id_fail'
export const GET_ALL_PERMISSIONS = 'get_all_permissions'
export const GET_ALL_PERMISSIONS_SUCCESS = 'get_all_permissions_success'
export const GET_ALL_PERMISSIONS_FAIL = 'get_all_permissions_fail'
export const DELETE_ROLE = 'delete_role'
export const DELETE_ROLE_SUCCESS = 'delete_role_success'
export const DELETE_ROLE_FAIL = 'delete_role_fail'

export const fetchRoles = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_ROLES })
  let response
  let filterParams = encodeFilter(getState().filter.roles)
  try {
    response = await axios({
      url: `/roles${filterParams}`
    })
    dispatch({ type: FETCH_ROLES_SUCCESS, payload: response.data })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'roles',
        name: 'pageCount',
        value: response.data.pagination.pageCount,
        type: 'pagination'
      }
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'roles',
        name: 'page',
        value: response.data.pagination.page,
        type: 'pagination'
      }
    })
  } catch (err) {
    dispatch({
      type: FETCH_ROLES_FAIL,
      // payload: err.response.data
      payload: _.get(err, 'response.data')
    })
  }
}

export const fetchRoleById = roleId => async dispatch => {
  dispatch({ type: FETCH_ROLE_BY_ID })
  let response
  try {
    response = await axios({
      url: `/roles/${roleId}`
    })
    dispatch({ type: FETCH_ROLE_BY_ID_SUCCESS, payload: response.data })
  } catch (err) {
    dispatch({
      type: FETCH_ROLE_BY_ID_FAIL,
      // payload: err.response.data 
      payload: _.get(err, 'response.data')
    })
  }
}

export const addRole = data => async dispatch => {
  dispatch({ type: ADD_NEW_ROLE })
  let response
  try {
    response = await axios({
      method: 'post',
      url: `/roles`,
      data
    })
    if (response.data && response.data.id) {
      dispatch(push(`/roles/confirmation/${response.data.success}`))
    }
    dispatch({ type: ADD_NEW_ROLE_SUCCESS, payload: response.data })
  } catch (err) {
    dispatch({
      type: ADD_NEW_ROLE_FAIL,
      // payload: err.response.data 
      payload: _.get(err, 'response.data')
    })
    throw err
  }
}
export const editRole = (roleId, data) => async dispatch => {
  dispatch({ type: EDIT_ROLE })
  let response
  try {
    response = await axios({
      method: 'put',
      url: `/roles/${roleId}`,
      data
    })
    if (response.data && response.data.id) {
      dispatch(push(`/roles/view/${roleId}`))
    }
    dispatch({ type: EDIT_ROLE_SUCCESS, payload: response.data })
  } catch (err) {
    dispatch({
      type: EDIT_ROLE_FAIL,
      //  payload: err.response.data 
      payload: _.get(err, 'response.data')
    })
    throw err
  }
}

export const appendRoles = (page = 1, pageSize = 10) => {
  return async dispatch => {
    dispatch({ type: APPEND_ROLES })
    let response
    try {
      response = await get({ url: `/roles?page=${page}&pageSize=${pageSize}` })
      dispatch({
        type: APPEND_ROLES_SUCCESS,
        payload: response.data
      })
    } catch (error) {
      dispatch({
        type: APPEND_ROLES_FAIL,
        payload: error
      })
    }
  }
}

export const getPermissions = () => async dispatch => {
  dispatch({ type: GET_PERMISSIONS })
  let response
  try {
    response = await get({ url: `/users/user` })
    dispatch({
      type: GET_PERMISSIONS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: GET_PERMISSIONS_FAIL,
      payload: error
    })
  }
}

export const getRoleById = roleId => {
  return async dispatch => {
    dispatch({ type: GET_ROLE_BY_ID })
    let response
    let url = `/roles/${roleId}`

    try {
      response = await get({ url })
      dispatch({
        type: GET_ROLE_BY_ID_SUCCESS,
        payload: response.data
      })
    } catch (error) {
      dispatch({
        type: GET_ROLE_BY_ID_FAIL,
        // payload: error.response.data.errors
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const deleteRole = id => {
  return async dispatch => {
    dispatch({ type: DELETE_ROLE })
    try {
      await remove({ url: `/roles/${id}` })
      dispatch({
        type: DELETE_ROLE_SUCCESS,
        payload: id
      })
    } catch (error) {
      dispatch({
        type: DELETE_ROLE_FAIL,
        payload: id
      })
      throw error
    }
  }
}

export const getAllPermissions = () => async dispatch => {
  dispatch({ type: GET_ALL_PERMISSIONS })
  let response
  try {
    response = await get({ url: '/roles/permissions' })
    dispatch({
      type: GET_ALL_PERMISSIONS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    dispatch({
      type: GET_ALL_PERMISSIONS_FAIL,
      payload: error
    })
  }
}
