import { get, put } from '../../helpers'
import _ from 'lodash'
export const GET_PLATFORM_SETTINGS = 'GET_PLATFORM_SETTINGS'
export const GET_PLATFORM_SETTINGS_FAIL = 'GET_PLATFORM_SETTINGS_FAIL'
export const GET_PLATFORM_SETTINGS_SUCCESS = 'GET_PLATFORM_SETTINGS_SUCCESS'
export const EDIT_PLATFORM = 'EDIT_PLATFORM'
export const EDIT_PLATFORM_SUCCESS = 'EDIT_PLATFORM_SUCCESS'
export const EDIT_PLATFORM_FAIL = 'EDIT_PLATFORM_FAIL'
export const EDIT_PLATFORM_BANK_DETAILS = 'EDIT_PLATFORM_BANK_DETAILS'
export const EDIT_PLATFORM_BANK_DETAILS_SUCCESS = 'EDIT_PLATFORM_BANK_DETAILS_SUCCESS'
export const EDIT_PLATFORM_BANK_DETAILS_FAIL = 'EDIT_PLATFORM_BANK_DETAILS_FAIL'

export const fetchPlatform = () => {
  return async dispatch => {
    dispatch({ type: GET_PLATFORM_SETTINGS })
    let response
    try {
      response = await get({ url: `/companies/platform` })
      dispatch({
        type: GET_PLATFORM_SETTINGS_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GET_PLATFORM_SETTINGS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editPlatformBankDetails = (data, companyId) => {
  return async dispatch => {
    dispatch({ type: EDIT_PLATFORM_BANK_DETAILS })
    let response
    try {
      response = await put({ url: `/companies/${companyId}/bank-accounts`, data })
      dispatch({
        type: EDIT_PLATFORM_BANK_DETAILS_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: EDIT_PLATFORM_BANK_DETAILS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editPlatform = ({ data, companyId, success }) => {
  return async dispatch => {
    dispatch({ type: EDIT_PLATFORM })
    let response
    try {
      response = await put({ url: `/companies/${companyId}`, data })
      // if (success) {
      //   success()
      // }
      dispatch({
        type: EDIT_PLATFORM_SUCCESS,
        payload: _.get(response, 'data'),
      })
      await dispatch(fetchPlatform())
    } catch (error) {
      dispatch({
        type: EDIT_PLATFORM_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

