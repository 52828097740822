import {
    FETCH_RECEIPT,
    FETCH_RECEIPT_FAIL,
    FETCH_RECEIPT_SUCCESS,
    FETCH_RECEIPTS,
    FETCH_RECEIPTS_FAIL,
    FETCH_RECEIPTS_SUCCESS,
    FETCH_LANGUAGES_SUCCESS
} from './ReceiptActions'
const INITIAL_STATE = {
  activeReceipt: {
    receiptLines: []
  },
  list: [],
  loadingSingle: false,
  loadingList: false,
  languages: []
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LANGUAGES_SUCCESS:
      return {...state, languages: action.payload.languages}
    case FETCH_RECEIPT:
      return {...state, loadingSingle: true}
    case FETCH_RECEIPT_SUCCESS:
      return {...state, loadingSingle: false, activeReceipt: {...action.payload, locationId: {value: action.payload.locationId, label: action.payload.name}}}
    case FETCH_RECEIPT_FAIL:
      return {...state, loadingSingle: false}
    case FETCH_RECEIPTS:
      return {...state, loadingList: true}
    case FETCH_RECEIPTS_SUCCESS:
      return {...state, loadingList: false, list: action.payload.transactionReceiptTemplates, pagination: action.payload.pagination}
    case FETCH_RECEIPTS_FAIL:
      return {...state, loadingList: false}
    default:
      return state
  }
}
