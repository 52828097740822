import styled from "styled-components";
import { primaryColor } from "../StyleConstants";

const CustomButton = styled.button`
  color: ${primaryColor};
  outline: none;
  border: none;
  letter-spacing: 0.2em;
  background-color: transparent;
  cursor: pointer;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid ${primaryColor};
  }
`;
export { CustomButton };
