import styled from 'styled-components'
import { errorColor } from '../common/StyleConstants'

const ErrorMessage = styled.div`
position: absolute;
left: 0;
white-space: nowrap;
width: 100%;
margin-top:0px;
padding-left: 10px;
color: ${errorColor};
font-size:13px;
opacity: 0;
transition: all .3s ease;


  ${props => ((props.error && props.touched) || props.serverError) ? `
    display: flex;
    justify-content: flex-end;
    margin-top:5px;
    padding: 0;
    opacity: 1;
    bottom:-15px;
    top: undefined;
  ` : `
    top: 100%;
  `}
  
  ${({ customErrorStyle }) => {
    let style = ''
    if (customErrorStyle === true) style = 'bottom: auto; white-space: break-spaces;';
    return style
  }}

  
  ${({ flexStart }) => {
    let style = ''
      if (flexStart === true) style = 'justify-content: flex-start;';
    return style
  }}

  @media all and (max-width: 560px){
    white-space: break-spaces;
    bottom: -25px;
  }
`
export { ErrorMessage }
