import {
  get, remove
} from "../../helpers";
import { encodeFilter } from "../../filter/EncodeFilter";
import { CHANGE_FILTER } from "../../filter/FilterActions";

export const FETCH_LOGS = "fetch_logs";
export const FETCH_LOGS_SUCCESS = "fetch_logs_success";
export const FETCH_LOGS_FAIL = "fetch_logs_fail";

export const FETCH_LOG_BY_ID = "fetch_log_by_id";
export const FETCH_LOG_BY_ID_SUCCESS = "fetch_log_by_id_success";
export const FETCH_LOG_BY_ID_FAIL = "fetch_log_by_id_fail";
export const DELETE_LOG = "delete_log";
export const DELETE_LOG_SUCCESS = "delete_log_success";
export const DELETE_LOG_FAIL = "delete_log_fail";

export const fetchLogs = () => async (dispatch, getState) => {
  dispatch({
    type: FETCH_LOGS
  });
  let response;
  let filterParams = encodeFilter({
    ...getState().filter.logs
  });
  let url = `/logging${filterParams}`;
  try {
    response = await get({
      url
    });
    dispatch({
      type: FETCH_LOGS_SUCCESS,
      payload: response.data
    });
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: "logs",
        name: "pageCount",
        value: response.data.pagination.pageCount,
        type: "pagination",
        noFetch: true
      }
    });
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: "logs",
        name: "page",
        value: response.data.pagination.page,
        type: "pagination",
        noFetch: true
      }
    });
  } catch (error) {
    dispatch({
      type: FETCH_LOGS_FAIL,
      payload: error.response.data
    });
  }
};

export const fetchLogById = logId => {
  return async dispatch => {
    dispatch({
      type: FETCH_LOG_BY_ID
    });
    let response;
    let url = `/logging/${logId}`;
    try {
      response = await get({
        url
      });
      dispatch({
        type: FETCH_LOG_BY_ID_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_LOG_BY_ID_FAIL,
        payload: error.response.data
      });
    }
  };
};
export const deleteLog = id => {
  return async dispatch => {
    dispatch({ type: DELETE_LOG })
    try {
      await remove({ url: `/logging/${id}` })
      dispatch({
        type: DELETE_LOG_SUCCESS,
        payload: id
      })
    } catch (error) {
      dispatch({
        type: DELETE_LOG_FAIL,
        payload: id
      })
    }
  }
}