import {
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAIL,
  FETCH_SINGLE_TRANSACTION,
  FETCH_SINGLE_TRANSACTION_FAIL,
  FETCH_SINGLE_TRANSACTION_SUCCESS,
  RETURN_INITIAL_TRANSACTIONS,
  FETCH_COSTUMER_PICTURE_FAIL,
  FETCH_COSTUMER_PICTURE_SUCCESS,
  FETCH_PARTIAL_TRANSACTIONS,
  FETCH_PARTIAL_TRANSACTIONS_SUCCESS,
  FETCH_PARTIAL_TRANSACTIONS_FAIL, CLEAR_PARTIAL_TRANSACTIONS,
  EDIT_TRANSACTION,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_TRANSACTION_FAIL
} from './TransactionActions';
import {
  DOWNLOAD_TRANSACTIONS,
  DOWNLOAD_TRANSACTIONS_SUCCESS,
  DOWNLOAD_TRANSACTIONS_FAIL
} from '../../filter/FilterActions'
import { FETCH_COUNTIES_TRANSACTION } from '../locations/LocationActions';

const INITIAL_STATE = {
  transactions: [],
  partialTransactions: { loading: false, error: false },
  activeTransaction: null,
  loading: false,
  activeState: [],
  filterCounties: [],
  picture: null,
  downloadTransactionsloading: false,
  transactionsListLoading: false,
  serverError: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RETURN_INITIAL_TRANSACTIONS:
      return INITIAL_STATE;
    case FETCH_COUNTIES_TRANSACTION:
      return { ...state, filterCounties: action.payload };

    // FETCH ALL TRANSACTIONS
    case FETCH_TRANSACTIONS:
      return { ...state, transactionsListLoading: true };
    case FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: action.payload, transactionsListLoading: false };
    case FETCH_TRANSACTIONS_FAIL:
      return { ...state, error: action.payload, transactionsListLoading: false };

    case FETCH_PARTIAL_TRANSACTIONS:
      return { ...state, partialTransactions: { ...state.partialTransactions, loading: true } };
    case CLEAR_PARTIAL_TRANSACTIONS:
      return {
        ...state,
        partialTransactions: {
          ...state.partialTransactions,
          loading: false, [action.payload]: undefined
        }
      };
    case FETCH_PARTIAL_TRANSACTIONS_SUCCESS:
      if (action.payload.page === 1) {
        return {
          ...state,
          partialTransactions: {
            ...state.partialTransactions,
            [action.payload.id]: action.payload.partialTransactions,
            loading: false
          },
        };
      }
      return {
        ...state,
        partialTransactions: {
          ...state.partialTransactions,
          [action.payload.id]: [
            ...state.partialTransactions[action.payload.id],
            ...action.payload.partialTransactions
          ],
          loading: false
        },
      };

    case FETCH_PARTIAL_TRANSACTIONS_FAIL:
      return { ...state, partialTransactions: { ...state.partialTransactions, error: action.payload, loading: false } };
    // FETCH TRANSACTION BY ID
    case FETCH_SINGLE_TRANSACTION:
      return { ...state, loading: true };
    case FETCH_SINGLE_TRANSACTION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case FETCH_SINGLE_TRANSACTION_SUCCESS:

      return { ...state, loading: false, activeTransaction: action.payload };
    case FETCH_COSTUMER_PICTURE_FAIL:
      return state;
    case FETCH_COSTUMER_PICTURE_SUCCESS:
      return {
        ...state,
        picture: action.payload
      };

    case DOWNLOAD_TRANSACTIONS:
      return { ...state, downloadTransactionsloading: true };
    case DOWNLOAD_TRANSACTIONS_SUCCESS:
      return { ...state, downloadTransactionsloading: false };
    case DOWNLOAD_TRANSACTIONS_FAIL:
      return { ...state, downloadTransactionsloading: false };

    case EDIT_TRANSACTION:
      return { ...state, loading: true };
    case EDIT_TRANSACTION_SUCCESS:
      return { ...state, loading: false };
    case EDIT_TRANSACTION_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
