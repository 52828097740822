// NPM and others

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

// Common components

import { TERMS_AND_CONDITIONS_ROUTE } from '../../router/ClientRoutes'
import { primaryColor } from './common/StyleConstants'

const FooterWrapper = styled.div`
margin-top: auto;
display:flex;
justify-content:flex-start;
align-items:center;
padding: 20px 0;
border-top:1px solid rgb(229, 229, 229);
font-family:Opensans;
${props => props.auth &&
  'justify-content:center; ' +
  'position: absolute;' +
  'width: 100%;' +
  'bottom: 0;'
  }
@media all and (max-width: 560px){
  flex-direction: column;
  align-self: stretch;
}
`

const Terms = styled.p`
color: rgb(122, 122, 122);
line-height: 1.2;
font-size:14px;
padding-right:10px;
margin-right: 10px;
border-right:1px solid rgb(229, 229, 229);
@media all and (max-width: 560px){
  border-right: none;
  padding-right: 0;
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
}
`

const Footer = (props) => (
  <Translate>{({ translate }) =>
    <FooterWrapper auth={props.auth}>
      <Terms>
        © Top Pay {(new Date().getFullYear())}. {translate('everyRight')}.
      </Terms>
      <Link
        style={{
          textTransform: 'uppercase',
          textDecoration: 'none',
          fontWeight: 'bold',
          color: primaryColor,
          fontSize: 14
        }}
        to={TERMS_AND_CONDITIONS_ROUTE}
      >
        {translate('terms')}
      </Link>

    </FooterWrapper>}
  </Translate>)

Footer.propTypes = {
  auth: PropTypes.bool
}
export { Footer }
