
import { get, post } from '../helpers'
import _ from 'lodash'

export const FETCH_LANGUAGES = 'fetch_languages'
export const FETCH_LANGUAGES_SUCCESS = 'fetch_languages_success'
export const FETCH_LANGUAGES_FAIL = 'fetch_languages_fail'

export const CHANGE_LANGUAGE = 'change_language'
export const CHANGE_LANGUAGE_SUCCESS = 'change_language_success'
export const CHANGE_LANGUAGE_FAIL = 'change_language_fail'

export const getAllLanguages = () => {
  return async dispatch => {
    let response
    dispatch({ type: FETCH_LANGUAGES })
    try {
      response = await get({ url: 'languages' })
      dispatch({
        type: FETCH_LANGUAGES_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_LANGUAGES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const changeLanguage = (languageId) => {
  const data = {
    languageId: languageId
  }
  return async dispatch => {
    let response
    dispatch({ type: CHANGE_LANGUAGE })
    try {
      response = await post({ url: `/settings`, data })
      dispatch({
        type: CHANGE_LANGUAGE_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: CHANGE_LANGUAGE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
