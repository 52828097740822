import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  REFRESHING_TOKEN,
  GET_PERMISSIONS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_SUCCESS,
  IMPERSONATE_USER_SUCCESS,
  IMPERSONATE_USER_FAIL,
  IMPERSONATE_USER
} from './LoginActions'

const INITIAL_STATE = {
  isAuthenticated: false,
  header: null,
  token: {
    refreshTokenPromise: null,
    expires_in: null,
    token_type: null,
    access_token: null,
    refresh_token: null
  },
  permissions: {},
  user: {},
  error: '',
  loading: false,
  company: {},
  type: 'platform'
}
// login reducer

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMPERSONATE_USER:
      return { ...state, loading: true, company: {}, type: 'platform' }
    case IMPERSONATE_USER_FAIL:
      return { ...state, error: 'fail', loading: false }
    case IMPERSONATE_USER_SUCCESS:
      localStorage.setItem('refreshTabToken', true)
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: '',
        type: action.payload.company ? action.payload.company.type : 'platform'
      }


    case GET_PERMISSIONS:
      return { ...state, loading: true, error: '', type: 'platform' }
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        type: action.payload.company ? action.payload.company.type : 'platform'
      }
    case GET_PERMISSIONS_FAIL:
      return { ...state, loading: false, error: action.payload }

    case LOGIN_SUCCESS:
      const logInHeader = `${action.payload.token_type} ${action.payload.access_token}`
      return {
        ...state,
        token: action.payload,
        header: logInHeader,
        error: '',
        isAuthenticated: true,
        form: INITIAL_STATE.form,
        loading: false
      }
    case LOGIN_FAIL:
      return {
        ...state,
        error: 'Invalid credentials',
        loading: false
      }
    case LOGOUT_SUCCESS:
      localStorage.setItem('refreshTabToken', true) //eslint-disable-line
      return {...state, ...INITIAL_STATE}
    case LOGOUT_FAIL:
      return state

    // TOKEN ACTIONS
    case REFRESH_TOKEN_SUCCESS:
      const { token_type, access_token } = action.payload
      const header = `${token_type} ${access_token}` //eslint-disable-line
      return {
        ...state,
        error: '',
        header,
        isAuthenticated: true,
        token: {
          ...state.token,
          ...action.payload,
          refreshTokenPromise: null
        },
        loading: false
      }
    case REFRESH_TOKEN_FAIL:
      return { ...state, token: { ...state.token, refreshTokenPromise: null }, error: 'Refresh token failed', loading: false }
    case REFRESHING_TOKEN:
      return { ...state, token: { ...state.token, refreshTokenPromise: action.payload } }
    // END TOKEN ACTIONS

    default:
      return state
  }
}
