import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {Field} from 'redux-form'
import {SectionItem} from '../common/index'
import {Input} from '../'
const FieldWrapper = styled.div`
width: 100%;
display:flex;
margin-bottom: 10px;
${props => props.bold &&
  `
  label{
    font-weight: 700;
  }
  `
}
`

class FormField extends React.Component {
  render () {
    const { icon, label, name, bold, required } = this.props
    return (
      <FieldWrapper bold={bold} {...this.props}>
        <SectionItem style={{minWidth: 240, width: 'auto'}} >
          {icon && <i className={`${icon && `lnr lnr-${icon}`}`} /> }
          {label && <label >{label}</label>}
        </SectionItem>
        <Field name={name} component={this.props.component || Input} required={required}/>
      </FieldWrapper>
    )
  }
}
FormField.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  bold: PropTypes.bool,
  required: PropTypes.bool
}
export {FormField}
