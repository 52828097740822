import { get, post, put, remove } from '../../helpers'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { encodeFilter } from '../../filter/EncodeFilter'
import { push } from 'react-router-redux'
import _ from 'lodash'
import { reset } from 'redux-form'
export const ADD_NEW_CASHIER = 'add_new_cashier'
export const ADD_NEW_CASHIER_SUCCESS = 'add_new_cashier_success'
export const ADD_NEW_CASHIER_FAIL = 'add_new_cashier_fail'

export const DELETE_CASHIER = 'delete_cashier'
export const DELETE_CASHIER_SUCCESS = 'delete_cashier_success'
export const DELETE_CASHIER_FAIL = 'delete_cashier_fail'

export const EDIT_CASHIER = 'edit_cashier'
export const EDIT_CASHIER_SUCCESS = 'edit_cashier_success'
export const EDIT_CASHIER_FAIL = 'edit_cashier_fail'

export const FETCH_CASHIERS = 'fetch_cashiers'
export const FETCH_CASHIERS_SUCCESS = 'fetch_cashiers_success'
export const FETCH_CASHIERS_FAIL = 'fetch_cashiers_fail'

export const FETCH_SINGLE_CASHIER = 'fetch_cashier_by_id'
export const FETCH_SINGLE_CASHIER_SUCCESS = 'fetch_cashier_by_id_success'
export const FETCH_SINGLE_CASHIER_FAIL = 'fetch_cashier_by_id_fail'

export const RESET_SERVER_ERROR = 'RESET_SERVER_ERROR'

export const fetchCashiers = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_CASHIERS })
    let response
    let filterParams = encodeFilter(getState().filter.cashiers)
    let url = `/cashiers${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_CASHIERS_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'cashiers',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'cashiers',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_CASHIERS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchCashierById = (cashierId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SINGLE_CASHIER })
    let response
    try {
      response = await get({ url: `/cashiers/${cashierId}` })
      dispatch({
        type: FETCH_SINGLE_CASHIER_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_CASHIER_FAIL,
        payload: _.get(error, 'response.data')
      })
      throw error
    }
  }
}

export const addNewCashier = (data) => {
  return async (dispatch) => {
    dispatch({ type: ADD_NEW_CASHIER })
    let response
    try {
      response = await post({ url: `/cashiers`, data })
      dispatch({
        type: ADD_NEW_CASHIER_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`/cashiers/confirmation/${response.data.id}/${response.data.locationId}`))
    } catch (error) {
      dispatch({
        type: ADD_NEW_CASHIER_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editCashier = (data, cashierId) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_CASHIER })
    let response
    try {
      response = await put({ url: `/cashiers/${cashierId}`, data })
      dispatch({
        type: EDIT_CASHIER_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`/cashiers/view/${cashierId}`))
    } catch (error) {
      dispatch({
        type: EDIT_CASHIER_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const deleteCashier = (cashierId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CASHIER })
    try {
      await remove({ url: `/cashiers/${cashierId}` })
      dispatch({
        type: DELETE_CASHIER_SUCCESS,
        payload: cashierId
      })
    } catch (error) {
      dispatch({
        type: DELETE_CASHIER_FAIL,
        payload: cashierId
      })
      throw error
    }
  }
}

export const cashierResetServerError = () => (dispatch) => {
  dispatch({ type: RESET_SERVER_ERROR, payload: {} })
}

export const cashierResetAddForm = () => (dispatch) => {
  dispatch(reset('cashierAddForm'))
}
