// import 'core-js/es6/string'
// import 'core-js/es7/array';
import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'
import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import './resources/linearicons_style.css'
import './App.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'


ReactDOM.render(<App />, document.getElementById('root'))
registerServiceWorker()
