import styled from "styled-components";
import { primaryColor, errorColor, secondaryColor } from "./StyleConstants";

const LocationIcon = styled.span`
  display: inline-block;
  min-width: 30px;
  max-width: 35px;
  // min-height: 30px;
  line-height: 30px;
  color: ${primaryColor};
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin: 3px;
  margin-right: 10px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 50%;
  border: 1px solid ${primaryColor};
  transition: background-color .2s ease, color .2s ease;
  text-decoration: none;
  box-sizing: content-box;
  @media all and (max-width: 1023px){
    min-width: 26px;
    max-width: 26px;
    line-height: 26px;
    font-size: 12px;
  }
  @media all and (max-width: 860px){
    min-width: 24px;
    max-width: 24px;
    line-height: 24px;
    font-size: 11px;
  }
  @media all and (max-width: 480px){
    min-width: 20px;
    max-width: 20px;
    line-height: 20px;
    font-size: 11px;
  }
  ${props =>
    props.hover &&
    `
    
    &:hover{
      background-color: ${primaryColor};
      color: #fff;
    }
  `}
  ${props =>
    props.payment &&
    `
    min-width: initial;
    max-width: initial;
    margin: 0;
    border: none;
    border-radius: 0;
  `}
  ${props =>
    props.red &&
    `
    cursor:initial;
    color: ${errorColor};
    border-color:${errorColor};
    &:hover{
      color:${errorColor};
      background-color: transparent;
    }

  
  `}
  ${props =>
    props.green &&
    `
    cursor:default;
    color: ${secondaryColor};
    border-color:${secondaryColor};
    &:hover{
      color:${secondaryColor};
      background-color:transparent;
    }

  
  `}
  ${props =>
    props.filterActive &&
    `
    background-color:${primaryColor};
    color:white;
  `}
`;
export { LocationIcon };
