import { get, put } from '../../helpers'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { encodeFilter } from '../../filter/EncodeFilter'
import _ from 'lodash'
export const FETCH_TRANSACTIONS = 'fetch_transactions'
export const FETCH_TRANSACTIONS_SUCCESS = 'fetch_transactions_success'
export const FETCH_TRANSACTIONS_FAIL = 'fetch_transactions_fail'
export const FETCH_PARTIAL_TRANSACTIONS = 'fetch_partial_transactions'
export const FETCH_PARTIAL_TRANSACTIONS_SUCCESS = 'fetch_partial_transactions_success'
export const FETCH_PARTIAL_TRANSACTIONS_FAIL = 'fetch_partial_transactions_fail'
export const CLEAR_PARTIAL_TRANSACTIONS = 'clear_partial_transactions'

export const FETCH_SINGLE_TRANSACTION = 'fetch_transaction_by_id'
export const FETCH_SINGLE_TRANSACTION_SUCCESS =
  'fetch_transaction_by_id_success'
export const FETCH_SINGLE_TRANSACTION_FAIL = 'fetch_transaction_by_id_fail'
export const RETURN_INITIAL_TRANSACTIONS = 'return_initial_transactions'

export const FETCH_COSTUMER_PICTURE_SUCCESS = 'fetch_costumer_picture_success'
export const FETCH_COSTUMER_PICTURE_FAIL = 'fetch_costumer_picture_fail'

export const EDIT_TRANSACTION = 'edit_transaction'
export const EDIT_TRANSACTION_SUCCESS = 'edit_transaction_success'
export const EDIT_TRANSACTION_FAIL = 'edit_transaction_fail'


export const returnInitialTransactions = () => {
  return async dispatch => {
    dispatch({ type: RETURN_INITIAL_TRANSACTIONS })
  }
}

export const fetchTransactions = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_TRANSACTIONS })
    let filters = { ...(getState().filter.transactions) }
    let role = getState().login.role.code
    delete filters.filterBy.parentId
    // filters.filterBy.test = {
    //   name: "hasAMLPhoto",
    //   operator:'=',
    //   value: 1,
    //   type: "filterBy"
    // }

    if ((role === 'ROLE_ADMIN' || role === 'ROLE_VENDOR') && (!filters.filterBy.settlementId || _.isEmpty(filters.filterBy.settlementId))) {
      filters.filterBy.parentId = {
        name: "parentId",
        operator: 'is null',
        value: '',
        type: "filterBy"
      }
    }

    if (filters && _.isEmpty(filters.orderBy)) {
      filters.orderBy = {
        type: 'orderBy',
        name: 'completed_at',
        page: 'transactions',
        value: 'DESC',
        operator: '='
      }
    }
    if (filters && !_.isEmpty(filters.orderBy)) {
      if (filters.orderBy.name === 'completed_at') {
        filters.filterBy.status = {
          type: 'orderBy',
          name: 'status',
          operator: '=',
          value: 'completed'
        }
      } else {
        filters.filterBy.status = {}
      }
    }
    let response
    let filterParams = encodeFilter(filters)
    let url = `/transactions${filterParams}`

    if (filters && filters.filterBy && filters.filterBy.maxAmount) {
      try {
        response = await get({ url })
        dispatch({
          type: FETCH_TRANSACTIONS_SUCCESS,
          payload: _.get(response, 'data.transactions')
        })
        dispatch({
          type: CHANGE_FILTER,
          payload: {
            page: 'transactions',
            name: 'pageCount',
            value: _.get(response, 'data.pagination.pageCount'),
            type: 'pagination'
          }
        })
        dispatch({
          type: CHANGE_FILTER,
          payload: {
            page: 'transactions',
            name: 'page',
            value: _.get(response, 'data.pagination.page'),
            type: 'pagination'
          }
        })
      } catch (error) {
        dispatch({
          type: FETCH_TRANSACTIONS_FAIL,
          payload: _.get(error, 'response.data.errors')
        })
        throw error
      }
    }
  }
}


export const fetchPartialTransactions = (id, page = 1) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PARTIAL_TRANSACTIONS })
    let response
    let filter = `?filterBy[0][0]=parentId&filterBy[0][1]==&filterBy[0][2]=${id}&page=${page}`
    let url = `/transactions${filter}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_PARTIAL_TRANSACTIONS_SUCCESS,
        payload: { id, page, partialTransactions: _.get(response, 'data.transactions') }
      })
      if (response.data && response.data.pagination && response.data.pagination.page < response.data.pagination.pageCount) {
        fetchPartialTransactions(id, page + 1)(dispatch)
      }
    } catch (error) {
      dispatch({
        type: FETCH_PARTIAL_TRANSACTIONS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
export const clearPartialTransactions = (id) => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_PARTIAL_TRANSACTIONS, payload: id })
  }
}

export const fetchTransactionById = transactionId => {
  return async dispatch => {
    dispatch({ type: FETCH_SINGLE_TRANSACTION })
    let response
    try {
      response = await get({ url: `/transactions/${transactionId}` })
      dispatch({
        type: FETCH_SINGLE_TRANSACTION_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_TRANSACTION_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchCostumerPicture = transactionId => {
  return async dispatch => {
    let response
    try {
      response = await get({
        url: `/transactions/${transactionId}/identity-document`
      })
      dispatch({
        type: FETCH_COSTUMER_PICTURE_SUCCESS,
        payload: _.get(response, 'data.image')
      })
    } catch (error) {
      dispatch({
        type: FETCH_COSTUMER_PICTURE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editTransaction = (data, transactionId) => {
  return async dispatch => {
    dispatch({ type: EDIT_TRANSACTION })
    let response
    try {
      response = await put({ url: `/transactions/${transactionId}`, data })
      dispatch({
        type: EDIT_TRANSACTION_SUCCESS,
        payload: _.get(response, 'data'),
      })
      await dispatch(fetchTransactionById(transactionId))
    } catch (error) {
      dispatch({
        type: EDIT_TRANSACTION_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
