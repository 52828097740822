import React, { Component } from 'react'
import { connect } from 'react-redux'

import { CustomSelect } from '../../partials/form'
import { appendVendors, fetchVendors } from './VendorActions'
import { changeFilter } from '../../filter/FilterActions'
import { getMySwalErrorPopup } from '../../../globals/getMySwalPopups'

class VendorsSelect extends Component {
  async componentDidMount() {
    const { fetchVendors, changeFilter, withoutPagination } = this.props
    try {
      await changeFilter({
        type: 'filterBy',
        name: 'vendorType',
        page: 'vendors',
        value: 'vendor',
        operator: '=',
      })
      await changeFilter({
        fetchFunction: fetchVendors,
        page: 'vendors',
        type: 'pagination',
        name: 'pageSize',
        value: withoutPagination === true ? 999 : 10,
        operator: '='
      })
    } catch (e) {
      getMySwalErrorPopup()
    }
  }

  onMenuScrollToBottom = async () => {
    const { pageCount, page, appendVendors } = this.props
    if (page + 1 <= pageCount) {
      try {
        await appendVendors(page + 1)
      } catch (e) {
        getMySwalErrorPopup()
      }
    }
  }
  onValueChange = (e) => {
    const { input, onValueClick, ifFromAddSettlementCompany } = this.props
    if (onValueClick) {
      onValueClick(e)
    }
    input.onChange(e)

    if (ifFromAddSettlementCompany) {
      this.props.input.onChange(e)
    }
  }

  searchVendors = async value => {
    const { changeFilter, fetchVendors } = this.props
    try {
      await changeFilter({
        fetchFunction: fetchVendors,
        page: 'vendors',
        name: 'searchBy',
        type: 'searchBy',
        value
      })
    } catch (e) {
      getMySwalErrorPopup()
    }
  }

  render() {
    const { vendors, listLoading, noPadding, addSelectAllFirst, itemToAddFirst, input } = this.props
    const options = vendors.map(vendor => ({ label: vendor.name, value: vendor.id }))
    if (addSelectAllFirst) {
      options.unshift(itemToAddFirst)
    }
    return (
      <CustomSelect
        {...this.props}
        required
        onInputChange={value => this.searchVendors(value)}
        onMenuScrollToBottom={this.onMenuScrollToBottom}
        onChange={this.onValueChange}
        options={options}
        listLoading={listLoading}
        noPadding={noPadding}
        translateCurrentValue={input && input.value && input.value.key ? true : false}
      />
    )
  }
}

export default connect(
  state => ({
    vendors: state.vendors.companies,
    page: state.vendors.pagination.currentPage,
    pageCount: state.vendors.pagination.pageCount,
    listLoading: state.vendors.listLoading
  }),
  {
    fetchVendors,
    appendVendors,
    changeFilter,
  }
)(VendorsSelect)
