import {
  ADD_IP, ADD_IP_FAIL, ADD_IP_SUCCESS,
  DELETE_IP,
  DELETE_IP_FAIL,
  DELETE_IP_SUCCESS,
  FETCH_IPS,
  FETCH_IPS_FAIL,
  FETCH_IPS_SUCCESS
} from './IpActions'

const INITIAL_STATE = {
  ips: [],
  loading: [],
  error: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_IPS: {
      return {...state, loading: true}
    }
    case FETCH_IPS_SUCCESS: {
      return {...state, loading: false, ...action.payload}
    }
    case FETCH_IPS_FAIL: {
      return {...state, loading: false, error: action.payload}
    }
    case DELETE_IP: {
      return {...state, loading: false}
    }
    case DELETE_IP_SUCCESS: {
      return {...state, loading: false, ...action.payload}
    }
    case DELETE_IP_FAIL: {
      return {...state, loading: false, error: action.payload}
    }
    case ADD_IP: {
      return {...state, loading: false}
    }
    case ADD_IP_SUCCESS: {
      return {...state, loading: false, ...action.payload}
    }
    case ADD_IP_FAIL: {
      return {...state, loading: false, error: action.payload}
    }
    default: {
      return state
    }
  }
}
