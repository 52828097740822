import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  .material-icons {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    &:hover {
      cursor: pointer;
    }
  }

  ${({ styles }) => {
    let style = ''
    if (styles) style = styles;
    return style
  }}
`

export class MaterialIcon extends React.Component {
  render() {
    return (
      <Wrapper styles={this.props.wrapperStyle ? this.props.wrapperStyle : ''}>
        {
          this.props.outline ?
            <i className={`material-icons-outlined ${this.props.className || ''}`} style={this.props.style} onClick={this.props.onClick}>
              {`${this.props.iconName}`}
            </i>
            :
            <i className={`material-icons ${this.props.className || ''}`} style={this.props.style} onClick={this.props.onClick}>
              {`${this.props.iconName}`}
            </i>
        }

      </Wrapper>
    )
  }
}