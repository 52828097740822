import styled from 'styled-components'
import {
  textColor,
} from './StyleConstants'

const SectionHeader = styled.h2`
  color: ${textColor};
  font-size: 18px;
  font-weight: 700;
  padding: 16px 0;
  text-transform: uppercase;
  &:first-of-type {
    padding-top: 0
  }
`

export { SectionHeader }
