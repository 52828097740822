import _ from 'lodash'
import { push } from 'react-router-redux'
import { API_URL_LIVE } from '../../../config/apiRoutes'
import { SETTLEMENTS_ROUTE } from '../../../router/ClientRoutes'
import { encodeFilter } from '../../filter/EncodeFilter'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { get, post, put } from '../../helpers'

export const GET_SETTLEMENTS = 'GET_SETTLEMENTS'
export const GET_SETTLEMENTS_SUCCESS = 'GET_SETTLEMENTS_SUCCESS'
export const GET_SETTLEMENTS_FAIL = 'GET_SETTLEMENTS_FAIL'

export const GET_SETTLEMENT = 'GET_SETTLEMENT'
export const GET_SETTLEMENT_SUCCESS = 'GET_SETTLEMENT_SUCCESS'
export const GET_SETTLEMENT_FAIL = 'GET_SETTLEMENT_FAIL'

export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS'
export const GET_CURRENCIES_FAIL = 'GET_CURRENCIES_FAIL'

export const ADD_SETTLEMENT = 'ADD_SETTLEMENT'
export const ADD_SETTLEMENT_SUCCESS = 'ADD_SETTLEMENT_SUCCESS'
export const ADD_SETTLEMENT_FAIL = 'ADD_SETTLEMENT_FAIL'

export const PREVIEW_SETTLEMENT = 'PREVIEW_SETTLEMENT'
export const PREVIEW_SETTLEMENT_SUCCESS = 'PREVIEW_SETTLEMENT_SUCCESS'
export const PREVIEW_SETTLEMENT_FAIL = 'PREVIEW_SETTLEMENT_FAIL'

export const UPDATE_SETTLEMENT = 'UPDATE_SETTLEMENT'
export const UPDATE_SETTLEMENT_SUCCESS = 'UPDATE_SETTLEMENT_SUCCESS'
export const UPDATE_SETTLEMENT_FAIL = 'UPDATE_SETTLEMENT_FAIL'

export const CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY'
export const CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS'
export const CHECK_ELIGIBILITY_FAIL = 'CHECK_ELIGIBILITY_FAIL'

export const GENERATE_INVOICE = 'GENERATE_INVOICE'
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS'
export const GENERATE_INVOICE_FAIL = 'GENERATE_INVOICE_FAIL'

export const CANCEL_INVOICE = 'CANCEL_INVOICE'
export const CANCEL_INVOICE_SUCCESS = 'CANCEL_INVOICE_SUCCESS'
export const CANCEL_INVOICE_FAIL = 'CANCEL_INVOICE_FAIL'

export const GET_STATUS_INTERVALS = 'GET_STATUS_INTERVALS'
export const GET_STATUS_INTERVALS_SUCCESS = 'GET_STATUS_INTERVALS_SUCCESS'
export const GET_STATUS_INTERVALS_FAIL = 'GET_STATUS_INTERVALS_FAIL'

export const GET_SETTLEMENT_TRANSACTIONS = 'GET_SETTLEMENT_TRANSACTIONS'
export const GET_SETTLEMENT_TRANSACTIONS_SUCCESS = 'GET_SETTLEMENT_TRANSACTIONS_SUCCESS'
export const GET_SETTLEMENT_TRANSACTIONS_FAIL = 'GET_SETTLEMENT_TRANSACTIONS_FAIL'

export const GENERATE_TRANSACTIONS_REPORT = 'GENERATE_TRANSACTIONS_REPORT'
export const GENERATE_TRANSACTIONS_REPORT_SUCCESS = 'GENERATE_TRANSACTIONS_REPORT_SUCCESS'
export const GENERATE_TRANSACTIONS_REPORT_FAIL = 'GENERATE_TRANSACTIONS_REPORT_FAIL'

export const DELETE_TRANSACTIONS_REPORT = 'DELETE_TRANSACTIONS_REPORT'
export const DELETE_TRANSACTIONS_REPORT_SUCCESS = 'DELETE_TRANSACTIONS_REPORT_SUCCESS'
export const DELETE_TRANSACTIONS_REPORT_FAIL = 'DELETE_TRANSACTIONS_REPORT_FAIL'

export const DOWNLOAD_SETTLEMENT_TRANSACTIONS = 'DOWNLOAD_SETTLEMENT_TRANSACTIONS'
export const DOWNLOAD_SETTLEMENT_TRANSACTIONS_SUCCESS = 'DOWNLOAD_SETTLEMENT_TRANSACTIONS_SUCCESS'
export const DOWNLOAD_SETTLEMENT_TRANSACTIONS_FAIL = 'DOWNLOAD_SETTLEMENT_TRANSACTIONS_FAIL'

export const FETCH_ISSUE_DATE_INTERVAL = 'FETCH_ISSUE_DATE_INTERVAL'
export const FETCH_ISSUE_DATE_INTERVAL_SUCCESS = 'FETCH_ISSUE_DATE_INTERVAL_SUCCESS'
export const FETCH_ISSUE_DATE_INTERVAL_FAIL = 'FETCH_ISSUE_DATE_INTERVAL_FAIL'

export const FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID = 'FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID'
export const FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_SUCCESS = 'FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_SUCCESS'
export const FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_FAIL = 'FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_FAIL'



export const getSettlements = () => async (dispatch, getState) => {
  dispatch({ type: GET_SETTLEMENTS })
  let response
  let filterParams = encodeFilter({
    ...getState().filter.settlements,
    isSettlement: true
  })
  let url = `/settlements${filterParams}`
  try {
    response = await get({ url })
    dispatch({
      type: GET_SETTLEMENTS_SUCCESS,
      payload: _.get(response, 'data'),
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'settlements',
        name: 'pageCount',
        value: _.get(response, 'data.pagination.pageCount'),
        type: 'pagination',
        noFetch: true
      }
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'settlements',
        name: 'page',
        value: _.get(response, 'data.pagination.page'),
        type: 'pagination',
        noFetch: true
      }
    })
  } catch (error) {
    dispatch({
      type: GET_SETTLEMENTS_FAIL,
      payload: _.get(error, 'response.data.errors.default.msg')
    })
    throw error
  }
}

export const addSettlement = data => {
  return async dispatch => {
    dispatch({ type: ADD_SETTLEMENT })
    let response
    try {
      response = await post({ url: '/settlements', data })
      dispatch({
        type: ADD_SETTLEMENT_SUCCESS,
        payload: _.get(response, 'data'),
      })
      const id = _.get(response, 'data.settlement.id')
      dispatch(push(`${SETTLEMENTS_ROUTE}/confirmation/${id}`))
    } catch (error) {
      dispatch({
        type: ADD_SETTLEMENT_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const previewSettlement = data => {
  return async dispatch => {
    dispatch({ type: PREVIEW_SETTLEMENT })
    let response
    try {
      response = await post({ url: '/settlements/preview', data })
      dispatch({
        type: PREVIEW_SETTLEMENT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: PREVIEW_SETTLEMENT_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const checkEligibility = (companyId, currency) => {
  return async dispatch => {
    dispatch({ type: CHECK_ELIGIBILITY })
    let response
    try {
      response = await get({
        url: `/settlements/check-eligibility?companyId=${companyId}&currency=${currency}`
      })
      dispatch({
        type: CHECK_ELIGIBILITY_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: CHECK_ELIGIBILITY_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const fetchSettlement = ({ id }) => {
  return async dispatch => {
    dispatch({ type: GET_SETTLEMENT })
    let response
    try {
      response = await get({ url: `/settlements/${id}` })
      dispatch({
        type: GET_SETTLEMENT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GET_SETTLEMENT_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const updateSettlement = (id, data) => {
  return async dispatch => {
    dispatch({ type: UPDATE_SETTLEMENT })
    let response
    try {
      response = await put({ url: `${API_URL_LIVE}/settlements/${id}`, data })
      dispatch({
        type: UPDATE_SETTLEMENT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: UPDATE_SETTLEMENT_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const uploadInvoice = (id, data) => {
  return async dispatch => {
    let response
    dispatch({
      type: GENERATE_INVOICE
    })
    try {
      response = await post({
        url: `${API_URL_LIVE}/settlements/${id}/invoice`,
        data
      })
      dispatch({
        type: GENERATE_INVOICE_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GENERATE_INVOICE_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const getCurrencies = id => {
  return async dispatch => {
    let response
    dispatch({
      type: GET_CURRENCIES
    })
    try {
      response = await get({
        url: `${API_URL_LIVE}/companies/${id}`
      })
      dispatch({
        type: GET_CURRENCIES_SUCCESS,
        payload: _.get(response, 'data.currencies'),
      })
    } catch (error) {
      dispatch({
        type: GET_CURRENCIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const generateInvoice = (id) => {
  return async dispatch => {
    let response
    dispatch({
      type: GENERATE_INVOICE
    })
    try {
      response = await post({
        url: `${API_URL_LIVE}/settlements/${id}/invoice/generate`
      })
      dispatch({
        type: GENERATE_INVOICE_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GENERATE_INVOICE_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const cancelInvoice = (id) => {
  return async dispatch => {
    let response
    dispatch({
      type: CANCEL_INVOICE
    })
    try {
      response = await post({
        url: `${API_URL_LIVE}/settlements/${id}/invoice/cancel`
      })
      dispatch({
        type: CANCEL_INVOICE_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: CANCEL_INVOICE_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const fetchSettlementStatusIntervals = (id) => {
  return async dispatch => {
    dispatch({ type: GET_STATUS_INTERVALS })
    let response
    try {
      response = await get({ url: `/settlements/${id}/status-intervals` })
      dispatch({
        type: GET_STATUS_INTERVALS_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GET_STATUS_INTERVALS_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const downloadSettlementTransactions = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: DOWNLOAD_SETTLEMENT_TRANSACTIONS })
    let response
    try {
      response = await get({ url: `/settlements/${id}/transactions/export` })
      dispatch({
        type: DOWNLOAD_SETTLEMENT_TRANSACTIONS_SUCCESS,
        payload: _.get(response, 'data'),
      })

    } catch (error) {
      dispatch({
        type: DOWNLOAD_SETTLEMENT_TRANSACTIONS_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const generateTransactionsReport = (id) => {
  return async dispatch => {
    let response
    dispatch({
      type: GENERATE_TRANSACTIONS_REPORT
    })
    try {
      response = await post({
        url: `${API_URL_LIVE}/settlements/${id}/transactions-report/generate`
      })
      dispatch({
        type: GENERATE_TRANSACTIONS_REPORT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: GENERATE_TRANSACTIONS_REPORT_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const deleteTransactionsReport = (id) => {
  return async dispatch => {
    let response
    dispatch({
      type: DELETE_TRANSACTIONS_REPORT
    })
    try {
      response = await post({
        url: `${API_URL_LIVE}/settlements/${id}/transactions-report/cancel`
      })
      dispatch({
        type: DELETE_TRANSACTIONS_REPORT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: DELETE_TRANSACTIONS_REPORT_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const fetchIssueDateInterval = (companyId, currency) => {
  return async dispatch => {
    dispatch({ type: FETCH_ISSUE_DATE_INTERVAL })
    let response
    try {
      response = await get({
        url: `/settlements/issue-date-interval?companyId=${companyId}&currency=${currency}`
      })
      dispatch({
        type: FETCH_ISSUE_DATE_INTERVAL_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: FETCH_ISSUE_DATE_INTERVAL_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

export const fetchSettlementIssueDateIntervals = (id) => {
  return async dispatch => {
    dispatch({ type: FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID })
    let response
    try {
      response = await get({ url: `/settlements/${id}/issue-date-interval` })
      dispatch({
        type: FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_FAIL,
        payload: _.get(error, 'response.data.errors.default.msg')
      })
      throw error
    }
  }
}

// export const getClientCurrencies = clientId => {
//   return async dispatch => {
//     dispatch({ type: GET_CURRENCIES })
//     let response
//     try {
//       response = await get({ url: `/companies/${clientId}` })
//       dispatch({
//         type: GET_CURRENCIES_SUCCESS,
//         payload: response.data
//       })
//     } catch (error) {
//       dispatch({
//         type: GET_CURRENCIES_FAIL,
//         payload: error.response.data.errors
//       })
//     }
//   }
// }
