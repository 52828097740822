import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { secondaryColor } from './StyleConstants'

const Wrapper = styled.div`
  margin-top:30px; 
    display:flex;
    align-items:center;
  .paginationContainer{
    display:flex;
    align-items:center;
    margin-left: auto;
    li{
       font-family: "Opensans";
        font-size: 12px; 
     
      text-decoration:none;
      border-radius:50%;
      margin-left:5px;
      cursor:pointer;
      width:40px;
      height:40px;
      a{
        display:flex;
        align-items:center;
        justify-content:center;
        &:focus{
          outline:0;
        }
        outline:0;
        width:100%;
        height:100%;
      }
      &:hover, &.selected{
        background-color: ${secondaryColor};
        color: white;
        font-weight: 700;
        transition: background-color .2s ease-in;
        
        @media only screen and (max-width:560px){
            border: none;
        }
      }
      
      @media only screen and (max-width: 640px) {
        width: 30px;
        height: 30px;
      } 
      
      @media only screen and (max-width: 390px) {
        width: 20px;
        height: 20px;
      } 
    }
    .break{
      padding: 13px 0 0 0;
      text-align: center;
      border: 0!important;
      color:initial!important;
      cursor: initial!important;
    }
  }
  .nextPageClass, .previousPageClass{
    font-family:'Linearicons-free';
    font-size:0;
    &:before{
      font-size:16px;
      content:'\\e876';
    }
  }
  .previousPageClass{
    &:before{
      content:'\\e875';
    }
  }
  @media only screen and (max-width: 640px) {
    .paginationContainer{
         li {
            margin: 0 2px;
            }
        }
      }
`

class Pagination extends Component {
  onPageChange = ({selected}) => { //eslint-disable-line
    this.props.onChange(selected + 1)
  }

  render () {
    return (
      <Wrapper>
        <ReactPaginate
          nextClassName='nextPage'
          previousClassName='previousPage'
          containerClassName='paginationContainer'
          onPageChange={this.onPageChange}
          pageRangeDisplayed={2}
          forcePage={this.props.forcePage}
          marginPagesDisplayed={1}
          pageCount={this.props.pageCount}
          nextLinkClassName='nextPageClass'
          previousLinkClassName='previousPageClass'
        />
      </Wrapper>
    )
  }
}

Pagination.propTypes = {}

export default Pagination
