import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { Translate } from 'react-localize-redux'
import { Field } from 'redux-form'

import MerchantsSelect from '../../pages/merchants/MerchantsSelect'
import VendorsSelect from '../../pages/vendors/VendorsSelect'
import { CustomSelect, FieldLabel } from '../form'
import CustomNumberInput from '../form/CustomNumberInput'
import { ActionButton } from './buttons'
import { IconButton, IconGroup, IconLabel } from './IconGroup'
import { Section } from './Section'
import { SectionItem } from './SectionItem'

export const commissionCompound = commissions => {
  let commissionsData = []
  _.map(commissions, item => {
    const { company } = item
    if (_.get(item, 'transaction.deposit.amount')) {
      commissionsData.push({
        companyId: company.value,
        type: 'fixed',
        amount: Number(_.get(item, 'transaction.deposit.amount')),
        transactionType: 'deposit'
      })
    }
    if (_.get(item, 'transaction.withdraw.amount')) {
      commissionsData.push({
        companyId: company.value,
        type: 'fixed',
        amount: Number(_.get(item, 'transaction.withdraw.amount')),
        transactionType: 'withdraw'
      })
    }
    if (_.get(item, 'monthly.amount')) {
      commissionsData.push({
        companyId: company.value,
        type: 'interval',
        amount: Number(_.get(item, 'monthly.amount')),
        value: _.get(item, 'monthly.value.value') || 'month'
      })
    }
    if (_.get(item, 'commissions.deposit.amount')) {
      commissionsData.push({
        companyId: company.value,
        type: 'percentage',
        amount: Number(_.get(item, 'commissions.deposit.amount')),
        minAmount: Number(_.get(item, 'commissions.deposit.minAmount')),
        maxAmount: Number(_.get(item, 'commissions.deposit.maxAmount')),
        transactionType: 'deposit'
      })
    }
    if (_.get(item, 'commissions.withdraw.amount')) {
      commissionsData.push({
        companyId: company.value,
        type: 'percentage',
        amount: Number(_.get(item, 'commissions.withdraw.amount')),
        minAmount: Number(_.get(item, 'commissions.withdraw.minAmount')),
        maxAmount: Number(_.get(item, 'commissions.withdraw.maxAmount')),
        transactionType: 'withdraw'
      })
    }
  })

  return commissionsData
}

export const resolveCommissions = (rawData, type) => {
  const getWhat = type === 'merchant' ? 'vendor' : 'merchant'
  const company = _.get(rawData[0], getWhat)

  let commissions = {
    company: {
      label: _.get(company, 'name') || null,
      value: _.get(company, 'id') || null
    },
    transaction: {},
    commissions: {},
    monthly: {}
  }
  _.map(rawData, ({ type, amount, value, transactionType, maxAmount, minAmount }) => {
    if (type === 'fixed') {
      commissions.transaction[transactionType] = {
        amount
      }
    } else if (type === 'percentage') {
      commissions.commissions[transactionType] = {
        amount,
        maxAmount,
        minAmount
      }
    } else if (type === 'interval') {
      commissions.monthly = {
        amount,
        value: { label: _.startCase(value), value: value }
      }
    }
  })

  return commissions
}

export const groupCommissions = (rawData, type) => {
  const groupByWhat = type === 'merchant' ? 'vendorId' : 'merchantId'
  const commissionsGroups = _.groupBy(rawData, groupByWhat)

  return [..._.map(commissionsGroups, (item, key) => resolveCommissions(item, type))]
}

class CommissionCalculator extends Component {
  render() {
    const { fields, isVendor } = this.props
    return (
      <Translate>
        {({ translate }) => (
          <Fragment>
            {fields && fields.map((name, index) => (
              <CommissionRow
                {...this.props}
                fields={fields}
                name={name}
                index={index}
                isVendor={isVendor}
                key={index}
              />
            ))}
            <SectionItem>
              <ActionButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  fields.push({})
                }}
              >
                <i className='lnr  lnr-plus-circle' />
                <span>{translate('addCommission')}</span>
              </ActionButton>
            </SectionItem>
          </Fragment>
        )}
      </Translate>
    )
  }
}

class CommissionRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showCommission: false,
      showTransaction: false,
      showMonthly: false,
      showProfit: false
    }
  }

  componentDidMount() {
    const { fields, index } = this.props
    const { transaction, commissions, monthly } = fields.get(index)
    !_.isEmpty(transaction) && this.showThis('showTransaction')
    !_.isEmpty(commissions) && this.showThis('showCommission')
    !_.isEmpty(monthly) && this.showThis('showMonthly')
  }

  showThis = what => {
    this.setState({ [what]: !this.state[what] })
  }

  render() {
    const { showCommission, showMonthly, showTransaction, showProfit } = this.state
    const { name, index, fields, isVendor } = this.props
    return (
      <Translate>
        {({ translate }) => (
          <Section className={'row mr-0 ml-0 mb-2'}>
            <SectionItem className={'col-12'}>
              <IconGroup onClick={() => fields.remove(index)}>
                <IconButton delete>
                  <i className={`lnr lnr-cross-circle`} />
                </IconButton>
                <IconLabel delete>
                  {translate(
                    !isVendor ? 'deleteVendorCommission' : 'deleteMerchantCommission'
                  )}
                </IconLabel>
              </IconGroup>
            </SectionItem>
            <SectionItem className={'col-12'}>
              <FieldLabel bold>
                {translate(!isVendor ? 'chooseVendor' : 'chooseMerchant')}:
              </FieldLabel>
              {!isVendor ? (
                <Field name={`${name}.company`} component={VendorsSelect} />
              ) : (
                <Field name={`${name}.company`} component={MerchantsSelect} />
              )}
            </SectionItem>
            <SectionItem className={'col-12'}>
              <IconGroup onClick={() => this.showThis('showTransaction')}>
                <IconButton delete={showTransaction}>
                  <i
                    className={`lnr ${
                      showTransaction ? 'lnr-cross-circle' : 'lnr-plus-circle'
                    }`}
                  />
                </IconButton>
                <IconLabel delete={showTransaction}>
                  {showTransaction
                    ? translate('deletePayPerTransaction')
                    : translate('addPayPerTransaction')}
                </IconLabel>
              </IconGroup>
            </SectionItem>
            {showTransaction && (
              <Fragment>
                <SectionItem className={'col-6'}>
                  <FieldLabel>{translate('deposit')}</FieldLabel>
                  <Field
                    name={`${name}.transaction.deposit.amount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
                <SectionItem className={'col-6'}>
                  <FieldLabel>{translate('withdraw')}</FieldLabel>
                  <Field
                    name={`${name}.transaction.withdraw.amount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
              </Fragment>
            )}
            <SectionItem className={'col-12'}>
              <IconGroup onClick={() => this.showThis('showCommission')}>
                <IconButton delete={showCommission}>
                  <i
                    className={`lnr ${
                      showCommission ? 'lnr-cross-circle' : 'lnr-plus-circle'
                    }`}
                  />
                </IconButton>
                <IconLabel delete={showCommission}>
                  {showCommission
                    ? translate('deleteCommissionPerTransaction')
                    : translate('addCommissionPerTransaction')}
                </IconLabel>
              </IconGroup>
            </SectionItem>
            {showCommission && (
              <Fragment>
                <SectionItem className={'col-4'}>
                  <FieldLabel>{translate('deposit')}</FieldLabel>
                  <Field
                    name={`${name}.commissions.deposit.amount`}
                    component={CustomNumberInput}
                    currency='%'
                  />
                </SectionItem>
                <SectionItem className={'col-4'}>
                  <FieldLabel>{translate('minDeposit')}</FieldLabel>
                  <Field
                    name={`${name}.commissions.deposit.minAmount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
                <SectionItem className={'col-4'}>
                  <FieldLabel>{translate('maxDeposit')}</FieldLabel>
                  <Field
                    name={`${name}.commissions.deposit.maxAmount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
                <SectionItem className={'col-4'}>
                  <FieldLabel>{translate('withdraw')}</FieldLabel>
                  <Field
                    name={`${name}.commissions.withdraw.amount`}
                    component={CustomNumberInput}
                    currency='%'
                  />
                </SectionItem>
                <SectionItem className={'col-4'}>
                  <FieldLabel>{translate('minWithdraw')}</FieldLabel>
                  <Field
                    name={`${name}.commissions.withdraw.minAmount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
                <SectionItem className={'col-4'}>
                  <FieldLabel>{translate('maxWithdraw')}</FieldLabel>
                  <Field
                    name={`${name}.commissions.withdraw.maxAmount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
              </Fragment>
            )}
            <SectionItem className={'col-12'}>
              <IconGroup onClick={() => this.showThis('showMonthly')}>
                <IconButton delete={showMonthly}>
                  <i
                    className={`lnr ${
                      showMonthly ? 'lnr-cross-circle' : 'lnr-plus-circle'
                    }`}
                  />
                </IconButton>
                <IconLabel delete={showMonthly}>
                  {showMonthly
                    ? translate('deleteDailyMonthlyCommission')
                    : translate('addDailyMonthlyCommission')}
                </IconLabel>
              </IconGroup>
            </SectionItem>
            {showMonthly && (
              <Fragment>
                <SectionItem className={'col-6'}>
                  <Field
                    name={`${name}.monthly.amount`}
                    component={CustomNumberInput}
                    currency='RON'
                  />
                </SectionItem>
                <SectionItem className={'col-6'}>
                  <Field
                    name={`${name}.monthly.value`}
                    component={CustomSelect}
                    defaultValue={'month'}
                    options={[
                      {
                        label: translate('monthly'),
                        value: 'month'
                      },
                      {
                        label: translate('daily'),
                        value: 'day'
                      }
                    ]}
                  />
                </SectionItem>
              </Fragment>
            )}
            <SectionItem className={'col-12'}>
              <IconGroup onClick={() => this.showThis('showProfit')}>
                <IconButton delete={showProfit}>
                  <i
                    className={`lnr ${
                      showProfit ? 'lnr-cross-circle' : 'lnr-plus-circle'
                    }`}
                  />
                </IconButton>
                <IconLabel delete={showProfit}>
                  {showProfit
                    ? translate('deletePerProfitCommission')
                    : translate('addPerProfitCommission')}
                </IconLabel>
              </IconGroup>
            </SectionItem>
            {showProfit && (
              <SectionItem className={'col-12'}>
                <Field
                  name={`${name}.profit.amount`}
                  component={CustomNumberInput}
                  currency='%'
                />
              </SectionItem>
            )}
          </Section>
        )}
      </Translate>
    )
  }
}

export { CommissionCalculator }
