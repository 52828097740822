import styled from 'styled-components'

import { colorSecondary, errorColor, primaryColor } from './StyleConstants'

const IconGroup = styled.div`
  flex: 0.6;
  display: flex;
  white-space: nowrap;
  ${({ center }) => center && `align-items: center; justify-content: center;`}
  ${({ end }) => end && `align-items: center; justify-content: flex-end;`}
  ${({ marginLeftAuto }) => marginLeftAuto && `margin-left: auto`}
  ${({ usersListIcon }) => usersListIcon && `flex: 0.3;`}
  ${({ link }) => link && `
    color: #014FFF;
    display: flex;
    align-items: center;
    font-family: "Opensans Semibold";
    font-size: 14px;
    cursor: pointer;

    :hover {
      opacity: 0.75;
    }

    i {
      font-size: 18px;
    }
  `}
`

const IconGroupSecretApi = styled.div`
  flex: 0.6;
  display: flex;
  max-width: 25px;
  align-items: center;
`

const IconLabel = styled.span`
  white-space: break-spaces;
  font-size: 12px;
  line-height: 24px;
  color: ${primaryColor};
  margin-left: 4px;
  opacity: 0.75;

  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }

  ${props =>
    props.delete &&
    `
    color: ${errorColor};
  `}

  ${props => props.noBreak && `
    white-space: nowrap;
  `}
`

const IconButton = styled.span`
   margin-right: 8px;
   text-align: center;
  //  background: ${primaryColor};
   background: #135AFC;
   border-radius: 100em;
   opacity: 0.75;
    height: 24px;
   width: 24px;
   
   i {
    font-size: 13px;
    line-height: 24px;
    color: white;
  }
   
   ${props =>
    props.scale &&
    `
   height: ${24 * (props.scale ? props.scale : 1)}px;
   width: ${24 * (props.scale ? props.scale : 1)}px;
   
   i {
    font-size: ${13 * (props.scale ? props.scale : 1)}px;
    line-height: ${24 * (props.scale ? props.scale : 1)}px;
  }
  `}
   
   &:last-child {
    margin-right: 0;
   }
  
  &:hover {
    cursor: pointer;
    opacity: 1;
    transition: opacity .2s ease-in;
  }
  
   ${props =>
    props.delete &&
    `
    background: transparent;
    border: 1px solid #E61515;
    opacity: 1;
    i {
      color: #E61515;
    }
    &:hover {
      background: white;
      transition: background .2s ease-in;
      opacity: 0.75;
      & i {
        color: #E61515;
        transition: color .2s ease-in;
      }
    }
  `}

    
  ${props =>
    props.edit &&
    `
    opacity: 1;
    border-radius: 100em;

    &:hover {
      opacity: 0.75;
      transition: opacity .2s ease-in;
    }
  `}

  ${props =>
    props.red &&
    `
   background: transparent;
   border: 1px solid #E61515;
   i {
     color: #E61515;
     font-size: 17px;
   }
   &:hover {
     background: #E61515;
     transition: background .2s ease-in;
     & i {
       color: white;
       transition: color .2s ease-in;
     }
   }
 `}

 ${props =>
    props.flex &&
    `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  
  ${props =>
    props.border &&
    `
    background: transparent;
    border: 1px solid ${primaryColor};
    i {
      color: ${primaryColor};
    }
    &:hover {
      background: ${primaryColor};
      transition: background .2s ease-in;
      & i {
        color: white;
        transition: color .2s ease-in;
      }
    
  `}
  
  ${props =>
    props.success &&
    `
    background: ${colorSecondary};
  `}
  
`

export { IconGroup, IconButton, IconLabel, IconGroupSecretApi }
