import React, { Component } from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components'
// import { Select } from './CustomSelect'
import { addIconColor } from '../common/StyleConstants'
import { Translate } from 'react-localize-redux'
import { FieldLabel } from '../form'
import CustomNumberInput from '../form/CustomNumberInput'
import { Section, SectionItem } from '../common'
import CustomInput from '../form/CustomInput'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  option {
    font-weight: bold;
    color: ${addIconColor};
  }
  @media all and (max-width: 560px) {
    width: 100%;
    margin-top: 10px;
  }
`

const Item = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

// const SubItem = styled.div`
//   margin-bottom:10px;
//   color: black;
//   display: flex;
//   align-items: center;
// `

class CommissionViewer extends Component {
  toggleComission = () => {
    const { showCommission } = this.state

    this.setState({ showCommission: !showCommission })
  }
  toggleMonthly = () => {
    const { showMonthly } = this.state

    this.setState({ showMonthly: !showMonthly })
  }
  toggleTransaction = () => {
    const { showTransaction } = this.state

    this.setState({ showTransaction: !showTransaction })
  }

  constructor(props) {
    super(props)
    this.state = {
      showCommission: false,
      showTransaction: false,
      showMonthly: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialValues &&
      nextProps.initialValues.commissions &&
      nextProps.initialValues.commissions.transaction &&
      (nextProps.initialValues.commissions.transaction.deposit ||
        nextProps.initialValues.commissions.transaction.withdraw)
    ) {
      this.setState({
        showTransaction: true
      })
    }
    if (
      nextProps.initialValues &&
      nextProps.initialValues.commissions &&
      nextProps.initialValues.commissions.commissions &&
      (nextProps.initialValues.commissions.commissions.deposit ||
        nextProps.initialValues.commissions.commissions.withdraw)
    ) {
      this.setState({
        showCommission: true
      })
    }
    if (
      nextProps.initialValues &&
      nextProps.initialValues.commissions &&
      nextProps.initialValues.commissions.monthly
    ) {
      this.setState({
        showMonthly: true
      })
    }
  }

  render() {
    const { showCommission, showMonthly, showTransaction } = this.state
    // const { deleteInputValues } = this.props

    return (
      <Translate>
        {({ translate }) => (
          <Wrapper>
            <Item>
              {showTransaction ? (
                <div>{translate('showPayPerTransaction')}</div>
              ) : null}
            </Item>
            {showTransaction ? (
              <Section style={{ width: '100%' }}>
                <SectionItem>
                  <i />
                  <FieldLabel>{translate('deposit')}:</FieldLabel>
                  <Field
                    name="commissions.transaction.deposit.amount"
                    component={CustomNumberInput}
                    currency="RON"
                    props={{ disabled: this.props.disabled }}
                  />
                  {/* </SectionItem>
                <SectionItem> */}
                  <i />
                  <FieldLabel>{translate('withdraw')}:</FieldLabel>
                  <Field
                    name="commissions.transaction.withdraw.amount"
                    component={CustomNumberInput}
                    currency="RON"
                    props={{ disabled: this.props.disabled }}
                  />
                </SectionItem>
              </Section>
            ) : (
              ''
            )}
            <Item>
              {showCommission ? (
                <div>{translate('showCommissionPerTransaction')}</div>
              ) : null}
            </Item>
            {showCommission ? (
              <Section style={{ width: '100%' }}>
                <SectionItem>
                  <i />
                  <FieldLabel>{translate('deposit')}:</FieldLabel>
                  <Field
                    name="commissions.commissions.deposit.amount"
                    component={CustomNumberInput}
                    currency="%"
                    props={{ disabled: this.props.disabled }}
                  />
                  {/* </SectionItem>
                <SectionItem> */}
                  <i />
                  <FieldLabel>{translate('maxDeposit')}:</FieldLabel>
                  <Field
                    name="commissions.commissions.deposit.maxAmount"
                    component={CustomNumberInput}
                    currency="RON"
                    props={{ disabled: this.props.disabled }}
                  />
                </SectionItem>
                <SectionItem>
                  <i />
                  <FieldLabel>{translate('withdraw')}:</FieldLabel>
                  <Field
                    name="commissions.commissions.withdraw.amount"
                    component={CustomNumberInput}
                    currency="%"
                    props={{ disabled: this.props.disabled }}
                  />
                  {/* </SectionItem>
                <SectionItem> */}
                  <i />
                  <FieldLabel>{translate('maxWithdraw')}:</FieldLabel>
                  <Field
                    name="commissions.commissions.withdraw.maxAmount"
                    component={CustomNumberInput}
                    currency="RON"
                    props={{ disabled: this.props.disabled }}
                  />
                </SectionItem>
              </Section>
            ) : (
              ''
            )}
            <Item>
              {showMonthly ? (
                <div>{translate('showDailyMonthlyCommission')}</div>
              ) : null}
            </Item>
            {showMonthly ? (
              <Section style={{ width: '100%' }}>
                <SectionItem>
                  <i />
                  {console.log(this.props)}
                  <FieldLabel>
                    {this.props.initialValues.commissions.monthly.value ===
                    'month'
                      ? translate('monthly')
                      : translate('daily')}
                  </FieldLabel>
                  <Field
                    name="commissions.monthly.amount"
                    component={CustomInput}
                    props={{ disabled: this.props.disabled }}
                  />
                </SectionItem>
              </Section>
            ) : (
              ''
            )}
          </Wrapper>
        )}
      </Translate>
    )
  }
}

export { CommissionViewer }
