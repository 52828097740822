import { FETCH_COUNTIES_MERCHANT } from '../locations/LocationActions'
import {
  ADD_LOGO_SUCCESS,
  ADD_LOGO,
  ADD_LOGO_FAIL,
  ADD_NEW_MERCHANT,
  ADD_NEW_MERCHANT_FAIL,
  ADD_NEW_MERCHANT_SUCCESS,
  APPEND_MERCHANTS,
  APPEND_MERCHANTS_FAIL,
  APPEND_MERCHANTS_SUCCESS,
  CHANGE_SEARCH_TERM,
  DELETE_LOGO_SUCCESS,
  DELETE_MERCHANT,
  DELETE_MERCHANT_FAIL,
  DELETE_MERCHANT_SUCCESS,
  EDIT_MERCHANT,
  EDIT_MERCHANT_FAIL,
  EDIT_MERCHANT_SUCCESS,
  FETCH_MERCHANTS,
  FETCH_MERCHANTS_FAIL,
  FETCH_MERCHANTS_SUCCESS,
  FETCH_SINGLE_MERCHANT,
  FETCH_SINGLE_MERCHANT_FAIL,
  FETCH_SINGLE_MERCHANT_SUCCESS,
  SET_PAGINATION_MERCHANTS,
  EDIT_MERCHANT_PASSWORD,
  EDIT_MERCHANT_PASSWORD_SUCCESS,
  EDIT_MERCHANT_PASSWORD_FAIL,
  RESET_SERVER_ERROR,
  EDIT_MERCHANT_BANK_DETAILS_FAIL,
  EDIT_MERCHANT_BANK_DETAILS_SUCCESS,
  EDIT_MERCHANT_BANK_DETAILS
} from './MerchantsActions'
import { sortCurrencies } from '../../../globals/sortCurrencies'

const INITIAL_STATE = {
  merchants: [],
  activeMerchant: {},
  error: {},
  loading: false,
  activeState: [],
  editMerchant: {},
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  serverError: {},
  searchTerm: '',
  filter: {},
  filterCounties: [],
  logoLoading: false,
  loadingList: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_LOGO:
      return { ...state, logoLoading: true }
    case ADD_LOGO_SUCCESS:
      return { ...state, logoLoading: false, activeMerchant: { ...state.activeMerchant, logoPath: action.payload.logoPath } }
    case ADD_LOGO_FAIL:
      return { ...state, logoLoading: false }

    case DELETE_LOGO_SUCCESS:
      return { ...state, activeMerchant: { ...state.activeMerchant, logoPath: null }, logoLoading: false }

    case FETCH_COUNTIES_MERCHANT:
      return { ...state, filterCounties: action.payload }
    // FILTER  && SEARCH
    case CHANGE_SEARCH_TERM:
      return { ...state, searchTerm: action.payload }
    // APPEND MERCHANTS TO LIST
    case APPEND_MERCHANTS:
      return { ...state, loading: true }
    case APPEND_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchants: [...state.merchants, ...action.payload.companies],
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        },
        loading: false
      }
    case APPEND_MERCHANTS_FAIL:
      return { ...state, error: action.payload, loading: false }

    // EDIT MERCHANT
    case EDIT_MERCHANT:
      return { ...state, loading: true }
    case EDIT_MERCHANT_SUCCESS:
      const newEditedMerchants = state.merchants.map(merchant => {
        if (merchant.id === action.payload.id) {
          return action.payload
        }
        return merchant
      })
      return {
        ...state,
        merchants: newEditedMerchants,
        activeMerchant: action.payload,
        loading: false,
        serverError: ''
      }
    case EDIT_MERCHANT_FAIL:
      return { ...state, loading: false, serverError: action.payload }

    // DELETE MERCHANT
    case DELETE_MERCHANT:
      return { ...state, loading: true }
    case DELETE_MERCHANT_SUCCESS:
      const newMerchants = [...state.merchants.filter(
        merchant => merchant.id !== action.payload
      )]
      return { ...state, merchants: newMerchants, loading: false }
    case DELETE_MERCHANT_FAIL:
      return { ...state, loading: false }

    // FETCH ALL MERCHANTS
    case FETCH_MERCHANTS:
      return { ...state, loading: true, loadingList: true }
    case FETCH_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchants: action.payload.companies,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        },
        loading: false,
        loadingList: false
      }
    case FETCH_MERCHANTS_FAIL:
      return { ...state, error: action.payload, loading: false, loadingList: false }

    // ADD NEW MERCHANT
    case ADD_NEW_MERCHANT:
      return { ...state, serverError: {}, loading: true }
    case ADD_NEW_MERCHANT_SUCCESS:
      return { ...state, name: '', error: {}, loading: false, activeMerchant: action.payload }
    case ADD_NEW_MERCHANT_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    // FETCH MERCHANT BY ID
    case FETCH_SINGLE_MERCHANT:
      return { ...state, loading: true }
    case FETCH_SINGLE_MERCHANT_FAIL:
      return { ...state, loading: false, error: action.payload }
    case FETCH_SINGLE_MERCHANT_SUCCESS:
      let activeCompany = action.payload
      let currencies = []
      if (action.payload.currencies) {
        currencies = sortCurrencies(action.payload.currencies)
      }
      activeCompany.currencies = currencies
      return {
        ...state,
        loading: false,
        activeMerchant: action.payload
      }

    case SET_PAGINATION_MERCHANTS: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload.currentPage
        }
      }
    }

    case EDIT_MERCHANT_PASSWORD:
      return { ...state, serverError: {}, loading: true }
    case EDIT_MERCHANT_PASSWORD_SUCCESS:
      return { ...state, user: action.payload, loading: false }
    case EDIT_MERCHANT_PASSWORD_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    case EDIT_MERCHANT_BANK_DETAILS:
      return { ...state, loading: true }
    case EDIT_MERCHANT_BANK_DETAILS_SUCCESS:
      let activeCompanyAux = state.activeMerchant
      activeCompanyAux.banks = action.payload
      return { ...state, loading: false, activeMerchant: activeCompanyAux }
    case EDIT_MERCHANT_BANK_DETAILS_FAIL:
      return { ...state, loading: false }

    case RESET_SERVER_ERROR:
      return { ...state, serverError: {} }

    default:
      return state
  }
}
