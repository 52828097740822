import styled from 'styled-components'

const ButtonsGroup = styled.div`
display:flex;
justify-content: space-around;


  ${({ commissionForm }) => commissionForm && `
    flex-direction: column;
  `}

  ${({ popupButton }) => popupButton && `
  justify-content: flex-end;
    flex-direction: row;
    padding: 0px 30px 30px 0px;
  `}

@media all and (max-width: 420px) {
  flex-direction: column;
  align-items:center;
  justify-content: center;
}
`
export { ButtonsGroup }
