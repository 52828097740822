import { get } from '../../helpers'
import { encodeFilter } from '../../filter/EncodeFilter'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import _ from 'lodash'

export const FETCH_BUNDLES = 'fetch_bundles'
export const FETCH_BUNDLES_SUCCESS = 'fetch_bundles_success'
export const FETCH_BUNDLES_FAIL = 'fetch_bundles_fail'
export const FETCH_SINGLE_BUNDLE = 'fetch_single_bundle'
export const FETCH_SINGLE_BUNDLE_SUCCESS = 'fetch_single_bundle_success'
export const FETCH_SINGLE_BUNDLE_FAIL = 'fetch_single_bundle_fail'

export const RETURN_INITIAL_BUNDLES = 'return_initial_bundles'

export const returnInitialBundles = () => {
  return async (dispatch) => {
    dispatch({ type: RETURN_INITIAL_BUNDLES })
  }
}

export const fetchBundles = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_BUNDLES })
  let response
  let filterParams = encodeFilter({ ...getState().filter.bundles, isBundles: true })
  let url = `/transaction-bundles${filterParams}`
  try {
    response = await get({ url })
    dispatch({
      type: FETCH_BUNDLES_SUCCESS,
      payload: _.get(response, 'data')
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'bundles',
        name: 'pageCount',
        value: _.get(response, 'data.pagination.pageCount'),
        type: 'pagination'
      }
    })
    dispatch({
      type: CHANGE_FILTER,
      payload: {
        page: 'bundles',
        name: 'page',
        value: _.get(response, 'data.pagination.page'),
        type: 'pagination'
      }
    })
  } catch (error) {
    dispatch({
      type: FETCH_BUNDLES_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
    throw error
  }
}

export const fetchBundlesById = (bundleId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SINGLE_BUNDLE })
    let response
    try {
      response = await get({ url: `/transaction-bundles/${bundleId}` })
      dispatch({
        type: FETCH_SINGLE_BUNDLE_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_BUNDLE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
