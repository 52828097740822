import _ from 'lodash'

import {isEmpty} from '../modules/helpers/validation/is-empty'

import enTranslations from './en.translations.json'
import roTranslations from './ro.translations.json'
import ptTranslations from './pt.translations.json'

export const global = () => {
  let globalTranslation = {}
  let missing = {}
  let missingEnglish = {}
  _.forEach(roTranslations, (value, key) => {
    if (!isEmpty(enTranslations[key])) {
      if (isEmpty(ptTranslations[key])) {
        missing[key] = enTranslations[key]
        globalTranslation[key] = [value, enTranslations[key], enTranslations[key]]
      } else {
        globalTranslation[key] = [value, enTranslations[key], ptTranslations[key]]
      }
    } else {
      missingEnglish[key] = roTranslations[key]
    }
  })
  return globalTranslation
}
