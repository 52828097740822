import _ from 'lodash'
import { reset } from 'redux-form'
import { encodeFilter } from '../../filter/EncodeFilter'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { get, post, put, remove } from '../../helpers'

export const SET_BANK = 'SET_BANK'
export const SET_ACCOUNT = 'SET_ACCOUNT'

export const setBank = ({ bank }) => {
    return async dispatch => {
        dispatch({
            type: SET_BANK,
            payload: bank
        })
    }
}

export const setAccount = ({ account }) => {
    return async dispatch => {
        dispatch({
            type: SET_ACCOUNT,
            payload: account
        })
    }
}

export const FETCH_PAYMENT_ACCOUNTS = 'FETCH_PAYMENT_ACCOUNTS'
export const FETCH_PAYMENT_ACCOUNTS_SUCCESS = 'FETCH_PAYMENT_ACCOUNTS_SUCCESS'
export const FETCH_PAYMENT_ACCOUNTS_FAIL = 'FETCH_PAYMENT_ACCOUNTS_FAIL'

export const fetchPaymentAccounts = () => {
    return async dispatch => {
        dispatch({ type: FETCH_PAYMENT_ACCOUNTS })
        let response
        try {
            // response = await get({ url: `/payments/accounts` })
            response = await get({ url: `/accounts` })
            dispatch({
                type: FETCH_PAYMENT_ACCOUNTS_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENT_ACCOUNTS_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const FETCH_PAYMENT_ACCOUNT = 'FETCH_PAYMENT_ACCOUNT'
export const FETCH_PAYMENT_ACCOUNT_SUCCESS = 'FETCH_PAYMENT_ACCOUNT_SUCCESS'
export const FETCH_PAYMENT_ACCOUNT_FAIL = 'FETCH_PAYMENT_ACCOUNT_FAIL'

export const fetchPaymentAccount = ({ id }) => {
    return async dispatch => {
        dispatch({ type: FETCH_PAYMENT_ACCOUNT })
        let response
        try {
            // response = await get({ url: `/payments/accounts/${id}` })
            // response = await get({ url: `accounts/${id}/payments` })
            response = await get({ url: `accounts/${id}` })
            dispatch({
                type: FETCH_PAYMENT_ACCOUNT_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENT_ACCOUNT_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAIL = 'FETCH_COMPANIES_FAIL'

export const fetchCompanies = () => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_COMPANIES })
        let response
        let filterParams = encodeFilter(getState().filter.companies)
        let url = `/companies${filterParams}`

        try {
            response = await get({ url })
            dispatch({
                type: FETCH_COMPANIES_SUCCESS,
                payload: _.get(response, 'data')
            })
        } catch (error) {
            dispatch({
                type: FETCH_COMPANIES_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const SET_PAYMENT_FIELD = 'SET_PAYMENT_FIELD'

export const setPaymentField = ({ field, value }) => {
    return async (dispatch) => {
        dispatch({
            type: SET_PAYMENT_FIELD,
            payload: {
                field, value
            }
        })
    }
}

export const ADD_PAYMENT = 'ADD_PAYMENT'
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS'
export const ADD_PAYMENT_FAIL = 'ADD_PAYMENT_FAIL'

export const addPayment = (data, accountId) => {
    return async dispatch => {
        dispatch({ type: ADD_PAYMENT })
        let response
        try {
            response = await post({ url: `/payments`, data })
            dispatch({
                type: ADD_PAYMENT_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: ADD_PAYMENT_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const FETCH_PAYMENT_TYPES = 'FETCH_PAYMENT_TYPES'
export const FETCH_PAYMENT_TYPES_SUCCESS = 'FETCH_PAYMENT_TYPES_SUCCESS'
export const FETCH_PAYMENT_TYPES_FAIL = 'FETCH_PAYMENT_TYPES_FAIL'

export const fetchPaymentTypes = () => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_PAYMENT_TYPES })
        let filterParams = encodeFilter(getState().filter.paymentTypes)
        let url = `/payments/types${filterParams}`
        let response
        try {
            response = await get({ url })
            dispatch({
                type: FETCH_PAYMENT_TYPES_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENT_TYPES_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const FETCH_PAYMENT_SUBTYPES = 'FETCH_PAYMENT_SUBTYPES'
export const FETCH_PAYMENT_SUBTYPES_SUCCESS = 'FETCH_PAYMENT_SUBTYPES_SUCCESS'
export const FETCH_PAYMENT_SUBTYPES_FAIL = 'FETCH_PAYMENT_SUBTYPES_FAIL'

export const fetchPaymentSubTypes = () => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_PAYMENT_SUBTYPES })
        let filterParams = encodeFilter(getState().filter.paymentSubTypes)
        let url = `/payments/types${filterParams}`
        let response
        try {
            response = await get({ url })
            dispatch({
                type: FETCH_PAYMENT_SUBTYPES_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENT_SUBTYPES_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS'
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS'
export const FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL'

export const fetchPayments = () => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_PAYMENTS })
        let response
        let account = getState().payments.account
        // dispatch({
        //     type: CHANGE_FILTER,
        //     payload: {
        //         type: 'filterBy',
        //         name: 'bankAccountId',
        //         page: 'payments',
        //         value: account.id,
        //         operator: '='
        //     }
        // })
        let filterParams = encodeFilter(getState().filter.payments)
        // let url = `/payments${filterParams}`
        let url = `accounts/${account.id}/payments${filterParams}`
        try {
            response = await get({ url })
            dispatch({
                type: FETCH_PAYMENTS_SUCCESS,
                payload: _.get(response, 'data'),
            })
            dispatch({
                type: CHANGE_FILTER,
                payload: {
                    page: 'payments',
                    name: 'pageCount',
                    value: _.get(response, 'data.pagination.pageCount'),
                    type: 'pagination'
                }
            })
            dispatch({
                type: CHANGE_FILTER,
                payload: {
                    page: 'payments',
                    name: 'page',
                    value: _.get(response, 'data.pagination.page'),
                    type: 'pagination'
                }
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENTS_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const ADD_PAYMENT_TYPE = 'ADD_PAYMENT_TYPE'
export const ADD_PAYMENT_TYPE_SUCCESS = 'ADD_PAYMENT_TYPE_SUCCESS'
export const ADD_PAYMENT_TYPE_FAIL = 'ADD_PAYMENT_TYPE_FAIL'

export const addPaymentType = data => {
    return async dispatch => {
        dispatch({ type: ADD_PAYMENT_TYPE })
        let response
        try {
            response = await post({ url: `/payments/types`, data })
            dispatch({
                type: ADD_PAYMENT_TYPE_SUCCESS,
                payload: _.get(response, 'data'),
                // payload: {
                //     code: "ssss",
                //     created_at: "2021-06-08T09:18:29.249Z",
                //     description: "111111",
                //     id: "16e12e05-a148-4abe-bdec-7c7378351cc5",
                //     is_system: 0,
                //     parentId: null,
                //     updated_at: "2021-06-08T09:18:29.249Z"
                // }
            })
        } catch (error) {
            dispatch({
                type: ADD_PAYMENT_TYPE_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const paymentResetAddForm = () => (dispatch) => {
    dispatch(reset('paymentAddForm'))
}

export const FETCH_PAYMENT_CURRENCIES = 'FETCH_PAYMENT_CURRENCIES'
export const FETCH_PAYMENT_CURRENCIES_SUCCESS = 'FETCH_PAYMENT_CURRENCIES_SUCCESS'
export const FETCH_PAYMENT_CURRENCIES_FAIL = 'FETCH_PAYMENT_CURRENCIES_FAIL'

export const fetchPaymentCurrencies = () => {
    return async dispatch => {
        dispatch({ type: FETCH_PAYMENT_CURRENCIES })
        let response
        try {
            response = await get({ url: `/payments/used-currencies` })
            dispatch({
                type: FETCH_PAYMENT_CURRENCIES_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENT_CURRENCIES_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const FETCH_PAYMENT_BY_ID = 'FETCH_PAYMENT_BY_ID'
export const FETCH_PAYMENT_BY_ID_SUCCESS = 'FETCH_PAYMENT_BY_ID_SUCCESS'
export const FETCH_PAYMENT_BY_ID_FAIL = 'FETCH_PAYMENT_BY_ID_FAIL'

export const fetchPaymentById = ({ id }) => {
    return async dispatch => {
        dispatch({ type: FETCH_PAYMENT_BY_ID })
        let response
        try {
            response = await get({ url: `/payments/${id}` })
            dispatch({
                type: FETCH_PAYMENT_BY_ID_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: FETCH_PAYMENT_BY_ID_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const DELETE_PAYMENT = 'DELETE_PAYMENT'
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS'
export const DELETE_PAYMENT_FAIL = 'DELETE_PAYMENT_FAIL'

export const deletePayment = ({ id }) => {
    return async dispatch => {
        dispatch({ type: DELETE_PAYMENT })
        let response
        try {
            response = await remove({ url: `/payments/${id}` })
            dispatch({
                type: DELETE_PAYMENT_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: DELETE_PAYMENT_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS'
export const UPDATE_PAYMENT_FAIL = 'UPDATE_PAYMENT_FAIL'

export const updatePayment = ({ id, data }) => {
    return async dispatch => {
        dispatch({ type: UPDATE_PAYMENT })
        let response
        try {
            response = await put({ url: `/payments/${id}`, data })
            dispatch({
                type: UPDATE_PAYMENT_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: UPDATE_PAYMENT_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}