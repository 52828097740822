import {
  FETCH_ROLES_SUCCESS,
  FETCH_ROLE_BY_ID_SUCCESS,
  APPEND_ROLES, APPEND_ROLES_FAIL,
  APPEND_ROLES_SUCCESS,
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_ROLE_BY_ID,
  GET_ROLE_BY_ID_SUCCESS,
  GET_ROLE_BY_ID_FAIL,
  GET_ALL_PERMISSIONS,
  GET_ALL_PERMISSIONS_FAIL,
  GET_ALL_PERMISSIONS_SUCCESS,
  FETCH_ROLES,
  FETCH_ROLE_BY_ID_FAIL, DELETE_ROLE, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAIL
} from './RoleActions'

const INITIAL_STATE = {
  list: [],
  activeRole: {},
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  searchTerm: '',
  listLoading: false,
  singleLoading: false,
  serverError: {},
  loading: false,
  permissions: {},
  allPermissions: {}
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        loading: true
      }
    case FETCH_ROLE_BY_ID_SUCCESS:
      return { ...state, loadingSingle: false, activeRole: action.payload }
    case FETCH_ROLE_BY_ID_FAIL:
      return { ...state, loadingSingle: false, error: action.payload }
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.roles,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        }
      }
    case APPEND_ROLES:
      return { ...state, loading: true }
    case APPEND_ROLES_SUCCESS:
      return {
        ...state,
        list: [...state.list, ...action.payload.roles],
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        },
        loading: false
      }
    case APPEND_ROLES_FAIL:
      return { ...state, error: action.payload, loading: false }
    case GET_PERMISSIONS: {
      return { ...state, loading: true }
    }
    case GET_PERMISSIONS_SUCCESS: {
      return { ...state, permissions: action.payload, loading: false }
    }
    case GET_PERMISSIONS_FAIL: {
      return { ...state, error: action.payload, loading: false }
    }
    case GET_ROLE_BY_ID:
      return { ...state, loading: true }
    case GET_ROLE_BY_ID_SUCCESS:
      return { ...state, loading: false, activeRole: action.payload }
    case GET_ROLE_BY_ID_FAIL:
      return { ...state, loading: false, serverError: action.payload }
    case GET_ALL_PERMISSIONS: {
      return { ...state, loading: true }
    }
    case GET_ALL_PERMISSIONS_SUCCESS: {
      return { ...state, allPermissions: action.payload.permissions, loading: false }
    }
    case GET_ALL_PERMISSIONS_FAIL: {
      return { ...state, error: action.payload, loading: false }
    }
    case DELETE_ROLE:
      return { ...state, loading: true }
    case DELETE_ROLE_SUCCESS:
      const newRoles = state.list.filter((role) =>
        role.id !== action.payload)
      return { ...state, list: newRoles, loading: false }
    case DELETE_ROLE_FAIL:
      return { ...state, loading: false }
    default:
      return state
  }
}
