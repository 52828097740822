/* global google */
import React from 'react'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps'
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'
import { primaryColor } from './common/StyleConstants'
import config from '../../config'

const CustomGoogleMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.google_maps_key}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px`, width: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap style={{ marginTop: 100 }}
    defaultZoom={17}
    defaultCenter={new google.maps.LatLng(props.defaultLat ? props.defaultLat : 47.15664647378635, props.defaultLong ? props.defaultLong : 27.59179562330246)} // 47.1562327,27.5169309,
  >
    <DrawingManager
      defaultOptions={{
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.CIRCLE
          ]
        },
        circleOptions: {
          fillColor: `${primaryColor}`,
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: false,
          editable: true,
          zIndex: 1
        }
      }}
    />
    {props.children}
  </GoogleMap>
)

/* export default class MyFancyComponent extends React.PureComponent {
  state = {
    isMarkerShown: false
  }

  componentDidMount () {
    this.delayedShowMarker()
  }

  delayedShowMarker = () => {
    setTimeout(() => {
      this.setState({ isMarkerShown: true })
    }, 3000)
  }

  handleMarkerClick = () => {
    this.setState({ isMarkerShown: false })
    this.delayedShowMarker()
  }

  render () {
    return (
      <MyMapComponent
        isMarkerShown
        onMarkerClick={this.handleMarkerClick}
      />
    )
  }
}
 */

export { CustomGoogleMap }
