import _ from 'lodash'

function pad(string, number) {
    return _.padStart(string, number, '0')
}

export function getSavedDocumentsName(object, fileType, extension) {
    let name = ''
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear().toString()
    const currentMonth = pad(currentDate.getMonth() + 1, 2)
    const currentDay = pad(currentDate.getDate(), 2)
    const currentHour = pad(currentDate.getHours(), 2)
    const currentMinues = pad(currentDate.getMinutes(), 2)

    const date = `${currentYear}${currentMonth}${currentDay}${currentHour}${currentMinues}`

    if (object.series) {
        name = `${object.series}_`
    }
    if (object.clientNumber) {
        name = `${name}${pad(object.clientNumber, 3)}_`
    }
    if (fileType) {
        name = `${name}${fileType}_`
    }

    name = name = `${name}${date}`

    if (extension) {
        name = name = `${name}.${extension}`
    }
    return name
}