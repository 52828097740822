import styled from 'styled-components'
import { primaryColor } from '../StyleConstants'
const ActionButton = styled.span`
  display: inline-block;
  padding: 15px 15px;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 400;
  opacity: .8;
  border: none;
  text-transform: uppercase;
  background-color: ${props => props.color ? props.color : primaryColor};
  ${props => props.border ? `border: 1px solid ${props.color}; background-color: transparent; color: ${props.color};` : ''}
  border-radius: 100em;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    opacity: 1;
    transition: opacity .2 ease-in;
  }
  
  & > .lnr {
    margin-right: 8px;
  }
  
  @media all and (max-width: 1023px) {
    font-size: 16px;
  }
`

export const VisbilityButton = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;

  @media all and (max-width: 767px) {
    top: 40px;
  }
`

export { ActionButton }
