import moment from 'moment'
import _ from 'lodash'
import {
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  REFRESHING_TOKEN
} from '../modules/auth/login/LoginActions'
import config from '../config'
import { API_REFRESH_TOKEN_URL } from '../config/apiRoutes'
import { saveToken } from '../local_storage'
import { post, setHeader } from '../modules/helpers'
import { sessionExpiredAlert } from '../globals/sessionExpiredAlert'

export default ({ dispatch, getState }) => {

  window.addEventListener('storage', (ev) => {
    // make sure to refresh the other tabs if there is more than one when de user disconnect/impersonate
    // if ((ev.key === 'refreshTabToken' || ev.key === 'savedTokenForTabSync') && ev.newValue !== ev.oldValue) {
    if ((ev.key === 'refreshTabToken') && ev.newValue !== ev.oldValue) {
      window.location.reload()
      localStorage.removeItem('refreshTabToken')
    }
  });

  return (next) => async (action) => {
    let token, response
    response = localStorage.getItem('savedTokenForTabSync')
    token = JSON.parse(response)
    if (typeof action === 'function') {
      if (token && token.access_token) {
        if (token && isExpired(token)) {
          // make sure we are not already refreshing the token
          let refreshTokenPromise
          let state = getState()
          if (state.login && state.login.token && state.login.token.refreshTokenPromise) {
            refreshTokenPromise = state.login.token.refreshTokenPromise
          }
          if (!refreshTokenPromise) {
            return await refreshToken(dispatch).then(() => next(action))
          } else {
            return await refreshTokenPromise.then(() => next(action))
          }
        } else {
          setHeader(`${token.token_type} ${token.access_token}`)
        }
      }
    }

    try {
      await next(action)
    } catch (e) {
      let error = _.get(e, 'response.data')
      if (error && (error.error_type === 'expired_token_error')) {
        await refreshToken(dispatch).then(() => next(action))
      } else throw e
    }
  }
}

const isExpired = (token) => {
  let currentTime = moment().valueOf()
  return currentTime > token.expires_in
}

const refreshToken = (dispatch) => {
  let token = JSON.parse(localStorage.getItem('savedTokenForTabSync'))
  let refreshTokenPromise = post(
    {
      url: API_REFRESH_TOKEN_URL,
      data: {
        refresh_token: token.refresh_token,
        client_id: config.client_id,
        client_secret: config.client_secret,
        grant_type: config.grant_type_refresh_token
      }
    }
  ).then(resp => {
    setHeader(`${resp.data.token_type} ${resp.data.access_token}`)
    const expires = ((resp.data.expires_in - 1) * 1000) + moment().valueOf()
    const payload = { ...resp.data, expires_in: expires }
    saveToken(payload)
    dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: payload
    })
    return resp ? Promise.resolve(resp) : Promise.reject(new Error('could not refresh token'))
  })
    .catch(ex => {
      dispatch({
        type: REFRESH_TOKEN_FAIL,
        payload: ex
      })
      let error = _.get(ex, 'response.data')
      if (error && (
        error.error_type === 'expired_token_error' ||
        error.error_type === 'invalid_refresh_token' ||
        error.error_type === 'token_error'
      )) {
        sessionExpiredAlert(dispatch)
      }
    })

  dispatch({
    type: REFRESHING_TOKEN,
    // we want to keep track of token promise in the state so that we don't     try to refresh the token again while refreshing is in process
    payload: refreshTokenPromise
  })

  return refreshTokenPromise
}
