import { get, post, put, remove } from '../../helpers'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { encodeFilter } from '../../filter/EncodeFilter'
import { push } from 'react-router-redux'
import { VIRTUAL_TERMINALS_ROUTE } from '../../../router/ClientRoutes'
import _ from 'lodash'

export const CLEAR_TERMINAL_FORM_FIELDS = 'clear_terminal_form_fields'

export const ADD_NEW_TERMINAL = 'add_new_terminal'
export const ADD_NEW_TERMINAL_SUCCESS = 'add_new_terminal_success'
export const ADD_NEW_TERMINAL_FAIL = 'add_new_terminal_fail'

export const DELETE_TERMINAL = 'delete_terminal'
export const DELETE_TERMINAL_SUCCESS = 'delete_terminal_success'
export const DELETE_TERMINAL_FAIL = 'delete_terminal_fail'

export const EDIT_TERMINAL = 'edit_terminal'
export const EDIT_TERMINAL_SUCCESS = 'edit_terminal_success'
export const EDIT_TERMINAL_FAIL = 'edit_terminal_fail'

export const APPEND_TERMINALS = 'APPEND_TERMINALS'
export const APPEND_TERMINALS_SUCCESS = 'APPEND_TERMINALS_SUCCESS'
export const APPEND_TERMINALS_FAIL = 'APPEND_TERMINALS_FAIL'

export const FETCH_TERMINALS = 'fetch_terminals'
export const FETCH_TERMINALS_SUCCESS = 'fetch_terminals_success'
export const FETCH_TERMINALS_FAIL = 'fetch_terminals_fail'

export const FETCH_SINGLE_TERMINAL = 'fetch_terminal_by_id'
export const FETCH_SINGLE_TERMINAL_SUCCESS = 'fetch_terminal_by_id_success'
export const FETCH_SINGLE_TERMINAL_FAIL = 'fetch_terminal_by_id_fail'

export const FETCH_PHONE_PROVIDERS = 'fetch_phone_providers'
export const FETCH_PHONE_PROVIDERS_SUCCESS = 'fetch_phone_providers_success'
export const FETCH_PHONE_PROVIDERS_FAIL = 'fetch_phone_providers_fail'

export const fetchTerminals = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_TERMINALS })
    let response
    let filterParams = encodeFilter(getState().filter.virtualTerminals)
    let url = `/terminals${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_TERMINALS_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'virtualTerminals',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'virtualTerminals',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_TERMINALS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const appendTerminals = (page = 1, pageSize = 10) => {
  return async (dispatch, getState) => {
    dispatch({ type: APPEND_TERMINALS })
    let response
    let filters = getState().filter.virtualTerminals
    filters.pagination.page.value += 1
    let filterParams = encodeFilter(filters)
    let url = `/terminals${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: APPEND_TERMINALS_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'terminals',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'terminals',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: APPEND_TERMINALS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
export const fetchPhoneProviders = () => {
  return async dispatch => {
    dispatch({ type: FETCH_PHONE_PROVIDERS })
    let response
    try {
      response = await get({ url: `/phone-providers` })
      dispatch({
        type: FETCH_PHONE_PROVIDERS_SUCCESS,
        payload: _.get(response, 'data.phoneProviders')
      })
    } catch (error) {
      dispatch({
        type: FETCH_PHONE_PROVIDERS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchTerminalById = terminalId => {
  return async dispatch => {
    dispatch({ type: FETCH_SINGLE_TERMINAL })
    let response
    try {
      response = await get({ url: `/terminals/${terminalId}` })
      dispatch({
        type: FETCH_SINGLE_TERMINAL_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_TERMINAL_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const deleteTerminal = terminalId => {
  return async dispatch => {
    dispatch({ type: DELETE_TERMINAL })
    try {
      await remove({ url: `/terminals/${terminalId}` })
      dispatch({
        type: DELETE_TERMINAL_SUCCESS,
        payload: terminalId
      })
      dispatch(push(`${VIRTUAL_TERMINALS_ROUTE}`))
    } catch (error) {
      dispatch({
        type: DELETE_TERMINAL_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const addTerminal = data => {
  return async dispatch => {
    dispatch({ type: ADD_NEW_TERMINAL })
    let response
    try {
      response = await post({ url: `/terminals`, data })
      dispatch({
        type: ADD_NEW_TERMINAL_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`${VIRTUAL_TERMINALS_ROUTE}/confirmation/${response.data.id}`))
    } catch (error) {
      dispatch({
        type: ADD_NEW_TERMINAL_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editTerminal = (data, terminalId) => {
  return async dispatch => {
    dispatch({ type: EDIT_TERMINAL })
    let response
    try {
      response = await put({ url: `/terminals/${terminalId}`, data })
      dispatch({
        type: EDIT_TERMINAL_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(`${VIRTUAL_TERMINALS_ROUTE}/view/${response.data.id}`))
    } catch (error) {
      dispatch({
        type: EDIT_TERMINAL_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const clearFields = () => ({ type: CLEAR_TERMINAL_FORM_FIELDS })

export const FETCH_TERMINAL_TYPES = 'FETCH_TERMINAL_TYPES'
export const FETCH_TERMINAL_TYPES_SUCCESS = 'FETCH_TERMINAL_TYPES_SUCCESS'
export const FETCH_TERMINAL_TYPES_FAIL = 'FETCH_TERMINAL_TYPES_FAIL'

export const fetchTerminalTypes = () => async dispatch => {
  dispatch({ type: FETCH_TERMINAL_TYPES })
  let response
  try {
    response = await get({ url: `/terminals/terminal-types` })
    dispatch({
      type: FETCH_TERMINAL_TYPES_SUCCESS,
      payload: _.get(response, 'data.terminalTypes')
    })
  } catch (error) {
    dispatch({
      type: FETCH_TERMINAL_TYPES_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
    throw error
  }
}
