import styled from 'styled-components'
import {primaryColor, secondaryColor} from '../common/StyleConstants'

const UnderHeader = styled.div`
height:125px;
background: linear-gradient(to right, ${primaryColor},${secondaryColor});
display:flex;
align-items:center;
justify-content:center;

@media only screen and (max-width: 640px) {
  height: 75px;
}

@media only screen and (max-width: 420px){
  height: 50px;
}
`

const UnderHeaderTitle = styled.p`
color:white;
letter-spacing:0.38em;
font-weight:bold;
font-size:24px;
font-family:Opensans;
text-transform:uppercase;
color: rgb(255, 255, 255);
line-height: 1.2;

`
export {UnderHeader, UnderHeaderTitle}
