import {
  FETCH_CURRENCIES_FAIL,
  FETCH_CURRENCIES_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAIL
} from './currencyActions'

const INITIAL_STATE = {
  currencyList: [],
  errors: {},
  loadingList: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_SUCCESS:
      const currencies = action.payload.currencies.map(currency => {
        return {
          value: currency.id, label: currency.code
        }
      })
      return { ...state, currencyList: currencies, errors: {} }
    case FETCH_CURRENCIES_FAIL:
      return { ...state, currencyList: [], errors: action.payload }

    case GET_CURRENCIES:
      return { ...state, currencyList: [], errors: {}, loadingList: true }
    case GET_CURRENCIES_SUCCESS:
      const currenciesList = action.payload && action.payload.currencies && action.payload.currencies.map(currency => {
        return {
          value: currency.id, label: currency.code
        }
      })
      return { ...state, currencyList: currenciesList, errors: {}, loadingList: false }

    case GET_CURRENCIES_FAIL:
      return { ...state, currencyList: [], errors: action.payload, loadingList: false }

    default:
      return state
  }
}
