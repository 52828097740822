import { post } from '../../helpers'
import config from '../../../config'
import { GET_PERMISSIONS_SUCCESS } from '../../auth/login/LoginActions'
import { saveState } from '../../../local_storage'
import { push } from 'react-router-redux'
import _ from 'lodash'

export const MODIFY_SETTINGS = 'modify_settings'
export const MODIFY_SETTINGS_SUCCESS = 'modify_settings_success'
export const MODIFY_SETTINGS_FAILURE = 'modify_settings_failure'

export const applyModifications = (newAccountDetails) => {
  return async (dispatch, getState) => {
    let response
    let state = getState()
    let isImpersonating = state.login.user.isImpersonating
    try {
      response = await post({ url: '/settings', data: newAccountDetails })
      let userAux = _.get(response, 'data.user')
      userAux.isImpersonating = isImpersonating
      dispatch({
        type: MODIFY_SETTINGS_SUCCESS,
        payload: _.get(response, 'data'),
      })
      saveState(config.toppay_permissions, { ...state.login, user: userAux })
      dispatch({
        type: GET_PERMISSIONS_SUCCESS,
        payload: { ...state.login, user: userAux }
      })
      if (response.data && response.data.user) {
        dispatch(push(`/settings/confirmation`))
      }
    } catch (error) {
      dispatch({
        type: MODIFY_SETTINGS_FAILURE,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
