import {
  FETCH_BUNDLES,
  FETCH_BUNDLES_SUCCESS,
  FETCH_BUNDLES_FAIL,
  FETCH_SINGLE_BUNDLE,
  FETCH_SINGLE_BUNDLE_FAIL,
  FETCH_SINGLE_BUNDLE_SUCCESS,
  RETURN_INITIAL_BUNDLES
} from './BundlesActions'

const INITIAL_STATE = {
  bundles: [],
  preview: {},
  activeBundle: null,
  activeState: [],
  loading: true,
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  error: {},
  serverError: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RETURN_INITIAL_BUNDLES:
      return INITIAL_STATE
    case FETCH_BUNDLES:
      return {
        ...state,
        loading: true
      }
    case FETCH_BUNDLES_SUCCESS:
      return {
        ...state,
        bundles: action.payload.transactionBundles,
        loading: false
      }
    case FETCH_BUNDLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case FETCH_SINGLE_BUNDLE: {
      return {
        ...state,
        loading: true
      }
    }
    case FETCH_SINGLE_BUNDLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeBundle: action.payload
      }
    }
    case FETCH_SINGLE_BUNDLE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    default:
      return state
  }
}
