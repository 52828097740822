import _ from 'lodash'
import { getSavedDocumentsName } from '../../globals/getSavedDocumentsName'
export const DOWNLOAD_SETTLEMENT_FILE = 'DOWNLOAD_SETTLEMENT_FILE'
export const DOWNLOAD_SETTLEMENT_FILE_SUCCESS = 'DOWNLOAD_SETTLEMENT_FILE_SUCCESS'
export const DOWNLOAD_SETTLEMENT_FILE_FAIL = 'DOWNLOAD_SETTLEMENT_FILE_FAIL'

export const DOWNLOAD_SAFE_DEPOSIT_FILE = 'DOWNLOAD_SAFE_DEPOSIT_FILE'
export const DOWNLOAD_SAFE_DEPOSIT_FILE_SUCCESS = 'DOWNLOAD_SAFE_DEPOSIT_FILE_SUCCESS'
export const DOWNLOAD_SAFE_DEPOSIT_FILE_FAIL = 'DOWNLOAD_SAFE_DEPOSIT_FILE_FAIL'

export const safeDownload = ({ link, id, settlement }) => async (dispatch, getState) => {
  dispatch({ type: DOWNLOAD_SETTLEMENT_FILE })
  let token = getState().login.token
  const tokenComplete = `${token.token_type} ${token.access_token}`
  let headers = new Headers()
  headers.append('Authorization', tokenComplete)
  let anchor = document.createElement('a')

  try {
    await fetch(link, { headers })
      .then(res => {
        dispatch({
          type: DOWNLOAD_SETTLEMENT_FILE_SUCCESS,
          payload: _.get(res, 'status')
        })
        if (res.status === 200) {
          res.blob()
            .then(blobby => {
              let responseExtension = blobby && blobby.type && blobby.type.split('/')
              let objectUrl = window.URL.createObjectURL(blobby)
              if (navigator.userAgent.indexOf('Firefox') !== -1) {
                window.location.href = objectUrl
              } else {
                anchor.href = objectUrl
                // anchor.download = `${id}`
                anchor.download = getSavedDocumentsName(settlement, settlement.fileType, responseExtension[1])
                anchor.click()
              }
              window.URL.revokeObjectURL(objectUrl)
            })
          // eslint-disable-next-line
        } else if (res.status === 404) { throw ('fileNotFound') }
      })
  } catch (error) {
    dispatch({
      type: DOWNLOAD_SETTLEMENT_FILE_FAIL,
      payload: ''
    })
    throw error
  }
}

export const safeDepositDownloadFile = ({ link, id, safeDeposit }) => async (dispatch, getState) => {
  dispatch({ type: DOWNLOAD_SAFE_DEPOSIT_FILE })
  let token = getState().login.token
  const tokenComplete = `${token.token_type} ${token.access_token}`
  let headers = new Headers()
  headers.append('Authorization', tokenComplete)
  let anchor = document.createElement('a')
  try {
    await fetch(link, { headers })
      .then(res => {
        dispatch({
          type: DOWNLOAD_SAFE_DEPOSIT_FILE_SUCCESS,
          payload: _.get(res, 'status')
        })
        if (res.status === 200) {
          res.blob()
            .then(blobby => {
              let responseExtension = blobby && blobby.type && blobby.type.split('/')
              let objectUrl = window.URL.createObjectURL(blobby)
              if (navigator.userAgent.indexOf('Firefox') !== -1) {
                window.location.href = objectUrl
              } else {
                anchor.href = objectUrl
                // anchor.download = `${id}`
                anchor.download = getSavedDocumentsName(safeDeposit, safeDeposit.fileType, responseExtension[1])
                anchor.click()
              }
              window.URL.revokeObjectURL(objectUrl)
            })
          // eslint-disable-next-line
        } else if (res.status === 404) { throw ('fileNotFound') }
      })
  } catch (error) {
    dispatch({
      type: DOWNLOAD_SAFE_DEPOSIT_FILE_FAIL,
      payload: ''
    })
    throw error
  }
}