import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import getTranslationsForSelectedLanguage from './getTranslationsForSwalAlert';
import { tokenExpiredlogOut } from '../modules/auth/login/LoginActions'

export function sessionExpiredAlert(dispatch) {
    let currentLanguage = localStorage.getItem('languageCode')
    let translations = getTranslationsForSelectedLanguage(currentLanguage)
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        title: 'Oops...',
        text: `${translations.token_expired}`,
        confirmButtonText: `${translations.disconnect}`,
        type: 'error'
    }).then(async res => {
        // try {
        //     await dispatch(logOut())
        // } catch (e) {
        //     await dispatch(tokenExpiredlogOut())
        // }
        await dispatch(tokenExpiredlogOut())
    })
}
