// client routes
export const TRANSACTIONS_ROUTE = '/transactions'
export const SETTINGS_ROUTE = '/settings'
export const SETTLEMENTS_ROUTE = '/settlements'
export const SAFE_DEPOSITS_ROUTE = '/security-deposits'
export const DASHBOARD_ROUTE = '/'
export const LIMITS_ROUTE = '/limits'
export const MERCHANTS_ROUTE = '/merchants'
export const CASHIERS_ROUTE = '/cashiers'
export const IP_ROUTE = '/ip'
export const VENDORS_ROUTE = '/vendors'
export const USERS_ROUTE = '/users'
export const CLIENTS_ROUTE = '/clients'
export const COMMISIONS_ROUTE = '/commisions'
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const HOME_ROUTE = '/'
export const RECOVER_PASSWORD_ROUTE = '/recover'
export const TERMS_AND_CONDITIONS_ROUTE = '/terms'
export const NOTIFICATIONS_ROUTE = '/notifications'
export const LOCATIONS_ROUTE = '/locations'
export const TERMINALS_ROUTE = '/terminals'
export const VIRTUAL_TERMINALS_ROUTE = '/virtual-terminals'
export const VENDOR_SETTINGS_ROUTE = '/'
export const AUTH_ROUTE = '/auth'
export const QR_DEPOSIT = '/qrcode-deposit'
export const QR_WITHDRAWAL = '/qrcode-withdrawal'
export const UNAUTHORIZED_ROUTE = '/not-allowed'
export const ROLES_ROUTE = '/roles'
export const TRANSACTION_BUNDLES_ROUTE = '/transaction-bundles'
export const LOGS_ROUTE = '/logs'
export const RELEASES_ROUTE = '/releases'
export const REPORTS_ROUTE = '/reports'
export const TECHNICIANS_ROUTE = '/technicians'
export const RECEIPTS_ROUTE = '/receipts'
export const PLATFORM_SETTINGS_ROUTE = '/platform-settings'

export const PAYMENTS_ROUTE = '/payments'
