import { ADD_LOGO_SUCCESS, DELETE_LOGO_SUCCESS } from '../merchants/MerchantsActions'
import {
  APPEND_VENDORS,
  APPEND_VENDORS_FAIL,
  APPEND_VENDORS_SUCCESS,
  DELETE_VENDOR,
  DELETE_VENDOR_FAIL,
  DELETE_VENDOR_SUCCESS,
  FETCH_SINGLE_VENDOR,
  FETCH_SINGLE_VENDOR_FAIL,
  FETCH_SINGLE_VENDOR_SUCCESS,
  FETCH_VENDORS,
  FETCH_VENDORS_FAIL,
  FETCH_VENDORS_SUCCESS,
  SET_PAGINATION_VENDORS,
  EDIT_VENDOR_PASSWORD,
  EDIT_VENDOR_PASSWORD_SUCCESS,
  EDIT_VENDOR_PASSWORD_FAIL,
  RESET_SERVER_ERROR,
  EDIT_VENDOR_BANK_DETAILS,
  EDIT_VENDOR_BANK_DETAILS_SUCCESS,
  EDIT_VENDOR_BANK_DETAILS_FAIL,
  ADD_NEW_VENDOR,
  ADD_NEW_VENDOR_SUCCESS,
  ADD_NEW_VENDOR_FAIL,
  EDIT_VENDOR,
  EDIT_VENDOR_SUCCESS,
  EDIT_VENDOR_FAIL,
  EDIT_VENDOR_WALLETS,
  EDIT_VENDOR_WALLETS_SUCCESS,
  EDIT_VENDOR_WALLETS_FAIL
} from './VendorActions'
import { sortCurrencies } from '../../../globals/sortCurrencies'

const INITIAL_STATE = {
  companies: [],
  activeCompany: null,
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  searchTerm: '',
  listLoading: false,
  singleLoading: false,
  serverError: {},
  user: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_LOGO_SUCCESS:
      if (action.companyType === 'vendor') {
        return { ...state, activeCompany: { ...state.activeCompany, logoPath: action.payload.logoPath } }
      } else {
        return state
      }
    case DELETE_LOGO_SUCCESS:
      if (action.companyType === 'vendor') {
        return { ...state, activeCompany: { ...state.activeCompany, logoPath: null } }
      } else {
        return state
      }
    case FETCH_VENDORS:
      return { ...state, listLoading: true }
    case APPEND_VENDORS:
      return { ...state, loading: true, listLoading: true }
    case APPEND_VENDORS_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, ...action.payload.companies],
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        },
        loading: false,
        listLoading: false
      }
    case APPEND_VENDORS_FAIL:
      return { ...state, error: action.payload, loading: false, listLoading: false }
    case FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        listLoading: false,
        companies: action.payload.companies,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        }
      }
    case FETCH_VENDORS_FAIL:
      return { ...state, listLoading: false }
    // FETCH VENDOR BY ID
    case FETCH_SINGLE_VENDOR:
      return { ...state, singleLoading: true }
    case FETCH_SINGLE_VENDOR_FAIL:
      return { ...state, singleLoading: false, error: action.payload }
    case FETCH_SINGLE_VENDOR_SUCCESS:
      let activeCompany = action.payload
      let currencies = []
      if (action.payload.currencies) {
        currencies = sortCurrencies(action.payload.currencies)
      }
      activeCompany.currencies = currencies
      return {
        ...state,
        singleLoading: false,
        activeCompany: activeCompany
      }
    case DELETE_VENDOR:
      return { ...state, loading: true }
    case DELETE_VENDOR_SUCCESS:
      const newMerchants = state.companies.filter((vendor) =>
        vendor.id !== action.payload)
      return { ...state, companies: newMerchants, loading: false }
    case DELETE_VENDOR_FAIL:
      return { ...state, loading: false }
    case SET_PAGINATION_VENDORS: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload.currentPage
        }
      }
    }


    case EDIT_VENDOR:
      return { ...state, singleLoading: true, serverError: {} }
    case EDIT_VENDOR_SUCCESS:
      const newEditedVendors = state.companies.map(vendor => {
        if (vendor.id === action.payload.id) {
          return action.payload
        }
        return vendor
      })
      return {
        ...state,
        companies: newEditedVendors,
        activeCompany: action.payload,
        singleLoading: false,
        serverError: {}
      }
    case EDIT_VENDOR_FAIL:
      return { ...state, singleLoading: false, serverError: action.payload }


    case EDIT_VENDOR_PASSWORD:
      return { ...state, loading: true }
    case EDIT_VENDOR_PASSWORD_SUCCESS:
      return { ...state, user: action.payload, loading: false }
    case EDIT_VENDOR_PASSWORD_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    case EDIT_VENDOR_BANK_DETAILS:
      return { ...state, loading: true }
    case EDIT_VENDOR_BANK_DETAILS_SUCCESS:
      let activeCompanyAux = state.activeCompany
      activeCompanyAux.banks = action.payload
      return { ...state, loading: false, activeCompany: activeCompanyAux }
    case EDIT_VENDOR_BANK_DETAILS_FAIL:
      return { ...state, loading: false }

    case RESET_SERVER_ERROR:
      return { ...state, serverError: {} }

    case ADD_NEW_VENDOR:
      return { ...state, serverError: {}, loading: true }
    case ADD_NEW_VENDOR_SUCCESS:
      return { ...state, error: {}, loading: false, activeCompany: action.payload }
    case ADD_NEW_VENDOR_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    case EDIT_VENDOR_WALLETS:
      return { ...state, loading: true }
    case EDIT_VENDOR_WALLETS_SUCCESS:
      let aux = state.activeCompany
      aux.products = action.payload
      return { ...state, loading: false, activeCompany: aux }
    case EDIT_VENDOR_WALLETS_FAIL:
      aux = state.activeCompany
      if (action.payload.products && action.payload.products.value) {
        aux.products = action.payload.products.value
      }
      return { ...state, loading: false, serverError: action.payload.products, activeCompany: aux }

    default:
      return state
  }
}
