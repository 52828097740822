
import { get } from '../helpers'
import _ from 'lodash'
import { API_URL_LIVE } from '../../config/apiRoutes'

export const FETCH_CURRENCIES_SUCCESS = 'fetch_currencies_success'
export const FETCH_CURRENCIES_FAIL = 'fetch_currencies_fail'

export const GET_CURRENCIES = 'get_currencies'
export const GET_CURRENCIES_SUCCESS = 'get_currencies_success'
export const GET_CURRENCIES_FAIL = 'get_currencies_fail'

export const getAllCurrencies = () => {
  return async dispatch => {
    let response
    try {
      response = await get({ url: 'currencies' })
      dispatch({
        type: FETCH_CURRENCIES_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_CURRENCIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const getCurrenciesByCompanyId = id => {
  return async dispatch => {
    let response
    await dispatch({
      type: GET_CURRENCIES
    })
    try {
      response = await get({
        url: `${API_URL_LIVE}/companies/${id}`
      })
      await dispatch({
        type: GET_CURRENCIES_SUCCESS,
        payload: _.get(response, 'data.currencies'),
      })
    } catch (error) {
      dispatch({
        type: GET_CURRENCIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
