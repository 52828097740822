import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  GET_EMPLOYEES,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEE_BY_ID,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAIL,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAIL,
  GET_ALL_USERS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_SUCCESS,
  SET_ACTIVE_USER,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAIL,
  APPEND_COMPANIES,
  APPEND_COMPANIES_SUCCESS,
  APPEND_COMPANIES_FAIL,
  FETCH_SINGLE_COMPANY,
  FETCH_SINGLE_COMPANY_SUCCESS,
  FETCH_SINGLE_COMPANY_FAIL,
  RESET_SERVER_ERROR
} from './UserActions'
import { isEmpty } from '../../helpers/validation/is-empty'

const INITIAL_STATE = {
  users: [],
  preview: {},
  activeUser: {},
  loading: false,
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  serverError: {},
  error: {},
  activeState: [],
  loadingAllUsers: false,
  companies: [],
  companyToAppend: {},
  companySingleLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, error: {}, loadingAllUsers: true }
    case GET_ALL_USERS_FAIL:
      return { ...state, error: action.payload, loadingAllUsers: false, users: [] }
    case GET_ALL_USERS_SUCCESS:
      return { ...state, error: {}, loadingAllUsers: false, users: action.payload }
    case ADD_USER:
      return { ...state, loading: true }
    case ADD_USER_SUCCESS:
      return { ...state, error: {}, loading: false }
    case ADD_USER_FAIL:
      return { ...state, serverError: action.payload, loading: false }
    case GET_EMPLOYEES:
      return { ...state, loading: true }
    case GET_EMPLOYEES_SUCCESS: {
      return { ...state, users: action.payload, loading: false }
    }
    case GET_EMPLOYEES_FAIL: {
      return { ...state, serverError: action.payload, loading: false, users: [] }
    }
    case GET_EMPLOYEE_BY_ID: {
      return { ...state, loading: true, activeUser: {} }
    }
    case GET_EMPLOYEE_BY_ID_SUCCESS: {
      return { ...state, activeUser: action.payload, loading: false }
    }
    case GET_EMPLOYEE_BY_ID_FAIL: {
      return { ...state, loading: false, serverError: action.payload }
    }
    case EDIT_EMPLOYEE: {
      return { ...state, loading: true }
    }
    case EDIT_EMPLOYEE_SUCCESS: {
      let newEditedUser = []
      if (!isEmpty(state.users)) {
        newEditedUser = state.users && state.users.employees && state.users.employees.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload
          }
          return user
        })
      }
      return { ...state, loading: false, users: newEditedUser }
    }
    case EDIT_EMPLOYEE_FAIL: {
      return { ...state, loading: false, serverError: action.payload }
    }

    case SET_ACTIVE_USER: {
      return { ...state, loading: false, activeUser: action.payload }
    }

    case FETCH_COMPANIES:
      return { ...state, loading: true }
    case FETCH_COMPANIES_SUCCESS: {
      return { ...state, companies: action.payload, loading: false }
    }
    case FETCH_COMPANIES_FAIL: {
      return { ...state, serverError: action.payload, loading: false }
    }

    case APPEND_COMPANIES:
      return { ...state, loading: true }
    case APPEND_COMPANIES_SUCCESS:
      let companies = [...state.companies.companies, ...action.payload.companies]

      let companiesAux = {
        companies: companies,
        pagination: {
          ...state.companies.pagination,
          pageCount: action.payload.pagination.pageCount,
          currentPage: action.payload.pagination.page
        }
      }
      return {
        ...state,
        companies: companiesAux,
        loading: false
      }
    case APPEND_COMPANIES_FAIL:
      return { ...state, error: action.payload, loading: false }


    case FETCH_SINGLE_COMPANY:
      return { ...state, loading: true, companyToAppend: {} }
    case FETCH_SINGLE_COMPANY_SUCCESS:
      let aux = state.companies.companies ? state.companies.companies : []
      // aux.push(action.payload)
      aux.unshift(action.payload)

      let newArr = {
        companies: aux,
        pagination: {
          ...state.companies.pagination
        }
      }

      return {
        ...state,
        companies: newArr,
        companyToAppend: action.payload,
        loading: false,
      }
    case FETCH_SINGLE_COMPANY_FAIL:
      return { ...state, error: action.payload, loading: false }

    case RESET_SERVER_ERROR:
      return { ...state, serverError: {} }

    default:
      return { ...state }
  }
}
