import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import windowSize from 'react-window-size'
import styled from 'styled-components'
import { MaterialIcon } from '../../resources/MaterialIcon'

import {
  CASHIERS_ROUTE,
  DASHBOARD_ROUTE,
  LOCATIONS_ROUTE,
  LOGS_ROUTE,
  MERCHANTS_ROUTE,
  PLATFORM_SETTINGS_ROUTE,
  RELEASES_ROUTE,
  REPORTS_ROUTE,
  ROLES_ROUTE,
  SAFE_DEPOSITS_ROUTE,
  SETTINGS_ROUTE,
  SETTLEMENTS_ROUTE,
  TERMINALS_ROUTE,
  TRANSACTION_BUNDLES_ROUTE,
  TRANSACTIONS_ROUTE,
  USERS_ROUTE,
  VENDORS_ROUTE,
  VIRTUAL_TERMINALS_ROUTE,
  PAYMENTS_ROUTE,
} from '../../router/ClientRoutes'
import { fetchMerchants } from '../pages/merchants/MerchantsActions'
import { fetchVendors } from '../pages/vendors/VendorActions'
import { bigPadding, primaryColor, secondaryColor } from './../partials'
import ListTitle from './SidebarListTitle'

const SidebarWrapper = styled.aside`
  position: relative;
  margin-top: 0;
  padding: 30px 10px;
  max-width: 250px;
  min-width: 320px;
  background: linear-gradient(${primaryColor}, ${secondaryColor});
  z-index: 1;
  height: auto;
  min-height: calc(100vh - 70px);
  @media all and (max-width: 1520px) {
    padding: 30px 10px;
    max-width: 240px;
    min-width: 240px;
  }
  @media all and (max-width: 768px) {
    & + main {
      position: relative;
      left: 0px;
      margin-left: 0;
      transition: all 0.3s ease;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.65);
        transition: all 0.3s ease;
        z-index: 99999;
      }
      ${props =>
    !props.collapsed &&
    `
        left: 170px;
        margin-left: -170px;
        &:before{
          opacity: 1;
          visibility: visible;
        }
      `};
    }
  }
  ${props =>
    props.collapsed &&
    `
  max-width:70px;
  min-width:70px;
  padding: 30px 0;
  font-size:25px;
  @media all and (max-width: 1400px){
    max-width: 100px;
    min-width: 100px;
  }
  @media all and (max-width: 1023px){
    max-width: 70px;
    min-width: 70px;
  }
  @media all and (max-width: 560px){
    max-width: 44px;
    min-width: 44px;
  }
  `} transition: width 0.4s;
`
const SidebarItem = styled.div`
  position: relative;
  margin-bottom: 10px;
  ${props =>
    props.collapsed &&
    `
  &:hover{
    ul{
      opacity: 1;
      margin-left: 0;
      visibility: visible;
    }
  }
  `};
`

const SidebarSeparator = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.9);

  margin: ${bigPadding} -10px;
`

// const collapsedSidebarList = `
//   opacity: 0;
//   visibility: hidden;
//   position: absolute;
//   top: -10px;
//   left: 100%;
//   margin-left: -10px;
//   padding: 15px 20px 10px;
//   background-color: ${primaryColor};
//   li{
//     white-space: nowrap;
//   }
//   &:before{
//     content: '';
//     position: absolute;
//     top: 20px;
//     left: -7px;
//     width: 0; 
//     height: 0; 
//     border-top: 8px solid transparent;
//     border-bottom: 8px solid transparent;
//     border-right: 8px solid ${primaryColor};
//   }
// `

// const SidebarList = styled.ul`
//   padding-right: 10px;
//   padding-left: 70px;
//   ${props =>
//     props.collapsed ? collapsedSidebarList : ''} @media all and (max-width: 560px) {
//     padding-left: 55px;
//   }
// `

const collapsedClose = `
  width:100%;
  margin-bottom:25px;
  text-align: center;  
  @media all and (max-width: 560px){
    margin-bottom: 25px;
  }
`
const notCollapsedClose = `
float:right;
margin-right:20px;
@media all and (max-width: 1400px){
  margin-top: -15px;
  margin-right: 0;
}
`

const Close = styled.div`
  position: relative;
  font-size: 24px;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  cursor: pointer;
  z-index: 1;
  ${props => (props.collapsed ? collapsedClose : notCollapsedClose)};
`

class Sidebar extends Component {
  handleCollapse = () => {
    //eslint-disable-line
    this.setState({ collapsed: !this.state.collapsed })
  }
  openOnAsideClick = e => {
    //eslint-disable-line
    if (this.state.collapsed && e.target.tagName === 'ASIDE') {
      this.setState({ collapsed: false })
    }
  }
  isLinkActive = (location, linkLocation) => {
    //eslint-disable-line
    if (linkLocation === '/') {
      return location === linkLocation
    }
    return location.indexOf(linkLocation) !== -1
  }

  constructor(params) {
    super(params)
    this.state = { collapsed: false, location: '' }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.windowWidth < 840) {
      this.setState({ collapsed: true })
    } else {
      this.setState({ collapsed: false })
    }
  }

  render() {
    const { collapsed } = this.state
    const { location, permissions, role, activeCompany } = this.props //eslint-disable-line
    if (!permissions || Object.keys(permissions).length === 0) {
      return <SidebarWrapper />
    }

    return (
      <Translate>
        {({ translate }) => (
          <SidebarWrapper collapsed={collapsed} onClick={this.openOnAsideClick}>
            <Close collapsed={collapsed} onClick={this.handleCollapse}>
              <span className={`lnr lnr-${collapsed ? 'menu' : 'cross'}`} />
            </Close>
            <div>
              <SidebarItem>
                <ListTitle
                  enabled
                  active={this.isLinkActive(location, DASHBOARD_ROUTE)}
                  route={DASHBOARD_ROUTE}
                  icon='home'
                  text={translate('dashboard')}
                  hidden={collapsed}
                />
              </SidebarItem>
              <SidebarSeparator />
              {permissions.merchant.readAll && (
                <SidebarItem>
                  <ListTitle
                    enabled={permissions.merchant.readAll || permissions.merchant.readOwn}
                    active={this.isLinkActive(location, MERCHANTS_ROUTE)}
                    route={MERCHANTS_ROUTE}
                    icon='apartment'
                    text={translate('merchants')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {(permissions.location.readAll || permissions.location.readOwn) && (
                <SidebarItem>
                  <ListTitle
                    enabled={permissions.location.readAll || permissions.location.readOwn}
                    active={this.isLinkActive(location, LOCATIONS_ROUTE)}
                    route={LOCATIONS_ROUTE}
                    icon='map-marker'
                    text={translate('locations')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {(permissions.cashier.readAll || permissions.cashier.readOwn) && (
                <SidebarItem>
                  <ListTitle
                    enabled={permissions.cashier.readAll || permissions.cashier.readOwn}
                    active={this.isLinkActive(location, CASHIERS_ROUTE)}
                    route={CASHIERS_ROUTE}
                    icon='users'
                    text={translate('cashiers')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {(permissions.terminal.readAll || permissions.terminal.readOwn) && (
                <SidebarItem>
                  <ListTitle
                    enabled={permissions.terminal.readAll || permissions.terminal.readOwn}
                    active={this.isLinkActive(location, TERMINALS_ROUTE)}
                    route={TERMINALS_ROUTE}
                    icon='keyboard'
                    text={translate('terminals')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {(permissions.terminal.readAll || permissions.terminal.readOwn) && (
                <SidebarItem>
                  <ListTitle
                    enabled={permissions.terminal.readAll || permissions.terminal.readOwn}
                    active={this.isLinkActive(location, VIRTUAL_TERMINALS_ROUTE)}
                    route={VIRTUAL_TERMINALS_ROUTE}
                    icon='keyboard'
                    text={translate('virtualTerminals')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}

              {permissions.vendor.readAll && (
                <SidebarItem>
                  <ListTitle
                    active={this.isLinkActive(location, VENDORS_ROUTE)}
                    route={VENDORS_ROUTE}
                    enabled={permissions.vendor.readAll}
                    icon='star'
                    text={translate('vendors')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {role.code === 'ROLE_ADMIN' && <SidebarSeparator />}

              {(!activeCompany || _.isEmpty(activeCompany)) && (
                <Translate>
                  {({ translate }) => (
                    <SidebarItem>
                      <ListTitle
                        active={this.isLinkActive(location, PAYMENTS_ROUTE)}
                        route={PAYMENTS_ROUTE}
                        enabled={true}
                        iconItem={
                          <MaterialIcon iconName="sync_alt" style={{fontSize: 'inherit'}}/>
                        }
                        text={translate('payments')}
                        hidden={collapsed}
                      >
                      </ListTitle>
                    </SidebarItem>
                  )}
                </Translate>
              )}

              {(permissions.transaction.readAll || permissions.transaction.readOwn) && (
                <Translate>
                  {({ translate }) => (
                    <SidebarItem>
                      <ListTitle
                        active={this.isLinkActive(location, TRANSACTIONS_ROUTE)}
                        route={TRANSACTIONS_ROUTE}
                        enabled={
                          permissions.transaction.readAll ||
                          permissions.transaction.readOwn
                        }
                        icon='cart'
                        text={translate('transactions')}
                        hidden={collapsed}
                      />
                      {/* <SidebarList collapsed={collapsed}>
            <ListItem active={this.isLinkActive(location, NOTIFICATIONS_ROUTE)} route={'/'}>Setari financiare</ListItem>
          </SidebarList> */}
                    </SidebarItem>
                  )}
                </Translate>
              )}
              {permissions.companyReport &&
                (permissions.companyReport.readAll ||
                  permissions.companyReport.readOwn) && (
                  <Translate>
                    {({ translate }) => (
                      <SidebarItem>
                        <ListTitle
                          active={this.isLinkActive(location, REPORTS_ROUTE)}
                          route={REPORTS_ROUTE + '/add-report'}
                          enabled={
                            permissions.companyReport &&
                            (permissions.companyReport.readAll ||
                              permissions.companyReport.readOwn)
                          }
                          icon='file-empty'
                          text={translate('reports')}
                          hidden={collapsed}
                        />
                      </SidebarItem>
                    )}
                  </Translate>
                )}
              {permissions.settlement && permissions.settlement.readAll && (
                <SidebarItem>
                  <ListTitle
                    active={this.isLinkActive(location, SETTLEMENTS_ROUTE)}
                    route={SETTLEMENTS_ROUTE}
                    enabled={
                      permissions.settlement.readAll || permissions.settlement.readOwn
                    }
                    icon='undo'
                    text={translate('settlements')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {permissions.settlement && permissions.settlement.readAll && (
                <SidebarItem>
                  <ListTitle
                    active={this.isLinkActive(location, SAFE_DEPOSITS_ROUTE)}
                    route={SAFE_DEPOSITS_ROUTE}
                    enabled={
                      permissions.settlement.readAll || permissions.settlement.readOwn
                    }
                    icon='undo'
                    text={translate('securityDeposits')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {permissions.transactionBundles && permissions.transactionBundles.readAll && (
                <SidebarItem>
                  <ListTitle
                    enabled
                    active={this.isLinkActive(location, TRANSACTION_BUNDLES_ROUTE)}
                    route={TRANSACTION_BUNDLES_ROUTE}
                    icon='history'
                    text={translate('bundle')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {permissions.user && permissions.user.readAll && (
                <SidebarItem>
                  <ListTitle
                    enabled
                    active={this.isLinkActive(location, USERS_ROUTE)}
                    route={USERS_ROUTE}
                    icon='users'
                    text={translate('users')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {role.code === 'ROLE_ADMIN' && <SidebarSeparator />}
              {permissions.role && permissions.role.readAll && (
                <SidebarItem>
                  <ListTitle
                    enabled
                    active={this.isLinkActive(location, ROLES_ROUTE)}
                    route={ROLES_ROUTE}
                    icon='eye'
                    text={translate('roles')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              <SidebarItem>
                <ListTitle
                  enabled
                  active={this.isLinkActive(location, SETTINGS_ROUTE)}
                  route={SETTINGS_ROUTE}
                  icon='cog'
                  text={translate('settings')}
                  hidden={collapsed}
                />
              </SidebarItem>
              {role.code === 'ROLE_ADMIN' && (
                <SidebarItem>
                  <ListTitle
                    enabled
                    active={this.isLinkActive(location, PLATFORM_SETTINGS_ROUTE)}
                    route={PLATFORM_SETTINGS_ROUTE}
                    icon='cog'
                    text={translate('platformSettings')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {permissions.release && permissions.release.readAll && (
                <SidebarItem>
                  <ListTitle
                    enabled
                    active={this.isLinkActive(location, RELEASES_ROUTE)}
                    route={RELEASES_ROUTE}
                    icon='tablet'
                    text={translate('apkReleases')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
              {permissions.log && permissions.log.readAll && (
                <SidebarItem>
                  <ListTitle
                    enabled
                    active={this.isLinkActive(location, LOGS_ROUTE)}
                    route={LOGS_ROUTE}
                    icon='warning'
                    text={translate('logs')}
                    hidden={collapsed}
                  />
                </SidebarItem>
              )}
            </div>
          </SidebarWrapper>
        )}
      </Translate>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.string
}

export default connect(
  state => ({
    user: state.login.user,
    permissions: state.login.permissions,
    role: state.login.role,
    companies: state.vendors.companies,
    merchants: state.merchants.merchants,
    activeCompany: state.login.company
  }),
  { fetchVendors, fetchMerchants }
)(windowSize(Sidebar))
