import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
display:block;
position:relative;
border:none;
outline:none;
flex: 1;
${props => props.width && `
width:${props.width}px;`}
span{
  position:absolute;
  top:50%;
  right:30px;
  font-size:12px;
  font-weight:bold;
  pointer-events:none;
  transform: translateY(-50%);
}
select {
  outline:none;
  border:none;
  height:35px;
  padding-left: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  width:100%;
  ${props => props.width && `
  width:${props.width}px;`}
  appearance:none;
  option {
    appearance:none;
    border:none;
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }
}
`

class SimpleSelect extends React.Component {
  render () {
    return (
      <Wrapper style={this.props.wrapperStyle} width={this.props.width}>
        <select {...this.props} >
          {this.props.children}
        </select>
        <span className='lnr lnr-chevron-down' />
      </Wrapper>
    )
  }
}

export {SimpleSelect}
