import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width:15px;
  height:15px;
  font-size:11px;
  display:flex;
  align-items:center;
  padding: 0 5px;
  
  @media only screen and (max-width: 640px) {
    width: 10px;
  }
  `
const Icon = styled.i`
  cursor:pointer;
  &:before{
    color:black;
  }
  &:after{
    content: '';
    color:#0291eb;
    
  }
  ${props => props.active && `

  &:before{
    color:#0291eb;
  }
  &:after{
    content: '';
    color:black;
    
  }
  `
}`

class SortIcon extends React.Component {
  render () {
    const {active, onSortClick, up} = this.props
    return (
      <Wrapper onClick={onSortClick}>
        <Icon className={`lnr lnr-arrow-${active && up ? 'up' : 'down'}`} active={active} />
      </Wrapper>
    )
  }
}

export {SortIcon}
