import styled from 'styled-components'
import { primaryColor } from '../common/StyleConstants'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const NavItemWrapper = styled.div`
display:flex;
align-items:center;
font-family:Opensans;
font-size: 14px;
font-weight:bold;
line-height: 1.2;
letter-spacing:0.24em;
cursor:default;
height:20px;
margin-right:20px;
padding-left:20px;
@media all and (max-width: 560px){
  margin-right:10px;
  padding-left:10px;
  border-left: none !important;
}
a {
  position: relative;
  display: inline-block;
  color: rgb(159, 163, 167);
  font-size: 18px;
  text-decoration:none;
  transition: color .3s ease;
  span {
    font-size: 12px;
  }
  > div{
    display: flex;
    align-items: center;
    i {
      margin-right:10px;
      font-size: 20px !important;
    }
  }
  &:hover
  {
    color: ${primaryColor};
    text-decoration:none;
    &:before{
        width: 100%;
    }
  }
  &:before{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    border-bottom: 2px solid ${primaryColor};
    transition: width .2s ease;
  }
  ${props => props.active ? `
    color: ${primaryColor};
    &:before{ width: 100%}` : ''
  }
}
@media all and (max-width: 1023px){
  a{
    font-size: 16px;
    > div{
      i{
        margin-right: 0;
      }
      span{
        display: none;
      }
    }
  }
}
${props => props.onlyText ? 'color:rgb(76,76,76);font-weight:400;'
  : `text-transform:uppercase;
`}
${props => props.onlyText && `
  @media all and (max-width: 640px){
    display: none;
  }
`}

${props => props.divider ? 'border-left: 2px solid #eee;' : ''}
`

class NavItem extends Component {
  render () {
    const { onlyText, style, onClick, active, divider, icon, children, route } = this.props
    return (
      <NavItemWrapper
        onlyText={onlyText}
        divider={divider}
        active={active}
        style={style}
        onClick={onClick}>
        {route && <Link to={route}>
          <div>
            {icon && <i className={`lnr lnr-${this.props.icon}`}/>}
            <span>{children}</span>
          </div>
        </Link>}

        {!route &&
        <div>
          {icon && <i className={`lnr lnr-${this.props.icon}`}/>}
          <span>{children}</span>
        </div>}
      </NavItemWrapper>
    )
  }
}

NavItem.propTypes = {
  children: PropTypes.node,
  onlyText: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.string,
  route: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  iconSize: PropTypes.number
}

export { NavItem }
