import {
  GET_BALANCE,
  GET_BALANCE_FAIL,
  GET_BALANCE_SUCCESS,
  GET_PLATFORM_BALANCE,
  GET_PLATFORM_BALANCE_FAIL,
  GET_PLATFORM_BALANCE_SUCCESS,
  GET_PLATFORM_CURRENCIES,
  GET_PLATFORM_CURRENCIES_FAIL,
  GET_PLATFORM_CURRENCIES_SUCCESS,
  RESET_CURRENT_CURRENCY
} from './BalancesActions'
import { sortCurrencies } from '../../../globals/sortCurrencies'

const INITIAL_STATE = {
  balance: {},
  settlement: {},
  completedSettlement: {},
  platformCurrencies: [],
  platformBalance: {},
  loading: false,
  error: {},
  currentCurrency: '',
  platformBalanceLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BALANCE:
      return { ...state, loading: true, balance: {} }
    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
        settlement: action.payload.lastActiveSettlement || {},
        completedSettlement: action.payload.lastCompletedSettlement || {},
        loading: false,
        currentCurrency: action.payload.currency,
      }
    case GET_BALANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        platformBalance: {}
      }
    case GET_PLATFORM_BALANCE:
      return { ...state, platformBalanceLoading: true, platformBalance: {} }
    case GET_PLATFORM_BALANCE_SUCCESS:
      return {
        ...state,
        platformBalance: action.payload,
        currentCurrency: action.payload.currency,
        platformBalanceLoading: false
      }
    case GET_PLATFORM_BALANCE_FAIL:
      return {
        ...state,
        platformBalanceLoading: false,
        error: action.payload
      }
    case GET_PLATFORM_CURRENCIES:
      return { ...state, loading: true }
    case GET_PLATFORM_CURRENCIES_SUCCESS:
      let currencies = sortCurrencies(action.payload)

      return {
        ...state,
        platformCurrencies: action.payload,
        currentCurrency: currencies && currencies[0] ? currencies[0].code : '',
        loading: false
      }
    case GET_PLATFORM_CURRENCIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case RESET_CURRENT_CURRENCY:
      return {
        ...state,
        loading: false,
        currentCurrency: action.payload
      }
    default:
      return state
  }
}
