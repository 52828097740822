import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { history } from '../store/configureStore'
import HomePage from '../modules/HomePage'
import AuthenticatedRoute from './AuthenticatedRoute'
import QrDeposit from '../modules/qrcode/QrDeposit'
import QrWithdrawal from '../modules/qrcode/QrWithdrawal'
import { withLocalize } from 'react-localize-redux'
import { global } from '../translations/getGlobal'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  HOME_ROUTE,
  RECOVER_PASSWORD_ROUTE,
  AUTH_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  QR_DEPOSIT,
  QR_WITHDRAWAL
} from './ClientRoutes'
import AuthPage from '../modules/auth/AuthPage'
const WorkInProgress = () => {
  return <div style={{ fontSize: 30, textAlign: 'center', marginTop: 150 }}>Work in proggress :(</div>
}

class MainRouter extends Component { //eslint-disable-line
  constructor (props) {
    super(props)

    const languages = [
      { name: 'Romanian', code: 'RO' },
      { name: 'English', code: 'US' },
      { name: 'Portuguese', code: 'PT' }
    ]

    const defaultLanguage = localStorage.getItem('languageCode') || languages[0].code
    const onMissingTranslation = () => { return 'Missing translation' }

    let globalTranslate = global()

    this.props.initialize({
      languages,
      translation: globalTranslate,
      options: { renderToStaticMarkup, defaultLanguage, onMissingTranslation }
    })
  }
  render () {
    const { authenticated } = this.props
    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route exact path={QR_DEPOSIT} component={QrDeposit} />
          <Route exact path={QR_WITHDRAWAL} component={QrWithdrawal} />
          <Route path={AUTH_ROUTE} component={AuthPage} />
          <Route path={LOGIN_ROUTE} component={() => <Redirect to={`${AUTH_ROUTE}${LOGIN_ROUTE}`} push />} />
          <Route path={REGISTER_ROUTE} component={() => <Redirect to={`${AUTH_ROUTE}${REGISTER_ROUTE}`} push />} />
          <Route exact path={RECOVER_PASSWORD_ROUTE} component={WorkInProgress} />
          <Route exact path={'/qr-testing'} component={QrTesting} />
          <AuthenticatedRoute path={HOME_ROUTE} authenticated={authenticated} component={HomePage} />
          <Route component={() => <div>404 not found</div>} />
        </Switch>
      </ConnectedRouter>)
  }
}
MainRouter.propTypes = {
  authenticated: PropTypes.bool
}
const mapStateToProps = state => {
  return {
    authenticated: state.login.isAuthenticated
  }
}
export default withLocalize(connect(mapStateToProps)(MainRouter))

class QrTesting extends React.Component {
  state = {
    codes: [],
    selected: '',
    qrCode: '',
    amount: 0,
    type: ''
  }
  componentDidMount () {
    axios({
      url: '/qr-testing',
      method: 'get'
    }).then(response => {
      this.setState({ codes: response.data })
    })
      .catch(err => {
        console.log(err)
      })
  }
  submit = () => {
    this.setState({ error: '' })
    // if (this.state.amount < 50) {
    //   this.setState({error: 'Amount must be greater than 50'})
    //   return
    // }
    if (this.state.selected === '') {
      this.setState({ error: 'Select a user' })
      return
    }
    axios({
      url: '/qr-testing',
      method: 'post',
      data: {
        // amount: this.state.amount,
        type: 'deposit',
        userId: this.state.selected
      }
    }).then(response => {
      this.setState({ qrCode: response.data })
    })
      .catch(err => {
        console.log(err)
      })
  }
  render () {
    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '50%' }}>

          {this.state.codes.map(item =>
            (<div style={{ display: 'flex' }}>
              <div style={{ flex: 1, padding: 10 }}>

                {item.vendorUsername}
              </div>
              <div style={{ flex: 1, padding: 10 }}>

                <input type='radio' id={item.id} name='radioUsername' onChange={(e) => { this.setState({ selected: e.target.id }) }} />
              </div>

            </div>))}
          {/* <div style={{paddingLeft: 20, alignItems: 'center', marginTop: 50}}>
            <div style={{display: 'flex'}}>
              <div style={{width: '50%'}}>Deposit</div>
              <input type='radio' name='type' id='deposit' onChange={e => { this.setState({type: e.target.id}) }} />

            </div>
            <div style={{display: 'flex', marginTop: 10, marginBottom: 30}}>
              <div style={{width: '50%'}}>Withdrawal</div>
              <input type='radio' name='type' id='withdrawal' onChange={e => { this.setState({type: e.target.id}) }} />

            </div>
          </div> */}
          <div style={{ paddingLeft: 20, marginVertical: 20, color: '#ce0000' }}>{this.state.error}</div>
          <button onClick={this.submit} style={{ width: 200, marginLeft: 50, height: 40, marginTop: 20 }}>Generate QR</button>
        </div>

        <div style={{ width: '50%' }}>
          {this.state.qrCode !== '' && <img src={this.state.qrCode} style={{ width: 200, height: 200 }} alt={'route'} />}
        </div>

      </div>

    </div>
  }
}
