import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeFilter } from '../../filter/FilterActions'
import { CustomSelect } from '../../partials/form'
import { appendMerchants, fetchMerchants } from '../merchants/MerchantsActions'
import { getMySwalErrorPopup } from '../../../globals/getMySwalPopups'

class MerchantsSelect extends Component {
  async componentDidMount() {
    const { fetchMerchants, changeFilter, withoutPagination, loadingList } = this.props
    if (!loadingList) {
      try {
        await changeFilter({
          type: 'filterBy',
          name: 'merchantType',
          page: 'merchants',
          value: 'merchant',
          operator: '=',
          noFetch: true
        })
        await changeFilter({
          // fetchFunction: fetchMerchants,
          page: 'merchants',
          type: 'pagination',
          name: 'pageSize',
          value: withoutPagination === true ? 999 : 10,
          operator: '=',
          noFetch: true
        })
        await fetchMerchants()
      } catch (e) {
        getMySwalErrorPopup()
      }
    }
  }

  onMenuScrollToBottom = async () => {
    const { pageCount, page, appendMerchants } = this.props
    if (page + 1 <= pageCount) {
      try {
        await appendMerchants(page + 1)
      } catch (e) {
        getMySwalErrorPopup()
      }
    }
  }

  searchMerchants = async value => {
    const { changeFilter, fetchMerchants } = this.props
    try {
      await changeFilter({
        fetchFunction: fetchMerchants,
        page: 'merchants',
        name: 'searchBy',
        type: 'searchBy',
        value
      })
    } catch (e) {
      getMySwalErrorPopup()
    }
  }

  onValueChange = e => {
    const { input, onValueClick, ifFromAddSettlementCompany } = this.props
    if (onValueClick) {
      onValueClick(e)
    }
    input.onChange(e)

    if (ifFromAddSettlementCompany) {
      this.props.input.onChange(e)
    }
  }

  render() {
    const { merchants, noPadding, addSelectAllFirst, itemToAddFirst, input, serverError } = this.props
    const options = merchants.map(merchant => ({
      label: merchant.name,
      value: merchant.id
    }))
    if (addSelectAllFirst) {
      options.unshift(itemToAddFirst)
    }

    return (
      <CustomSelect
        {...this.props}
        required
        // onInputChange={value => this.searchMerchants(value)}
        onMenuScrollToBottom={this.onMenuScrollToBottom}
        onChange={this.onValueChange}
        options={options}
        noPadding={noPadding}
        translateCurrentValue={input && input.value && input.value.key ? true : false}
        serverError={serverError}
      />
    )
  }
}

MerchantsSelect.propTypes = {}

export default connect(
  state => ({
    merchants: state.merchants.merchants,
    page: state.merchants.pagination.currentPage,
    pageCount: state.merchants.pagination.pageCount,
    loadingList: state.merchants.loadingList
  }),
  {
    fetchMerchants,
    appendMerchants,
    changeFilter
  }
)(MerchantsSelect)
