import axios from 'axios'

const putRequest = ({ url, data }) => {
  return axios({
    method: 'PUT',
    data,
    url
  })
}
export default putRequest
