import React from 'react'
import styled from 'styled-components'
import {secondaryTextColor} from './StyleConstants'

const BreadcrumbWrapper = styled.div`
  ul{
    display: inline-block;
    // padding-bottom: 30px;
    list-style: none;
    padding-left: 0;
    li{
      display: inline-block;
      color: ${secondaryTextColor};
      &:after{
        content: '>';
        margin: 0 16px;
        font-size: 16px;
      }
      &:last-child:after{
        content: initial;
      }
      > span{
        color: #686F7F;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 5px;
        font-family: 'Opensans Bold';
      }
      a{
        &:hover{
          color:#000;
        }
        color: rgba(0,0,0,.6);
        font-size: 16px;
        font-weight: 700;
        text-decoration: none !important;
        text-transform: uppercase;
        letter-spacing: 5px;
        transition: color .2s ease;
      }
    }
  }
  @media all and (max-width: 1023px){
    ul{
      padding-bottom: 20px;
      li{
        &:after{
          margin: 0 10px;
          font-size: 14px;
        }
        > span{
          letter-spacing: 2px;
        }
        a{
          letter-spacing: 2px;
        }
      }
    }
  }
  @media all and (max-width: 768px){
    ul{
      padding-bottom: 15px;
      li{
        &:after{
          font-size: 12px;
        }
        > span{
          font-size: 10px;
          letter-spacing: 1px;
        }
        a{
          font-size: 10px;
          letter-spacing: 1px;
        }
      }
    }
  }
`
const Breadcrumb = (props) => (
  <BreadcrumbWrapper>
    <ul>
      {props.children}
    </ul>
  </BreadcrumbWrapper>
)

export {Breadcrumb}
