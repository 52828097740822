import {
  CHANGE_FILTER,
  CLEAR_ALL_FILTERS,
  CLEAR_ALL_FILTERS_BUT_ONE,
  CLEAR_ALL_FILTERS_ON_A_PAGE,
  CLEAR_SPECIFIC_FILTER_ON_PAGE,
  RESET_VENDOR_SEARCH_BY,
  RESET_MERCHANT_SEARCH_BY
} from './FilterActions'

const INITIAL_STATE = {
  companies: {
    filterBy: {},
    pagination: {}
  },
  payments: {
    filterBy: {
      transactionType: { name: 'transactionType', type: 'filterBy', value: 'all', operator: undefined }
    }
  },
  allocations: {
    filterBy: {}
  },
  ips: {
    filterBy: {},
    orderBy: {}
  },
  paymentTypes: {
    filterBy: {},
    pagination: {}
  },
  paymentSubTypes: {
    filterBy: {},
    pagination: {}
  },
  transactions: {
    orderBy: {},
    filterBy: {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: undefined }
    },
    pagination: {},
    match: ''
  },
  roles: {
    orderBy: {},
    filterBy: {},
    pagination: {},
    match: ''
  },
  terminals: {
    orderBy: {},
    filterBy: {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: '=' },
      lastPinged: { name: 'lastPinged', type: 'filterBy', value: 'all', operator: '=' },
      isVIP: { name: 'isVIP', type: 'filterBy', value: 'all', operator: '=' },
      isVirtualTerminal: { name: 'isVirtualTerminal', type: 'filterBy', value: 0, operator: '=' },
      installedApkVersionId: { name: 'installedApkVersionId', type: 'filterBy', value: 'all', operator: '=' },
    },
    pagination: {}
  },
  virtualTerminals: {
    orderBy: {},
    filterBy:
    {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: undefined },
      isVirtualTerminal: { name: 'isVirtualTerminal', type: 'filterBy', value: 'virtual', operator: undefined },
      isVIP: { name: 'isVIP', type: 'filterBy', value: 'all', operator: '=' },
    },
    pagination: {}
  },
  merchants: {
    orderBy: {},
    filterBy: {
      is_internal: {
        name: 'is_internal',
        operator: undefined,
        type: "filterBy",
        value: 'all'
      },
      merchantType: { name: 'merchantType', type: 'filterBy', value: 'merchant', operator: '=' }
    },
    pagination: {}
  },
  locations: {
    orderBy: {},
    filterBy: {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: undefined }
    },
    pagination: {
      page: {
        name: 'page',
        type: 'pagination',
        value: 1
      },
      pageCount: {
        name: 'pageCount',
        type: 'pagination',
        value: 1
      }
    }
  },
  clients: {
    orderBy: {},
    filterBy: {},
    pagination: {}
  },
  cashiers: {
    orderBy: {},
    filterBy: {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: undefined },
      isLocationManager: {
        name: 'isLocationManager',
        type: 'filterBy',
        value: 'all',
        operator: undefined
      }
    },
    pagination: {}
  },
  settlements: {
    orderBy: {},
    filterBy: {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: undefined },
      clientType: { name: 'clientType', type: 'filterBy', value: 'all', operator: undefined }
    },
    pagination: {}
  },
  safeDeposits: {
    orderBy: {},
    filterBy: {
      status: { name: 'status', type: 'filterBy', value: 'all', operator: undefined },
      clientType: { name: 'clientType', type: 'filterBy', value: 'all', operator: undefined }
    },
    pagination: {}
  },
  users: {
    orderBy: {},
    filterBy: {},
    pagination: {}
  },
  bundles: {
    orderBy: {},
    filterBy: {},
    pagination: {}
  },
  dashboard: {
    orderBy: {},
    filterBy: {},
    pagination: {}
  },
  logs: {
    orderBy: {},
    filterBy: {
      status: { name: 'errorSource', type: 'filterBy', value: 'all', operator: undefined }
    },
    pagination: {}
  },
  release: {
    orderBy: {},
    filterBy: {},
    pagination: {}
  },
  vendors: {
    searchBy: {},
    filterBy: {
      vendorType: { name: 'vendorType', type: 'filterBy', value: 'vendor', operator: '=' }
    },
    pagination: {}
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_FILTER:
      const { page, name, value, operator, type } = action.payload
      return {
        ...state,
        [page]: {
          ...state[page],
          ...(type === 'orderBy' || type === 'searchBy'
            ? {
              [type]: {
                name,
                type,
                value,
                operator
              }
            }
            : {
              [type]: {
                ...state[page][type],
                [name]: {
                  name,
                  type,
                  value,
                  operator
                }
              }
            })
        }
      }
    case CLEAR_ALL_FILTERS_ON_A_PAGE:
      let deletePage = { ...state }
      // const { page: clearAllPage } = action.payload
      let clearAllPage = action.payload.page
      let clearedPage
      if (state[clearAllPage].filterBy.maxAmount) {
        const maxAmount = deletePage[clearAllPage].filterBy.maxAmount.value
        delete deletePage[clearAllPage]
        clearedPage = {
          ...deletePage,
          [clearAllPage]: {
            maxAmount: { ...state[clearAllPage].filterBy.maxAmount },
            pagination: {},
            orderBy: {},
            searchBy: {},
            filterBy: {
              ...INITIAL_STATE[clearAllPage].filterBy,
              amount: {
                name: 'amount',
                type: 'filterBy',
                page: action.payload.page,
                value: maxAmount
              },
              maxAmount: {
                name: 'maxAmount',
                type: 'filterBy',
                page: action.payload.page,
                value: maxAmount
              },
              transactionBundleId: {
                // ...state[clearAllPage].filterBy.transactionBundleId
              },
              settlementId: {
                ...state[clearAllPage].filterBy.settlementId
              },
              safeDepositId: {
                ...state[clearAllPage].filterBy.safeDepositId
              }
            }
          }
        }
        return clearedPage
      }
      delete deletePage[clearAllPage]
      clearedPage = {
        ...deletePage,
        [clearAllPage]: {
          ...INITIAL_STATE[clearAllPage]
        }
      }

      if (clearAllPage.toString() === 'merchants') {
        clearedPage[clearAllPage].filterBy = {
          merchantType: {
            name: 'merchantType',
            type: 'filterBy',
            value: 'merchant',
            operator: '='
          },
          is_internal: {
            name: 'is_internal',
            operator: undefined,
            type: "filterBy",
            value: 'all'
          }
        }
      }

      return clearedPage

    case CLEAR_ALL_FILTERS_BUT_ONE:
      const { survivor } = action.payload
      if (survivor === 'transactions') {
        const { params } = action.payload
        if (!params.settlementId) {
          delete state[survivor].filterBy.settlementId
        }
        if (!params.safeDepositId) {
          delete state[survivor].filterBy.safeDepositId
        }
        if (!params.transactionBundleId) {
          delete state[survivor].filterBy.transactionBundleId
        }
        return {
          ...INITIAL_STATE,
          [survivor]: {
            ...state[survivor]
          }
        }
      } else if (survivor === 'merchants') {
        return {
          ...INITIAL_STATE,
          [survivor]: {
            ...state[survivor]
          }
        }
      } else {
        return {
          ...INITIAL_STATE,
          [survivor]: {
            ...state[survivor]
          },
          merchants: {
            ...INITIAL_STATE.merchants,
            filterBy: {
              merchantType: {
                name: 'merchantType',
                type: 'filterBy',
                value: 'merchant',
                operator: '='
              },
              is_internal: {
                name: 'is_internal',
                operator: undefined,
                type: "filterBy",
                value: 'all'
              }
            }
          }
        }
      }

    case CLEAR_SPECIFIC_FILTER_ON_PAGE:
      const {
        page: specificPage,
        type: specificType,
        name: specificName
      } = action.payload
      let filters = { ...state[specificPage] }
      if (filters[specificType]) {
        if (filters[specificType][specificName]) {
          delete filters[specificType][specificName]
        } else {
          delete filters[specificType]
        }
      }
      return {
        ...state,
        [specificPage]: {
          ...filters
        }
      }
    case CLEAR_ALL_FILTERS:
      return {
        ...INITIAL_STATE,
        merchant: {
          ...INITIAL_STATE.merchant,
          filterBy: {
            merchantType: {
              name: 'merchantType',
              type: 'filterBy',
              value: 'merchant',
              operator: '='
            },
            is_internal: {
              name: 'is_internal',
              operator: undefined,
              type: "filterBy",
              value: 'all'
            },
            transactionBundleId: {
              // ...state.transactionBundleId
            },
            settlementId: {
              ...state.settlementId
            },
            safeDepositId: {
              ...state.safeDepositId
            }
          }
        }
      }

    case RESET_MERCHANT_SEARCH_BY:
      return {
        ...INITIAL_STATE,
        merchants: {
          ...INITIAL_STATE.merchants,
          searchBy: {}
        }
      }

    case RESET_VENDOR_SEARCH_BY:
      return {
        ...INITIAL_STATE,
        vendors: {
          ...INITIAL_STATE.vendors,
          searchBy: {}
        }
      }
    default:
      return state
  }
}
