export const dynamicLanguage = {
    RO: {
        "lengthRequirements": (params) => `Campul trebuie sa contina minim ${params[0] ? params[0] : 6} caractere si maximum ${params[1] ? params[1] : 20}`,
    },
    US: {
        "lengthRequirements": (params) => `The field must have at least ${params[0] ? params[0] : 6} characters and maximum ${params[1] ? params[1] : 20}`,
    },
    PT:  {
        "lengthRequirements": (params) => `The field must have at least ${params[0] ? params[0] : 6} characters and maximum ${params[1] ? params[1] : 20}`,
    }
}