import {
  ADD_SETTLEMENT,
  ADD_SETTLEMENT_FAIL,
  ADD_SETTLEMENT_SUCCESS,
  CHECK_ELIGIBILITY,
  CHECK_ELIGIBILITY_FAIL,
  CHECK_ELIGIBILITY_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_SUCCESS,
  GET_SETTLEMENT,
  GET_SETTLEMENT_FAIL,
  GET_SETTLEMENT_SUCCESS,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_FAIL,
  GET_SETTLEMENTS_SUCCESS,
  PREVIEW_SETTLEMENT,
  PREVIEW_SETTLEMENT_FAIL,
  PREVIEW_SETTLEMENT_SUCCESS,
  UPDATE_SETTLEMENT,
  UPDATE_SETTLEMENT_FAIL,
  UPDATE_SETTLEMENT_SUCCESS,
  GENERATE_INVOICE,
  GENERATE_INVOICE_SUCCESS,
  GENERATE_INVOICE_FAIL,
  CANCEL_INVOICE,
  CANCEL_INVOICE_SUCCESS,
  CANCEL_INVOICE_FAIL,
  // GET_STATUS_INTERVALS,
  GET_STATUS_INTERVALS_SUCCESS,
  GET_STATUS_INTERVALS_FAIL,
  GENERATE_TRANSACTIONS_REPORT,
  GENERATE_TRANSACTIONS_REPORT_SUCCESS,
  GENERATE_TRANSACTIONS_REPORT_FAIL,
  DELETE_TRANSACTIONS_REPORT,
  DELETE_TRANSACTIONS_REPORT_SUCCESS,
  DELETE_TRANSACTIONS_REPORT_FAIL,
  DOWNLOAD_SETTLEMENT_TRANSACTIONS,
  DOWNLOAD_SETTLEMENT_TRANSACTIONS_SUCCESS,
  DOWNLOAD_SETTLEMENT_TRANSACTIONS_FAIL,
  FETCH_ISSUE_DATE_INTERVAL,
  FETCH_ISSUE_DATE_INTERVAL_SUCCESS,
  FETCH_ISSUE_DATE_INTERVAL_FAIL,
  FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID,
  FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_SUCCESS,
  FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_FAIL
} from './SettlementsActions'

import {
  DOWNLOAD_SETTLEMENT_FILE,
  DOWNLOAD_SETTLEMENT_FILE_SUCCESS,
  DOWNLOAD_SETTLEMENT_FILE_FAIL
} from '../../helpers/downloadFiles'

const INITIAL_STATE = {
  settlements: [],
  activeSettlement: {},
  error: '',
  loading: false,
  eligibility: {},
  errorEligibility: '',
  loadingEligibility: false,
  preview: {},
  errorPreview: '',
  loadingPreview: false,
  currencies: [],
  loadingCurrencies: false,
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  generatedInvoice: '',
  canceledInvoice: '',
  statusIntervals: '',
  csvData: '',
  downloadFileStatus: '',
  activeSettlementLoading: false,
  downloadFilesLoading: false,
  issueDateIntervals: {},
  loadingIssueDateIntervals: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTLEMENTS:
      return { ...state, loading: true, error: {} }
    case GET_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        settlements: action.payload.settlements,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount
        },
        loading: false
      }
    case GET_SETTLEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case GET_SETTLEMENT: {
      return {
        ...state,
        loading: true,
        activeSettlementLoading: true
      }
    }
    case GET_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        activeSettlementLoading: false,
        activeSettlement: action.payload
      }
    }
    case GET_SETTLEMENT_FAIL: {
      return {
        ...state,
        loading: false,
        activeSettlementLoading: false,
        error: action.payload
      }
    }
    case ADD_SETTLEMENT:
      return { ...state, loading: true, error: {} }
    case ADD_SETTLEMENT_SUCCESS:
      return {
        ...state,
        activeSettlement: action.payload.settlement,
        loading: false,
        preview: {},
        eligibility: {}
      }
    case ADD_SETTLEMENT_FAIL:
      return { ...state, error: action.payload, loading: false }
    case PREVIEW_SETTLEMENT:
      return { ...state, preview: {}, loadingPreview: true }
    case PREVIEW_SETTLEMENT_SUCCESS:
      return {
        ...state,
        preview: action.payload,
        errorPreview: '',
        loadingPreview: false
      }
    case PREVIEW_SETTLEMENT_FAIL:
      return {
        ...state,
        errorPreview: action.payload,
        loadingPreview: false
      }
    case UPDATE_SETTLEMENT:
      return { ...state, loading: true, error: {} }
    case UPDATE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        activeSettlement: action.payload
      }
    case UPDATE_SETTLEMENT_FAIL:
      return { ...state, error: action.payload, loading: false }
    case CHECK_ELIGIBILITY:
      return { ...state, eligibility: {}, loadingEligibility: true }
    case CHECK_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        eligibility: action.payload,
        errorEligibility: '',
        loadingEligibility: false
      }
    case CHECK_ELIGIBILITY_FAIL:
      return {
        ...state,
        errorEligibility: action.payload,
        loadingEligibility: false
      }
    case GET_CURRENCIES: {
      return {
        ...state,
        currencies: [],
        loadingCurrencies: true,
        loadingEligibility: true
      }
    }
    case GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        loadingCurrencies: false,
        currencies: action.payload,
        loadingEligibility: false
      }
    }
    case GET_CURRENCIES_FAIL: {
      return {
        ...state,
        loadingCurrencies: false,
        loadingEligibility: false
      }
    }

    case GENERATE_INVOICE: {
      return {
        ...state,
        loading: true
      }
    }
    case GENERATE_INVOICE_SUCCESS: {
      let updatedSettlement = state.activeSettlement
      updatedSettlement.invoice = action.payload.invoice
      updatedSettlement.invoiceId = action.payload.invoice.id
      return {
        ...state,
        loading: false,
        generatedInvoiceMessage: action.payload,
        activeSettlement: updatedSettlement
      }
    }
    case GENERATE_INVOICE_FAIL: {
      return {
        ...state,
        loading: false,
        generatedInvoice: action.payload
      }
    }

    case CANCEL_INVOICE: {
      return {
        ...state,
        loading: true
      }
    }
    case CANCEL_INVOICE_SUCCESS: {
      let updatedSettlement = state.activeSettlement
      updatedSettlement.invoice = null
      updatedSettlement.invoiceId = null
      return {
        ...state,
        loading: false,
        canceledInvoice: action.payload,
        activeSettlement: updatedSettlement
      }
    }
    case CANCEL_INVOICE_FAIL: {
      return {
        ...state,
        loading: false,
        canceledInvoiceMessage: action.payload
      }
    }

    case GET_STATUS_INTERVALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        statusIntervals: action.payload,
      }
    }
    case GET_STATUS_INTERVALS_FAIL: {
      return {
        ...state,
        loading: false,
        statusIntervals: action.payload
      }
    }

    case GENERATE_TRANSACTIONS_REPORT: {
      return {
        ...state,
        loading: true
      }
    }
    case GENERATE_TRANSACTIONS_REPORT_SUCCESS: {
      let updatedSettlement = state.activeSettlement
      updatedSettlement.transactionsReportId = action.payload.transactionsReport.id
      return {
        ...state,
        activeSettlement: updatedSettlement,
        loading: false
      }
    }
    case GENERATE_TRANSACTIONS_REPORT_FAIL: {
      return {
        ...state,
        loading: false
      }
    }

    case DELETE_TRANSACTIONS_REPORT: {
      return {
        ...state,
        loading: true
      }
    }
    case DELETE_TRANSACTIONS_REPORT_SUCCESS: {
      let updatedSettlement = state.activeSettlement
      updatedSettlement.transactionsReportId = null
      return {
        ...state,
        loading: false,
        activeSettlement: updatedSettlement
      }
    }
    case DELETE_TRANSACTIONS_REPORT_FAIL: {
      return {
        ...state,
        loading: false
      }
    }

    case DOWNLOAD_SETTLEMENT_TRANSACTIONS: {
      return {
        ...state,
        downloadFilesLoading: true,
        csvData: ''
      }
    }
    case DOWNLOAD_SETTLEMENT_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        downloadFilesLoading: false,
        csvData: action.payload
      }
    }
    case DOWNLOAD_SETTLEMENT_TRANSACTIONS_FAIL: {
      return {
        ...state,
        downloadFilesLoading: false,
        csvData: ''
      }
    }

    case DOWNLOAD_SETTLEMENT_FILE: {
      return {
        ...state,
        downloadFilesLoading: true,
        downloadFileStatus: ''
      }
    }
    case DOWNLOAD_SETTLEMENT_FILE_SUCCESS: {
      return {
        ...state,
        downloadFilesLoading: false,
        downloadFileStatus: action.payload
      }
    }
    case DOWNLOAD_SETTLEMENT_FILE_FAIL: {
      return {
        ...state,
        downloadFilesLoading: false,
        downloadFileStatus: action.payload
      }
    }

    case FETCH_ISSUE_DATE_INTERVAL: {
      return {
        ...state,
        loadingIssueDateIntervals: true,
      }
    }
    case FETCH_ISSUE_DATE_INTERVAL_SUCCESS: {
      return {
        ...state,
        loadingIssueDateIntervals: false,
        issueDateIntervals: action.payload,
      }
    }
    case FETCH_ISSUE_DATE_INTERVAL_FAIL: {
      return {
        ...state,
        loadingIssueDateIntervals: false,
        issueDateIntervals: action.payload
      }
    }

    case FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID: {
      return {
        ...state,
        loadingIssueDateIntervals: true,
      }
    }
    case FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_SUCCESS: {
      return {
        ...state,
        loadingIssueDateIntervals: false,
        issueDateIntervals: action.payload,
      }
    }
    case FETCH_ISSUE_DATE_INTERVAL_BY_SETTLEMENT_ID_FAIL: {
      return {
        ...state,
        loadingIssueDateIntervals: false,
        issueDateIntervals: action.payload
      }
    }

    default:
      return state
  }
}
