// NPM and others

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Common component

import { secondaryColor, secondaryColorDarker } from '../StyleConstants'

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-size: 18px;
  border-radius: 50%;
  border-color: ${secondaryColor};
  background-color: ${secondaryColor};
  color: white;
  transition: all 0.2s ease;
  > span {
    margin-left: 5px;
  }
  @media all and (max-width: 1023px) {
    width: 48px;
    height: 48px;
    font-size: 18px;
  }
  @media all and (max-width: 768px) {
    width: 42px;
    height: 42px;
    font-size: 16px;
  }
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: all 0.2s ease;
  color: ${secondaryColor};
  text-transform: uppercase;
  line-height: 1.2;
  cursor: pointer;
  > p {
    margin-left: 10px;
    font-weight: 700;
    letter-spacing: 3.36px;
    line-height: 1.3;
    text-align: left;
  }
  &:hover {
    transform: translateY(-3px);
    color: ${secondaryColorDarker};
    div {
    }
  }
  @media all and (max-width: 1023px) {
    > p {
      display: none;
    }
  }
  @media all and (max-width: 420px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    > p {
      flex: 1;
      display: block;

      br {
        display: none;
      }
    }
  }
`

class AddNew extends Component {
  render () {
    const { icon, children, route, style } = this.props
    return (
      <Link to={route || ''} style={{ display: 'flex', alignItems: 'stretch' }}>
        <Wrapper style={style}>
          <Icon>
            +<span className={`lnr lnr-${icon}`} />
          </Icon>
          <p>{children}</p>
        </Wrapper>
      </Link>
    )
  }
}

AddNew.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  route: PropTypes.string
}

export { AddNew }
