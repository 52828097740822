import styled from 'styled-components'

const FormHelp = styled.p`
margin-top: 15px;
margin-bottom: 15px;
padding-left: 45px;
font-size: 12px;
color: #606060;
@media all and (max-width: 1023px){
    padding-left: 32px;
}
`
export {FormHelp}
