// NPM and others

import React, { Component } from "react";

// Common components

import { SectionItem } from "../../partials/common";

class SectionItemDetails extends Component {
  render() {
    const { iconClass, title, paragraph } = this.props;
    return (
      <SectionItem responsive>
        <i className={iconClass || ""} />
        <h3>{title}:</h3>
        {paragraph && <p>{paragraph}</p>}
      </SectionItem>
    );
  }
}

export default SectionItemDetails;
