import React, { Component } from 'react'
import styled from 'styled-components'
import { Translate } from 'react-localize-redux'

const Wrapper = styled.div`
    height: 100px;
    width: 100%;
    display:flex;
    align-items:center;
    justify-content:center;
    color: blue; 
    font-family: 'Opensans Semibold';
    text-transform: uppercase;
    font-size: 20px;
`
export default class NotAuthorized extends Component {
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Wrapper>
            {translate('notAuthorized')}
          </Wrapper>
        )}
      </Translate>
    )
  }
}
