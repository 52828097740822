import styled from 'styled-components'

const FormLabel = styled.label`
flex:1;
display:flex;
justify-content:flex-start;
letter-spacing:0.12em;
font-family:Opensans;
font-size:12px;
align-items:center;
font-size: 11.623px;
color: rgb(159, 163, 167);
font-weight: bold;
text-transform: uppercase;
line-height: 2.237;
`
export {FormLabel}
