import styled from 'styled-components'

const Table = styled.div`
  // background-color: rgba(0,0,0,.02);
  background-color: #FAFAFA;
  border-radius: 8px;
  overflow: hidden;
  font-size: 14px;
  min-height: 500px;
  & > a  {
    & > *{
        background-color: rgba(0,0,0,.02);
        &:hover
        {
          background-color: rgba(169,204,255,.3) !IMPORTANT;
        }
    }
    
    &:nth-child(2n){
      & > * {
        background-color: transparent;
      }
    }
  }
`

export { Table }
