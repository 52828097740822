import _ from 'lodash'
import { push } from 'react-router-redux'

import { encodeFilter } from '../../filter/EncodeFilter'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import { get, post, put, remove } from '../../helpers'
import { reset } from 'redux-form'

// import {FETCH_CITIES, FETCH_CITIES_FAIL, FETCH_CITIES_SUCCESS} from "../locations/LocationActions";
export const ADD_NEW_MERCHANT = 'add_new_merchant'
export const ADD_NEW_MERCHANT_SUCCESS = 'add_new_merchant_success'
export const ADD_NEW_MERCHANT_FAIL = 'add_new_merchant_fail'

export const DELETE_MERCHANT = 'delete_merchant'
export const DELETE_MERCHANT_SUCCESS = 'delete_merchant_success'
export const DELETE_MERCHANT_FAIL = 'delete_merchant_fail'

export const EDIT_MERCHANT = 'edit_merchant'
export const EDIT_MERCHANT_SUCCESS = 'edit_merchant_success'
export const EDIT_MERCHANT_FAIL = 'edit_merchant_fail'

export const FETCH_MERCHANTS = 'fetch_merchants'
export const FETCH_MERCHANTS_SUCCESS = 'fetch_merchants_success'
export const FETCH_MERCHANTS_FAIL = 'fetch_merchants_fail'

export const FETCH_SINGLE_MERCHANT = 'fetch_merchant_by_id'
export const FETCH_SINGLE_MERCHANT_SUCCESS = 'fetch_merchant_by_id_success'
export const FETCH_SINGLE_MERCHANT_FAIL = 'fetch_merchant_by_id_fail'

export const APPEND_MERCHANTS = 'append_merchants_to_list'
export const APPEND_MERCHANTS_SUCCESS = 'append_merchants_to_list_success'
export const APPEND_MERCHANTS_FAIL = 'append_merchants_to_list_fail'

export const ADD_LOGO = 'ADD_LOGO'
export const ADD_LOGO_SUCCESS = 'ADD_LOGO_SUCCESS'
export const ADD_LOGO_FAIL = 'ADD_LOGO_FAIL'

export const DELETE_LOGO = 'DELETE_LOGO'
export const DELETE_LOGO_SUCCESS = 'DELETE_LOGO_SUCCESS'
export const DELETE_LOGO_FAIL = 'DELETE_LOGO_FAIL'

export const CHANGE_SEARCH_TERM = 'CHANGE_MERCHANT_SEARCH_TERM'

export const EDIT_MERCHANT_PASSWORD = 'edit_merchant_password'
export const EDIT_MERCHANT_PASSWORD_SUCCESS = 'edit_merchant_password_success'
export const EDIT_MERCHANT_PASSWORD_FAIL = 'edit_merchant_password_fail'

export const SET_PAGINATION_MERCHANTS = 'set_pagination_merchants'
export const RESET_SERVER_ERROR = 'reset_server_error'

export const EDIT_MERCHANT_BANK_DETAILS = 'edit_merchant_bank_details'
export const EDIT_MERCHANT_BANK_DETAILS_SUCCESS = 'edit_merchant_bank_details_success'
export const EDIT_MERCHANT_BANK_DETAILS_FAIL = 'edit_merchant_bank_details_fail'


export const addCompanyLogo = ({ id, data, companyType }) => async dispatch => {
  let response
  dispatch({ type: ADD_LOGO })
  try {
    response = await post({ url: `/companies/${id}/logo`, data })
    dispatch({
      type: ADD_LOGO_SUCCESS,
      payload: _.get(response, 'data'),
      companyType
    })
  } catch (e) {
    if (e && e.response && e.response.data) {
      dispatch({
        type: ADD_LOGO_FAIL,
        payload: _.get(e, 'response.data ')
      })
    }
    throw e
  }
}
export const deleteCompanyLogo = ({ id, companyType }) => async dispatch => {
  let response
  dispatch({ type: DELETE_LOGO })
  try {
    response = await remove({ url: `/companies/${id}/logo` })
    dispatch({
      type: DELETE_LOGO_SUCCESS,
      payload: _.get(response, 'data'),
      companyType
    })
  } catch (e) {
    if (e && e.response && e.response.data) {
      dispatch({
        type: DELETE_LOGO_FAIL,
        payload: _.get(e, 'response.data ')
      })
    }
    throw e
  }
}

export const changeSearch = payload => ({ type: CHANGE_SEARCH_TERM, payload })

export const fetchMerchants = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_MERCHANTS })
    let response
    let filterParams = encodeFilter(getState().filter.merchants)
    let url = `/companies${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: FETCH_MERCHANTS_SUCCESS,
        payload: _.get(response, 'data'),
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'merchants',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'merchants',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_MERCHANTS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}
export const appendMerchants = (page = 1, pageSize = 10) => {
  return async (dispatch, getState) => {
    dispatch({ type: APPEND_MERCHANTS })
    let response
    let filters = getState().filter.merchants
    if (filters.pagination.page) {
      filters.pagination.page.value += 1
    }
    let filterParams = encodeFilter(filters)
    let url = `/companies${filterParams}`
    try {
      response = await get({ url })
      dispatch({
        type: APPEND_MERCHANTS_SUCCESS,
        payload: _.get(response, 'data'),
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'merchants',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'merchants',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: APPEND_MERCHANTS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchMerchantById = merchantId => {
  return async dispatch => {
    dispatch({ type: FETCH_SINGLE_MERCHANT })
    let response
    try {
      response = await get({ url: `/companies/${merchantId}` })
      dispatch({
        type: FETCH_SINGLE_MERCHANT_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_MERCHANT_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const deleteMerchant = merchantId => {
  return async dispatch => {
    dispatch({ type: DELETE_MERCHANT })
    try {
      await remove({ url: `/companies/${merchantId}` })
      dispatch({
        type: DELETE_MERCHANT_SUCCESS,
        payload: merchantId
      })
    } catch (error) {
      dispatch({
        type: DELETE_MERCHANT_FAIL,
        payload: merchantId
      })
      throw error
    }
  }
}

export const addNewMerchant = data => {
  return async dispatch => {
    dispatch({ type: ADD_NEW_MERCHANT })
    let response
    try {
      response = await post({ url: `/companies`, data })
      dispatch({
        type: ADD_NEW_MERCHANT_SUCCESS,
        payload: _.get(response, 'data'),
      })
      dispatch(push(`/merchants/confirmation/${response.data.id}/${response.data.name}`))
    } catch (error) {
      dispatch({
        type: ADD_NEW_MERCHANT_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editMerchant = (data, merchantId) => {
  const obj = {
    company: data.company,
    oauthClients: data.oauthClients,
    user: data.user,
    currencies: data.currencies
  }

  return async dispatch => {
    try {
      await dispatch(editMerchantDetails(obj, merchantId))
    } catch (err) {
      throw err
    }
  }
}

export const editMerchantPassword = (data) => {
  return async dispatch => {
    dispatch({ type: EDIT_MERCHANT_PASSWORD })
    let response
    try {
      response = await post({ url: `/settings`, data })
      dispatch({
        type: EDIT_MERCHANT_PASSWORD_SUCCESS,
        payload: _.get(response, 'data'),
      })
    } catch (error) {
      dispatch({
        type: EDIT_MERCHANT_PASSWORD_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

const editMerchantDetails = (data, merchantId) => {
  return async dispatch => {
    dispatch({ type: EDIT_MERCHANT })
    let response
    try {
      response = await put({ url: `/companies/${merchantId}`, data })
      dispatch({
        type: EDIT_MERCHANT_SUCCESS,
        payload: _.get(response, 'data'),
      })
      await dispatch(fetchMerchantById(merchantId))
    } catch (error) {
      dispatch({
        type: EDIT_MERCHANT_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editMerchantBankDetails = (data, companyId) => {
  return async dispatch => {
    dispatch({ type: EDIT_MERCHANT_BANK_DETAILS })
    let response
    try {
      response = await put({ url: `/companies/${companyId}/bank-accounts`, data })
      dispatch({
        type: EDIT_MERCHANT_BANK_DETAILS_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: EDIT_MERCHANT_BANK_DETAILS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const merchantResetCurrentPage = () => (dispatch) => {
  dispatch({ type: SET_PAGINATION_MERCHANTS, payload: { currentPage: 1 } })
}

export const merchantResetServerError = () => (dispatch) => {
  dispatch({ type: RESET_SERVER_ERROR, payload: {} })
}

export const merchantResetAddForm = () => (dispatch) => {
  dispatch(reset('merchantAddForm'))
}