import PropTypes from 'prop-types'
import React from 'react'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

import { primaryColor, secondaryColor } from '../common/StyleConstants'
import { ErrorMessage } from './ErrorMessage'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justfy-content: center;
  height: 20px;
  flex: 1;
`
const Input = styled.input`
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    user-select: none;
    ${props => props.disabled && 'cursor:default;'}
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    min-width: 16px;
    height: 16px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
    transition: all 0.2s ease;
    ${props => props.disabled && 'cursor:default;'}
    ${props => props.radio && 'border-radius: 50%;'}
  }

  & + label:after {
    content: '';
    position: absolute;
    min-width: 10px;
    height: 10px;
    left: 3px;
    top: 50%;
    margin-top: -5px;
    transform: scale(0);
    opacity: 0;
    background: linear-gradient(to right, ${primaryColor}, ${secondaryColor});
    transition: all 0.2s ease;
    z-index: 0;
    ${props => props.disabled && 'cursor:default;'}
    ${props => props.radio && 'border-radius: 50%;'}
  }

  &:checked {
    & + label:after {
      transform: scale(1);
      opacity: 1;
    }
  }
  border: 1px solid #000;
  ${props => props.inputStyle}
  ${props => props.disabled && 'cursor:default;'}
`

const Checkbox = props => {
  let checked
  if (props.input) {
    checked = props.input.value
  } else {
    checked = props.value
  }

  const { meta } = props

  return (
    <Translate>
      {({ translate }) => (
        <Wrapper style={props.style}>
          <Input
            type='checkbox'
            id={props.id || 'checkbox'}
            disabled={props.disabled}
            radio={props.radio}
            checked={checked}
            onChange={() => props.onChange(!checked)}
            {...props.input}
          />
          <label htmlFor={props.id || 'checkbox'} style={{ fontSize: 15 }}>
            {props.label}
          </label>
          {props.children}
          {meta && meta.error && (
            <ErrorMessage
              error={meta.error}
              touched={meta.touched}
              style={{ padding: '0' }}
            >
              {translate(meta.error)}
            </ErrorMessage>
          )}
        </Wrapper>
      )}
    </Translate>
  )
}

Checkbox.propTypes = {
  children: PropTypes.node,
  input: PropTypes.object
}
export { Checkbox }
