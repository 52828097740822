// NPM and others
import _ from 'lodash'
import React from 'react'
import { Translate } from 'react-localize-redux'
import styled from 'styled-components'

import { errorColor } from '../StyleConstants'
import { ButtonUpload } from './ButtonUpload'

const ButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;

  label {
    padding: 4px 16px;
    margin: 0;
    margin-bottom: 0 !important;
  }

  & > * {
    width: unset;
    max-width: unset;
  }

  input {
    font-size: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
  }
`

const UploadButton = ({ name, accepts, input, meta: { error, touched } }) => {
  return (
    <Translate>
      {({ translate }) => (
        <ButtonWrapper>
          <ButtonUpload>
            <label htmlFor={'upload'}>{_.get(input, 'value.name') ? input.value.name : translate('choseFile')}</label>
          </ButtonUpload>
          <input
            type='file'
            name={name}
            id='upload'
            accept={accepts}
            onChange={e => input.onChange(e.target.files[0])}
          />
          {error && touched && (
            <span style={{ color: errorColor }}>{translate(error)}</span>
          )}
        </ButtonWrapper>
      )}
    </Translate>
  )
}

export default UploadButton
