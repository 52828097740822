import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  primaryColor,
  textColor,
  inactiveColorBackground
} from '../common/StyleConstants'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  line-height: 1.4;
  // color: ${textColor};
  color: #1B2640;
  font-size: 14px;
  transition: background-color 0.2s ease;
  padding: 0 15px;
  justify-content: space-around;
  flex-wrap: wrap;
  min-height: 46px;
  & > * {
    display: flex;
  }

  &:hover {
    background-color: ${inactiveColorBackground};
    > span {
      color: ${primaryColor};
    }
  }

  ${props => props.commissionsHistory && `
    min-height: 70px;
    &:hover {
      background-color: #F0F0F0;
    }
  `}

  a {
    color: ${textColor};
    transition: color 0.2s ease;
    &:hover {
      color: ${primaryColor};
    }
  }
  @media all and (max-width: 1200px) {
    p {
      font-size: 12px;
    }
  }

`

class TableRow extends Component {
  render () {
    return (
      <Wrapper
        style={this.props.style}
        className={
          window.location.href.includes('transactions')
            ? 'border-bottom-sm'
            : null
        }
      >
        {this.props.children}
      </Wrapper>
    )
  }
}

TableRow.propTypes = {
  children: PropTypes.node.isRequired
}

export { TableRow }
