import { get, post, put, remove } from '../../helpers'
import { push } from 'react-router-redux'
import { VENDORS_ROUTE } from '../../../router/ClientRoutes'
import { encodeFilter } from '../../filter/EncodeFilter'
import { CHANGE_FILTER } from '../../filter/FilterActions'
import _ from 'lodash'
import { reset } from 'redux-form'

export const FETCH_VENDORS = 'fetch_vendors'
export const FETCH_VENDORS_SUCCESS = 'fetch_vendors_success'
export const FETCH_VENDORS_FAIL = 'fetch_vendors_fail'
export const SET_PAGINATION_VENDORS = 'set_pagination_vendors'

export const FETCH_SINGLE_VENDOR = 'fetch_vendor_by_id'
export const FETCH_SINGLE_VENDOR_SUCCESS = 'fetch_vendor_by_id_success'
export const FETCH_SINGLE_VENDOR_FAIL = 'fetch_vendor_by_id_fail'

export const ADD_NEW_VENDOR = 'add_new_vendor'
export const ADD_NEW_VENDOR_SUCCESS = 'add_new_vendor_success'
export const ADD_NEW_VENDOR_FAIL = 'add_new_vendor_fail'

export const DELETE_VENDOR = 'delete_vendor'
export const DELETE_VENDOR_SUCCESS = 'delete_vendor_success'
export const DELETE_VENDOR_FAIL = 'delete_vendor_fail'

export const EDIT_VENDOR = 'edit_vendor'
export const EDIT_VENDOR_SUCCESS = 'edit_vendor_success'
export const EDIT_VENDOR_FAIL = 'edit_vendor_fail'

export const EDIT_VENDOR_PASSWORD = 'edit_vendor_password'
export const EDIT_VENDOR_PASSWORD_SUCCESS = 'edit_vendor_password_success'
export const EDIT_VENDOR_PASSWORD_FAIL = 'edit_vendor_password_fail'

export const APPEND_VENDORS = 'append_vendors'
export const APPEND_VENDORS_SUCCESS = 'append_vendors_success'
export const APPEND_VENDORS_FAIL = 'append_vendors_fail'

export const RESET_SERVER_ERROR = 'reset_server_error'

export const EDIT_VENDOR_BANK_DETAILS = 'edit_vendor_bank_details'
export const EDIT_VENDOR_BANK_DETAILS_SUCCESS = 'edit_vendor_bank_details_success'
export const EDIT_VENDOR_BANK_DETAILS_FAIL = 'edit_vendor_bank_details_fail'

export const EDIT_VENDOR_WALLETS = 'edit_vendor_wallets'
export const EDIT_VENDOR_WALLETS_SUCCESS = 'edit_vendor_wallets_success'
export const EDIT_VENDOR_WALLETS_FAIL = 'edit_vendor_wallets_fail'

export const fetchVendors = () => {
  return async (dispatch, getState) => {
    dispatch({ type: FETCH_VENDORS })
    let response
    let filterParams = encodeFilter(getState().filter.vendors)
    let url = `/companies${filterParams}`
    // let activeMerchant = getState().merchants.activeMerchant

    try {
      response = await get({ url })
      dispatch({
        type: FETCH_VENDORS_SUCCESS,
        payload: _.get(response, 'data')
      })
      // if (activeMerchant && !_.isEmpty(activeMerchant)) {
      //   let currentPath = getState().routing.location.pathname
      //   if (currentPath.includes('merchants')) {
      //     const data = {
      //       references: activeMerchant.type,
      //       merchantId: activeMerchant.id
      //     }
      //     dispatch(
      //       getCommission(data)
      //     )
      //   }
      // }
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'vendors',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination',
          operator: '='
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'vendors',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination',
          operator: '='
        }
      })
    } catch (error) {
      dispatch({
        type: FETCH_VENDORS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const appendVendors = (page = 1, pageSize = 10) => {
  return async (dispatch, getState) => {
    dispatch({ type: APPEND_VENDORS })
    let response
    let filters = getState().filter.vendors
    if (filters.pagination.page) {
      filters.pagination.page.value += 1
    }
    let filterParams = encodeFilter(filters)
    let url = `/companies${filterParams}`

    try {
      response = await get({ url })
      dispatch({
        type: APPEND_VENDORS_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'vendors',
          name: 'pageCount',
          value: _.get(response, 'data.pagination.pageCount'),
          type: 'pagination'
        }
      })
      dispatch({
        type: CHANGE_FILTER,
        payload: {
          page: 'vendors',
          name: 'page',
          value: _.get(response, 'data.pagination.page'),
          type: 'pagination'
        }
      })
    } catch (error) {
      dispatch({
        type: APPEND_VENDORS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const fetchVendorById = companyId => {
  return async dispatch => {
    dispatch({ type: FETCH_SINGLE_VENDOR })
    let response
    try {
      response = await get({ url: `/companies/${companyId}` })
      dispatch({
        type: FETCH_SINGLE_VENDOR_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: FETCH_SINGLE_VENDOR_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const addNewVendor = data => {
  return async dispatch => {
    dispatch({ type: ADD_NEW_VENDOR })
    let response
    try {
      response = await post({ url: `/companies`, data })
      dispatch({
        type: ADD_NEW_VENDOR_SUCCESS,
        payload: _.get(response, 'data')
      })
      dispatch(push(VENDORS_ROUTE))
    } catch (error) {
      dispatch({
        type: ADD_NEW_VENDOR_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editVendor = (data, companyId) => {
  const obj = {
    company: data.company,
    user: data.user,
    currencies: data.currencies,
    allowedCustomerCurrencies: data.allowedCustomerCurrencies,
    oauthClients: data.oauthClients
  }

  return async dispatch => {
    try {
      await dispatch(editVendorDetails(obj, companyId))
    } catch (err) {
      throw err
    }
  }
}

export const editVendorPassword = (data) => {
  return async dispatch => {
    dispatch({ type: EDIT_VENDOR_PASSWORD })
    let response
    try {
      response = await post({ url: `/settings`, data })
      dispatch({
        type: EDIT_VENDOR_PASSWORD_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: EDIT_VENDOR_PASSWORD_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

const editVendorDetails = (data, companyId) => {
  return async dispatch => {
    dispatch({ type: EDIT_VENDOR })
    let response
    try {
      response = await put({ url: `/companies/${companyId}`, data })
      dispatch({
        type: EDIT_VENDOR_SUCCESS,
        payload: _.get(response, 'data')
      })
      await dispatch(fetchVendorById(companyId))
    } catch (error) {
      dispatch({
        type: EDIT_VENDOR_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const editVendorBankDetails = (data, companyId) => {
  return async dispatch => {
    dispatch({ type: EDIT_VENDOR_BANK_DETAILS })
    let response
    try {
      response = await put({ url: `/companies/${companyId}/bank-accounts`, data })
      dispatch({
        type: EDIT_VENDOR_BANK_DETAILS_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: EDIT_VENDOR_BANK_DETAILS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const deleteVendor = companyId => {
  return async dispatch => {
    dispatch({ type: DELETE_VENDOR })
    try {
      await remove({ url: `/companies/${companyId}` })
      dispatch({
        type: DELETE_VENDOR_SUCCESS,
        payload: companyId
      })
      dispatch(push(VENDORS_ROUTE))
    } catch (error) {
      dispatch({
        type: DELETE_VENDOR_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}

export const vendorResetCurrentPage = () => (dispatch) => {
  dispatch({ type: SET_PAGINATION_VENDORS, payload: { currentPage: 1 } })
}

export const vendorResetServerError = () => (dispatch) => {
  dispatch({ type: RESET_SERVER_ERROR, payload: {} })
}

export const vendorResetAddForm = () => (dispatch) => {
  dispatch(reset('vendorAddForm'))
}

export const editVendorWallets = (data, companyId) => {
  return async dispatch => {
    dispatch({ type: EDIT_VENDOR_WALLETS })
    let response
    try {
      response = await put({ url: `/companies/${companyId}/products`, data })
      dispatch({
        type: EDIT_VENDOR_WALLETS_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: EDIT_VENDOR_WALLETS_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
      throw error
    }
  }
}