import styled from "styled-components";
import {
  primaryColor,
  colorSecondary,
  inactiveColor,
  pending,
  errorColor
} from "./StyleConstants";

const StatusBar = styled.div`
  font-size:12px;
  font-family:'Opensans';
  width: 120px;
  font-weight: bold;
  color:white;
  display:flex;
  align-items:center;
  justify-content: center;
  height:20px;
  padding:0 15px;
  letter-spacing: 1.2px;
  border-radius:30px;
  text-transform:uppercase;
  background-color: ${inactiveColor};
  transition:background-color .2s ease, width .2s ease, margin-left .2s ease;
  @media all and (max-width: 1200px){
    font-size: 10px;
    padding: 0 10px;
  }
  ${props =>
    props.orange &&
    `
    background-color: rgb(236, 156, 69);
  `}

  ${props =>
    props.vodafone &&
    `
    background-color: rgb(225, 69, 65);
  `}

  ${props =>
    props.primary &&
    `
    background-color: ${primaryColor};
  `}

  ${props =>
    props.active &&
    `
  background-color: ${colorSecondary};`}

  ${props =>
    props.status === "enabled" &&
    `
    background-color: ${colorSecondary};
  `}
  
  ${props =>
    props.status === "pending" &&
    `
    background-color: ${pending}
  `}

  ${props =>
    props.status === "disabled" &&
    `
    background-color: ${inactiveColor};
  `}
  ${props => props.color && `background-color:${props.color};`}
  
    ${props => {
      if (props.type) {
        switch (props.type) {
          case "pending":
            return `background-color:#ffcc80;`;
          case "completed":
            return `background-color:#00b9a1;`;
          case "canceled":
            return `background-color: ${errorColor};`;
          case "accepted":
            return `background-color:${primaryColor};`;
          case "open":
            return `background-color:${primaryColor};`;
          case "closed":
            return `background-color: ${errorColor};`;
          default:
            return "";
        }
      }
    }}
  
`;
export { StatusBar };
