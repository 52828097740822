import { get, post, put } from '../../helpers'
import { kebabCase } from 'lodash'
import { push } from 'react-router-redux'
import _ from 'lodash'

export const ADD_RECEIPT = 'ADD_RECEIPT'
export const ADD_RECEIPT_SUCCESS = 'ADD_RECEIPT_SUCCESS'
export const ADD_RECEIPT_FAIL = 'ADD_RECEIPT_FAIL'

export const EDIT_RECEIPT = 'EDIT_RECEIPT'
export const EDIT_RECEIPT_SUCCESS = 'EDIT_RECEIPT_SUCCESS'
export const EDIT_RECEIPT_FAIL = 'EDIT_RECEIPT_FAIL'

export const FETCH_RECEIPT = 'FETCH_RECEIPT'
export const FETCH_RECEIPT_SUCCESS = 'FETCH_RECEIPT_SUCCESS'
export const FETCH_RECEIPT_FAIL = 'FETCH_RECEIPT_FAIL'

export const FETCH_RECEIPTS = 'FETCH_RECEIPTS'
export const FETCH_RECEIPTS_SUCCESS = 'FETCH_RECEIPTS_SUCCESS'
export const FETCH_RECEIPTS_FAIL = 'FETCH_RECEIPTS_FAIL'

export const ASSIGN_RECEIPT = 'ASSIGN_RECEIPT'
export const ASSIGN_RECEIPT_SUCCESS = 'ASSIGN_RECEIPT_SUCCESS'
export const ASSIGN_RECEIPT_FAIL = 'ASSIGN_RECEIPT_FAIL'

export const FETCH_LANGUAGES = 'FETCH_LANGUAGES'
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS'
export const FETCH_LANGUAGES_FAIL = 'FETCH_LANGUAGES_FAIL'

export const fetchReceipt = receiptId => async dispatch => {
  dispatch({ type: FETCH_RECEIPT })
  let response
  try {
    response = await get({ url: `/transaction-receipt-templates/${receiptId}` })
    dispatch({
      type: FETCH_RECEIPT_SUCCESS,
      payload: _.get(response, 'data'),
    })
  } catch (error) {
    dispatch({
      type: FETCH_RECEIPT_FAIL,
      payload: _.get(error, 'response.data')
    })
  }
}

export const addReceipt = data => async dispatch => {
  dispatch({ type: ADD_RECEIPT })
  let response
  try {
    response = await post({
      url: `/transaction-receipt-templates`,
      data: {
        receiptLines: data.receiptLines,
        transactionReceiptTemplate: {
          name: data.name,
          code: kebabCase(data.name),
          languageId: data.languageId,
          type: data.type
        }
      }
    })
    dispatch({
      type: ADD_RECEIPT_SUCCESS,
      payload: _.get(response, 'data'),
    })
    dispatch(push('/receipts/success'))
  } catch (error) {
    dispatch({
      type: ADD_RECEIPT_FAIL,
      payload: _.get(error, 'response.data')
    })
  }
}

export const editReceipt = ({ id, data }) => async dispatch => {
  dispatch({ type: EDIT_RECEIPT })
  let response
  try {
    response = await put({
      url: `/transaction-receipt-templates/${id}`,
      data: {
        receiptLines: data.receiptLines.map(item => {
          const newItem = { ...item }
          delete newItem.trTemplatesId
          delete newItem.id
          return newItem
        }),
        transactionReceiptTemplate: {
          name: data.name,
          code: kebabCase(data.name),
          languageId: data.languageId,
          type: data.type
        }
      }
    })
    dispatch({
      type: EDIT_RECEIPT_SUCCESS,
      payload: _.get(response, 'data'),
    })
    dispatch(push('/receipts/success'))
  } catch (error) {
    dispatch({
      type: EDIT_RECEIPT_FAIL,
      payload: _.get(error, 'response.data')
    })
  }
}

export const assignReceipt = ({ rejected, receiptId, ...data }) => async (dispatch) => {
  let response
  dispatch({ type: ASSIGN_RECEIPT })
  try {
    response = await post({ url: `/transaction-receipt-templates/${receiptId}/batch-assign`, data })
    dispatch({
      type: ASSIGN_RECEIPT_SUCCESS,
      payload: _.get(response, 'data'),
    })
    let { rejectedCompanyIds } = response.data

    if (rejectedCompanyIds.length === 0) {
      dispatch(
        push(
          `/receipts/success`
        )
      )
    } else {
      rejected(rejectedCompanyIds)
    }
  } catch (error) {
    dispatch({
      type: ASSIGN_RECEIPT_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
  }
}
export const fetchReceipts = () => async (dispatch) => {
  let response
  dispatch({ type: FETCH_RECEIPTS })
  try {
    response = await get({ url: `/transaction-receipt-templates` })
    dispatch({
      type: FETCH_RECEIPTS_SUCCESS,
      payload: _.get(response, 'data'),
    })
  } catch (error) {
    dispatch({
      type: FETCH_RECEIPTS_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
  }
}
export const fetchAvailableLanguages = () => async (dispatch) => {
  let response
  dispatch({ type: FETCH_LANGUAGES })
  try {
    response = await get({ url: `/languages?pageSize=100` })
    dispatch({
      type: FETCH_LANGUAGES_SUCCESS,
      payload: _.get(response, 'data'),
    })
  } catch (error) {
    dispatch({
      type: FETCH_LANGUAGES_FAIL,
      payload: _.get(error, 'response.data.errors')
    })
  }
}
