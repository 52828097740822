import styled from 'styled-components'

const FiltersGroup = styled.div`
  display:flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  margin-bottom: 24px;
  width: 100%;
  align-items: center;

& > * {
  // flex: 1;
  flex: auto;
  //  padding: 0 16px;
  padding: 5px 10px;
}

${props => props.center && `display: grid; justify-content: center;`}
`

const Filter = styled.div`
  ${props => props.center && `text-align: center`}
`

const FilterLabel = styled.label`
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0,0,0,.5);
  display: block;
  padding-left: 10px;
  margin-bottom: 4px;
`
export { FiltersGroup, FilterLabel, Filter }
