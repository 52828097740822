import React, { Component } from 'react'
import axios from 'axios'

class QrDeposit extends Component {
  state = {qr: null}
  componentDidMount () {
    axios({
      url: '/qrcode-deposit',
      method: 'get'
    }).then((response) => {
      this.setState({qr: response.data})
    }
     )
    .catch(err => console.log(err))
  }

  render () {
    if (!this.state.qr) {
      return <div>Loading</div>
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column '}}>
        <img src={this.state.qr} style={{height: 300, width: 300}} alt={'deposit'}/>
      </div>
    )
  }
}

export default QrDeposit
