import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from './ErrorMessage'
import { secondaryColor, textColor, errorColor } from '../common/StyleConstants'
import { Translate } from 'react-localize-redux'
import { getLocaleDateFormat } from '../../../globals/getLocaleDateFormat'
import { typeOf } from 'mathjs'
import { dynamicLanguage } from '../../../translations/dynamic-translations'
import _ from 'lodash'
import { connect } from 'react-redux'

const Wrapper = styled.div`
  position: relative;
  flex:1;
  width: 100%;
  @media all and (max-width: 560px){
    min-width: 100%;
    margin-top: 10px;
    padding-left: 24px;
      ${({ noPadding }) => {
    let style = ''
    if (noPadding === true) style = 'padding: 0;';
    return style
  }}
  }
  ${props =>
    props.required &&
    `
    &:after{
      background-color:inherit;
      content:'*';
      position:absolute;
      top: 50%;
      right: 5px;
      margin-top: -8px;
      color:${textColor};
      transition: color .3s ease;
    }
    `}
  ${props =>
    !props.error &&
    props.touched &&
    `
    &:after{
      color:${secondaryColor};
    }
  `}
  ${props =>
    ((props.error && props.touched) || props.serverError) &&
    `
    margin-bottom:25px;
    &:after{
      color:${errorColor};
    }
  `}
`
const InputFieldWrap = styled.div`
  position: relative;
  &:before {
    content: '';
    background-color: inherit;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    transform: translate(-50%);
    border-bottom: 1px solid ${errorColor};
    color: ${errorColor};
    transition: width 0.3s ease;
  }

  ${props =>
    ((props.error && props.touched) || props.serverError) &&
    `
      &:before{
        width: 100%;
      }
`}
`
const InputFieldWrapDisabled = styled.div`
  position: relative;
  &:before {
    content: '';
    background-color: inherit;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    transform: translate(-50%);
    transition: width 0.3s ease;
  }
`
const InputFieldStyle = styled.input`
  display:block;
  line-height:35px;
  margin-left: 10px;
  width:100%;
  padding-bottom:5px;
  padding-right:40px;
  padding-left:10px;
  margin:0;
  letter-spacing:0.075em;
  color: ${textColor};
  font-size: 14px;
  outline:none;
  border:none;
  // border-bottom:1px solid rgb(204,204,204);

  border: 1px solid #E6E7EA;
  border-radius: 4px;

  color: #1B2640;
  font-family: "Open Sans, SemiBold";
  font-size: 14px;

  &[type='password']{
    font-size: 24px;
    letter-spacing: 8px;
    color: ${secondaryColor};
  }

  ${({ noPaddingRight }) => {
    let style = ''
    if (noPaddingRight === true) style = 'padding-right: 0;';
    return style
  }}
  @media all and (max-width: 1023px){
    font-size: 12px;
  }
`
const InputFieldStyleDisabled = styled.input`
  display:block;
  line-height:35px;
  margin-left: 10px;
  width:100%;
  padding-bottom:5px;
  padding-left:10px;
  margin:0;
  font-family:"Opensans";
  letter-spacing:0.075em;
  color: ${textColor};
  font-size: 14px;
  outline:none;
  border:none;
  &[type='password']{
    font-size: 24px;
    letter-spacing: 8px;
    color: ${secondaryColor};
  }
  @media all and (max-width: 1023px){
    font-size: 12p;x
  }

  @media all and (max-width: 560px) {
    ${({ noPadding }) => {
    let style = ''
    if (noPadding === true) style = 'padding: 0;';
    return style
  }}
  }
`

class CustomInput extends React.Component {
  render() {
    let {
      type,
      required,
      wrapperStyle,
      input,
      meta: { error, touched },
      serverError,
      placeholder,
      disabled,
      inputStyle,
      noPadding,
      min,
      customErrorStyle,
      noPaddingRight,
      max,
      trimString,
      languageLoaging,
      defaultValue,
      changeField
    } = this.props
    let actualInput
    const { onChange } = input

    const updateField = (value) => {
      changeField && changeField(value)
      onChange(value)
    }
    if (trimString === true) {
      input.value = input.value.split(' ').join('')
    }

    let currentLanguage = localStorage.getItem('languageCode')
    let translations = _.pick(dynamicLanguage, currentLanguage)[currentLanguage]
    let errorKey = ''
    if (error && typeOf(error) === 'Object') {
      errorKey = translations[`${error.key}`]
    }

    if (disabled) {
      if (
        (input.name === 'contractDate' || input.name === 'expireDate') &&
        input.value
      ) {
        // input.value = moment(input.value).format('YYYY-MM-D')
        input.value = getLocaleDateFormat(input.value)
      }
      actualInput = (
        <InputFieldWrapDisabled>
          <InputFieldStyleDisabled {...input} type={type} disabled style={inputStyle} />
        </InputFieldWrapDisabled>
      )
    } else {
      actualInput = (
        <InputFieldWrap
          error={error}
          touched={touched}
          serverError={serverError}
          customErrorStyle={customErrorStyle}
        >
          <InputFieldStyle
            autoComplete="new-password"
            onKeyDown={type === 'number' ? (evt) => (evt.key === 'e' || evt.key === 'E') && evt.preventDefault() : ''}
            {...input} defaultValue={defaultValue} onChange={(e) => updateField(e)} type={type} placeholder={placeholder} style={inputStyle} min={min} max={max} noPaddingRight={noPaddingRight} />
        </InputFieldWrap>
      )
    }

    return (
      !languageLoaging ?
        <Translate>
          {({ translate }) => (
            <Wrapper
              required={required}
              style={wrapperStyle}
              error={error}
              touched={touched}
              serverError={serverError}
              noPadding={noPadding}
            >
              {actualInput}
              <ErrorMessage
                error={error}
                touched={touched}
                serverError={serverError}
                customErrorStyle={customErrorStyle}
              >
                {/* {(error && translate(error)) || serverError} */}
                {/* {error ? (typeOf(error) === 'string' ? translate(error) :
                  errorKey(error.params))
                  : serverError
                } */}
                {serverError ? serverError : error ? (typeOf(error) === 'string' ? translate(error) :
                  errorKey(error.params))
                  : null}
              </ErrorMessage>
            </Wrapper>
          )}
        </Translate> : ''
    )
  }
}
CustomInput.propTypes = {
  required: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  error: PropTypes.object
}

export default connect(
  state => ({
    //  languages: state.languages.languagesList.languages,
    languageLoaging: state.languages.loading
  }),
  {}
)(CustomInput)

// export { CustomInput }
