import styled from 'styled-components'
import {primaryColor, primaryColorDarker, textColor} from '../common/StyleConstants'

const MerchantName = styled.span`
color: ${textColor};
font-weight: 700;
text-transform: uppercase;
cursor:initial;

${props => props.primary && `color:${primaryColor}`}
a {
    color: ${primaryColor};
    transition: color .2s ease;
    &:hover{
        color: ${primaryColorDarker};
    }
}
`
export {MerchantName}
