import styled from "styled-components";
import { primaryColor } from "../StyleConstants";
const mainColor = primaryColor;

const RoundButton = styled.span`
  display: inline-block;
  width: 50px;
  line-height: 46px;
  text-align: center;
  color: ${mainColor};
  font-size: 20px;
  border: 1px solid ${mainColor};
  border-radius: 50%;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: ${mainColor};
  }
  @media all and (max-width: 1023px) {
    width: 40px;
    line-height: 38px;
    font-size: 16px;
  }
`;

export { RoundButton };
