import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from '../../../resources/logo-toppay.png';

const HeaderWrapper = styled.div`
  padding: 0 60px;
  height: 70px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 3;
  box-shadow: 0 4px 24px rgba(0,0,0,0.08);
  justify-content: space-between;

  &,
  & * {
    font-size: 12px !important;
  }
`;

const Logo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    max-width: 120px;
  }
  @media all and (max-width: 640px) {
    img {
      max-width: 90px;
    }
  }
`;
const TopNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 5;
`;

const Header = props => {
  return (
    <HeaderWrapper>
      <Logo>
        <img alt="TopPay logo" src={logo} />
        {/* <LanguageSelect /> */}
      </Logo>
      <TopNav>{props.children}</TopNav>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  children: PropTypes.node
};

export { Header };
