import React, {Component} from 'react'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import {secondaryColor} from '../common/StyleConstants'
const Wrapper = styled.div`
  position: relative;
  font-size:16px;
  line-height: 2.5;
  color: rgb(76, 76, 76); 
  margin-left: auto;
  > div {
    @media all and (max-width: 767px){
      position: absolute;
      right: -7px;
      top: -30px;
      padding: 0 20px;
      z-index: 1;
      border-radius: 10px;
      background-color: #dedcdc;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;
      &:before{
        content: '';
        position: absolute;
        bottom: -5px;
        right: 7px;
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #dedcdc;
      }
      ${props => props.visible && `
        opacity: 1;
        visibility: visible;
        top: -45px;
      `}
    }
  }
  .show-icons{
    padding: 5px;
    margin-right: 0;
    cursor: pointer;
    color: #ccc;
    display: none;
    @media all and (max-width: 767px){
      display: flex;
      flex-direction: column;
    }
    &:before, &:after, >i{
      content: '';
      width: 4px;
      height: 4px;
      margin: 2px 0;
      display: inline-block;
      background-color: #ccc;
      border-radius: 50%;
    }
    &:hover{
      &:before, &:after, >i{
        color: #333;
      }
    }
  }
  span, a{
    margin-right:15px;
    cursor:pointer;
    transition: color .2s ease;
    &:hover{
      color:${secondaryColor};
    }
    &:last-child{
      margin-right: 0;
    }
  }
  @media all and (max-width: 1023px){
    span, a{
      margin-right: 10px;
    }
  }
`

class ModifyIcons extends Component {
  constructor (params) {
    super(params)
    this.state = {visible: false}
  }
  onDeleteClick = (e) => {
    e.preventDefault()
    this.props.onDeleteClick()
  }
  handleVisiblity = (e) => { //eslint-disable-line
    e.preventDefault()
    this.setState({visible: !this.state.visible})
  }
  render () {
    const { visible } = this.state
    const { onMapClick, onEditClick, onDeleteClick, style, history } = this.props
    return (
      <Wrapper style={style} className={this.props.className} visible={visible}>
        <span className='show-icons' onClick={this.handleVisiblity}><i /></span>
        <div style={{display: "flex"}}>
          {onMapClick && <span onClick={onMapClick} className={`lnr lnr-map`} />}
          {onEditClick && <span className={`lnr lnr-pencil`} onClick={(e) => { e.preventDefault(); history.push(onEditClick) }} />}
          {onDeleteClick && <span onClick={this.onDeleteClick} className={`lnr lnr-trash`} />}
        </div>
      </Wrapper>
    )
  }
}

ModifyIcons.propTypes = {

}

ModifyIcons = withRouter(ModifyIcons)
export {ModifyIcons}
