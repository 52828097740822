import {
  GET_PLATFORM_SETTINGS_SUCCESS,
  GET_PLATFORM_SETTINGS,
  EDIT_PLATFORM,
  EDIT_PLATFORM_SUCCESS,
  EDIT_PLATFORM_FAIL,
  EDIT_PLATFORM_BANK_DETAILS,
  EDIT_PLATFORM_BANK_DETAILS_SUCCESS,
  EDIT_PLATFORM_BANK_DETAILS_FAIL
} from './PlatformSettingsActions'
const INITIAL_STATE = {
  platform: {}
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PLATFORM_SETTINGS:
      return { ...state, loading: true }
    case GET_PLATFORM_SETTINGS_SUCCESS:
      return {
        ...state,
        platform: action.payload,
        selectedCurrency: action.payload.currencies ? action.payload.currencies[0] : null,
        loading: false
      }

    case EDIT_PLATFORM:
      return { ...state, loading: true }
    case EDIT_PLATFORM_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case EDIT_PLATFORM_FAIL:
      return { ...state, loading: false }

    case EDIT_PLATFORM_BANK_DETAILS:
      return { ...state, loading: true }
    case EDIT_PLATFORM_BANK_DETAILS_SUCCESS:
      let activeCompanyAux = state.platform
      activeCompanyAux.banks = action.payload
      return { ...state, loading: false, platform: activeCompanyAux }
    case EDIT_PLATFORM_BANK_DETAILS_FAIL:
      return { ...state, loading: false }

    default:
      return state
  }
}
