import _ from 'lodash'
import { API_URL_LIVE } from '../../../config/apiRoutes'
import { get } from '../../helpers'

export const GET_BALANCE = 'GET_BALANCE'
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS'
export const GET_BALANCE_FAIL = 'GET_BALANCE_FAIL'

export const GET_PLATFORM_BALANCE = 'GET_PLATFORM_BALANCE'
export const GET_PLATFORM_BALANCE_SUCCESS = 'GET_PLATFORM_BALANCE_SUCCESS'
export const GET_PLATFORM_BALANCE_FAIL = 'GET_PLATFORM_BALANCE_FAIL'

export const GET_PLATFORM_CURRENCIES = 'GET_PLATFORM_CURRENCIES'
export const GET_PLATFORM_CURRENCIES_SUCCESS = 'GET_PLATFORM_CURRENCIES_SUCCESS'
export const GET_PLATFORM_CURRENCIES_FAIL = 'GET_PLATFORM_CURRENCIES_FAIL'

export const RESET_CURRENT_CURRENCY = 'RESET_CURRENT_CURRENCY'

export const getBalance = ({ id, currency }) => {
  return async dispatch => {
    dispatch({ type: GET_BALANCE })
    let response
    try {
      response = await get({
        url: `${API_URL_LIVE}/companies/${id}/balances?currency=${currency}`
      })
      dispatch({
        type: GET_BALANCE_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: GET_BALANCE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const getPlatformBalance = ({ currency }) => {
  return async dispatch => {
    dispatch({ type: GET_PLATFORM_BALANCE })
    let response
    try {
      response = await get({
        url: `${API_URL_LIVE}/companies/platform/balances?currency=${currency}`
      })
      dispatch({
        type: GET_PLATFORM_BALANCE_SUCCESS,
        payload: _.get(response, 'data')
      })
    } catch (error) {
      dispatch({
        type: GET_PLATFORM_BALANCE_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const getPlatformCurrencies = () => {
  return async dispatch => {
    dispatch({ type: GET_PLATFORM_CURRENCIES })
    let response
    try {
      response = await get({ url: `${API_URL_LIVE}/currencies/active` })
      dispatch({
        type: GET_PLATFORM_CURRENCIES_SUCCESS,
        payload: _.get(response, 'data.currencies')
      })
    } catch (error) {
      dispatch({
        type: GET_PLATFORM_CURRENCIES_FAIL,
        payload: _.get(error, 'response.data.errors')
      })
    }
  }
}

export const resetCurrentCurrency = () => {
  return async dispatch => {
    dispatch({
      type: RESET_CURRENT_CURRENCY,
      payload: ''
    })
  }
}
