import { encodeFilter } from "../../filter/EncodeFilter"
import { get, post } from "../../helpers"
import _ from 'lodash'
import { getLocaleDateFormat } from "../../../globals/getLocaleDateFormat"

export const FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS = 'FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS'
export const FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_SUCCESS = 'FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_SUCCESS'
export const FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_FAIL = 'FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_FAIL'

export const fetchAllocationAvailableTransactions = ({ currency }) => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS })
        let response
        let filtersAux = getState().filter.allocations
        delete filtersAux.pagination
        // let payment = getState().payments.payment
        // changeFilter({
        //     type: 'filterBy',
        //     name: 'positive',
        //     page: 'allocations',
        //     value: payment && payment.amount && payment.amount > 0 ? 1 : 0,
        //     operator: '='
        // })
        let filterParams = encodeFilter(filtersAux)
        let url = `/allocations/available-transactions${filterParams}`
        try {
            response = await get({ url })
            let data = prepareUnallocatedCollection({ collection: _.get(response, 'data'), currency, isLocked: false })
            dispatch({
                type: FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_SUCCESS,
                // payload: _.get(response, 'data'),
                payload: data,
            })
            setAllocationField({
                field: 'availableTransactionsAux',
                value: JSON.parse(JSON.stringify(data))
            })
        } catch (error) {
            dispatch({
                type: FETCH_ALLOCATIONS_AVAILABLE_TRANSACTIONS_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const ADD_ALLOCATION = 'ADD_ALLOCATION'
export const ADD_ALLOCATION_SUCCESS = 'ADD_ALLOCATION_SUCCESS'
export const ADD_ALLOCATION_FAIL = 'ADD_ALLOCATION_FAIL'

export const addAllocation = ({ data }) => {
    return async dispatch => {
        dispatch({ type: ADD_ALLOCATION })
        let response
        try {
            response = await post({ url: `/allocations`, data })
            dispatch({
                type: ADD_ALLOCATION_SUCCESS,
                payload: _.get(response, 'data'),
            })
        } catch (error) {
            dispatch({
                type: ADD_ALLOCATION_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

export const SET_ALLOCATION_FIELD = 'SET_ALLOCATION_FIELD'

export const setAllocationField = ({ field, value }) => {
    return async (dispatch) => {
        dispatch({
            type: SET_ALLOCATION_FIELD,
            payload: {
                field, value
            }
        })
    }
}

export const RETURN_INITIAL_STATE = 'RETURN_INITIAL_STATE'

export const returnInitialState = () => {
    return async (dispatch) => {
        dispatch({
            type: RETURN_INITIAL_STATE
        })
    }
}


export const FETCH_ALLOCATIONS_FOR_TRANSACTIONS = 'FETCH_ALLOCATIONS_FOR_TRANSACTIONS'
export const FETCH_ALLOCATIONS_FOR_TRANSACTIONS_SUCCESS = 'FETCH_ALLOCATIONS_FOR_TRANSACTIONS_SUCCESS'
export const FETCH_ALLOCATIONS_FOR_TRANSACTIONS_FAIL = 'FETCH_ALLOCATIONS_FOR_TRANSACTIONS_FAIL'

export const fetchAllocationsForTransactions = ({ currency, type, id }) => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_ALLOCATIONS_FOR_TRANSACTIONS })
        let response
        // let filterParams = encodeFilter(getState().filter.allocations)
        // let url = `/allocations/for-transactions${filterParams}`
        let filter = `?&type=${type}&id=${id}`
        let url = `/allocations/for-transaction${filter}`
        try {
            response = await get({ url })
            let data = prepareAllocatedCollection({ collection: _.get(response, 'data'), currency, isLocked: true, id: id })
            await dispatch(setAllocationField({
                field: 'allocatedTransactionsAux',
                value: JSON.parse(JSON.stringify(data))
            }))
            dispatch({
                type: FETCH_ALLOCATIONS_FOR_TRANSACTIONS_SUCCESS,
                // payload: _.get(response, 'data'),
                payload: data,
            })
        } catch (error) {
            dispatch({
                type: FETCH_ALLOCATIONS_FOR_TRANSACTIONS_FAIL,
                payload: _.get(error, 'response.data.errors')
            })
            throw error
        }
    }
}

const prepareUnallocatedCollection = ({ collection, currency, isLocked }) => {
    let data = []
    if (collection && collection.length > 0) {
        collection.forEach(item => {
            if (item.remainingAllocationAmount) {
                let obj = {
                    // type: item.type,
                    type: item.type === 'deposit' ? "safe deposit" : item.type,
                    amount: Math.abs(item.remainingAllocationAmount),
                    allocatedAmount: item.remainingAllocationAmount,
                    amountAux: item.remainingAllocationAmount,
                    currency: currency,
                    identifier: item.series
                        ? `${item.series} ${item.clientNumber ? _.padStart(item.clientNumber, 3, '0') : ''}`
                        : `No. ${item.paymentNumber}`,
                    id: item.id,
                    date: getLocaleDateFormat(item.created_at),
                    isLocked: isLocked
                }
                data.push(obj)
            }
        })
    }

    data.sort(function (a, b) {
        if (a.date < b.date) { return -1; }
        if (a.date > b.date) { return 1; }
        return 0;
    })

    return data
}

const prepareAllocatedCollection = ({ collection, currency, isLocked, id }) => {
    let data = []
    if (collection && collection.length > 0) {
        collection.forEach(item => {
            let itemType = '', identifier = ''
            if (id !== item.fromId) {
                itemType = item.fromType
                identifier = item.fromType && item.fromType !== 'payment' ?
                    `${item.fromTransaction.series}   ${_.padStart(item.fromTransaction.clientNumber, 3, '0')}` :
                    `No. ${item.fromTransaction.paymentNumber}`
            } else {
                itemType = item.toType
                identifier = item.toType && item.toType !== 'payment' ?
                    `${item.toTransaction.series}   ${_.padStart(item.toTransaction.clientNumber, 3, '0')}` :
                    `No. ${item.toTransaction.paymentNumber}`
            }

            let obj = {
                // type: item.toType,
                type: item.toType === 'deposit' ? 'safe deposit' : itemType,
                amount: Math.abs(item.allocatedAmount),
                allocatedAmount: item.allocatedAmount,
                amountAux: item.allocatedAmount,
                currency: currency,
                identifier: identifier,
                id: item.id,
                date: getLocaleDateFormat(item.created_at),
                isLocked: isLocked
            }
            data.push(obj)
        })
    }

    data.sort(function (a, b) {
        if (a.date < b.date) { return -1; }
        if (a.date > b.date) { return 1; }
        return 0;
    })

    return data
}