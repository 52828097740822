import {
  ADD_NEW_TERMINAL,
  ADD_NEW_TERMINAL_SUCCESS,
  ADD_NEW_TERMINAL_FAIL,
  DELETE_TERMINAL,
  DELETE_TERMINAL_SUCCESS,
  DELETE_TERMINAL_FAIL,
  EDIT_TERMINAL,
  EDIT_TERMINAL_SUCCESS,
  EDIT_TERMINAL_FAIL,
  FETCH_TERMINALS,
  FETCH_TERMINALS_SUCCESS,
  FETCH_TERMINALS_FAIL,
  FETCH_SINGLE_TERMINAL,
  FETCH_SINGLE_TERMINAL_FAIL,
  FETCH_SINGLE_TERMINAL_SUCCESS,
  FETCH_PHONE_PROVIDERS,
  FETCH_PHONE_PROVIDERS_SUCCESS,
  FETCH_PHONE_PROVIDERS_FAIL,
  CLEAR_TERMINAL_FORM_FIELDS,
  APPEND_TERMINALS,
  APPEND_TERMINALS_FAIL,
  APPEND_TERMINALS_SUCCESS,
  FETCH_TERMINAL_TYPES,
  FETCH_TERMINAL_TYPES_FAIL,
  FETCH_TERMINAL_TYPES_SUCCESS,
  FETCH_CURRENT_VERSIONS,
  FETCH_CURRENT_VERSIONS_SUCCESS,
  FETCH_CURRENT_VERSIONS_FAIL
} from './TerminalActions'
import { FETCH_COUNTIES_TERMINAL } from '../locations/LocationActions'

const INITIAL_STATE = {
  terminals: [],
  activeTerminal: {},
  error: {},
  loading: false,
  activeState: [],
  editTerminal: {},
  pagination: {
    pageCount: 0,
    currentPage: 1
  },
  serverError: {},
  phoneProviders: [],
  filterCounties: [],
  types: [],
  loadingTypes: false,
  installedApkVersions: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TERMINAL_TYPES:
      return { ...state, loadingTypes: true }
    case FETCH_TERMINAL_TYPES_FAIL:
      return { ...state, loadingTypes: false }
    case FETCH_TERMINAL_TYPES_SUCCESS:
      return { ...state, loadingTypes: false, types: action.payload }
    case FETCH_COUNTIES_TERMINAL:
      return { ...state, filterCounties: action.payload }
    case CLEAR_TERMINAL_FORM_FIELDS:
      return { ...state, serverError: {}, activeTerminal: {} }
    // PHONE PROVIDERS
    case FETCH_PHONE_PROVIDERS:
      return { ...state, loading: false }
    case FETCH_PHONE_PROVIDERS_SUCCESS:
      return { ...state, phoneProviders: action.payload, loading: false }
    case FETCH_PHONE_PROVIDERS_FAIL:
      return { ...state, error: action.payload, loading: false }

    // EDIT TERMINAL
    case EDIT_TERMINAL:
      return { ...state, loading: true, serverError: {} }
    case EDIT_TERMINAL_SUCCESS:
      return { ...state, loading: false }
    case EDIT_TERMINAL_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    // DELETE TERMINAL
    case DELETE_TERMINAL:
      return { ...state, loading: true }
    case DELETE_TERMINAL_SUCCESS:
      const newTerminals = state.terminals.filter(
        terminal => terminal.id !== action.payload
      )
      return { ...state, terminals: newTerminals, loading: false }
    case DELETE_TERMINAL_FAIL:
      return { ...state, loading: false }

    // FETCH ALL TERMINALS
    case FETCH_TERMINALS:
      return { ...state, loading: true, activeTerminal: {} }
    case FETCH_TERMINALS_SUCCESS:
      return {
        ...state,
        terminals: action.payload.terminals,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount
        },
        loading: false
      }
    case FETCH_TERMINALS_FAIL:
      return { ...state, error: action.payload, loading: false }
    case APPEND_TERMINALS:
      return { ...state, loading: true, activeTerminal: {} }
    case APPEND_TERMINALS_SUCCESS:
      return {
        ...state,
        terminals: [...state.terminals, ...action.payload.terminals],
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount
        },
        loading: false
      }
    case APPEND_TERMINALS_FAIL:
      return { ...state, error: action.payload, loading: false }

    // ADD NEW TERMINAL
    case ADD_NEW_TERMINAL:
      return { ...state, loading: true }
    case ADD_NEW_TERMINAL_SUCCESS:
      return { ...state, name: '', serverError: {}, loading: false }
    case ADD_NEW_TERMINAL_FAIL:
      return { ...state, serverError: action.payload, loading: false }

    // FETCH TERMINAL BY ID
    case FETCH_SINGLE_TERMINAL:
      return {
        ...state,
        loading: true,
        error: {},
        activeTerminal: {},
        // phoneProviders: []
      }
    case FETCH_SINGLE_TERMINAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        activeTerminal: {}
      }
    case FETCH_SINGLE_TERMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        activeTerminal: action.payload,
        error: {}
      }

    case FETCH_CURRENT_VERSIONS:
      return {
        ...state,
        loading: true,
        error: {},
        installedApkVersions: []
      }
    case FETCH_CURRENT_VERSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        installedApkVersions: action.payload
      }
    case FETCH_CURRENT_VERSIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        installedApkVersions: []
      }

    default:
      return state
  }
}
