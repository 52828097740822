// NPM and others

import styled from 'styled-components'

// Common components

import {secondaryColor, colorWhite} from '../StyleConstants'

const EditButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${secondaryColor};
    color: ${secondaryColor};
    padding: 10px 15px;
    border-radius: 8px;

    span {
        padding-right: 20px;
    }

    &:hover{
        background-color: ${secondaryColor};
        color: ${colorWhite};
    }
`

export {EditButton}
