import { routerReducer as routing } from 'react-router-redux'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import LoginReducer from '../modules/auth/login/LoginReducer'
import CurrenciesReducer from '../modules/currency/currencyReducer'
import FilterReducer from '../modules/filter/FilterReducer'
import ReleasesReducer from '../modules/pages/apk/ReleasesReducer'
import CashierReducer from '../modules/pages/cashiers/CashierReducer'
import DashboardReducer from '../modules/pages/dashboard/DashboardReducer'
import LocationsReducer from '../modules/pages/locations/LocationReducer'
import LogsReducer from '../modules/pages/logs/LogsReducer'
import MerchantsReducer from '../modules/pages/merchants/MerchantsReducer'
import PlatformSettingsReducer from '../modules/pages/platformSettings/PlatformSettingsReducer'
import ReceiptReducer from '../modules/pages/receipts/ReceiptReducer'
import SafeDepositsReducer from '../modules/pages/safeDeposits/SafeDepositsReducer'
import SettingsReducer from '../modules/pages/settings/SettingsReducer'
import SettlementsReducer from '../modules/pages/settlements/SettlementsReducer'
import TerminalReducer from '../modules/pages/terminals/TerminalReducer'
import BundlesReducer from '../modules/pages/transactionBundles/BundlesReducer'
import TransactionReducer from '../modules/pages/transactions/TransactionReducer'
import UsersReducer from '../modules/pages/users/UsersReducer'
import VendorReducer from '../modules/pages/vendors/VendorReducer'
import VirtualTerminalReducer from '../modules/pages/virtualTerminals/VirtualTerminalReducer'
import BalanceReducer from '../modules/partials/balances/BalancesReducer'
import CommissionsReducer from '../modules/partials/commissions/CommissionsReducer'
import RoleReducer from '../modules/roles/RoleReducer'
import LanguageReducer from '../modules/language/languageReducer'
import PaymentsReducer from '../modules/pages/payments/PaymentsReducer'
import AllocationsReducer from '../modules/pages/allocations/AllocationReducer'
import IpReducer from '../modules/pages/ip/IpReducer'

// Reducers
const filterConfig = {
  key: 'filter',
  storage
}

const filterPersist = persistReducer(filterConfig, FilterReducer)

const rootReducer = combineReducers({
  routing,
  login: LoginReducer,
  form: formReducer,
  merchants: MerchantsReducer,
  cashiers: CashierReducer,
  locations: LocationsReducer,
  terminals: TerminalReducer,
  virtualTerminals: VirtualTerminalReducer,
  transactions: TransactionReducer,
  vendors: VendorReducer,
  roles: RoleReducer,
  settlements: SettlementsReducer,
  safeDeposits: SafeDepositsReducer,
  settings: SettingsReducer,
  filter: filterPersist,
  bundles: BundlesReducer,
  users: UsersReducer,
  dashboard: DashboardReducer,
  logs: LogsReducer,
  release: ReleasesReducer,
  currencies: CurrenciesReducer,
  receipt: ReceiptReducer,
  balances: BalanceReducer,
  commissions: CommissionsReducer,
  platformSettings: PlatformSettingsReducer,
  languages: LanguageReducer,
  payments: PaymentsReducer,
  allocations: AllocationsReducer,
  ips: IpReducer
})

export default rootReducer
