import {
  ADD_SAFE_DEPOSIT,
  ADD_SAFE_DEPOSIT_FAIL,
  ADD_SAFE_DEPOSIT_SUCCESS,
  GET_CURRENCIES,
  GET_CURRENCIES_FAIL,
  GET_CURRENCIES_SUCCESS,
  GET_SAFE_DEPOSIT,
  GET_SAFE_DEPOSIT_FAIL,
  GET_SAFE_DEPOSIT_SUCCESS,
  GET_SAFE_DEPOSITS_LIST,
  GET_SAFE_DEPOSITS_LIST_FAIL,
  GET_SAFE_DEPOSITS_LIST_SUCCESS,
  UPDATE_SAFE_DEPOSIT,
  UPDATE_SAFE_DEPOSIT_FAIL,
  UPDATE_SAFE_DEPOSIT_SUCCESS,
  GET_STATUS_INTERVALS_SUCCESS,
  GET_STATUS_INTERVALS_FAIL,
  CHECK_ELIGIBILITY,
  CHECK_ELIGIBILITY_SUCCESS,
  CHECK_ELIGIBILITY_FAIL
} from './SafeDepositsActions'

import {
  DOWNLOAD_SAFE_DEPOSIT_FILE,
  DOWNLOAD_SAFE_DEPOSIT_FILE_SUCCESS,
  DOWNLOAD_SAFE_DEPOSIT_FILE_FAIL
} from '../../helpers/downloadFiles'

const INITIAL_STATE = {
  safeDepositsList: [],
  currencies: [],
  loadingCurrencies: false,
  activeSafeDeposit: {},
  loading: false,
  pagination: {},
  error: {},
  activeSafeDepositLoading: false,
  eligibility: {},
  errorEligibility: {},
  loadingEligibility: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SAFE_DEPOSITS_LIST:
      return { ...state, loading: true }
    case GET_SAFE_DEPOSITS_LIST_SUCCESS:
      return {
        ...state,
        safeDepositsList: action.payload.safeDeposits,
        pagination: {
          ...state.pagination,
          pageCount: action.payload.pagination.pageCount
        },
        loading: false
      }
    case GET_SAFE_DEPOSITS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case GET_SAFE_DEPOSIT:
      return {
        ...state,
        loading: true,
        activeSafeDepositLoading: true
      }
    case GET_SAFE_DEPOSIT_SUCCESS:
      return {
        ...state,
        activeSafeDeposit: action.payload,
        error: {},
        loading: false,
        activeSafeDepositLoading: false
      }
    case GET_SAFE_DEPOSIT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        activeSafeDepositLoading: false
      }
    case ADD_SAFE_DEPOSIT:
      return {
        ...state,
        loading: true
      }
    case ADD_SAFE_DEPOSIT_SUCCESS:
      return {
        ...state,
        activeSafeDeposit: action.payload,
        error: {},
        loading: false
      }
    case ADD_SAFE_DEPOSIT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case UPDATE_SAFE_DEPOSIT:
      return {
        ...state,
        loading: true
      }
    case UPDATE_SAFE_DEPOSIT_SUCCESS:
      return {
        ...state,
        activeSafeDeposit: action.payload,
        error: {},
        loading: false
      }
    case UPDATE_SAFE_DEPOSIT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_CURRENCIES: {
      return {
        ...state,
        currencies: [],
        loadingCurrencies: true
      }
    }
    case GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        loadingCurrencies: false,
        currencies: action.payload
      }
    }
    case GET_CURRENCIES_FAIL: {
      return {
        ...state,
        loadingCurrencies: false
      }
    }

    case GET_STATUS_INTERVALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        statusIntervals: action.payload,
      }
    }
    case GET_STATUS_INTERVALS_FAIL: {
      return {
        ...state,
        loading: false,
        statusIntervals: action.payload
      }
    }

    case DOWNLOAD_SAFE_DEPOSIT_FILE: {
      return {
        ...state,
        loading: true,
      }
    }
    case DOWNLOAD_SAFE_DEPOSIT_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }
    case DOWNLOAD_SAFE_DEPOSIT_FILE_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }

    case CHECK_ELIGIBILITY:
      return { ...state, eligibility: {}, loadingEligibility: true }
    case CHECK_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        eligibility: action.payload,
        errorEligibility: {},
        loadingEligibility: false
      }
    case CHECK_ELIGIBILITY_FAIL:
      return {
        ...state,
        errorEligibility: action.payload,
        loadingEligibility: false
      }

    default:
      return state
  }
}
