import {
  FETCH_LANGUAGES,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_FAIL,
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAIL
} from './languageActions'

const INITIAL_STATE = {
  languagesList: [],
  errors: {},
  loading: false,
  selectedLanguage: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LANGUAGES:
      return { ...state, loading: true }
    case FETCH_LANGUAGES_SUCCESS:
      return { ...state, languagesList: action.payload, errors: {}, loading: false }
    case FETCH_LANGUAGES_FAIL:
      return { ...state, languagesList: [], errors: action.payload, loading: false }

    case CHANGE_LANGUAGE:
      return { ...state, loading: true }
    case CHANGE_LANGUAGE_SUCCESS:
      return { ...state, selectedLanguage: action.payload, loading: false }
    case CHANGE_LANGUAGE_FAIL:
      return { ...state, errors: action.payload, loading: false }

    default:
      return state
  }
}
