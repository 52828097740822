import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import Flag from 'react-flagkit'
import Select from 'react-select'
import styled from 'styled-components'
import { changeLanguage } from '../../../modules/language/languageActions'
import { getMySwalSuccessfullPopup, getMySwalErrorPopup } from '../../../globals/getMySwalPopups'

const SelectWrap = styled.div`
outline:none;
box-shadow:none;
position:relative;
cursor: pointer;

  .Select-clear-zone{
    display:none;
  }
  .Select-arrow-zone{
    position: absolute;
    top: 12px;
    right: 8px;
  }
  .Select-arrow{
    border:none;
    font-size: 9px !important;
    &:before{
      font-family:'Linearicons-Free';
      content:"\\E874";

    }
  }
  .Select-control{
    cursor: pointer;
    position: relative;
    border:none;
    border-radius:0;
    box-shadow:none !important;
    box-sizing:border-box;

    .Select-value {
        display: flex;
        align-items: center;

        .Select-value-label{
          display: flex;
          align-items: center;

          ${({ loading }) => {
    let style = ''
    if (loading === true) style = 'cursor: wait;';
    return style
  }}
        }
      }
    }
  }
  @media all and (max-width: 1023px){
    font-size: 14px;
    .Select-arrow-zone{
      top: 12px;
      width: 15px;
      font-size: 10px;
    }
  }
  }
`

class LanguageSelect extends Component {

  onValueChange = async e => {
    const { changeLanguage } = this.props
    this.props.setActiveLanguage(e.value)

    try {
      await changeLanguage(e.id)
      getMySwalSuccessfullPopup()
    } catch (e) {
      getMySwalErrorPopup()
    }
  };

  render() {
    const { languagesList, languages, loading } = this.props
    let options = []
    languages && languagesList && languages.forEach(item => {
      let index = item.code === 'US'
        ? languagesList.find(language => language.code === 'EN')
        : languagesList.find(language => language.code === item.code)

      if (index !== undefined) {
        options.push({
          label: index.code === 'EN'
            ? <Flag key={index} size={18} country={'US'} />
            : <Flag key={index} size={18} country={index.code} />,
          value: index.code === 'EN' ? 'US' : index.code,
          id: index.id
        })
      }
      return
    })

    return (
      <Translate>
        {activeLanguage => (
          <SelectWrap style={{ width: '60px' }} loading={loading}>
            <Select
              searchable={false}
              options={options}
              onChange={this.onValueChange}
              value={
                activeLanguage.activeLanguage &&
                  activeLanguage.activeLanguage.code
                  ? activeLanguage.activeLanguage.code
                  : 'RO'
              }
              selectedValue={
                activeLanguage.activeLanguage &&
                  activeLanguage.activeLanguage.code
                  ? activeLanguage.activeLanguage.code
                  : 'RO'
              }
            />
          </SelectWrap>
        )}
      </Translate>
    )
  }
}

export default withLocalize(connect(
  state => ({
    loading: state.languages.loading,
    languagesList: state.languages.languagesList.languages
  }),
  { changeLanguage }
)(LanguageSelect))
