import { MODIFY_SETTINGS, MODIFY_SETTINGS_FAILURE, MODIFY_SETTINGS_SUCCESS } from './SettingsActions'

const initialState = {
  settings: {
    firstName: '',
    lastName: '',
    email: ''
  },
  error: {},
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MODIFY_SETTINGS:
      return {
        ...state,
        loading: true,
        error: {}
      }
    case MODIFY_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    }
    case MODIFY_SETTINGS_SUCCESS: {
      return {
        settings: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email
        },
        ...state,
        loading: false,
        error: {}
      }
    }
    default:
      return state
  }
}
