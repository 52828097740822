import React, { Component } from "react";
import Select from "react-select";
import styled from "styled-components";
import { primaryColor, secondaryFont } from "./common/StyleConstants";
const Wrapper = styled.div`
  .Select-control {
    border-radius: 20px;
    min-height: 30px;
    height: 30px;
    margin-bottom: -2px;
  }
  .Select-multi-value-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
  }
  .Select-placeholder {
    font-size: 12px;
    margin-bottom: -2px;
  }
  .Select-arrow-zone {
    top: 2px;
  }
  .Select-clear-zone {
    top: 3px;
  }
  .Select-input {
    input {
      margin-top: 3px;
    }
  }
  .Select-value {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: white;
    color: ${primaryColor};
    border-radius: 50px;
    border-color: ${primaryColor};
    display: none;
  }
  .Select-value-label {
    font-family: ${secondaryFont};
    font-size: 10px;
  }
  .Select-value-icon {
    display: none;
  }
`;

export default class LocationFilterSelect extends Component {
  render() {
    const { options, value, onChange, placeholder } = this.props;
    return (
      <Wrapper>
        <Select
          style={{ minWidth: 100 }}
          required={false}
          placeholder={placeholder}
          value={value}
          multi
          onChange={onChange}
          options={options}
          closeMenuOnSelect={false}
        />
      </Wrapper>
    );
  }
}
