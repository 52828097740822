import Joi from 'joi'

require('dotenv').config()

const envVarsSchema = Joi.object({
  REACT_APP_ENV: Joi.string()
    .allow(['development', 'production', 'test', 'provision'])
    .default('development'),
  REACT_APP_API_URL: Joi.string()
    .required().description('API URL'),
  REACT_APP_TOKEN_NAME: Joi.string()
    .required().description('Access token name'),
  REACT_APP_TOPPAY_PERMISSIONS: Joi.string()
    .required().description('Permissions name'),
  REACT_APP_CLIENT_ID: Joi.string().required()
    .description('Client id'),
  REACT_APP_GRANT_TYPE_PASSWORD: Joi.string().required()
    .description('Grant type for password'),
  REACT_APP_GRANT_TYPE_REFRESH_TOKEN: Joi.string().required()
    .description('Grant type for refresh token'),
  REACT_APP_CLIENT_SECRET: Joi.string().required()
    .description('Client secret'),
  REACT_APP_GOOGLE_MAPS_KEY: Joi.string().required()
    .description('Google maps key')
}).unknown()
  .required()

const {error, value: envVars} = Joi.validate(process.env, envVarsSchema)
if (error) {
  throw new Error(`Config validation error: ${error.message}`)
}

const config = {
  env: envVars.REACT_APP_ENV,
  API_URL: envVars.REACT_APP_API_URL,
  toppay_permissions: envVars.REACT_APP_TOPPAY_PERMISSIONS,
  token_name: envVars.REACT_APP_TOKEN_NAME,
  client_id: envVars.REACT_APP_CLIENT_ID,
  grant_type_password: envVars.REACT_APP_GRANT_TYPE_PASSWORD,
  grant_type_refresh_token: envVars.REACT_APP_GRANT_TYPE_REFRESH_TOKEN,
  client_secret: envVars.REACT_APP_CLIENT_SECRET,
  google_maps_key: envVars.REACT_APP_GOOGLE_MAPS_KEY
}
export default config
