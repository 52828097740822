import React, { Component } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: inline-block;
  user-select: none;
  label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
    border-radius: 10px;
    background-color: #eee;
    transition: background-color 0.2s ease;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0;
      width: 24px;
      background-color: #b2b2b2;
      border-radius: 50%;
      box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.18);
      transition: all 0.2s ease;
    }
  }
  input {
    opacity: 0;
    width: 0;
    margin: 0;
    &:checked ~ label {
      background-color: #135AFC33;
      &:before {
        left: calc(100% - 24px);
        background-color: #135AFC;
      }
    }
  }
  @media all and (max-width: 1023px) {
    label {
      width: 40px;
      height: 16px;
      &:before {
        width: 20px;
      }
    }
    input {
      &:checked ~ label {
        &:before {
          left: calc(100% - 20px);
        }
      }
    }
  }
`

class SwitchItem extends Component {
  render () {
    const {htmlFor, input, name} = this.props
    return (
      <Wrapper>
        <input
          type='checkbox'
          {...input}
          checked={input.value}
          id={htmlFor || name || 'checkbox'}
        />
        <label htmlFor={htmlFor || name || 'checkbox'} />
      </Wrapper>
    )
  }
}

SwitchItem.propTypes = {}

export { SwitchItem }
