import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import {textColor} from '../common/StyleConstants'

const Wrapper = styled.div`
  color:${textColor};
  cursor:default;
`
const Price = (props) => (
  <Wrapper className={props.className}>
    {props.children} Lei
  </Wrapper>
)
Price.propTypes = {
  children: PropTypes.node
}

export {Price}
