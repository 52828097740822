import { primaryColor } from '../common/StyleConstants'
import styled from 'styled-components'

const Location = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${primaryColor};
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
`

export { Location }
