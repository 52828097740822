import config from '../config'

const removeToken = () => {
  try {
    localStorage.removeItem(config.token_name)
    localStorage.removeItem('savedTokenForTabSync')
    return true
  } catch (e) {
    return false
  }
}

export { removeToken }
